import { JoePairABI, Pair } from '@traderjoe-xyz/sdk'
import { Token } from '@traderjoe-xyz/sdk-core'
import { POOL_SHARE_PRECISION } from 'constants/pool'
import useChainId from 'hooks/useChainId'
import { getAddress } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UsePairUserBalanceProps {
  address?: string
  pair?: Pair
}

export interface UsePairUserBalanceData {
  balance: bigint | undefined
  decimals: number | undefined
  pooledToken0: number
  pooledToken1: number
  poolShare: number
  token0: Token
  token1: Token
  totalSupply: bigint | undefined
}

const usePairUserBalance = ({
  address,
  pair
}: UsePairUserBalanceProps): {
  isLoading: boolean
  refetchUserBalance: () => void
  data?: UsePairUserBalanceData
} => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  const contract = {
    abi: JoePairABI,
    address: address ? getAddress(address) : undefined,
    chainId
  }
  const { data, isLoading, refetch } = useReadContracts({
    contracts: [
      {
        ...contract,
        args: account ? [account] : undefined,
        functionName: 'balanceOf'
      },
      { ...contract, functionName: 'totalSupply' },
      { ...contract, functionName: 'decimals' }
    ],
    query: { enabled: !!account && !!address && !!pair }
  })

  const balance = data?.[0].result as bigint | undefined
  const totalSupply = data?.[1].result as bigint | undefined
  const decimals = data?.[2].result as number | undefined

  const poolShare =
    totalSupply && balance && totalSupply > 0
      ? Number((balance * BigInt(POOL_SHARE_PRECISION)) / totalSupply) /
        POOL_SHARE_PRECISION
      : 0

  const scaledPoolShare = BigInt(Math.trunc(poolShare * POOL_SHARE_PRECISION))

  const pooledToken0 =
    pair && poolShare
      ? parseFloat(
          pair.reserve0
            .multiply(scaledPoolShare)
            .divide(BigInt(POOL_SHARE_PRECISION))
            .toSignificant(7)
        )
      : 0
  const pooledToken1 =
    pair && poolShare
      ? parseFloat(
          pair.reserve1
            .multiply(scaledPoolShare)
            .divide(BigInt(POOL_SHARE_PRECISION))
            .toSignificant(7)
        )
      : 0

  return {
    data: pair
      ? {
          balance,
          decimals,
          poolShare,
          pooledToken0,
          pooledToken1,
          token0: pair.token0,
          token1: pair.token1,
          totalSupply
        }
      : undefined,
    isLoading,
    refetchUserBalance: refetch
  }
}

export default usePairUserBalance
