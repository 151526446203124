import { Divider, Flex, HStack, Spinner, Text, VStack } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { TokenInfo, TokenInfoTag } from 'types/tokensList'

interface CurrencyRowProps {
  isLoadingBalance: boolean
  onClick: () => void
  onTagClick: (tag: TokenInfoTag) => void
  shouldShowTags: boolean
  tokenInfo: TokenInfo
  balance?: string
  subtitle?: JSX.Element
  usdValue?: string
}

const CurrencyRow = ({
  balance,
  isLoadingBalance,
  onClick,
  onTagClick,
  shouldShowTags,
  subtitle,
  tokenInfo,
  usdValue
}: CurrencyRowProps) => {
  return (
    <Flex
      data-cy={`token-item-${tokenInfo.symbol}`}
      w="full"
      align="center"
      justify="space-between"
      cursor="pointer"
      px={4}
      py={3}
      borderRadius="xl"
      _hover={{ bg: 'hover' }}
      onClick={onClick}
    >
      <HStack spacing={4}>
        <CurrencyLogo
          address={tokenInfo.address}
          symbol={tokenInfo.symbol}
          boxSize={8}
        />
        <VStack align="flex-start" spacing={0.5}>
          <HStack>
            <Text fontWeight="semibold">{tokenInfo.symbol}</Text>
            <Text fontSize="sm">{tokenInfo.name}</Text>
          </HStack>
          {subtitle ? (
            subtitle
          ) : shouldShowTags ? (
            <HStack>
              {tokenInfo.tags.map((tag, i) => (
                <Flex key={i} align="center">
                  <Text
                    fontSize="sm"
                    color="textSecondary"
                    _hover={{
                      color: 'textPrimary',
                      textDecoration: 'underline'
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      onTagClick(tag)
                    }}
                  >
                    {tag}
                  </Text>
                  {i < tokenInfo.tags.length - 1 ? (
                    <Divider orientation="vertical" h="16px" ml={2} />
                  ) : null}
                </Flex>
              ))}
            </HStack>
          ) : null}
        </VStack>
      </HStack>
      {isLoadingBalance ? (
        <Spinner size="sm" color="textSecondary" />
      ) : balance ? (
        <VStack align="flex-end" spacing={0}>
          <Text fontSize="sm" fontWeight="semibold">
            {balance}
          </Text>
          {usdValue ? (
            <Text fontSize="sm" textColor="textSecondary">
              {usdValue}
            </Text>
          ) : null}
        </VStack>
      ) : null}
    </Flex>
  )
}

export default CurrencyRow
