import { Currency, TokenAmount } from '@traderjoe-xyz/sdk-core'
import useDebounce from 'hooks/useDebounce'
import { useMemo } from 'react'
import { TradeBestPath } from 'types/trade'
import { compareTradeRoute } from 'utils/swap'

import useGetOnChainBestTrade from './useGetOnChainBestTrade'
import useGetRoutesFromBarn from './useGetRoutesFromBarn'

interface UseGetTradeRoutesProps {
  isExactIn: boolean
  inputCurrency?: Currency
  outputCurrency?: Currency
  refetchInterval?: number
  typedTokenAmount?: TokenAmount
}

const useGetTradeRoutes = ({
  inputCurrency,
  isExactIn,
  outputCurrency,
  refetchInterval,
  typedTokenAmount
}: UseGetTradeRoutesProps): {
  data: TradeBestPath[]
  isFetching: boolean
  lastFetchTime: number
  refetch: () => void
} => {
  const debouncedAmount = useDebounce(typedTokenAmount, 250)

  const barnResult = useGetRoutesFromBarn({
    amount: debouncedAmount?.raw?.toString(),
    currencyIn: inputCurrency,
    currencyOut: outputCurrency,
    isExactIn,
    refetchInterval
  })

  const onChainResult = useGetOnChainBestTrade({
    inputCurrency,
    isExactIn,
    outputCurrency,
    refetchInterval,
    typedTokenAmount: debouncedAmount
  })

  const refetch = () => {
    barnResult.refetch()
    onChainResult.refetch()
  }

  const trades = useMemo(() => {
    const results = [...barnResult.data, onChainResult.data]

    let _trades: TradeBestPath[] = []
    for (const result of results) {
      if (
        result &&
        !_trades.some((trade) => compareTradeRoute(trade, result))
      ) {
        _trades = [..._trades, result]
      }
    }

    return _trades
      .sort((a, b) => Number(b.amountOut.value - a.amountOut.value))
      .slice(0, 3)
  }, [barnResult.data, onChainResult.data])

  if (barnResult.isFetching || onChainResult.isFetching) {
    return {
      data: [],
      isFetching: true,
      lastFetchTime: barnResult.lastFetchTime,
      refetch
    }
  }

  if (!barnResult.data && !onChainResult.data) {
    return {
      data: [],
      isFetching: false,
      lastFetchTime: barnResult.lastFetchTime,
      refetch
    }
  }

  return {
    data: trades,
    isFetching: false,
    lastFetchTime: barnResult.lastFetchTime,
    refetch
  }
}

export default useGetTradeRoutes
