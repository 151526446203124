import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  Hide,
  HStack,
  IconButton,
  Text
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ConnectButton as RainbowKitConnectButton } from '@rainbow-me/rainbowkit'
import AppChainSelector from 'components/AppChainSelector'
import MobileAccountMenu from 'components/MobileAccountMenu'
import WalletMenu from 'components/WalletMenu'
import PopoverSettingsMenu from 'components/WalletMenu/SettingScreen/PopoverSettingsScreen'
import { supportedChains } from 'constants/chains'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import useDisableConnectOptionsIfTermsNotAccepted from 'hooks/useDisableConnectOptionsIfTermsNotAccepted'
import useSwitchChain from 'hooks/useSwitchChain'
import React from 'react'
import { useSelectedChainId } from 'state/settings/hooks'
import { MySettingsIcon, WalletIcon, WarningIcon } from 'theme/icons'
import { e2eTestsConfig, isRunningE2ETests } from 'utils/e2e'
import { useConnect } from 'wagmi'

interface ConnectButtonProps {
  chainSelector?: boolean
}

const ConnectButton = ({ chainSelector }: ConnectButtonProps) => {
  const { connect, connectors } = useConnect()
  const { switchChain } = useSwitchChain()
  const { selectedChainId } = useSelectedChainId()

  const selectedChain = supportedChains.filter(
    (chain) => chain.id === selectedChainId
  )[0]
  const chainInUrlParam = useChainFromUrlParam()
  const targetChain = chainInUrlParam ?? selectedChain

  useDisableConnectOptionsIfTermsNotAccepted()

  return (
    <RainbowKitConnectButton.Custom>
      {({
        account,

        chain,
        mounted,
        openConnectModal
      }) => {
        const ready = mounted
        const connected = ready && !!account && !!chain

        const onConnectWalletClick = isRunningE2ETests
          ? () =>
              connect({
                chainId: e2eTestsConfig.chain.id,
                connector: connectors[0]
              })
          : openConnectModal

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none'
              }
            })}
          >
            {(() => {
              return (
                <Flex alignItems="center" justifyContent="center" gap={4}>
                  <Hide below="lg">
                    <AppChainSelector />
                  </Hide>
                  {chainSelector ? null : !connected ? (
                    <>
                      <Hide below="lg">
                        <HStack spacing={0} flexShrink={0}>
                          <Button
                            data-cy="connect-wallet-button"
                            variant="outline"
                            h="48px"
                            leftIcon={<WalletIcon w="20px" />}
                            onClick={onConnectWalletClick}
                            borderRight={0}
                            borderRadius={0}
                            borderLeftRadius="2xl"
                          >
                            <Text>
                              <Trans>Connect Wallet</Trans>
                            </Text>
                          </Button>
                          <Divider orientation="vertical" h="24px" />
                          <PopoverSettingsMenu
                            popoverTrigger={
                              <IconButton
                                aria-label="settings menu"
                                icon={<MySettingsIcon />}
                                variant="outline"
                                boxSize={12}
                                borderLeft={0}
                                borderRadius={0}
                                borderRightRadius="2xl"
                              />
                            }
                          />
                        </HStack>
                      </Hide>
                      <Hide above="lg">
                        <IconButton
                          bg="transparent"
                          aria-label="connect-wallet"
                          w="full"
                          onClick={onConnectWalletClick}
                        >
                          <Flex alignItems="center" pos="relative">
                            <WalletIcon w="21px" h="18px" />
                            <Box
                              pos="absolute"
                              top={-1}
                              boxSize={2}
                              right={-3}
                              borderRadius="full"
                              bgColor="avaxRed"
                            />
                          </Flex>
                        </IconButton>
                      </Hide>
                    </>
                  ) : chain.id !== targetChain.id ? (
                    <>
                      <Hide below="md">
                        <Button
                          flexShrink={0}
                          data-cy="wrong-network-button"
                          colorScheme="red"
                          h="48px"
                          rightIcon={<ChevronDownIcon />}
                          onClick={() =>
                            switchChain({ chainId: targetChain.id })
                          }
                        >
                          <Trans>Wrong network</Trans>
                        </Button>
                      </Hide>
                      <Hide above="md">
                        <Button
                          data-cy="wrong-network-button"
                          colorScheme="yellow"
                          onClick={() =>
                            switchChain({ chainId: targetChain.id })
                          }
                        >
                          <WarningIcon boxSize={5} fill="white" />
                        </Button>
                      </Hide>
                    </>
                  ) : (
                    <>
                      <Hide below="lg">
                        <WalletMenu
                          accountDisplayName={account.displayName}
                          walletAddress={account.address}
                        />
                      </Hide>
                      <Hide above="lg">
                        <MobileAccountMenu walletAddress={account.address} />
                      </Hide>
                    </>
                  )}
                </Flex>
              )
            })()}
          </div>
        )
      }}
    </RainbowKitConnectButton.Custom>
  )
}

export default ConnectButton
