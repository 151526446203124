import {
  Box,
  Flex,
  SimpleGrid,
  SkeletonText,
  Text,
  VStack
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { VaultABI } from '@traderjoe-xyz/sdk-v2'
import WarningOutlined from 'components/WarningOutlined'
import useChainId from 'hooks/useChainId'
import useDebounce from 'hooks/useDebounce'
import React from 'react'
import { formattedNum } from 'utils/format'
import { formatUnits, getAddress } from 'viem'
import { useReadContract } from 'wagmi'

interface VaultAddLiquidityWarningProps {
  amount0: bigint
  amount1: bigint
  currency0: Currency
  currency1: Currency
  vaultAddress: string
}

const VaultAddLiquidityWarning = ({
  amount0,
  amount1,
  currency0,
  currency1,
  vaultAddress
}: VaultAddLiquidityWarningProps) => {
  const chainId = useChainId()

  const debouncedAmount0 = useDebounce(amount0, 500)
  const debouncedAmount1 = useDebounce(amount1, 500)

  const vaultContract = {
    abi: VaultABI,
    address: getAddress(vaultAddress)
  } as const

  const { data: previewSharesData, isLoading: isLoadingPreviewShares } =
    useReadContract({
      ...vaultContract,
      args: [debouncedAmount0, debouncedAmount1],
      chainId,
      functionName: 'previewShares'
    })
  const shares = previewSharesData?.[0]

  const { data: previewAmountsData, isLoading: isLoadingPreviewAmounts } =
    useReadContract({
      ...vaultContract,
      args: shares ? [shares] : undefined,
      chainId,
      functionName: 'previewAmounts',
      query: { enabled: !!shares }
    })
  const previewAmount0 = previewAmountsData?.[0]
  const previewAmount1 = previewAmountsData?.[1]

  const aptTokenDecimals = 12
  const fmtSharesAmount = shares
    ? formattedNum(formatUnits(shares, aptTokenDecimals))
    : ''
  const fmtAmount0 = previewAmount0
    ? formattedNum(formatUnits(previewAmount0, currency0.decimals))
    : ''
  const fmtAmount1 = previewAmount1
    ? formattedNum(formatUnits(previewAmount1, currency1.decimals))
    : ''

  return (
    <Flex data-cy="vault-add-liquidity-warning" flexDir="column" gap={4}>
      <SimpleGrid columns={2} gap={4}>
        <Text fontSize="sm">You will receive:</Text>
        {fmtSharesAmount && !isLoadingPreviewShares ? (
          <Text>
            <Box as="b">{fmtSharesAmount}</Box> APT
          </Text>
        ) : (
          <SkeletonText w="100px" noOfLines={1} skeletonHeight={6} />
        )}
        <Text fontSize="sm">Current underlying value:</Text>
        <VStack align="flex-start" spacing={0.5}>
          {fmtAmount0 && !isLoadingPreviewAmounts ? (
            <Text>
              <Box as="b">{fmtAmount0}</Box> {currency0.symbol}
            </Text>
          ) : (
            <SkeletonText w="100px" noOfLines={1} skeletonHeight={6} />
          )}
          {fmtAmount1 && !isLoadingPreviewAmounts ? (
            <Text>
              <Box as="b">{fmtAmount1}</Box> {currency1.symbol}
            </Text>
          ) : (
            <SkeletonText w="100px" noOfLines={1} skeletonHeight={6} />
          )}
        </VStack>
      </SimpleGrid>
      <WarningOutlined
        message={t`APT Tokens may be redeemed for a proportional share of underlying pool assets. The underlying tokens of your APT tokens may change over time`}
      />
    </Flex>
  )
}

export default VaultAddLiquidityWarning
