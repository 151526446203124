import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Pair, ROUTER_ADDRESS } from '@traderjoe-xyz/sdk'
import { Currency } from '@traderjoe-xyz/sdk-core'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CurrencyInput from 'components/CurrencyInput'
import MaxButton from 'components/MaxButton'
import WarningOutlined from 'components/WarningOutlined'
import Web3Button from 'components/Web3Button'
import useAddLiquidity from 'hooks/pool/v1/useAddLiquidity'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import { UseCurrencyInputAmountResult } from 'hooks/useCurrencyInputAmount'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, { useCallback } from 'react'
import { tryParseAmount } from 'utils/swap'
import { getCurrencyAddress, wrappedCurrency } from 'utils/wrappedCurrency'

interface AddLiquidityPanelProps {
  amountInput0: UseCurrencyInputAmountResult
  amountInput1: UseCurrencyInputAmountResult
  isPoolLowLiquidity: boolean
  onToken0Select: (currency: Currency) => void
  onToken1Select: (currency: Currency) => void
  loadingText?: string
  onAddLiquiditySuccess?: () => void
  pair?: Pair
  token0?: Currency
  token1?: Currency
}

const AddLiquidityPanel = ({
  amountInput0,
  amountInput1,
  isPoolLowLiquidity,
  loadingText,
  onAddLiquiditySuccess,
  onToken0Select,
  onToken1Select,
  pair,
  token0,
  token1
}: AddLiquidityPanelProps) => {
  const chainId = useChainId()

  const tokenAddress0 = getCurrencyAddress(token0)
  const tokenAddress1 = getCurrencyAddress(token1)

  const {
    amount: amount0,
    amountBN: amount0BN,
    setAmount: setAmount0
  } = amountInput0

  const {
    amount: amount1,
    amountBN: amount1BN,
    setAmount: setAmount1
  } = amountInput1

  const {
    approvalType: approvalType0,
    approve: approveToken0,
    isApproved: isToken0Approved,
    isApproving: isApprovingToken0,
    reset: resetApproveToken0,
    setApprovalType: setApprovalType0
  } = useApproveSpenderIfNeeded({
    amount: amount0BN,
    spender: ROUTER_ADDRESS[chainId],
    token: tokenAddress0,
    tokenSymbol: token0?.symbol
  })

  const {
    approvalType: approvalType1,
    approve: approveToken1,
    isApproved: isToken1Approved,
    isApproving: isApprovingToken1,
    reset: resetApproveToken1,
    setApprovalType: setApprovalType1
  } = useApproveSpenderIfNeeded({
    amount: amount1BN,
    spender: ROUTER_ADDRESS[chainId],
    token: tokenAddress1,
    tokenSymbol: token1?.symbol
  })

  const { data: balance0, refetch: refetchTokenBalance0 } = useTokenBalance({
    token: tokenAddress0
  })
  const debouncedRefetchBalance0 = debounce(() => refetchTokenBalance0(), 4000)
  const { data: balance1, refetch: refetchTokenBalance1 } = useTokenBalance({
    token: tokenAddress1
  })
  const debouncedRefetchBalance1 = debounce(() => refetchTokenBalance1(), 4000)

  const { addLiquidity, isLoading: isAddingLiquidity } = useAddLiquidity({
    amountADesired: amount0BN,
    amountBDesired: amount1BN,
    enabled:
      (isToken0Approved === true || token0?.isNative === true) &&
      (isToken1Approved === true || token1?.isNative === true),
    onSuccess: () => {
      debouncedRefetchBalance0()
      debouncedRefetchBalance1()
      setAmount0('')
      setAmount1('')
      resetApproveToken0()
      resetApproveToken1()
      onAddLiquiditySuccess?.()
    },
    tokenA: token0,
    tokenB: token1
  })

  const isExceedingBalance0 =
    token0 && balance0 ? Number(balance0.formatted) < Number(amount0) : false
  const isExceedingBalance1 =
    token1 && balance1 ? Number(balance1.formatted) < Number(amount1) : false

  const isAddLiquidityDisabled = isExceedingBalance0 || isExceedingBalance1

  const onValue0Change = useCallback(
    (value: string) => {
      setAmount0(value)
      if (!pair) return
      const wrappedCurrency0 = wrappedCurrency(token0, chainId)
      const amount = tryParseAmount(value, wrappedCurrency0)
      const price =
        wrappedCurrency0 && amount
          ? pair.priceOf(wrappedCurrency0).quote(amount)
          : undefined
      setAmount1(price?.toSignificant(6) ?? '')
    },
    [chainId, pair, setAmount0, setAmount1, token0]
  )

  const onValue1Change = useCallback(
    (value: string) => {
      setAmount1(value)
      if (!pair) return
      const wrappedCurrency1 = wrappedCurrency(token1, chainId)
      const amount = tryParseAmount(value, wrappedCurrency1)
      const price =
        wrappedCurrency1 && amount
          ? pair.priceOf(wrappedCurrency1).quote(amount)
          : undefined
      setAmount0(price?.toSignificant(6) ?? '')
    },
    [chainId, pair, setAmount0, setAmount1, token1]
  )

  return (
    <VStack align="flex-start" spacing={4}>
      <CurrencyInput
        data-cy="add-liquidity-input-0"
        currency={token0}
        currencyAddress={tokenAddress0}
        value={amount0}
        onValueChange={onValue0Change}
        balance={balance0?.formatted}
        error={
          isExceedingBalance0 ? t`Not enough ${token0?.symbol}` : undefined
        }
        onCurrencyChange={onToken0Select}
        rightElement={
          balance0 ? (
            <MaxButton
              borderRadius={12}
              balance={balance0.formatted}
              onClick={() => onValue0Change(balance0.formatted)}
            />
          ) : undefined
        }
      />
      <CurrencyInput
        data-cy="add-liquidity-input-1"
        currency={token1}
        currencyAddress={tokenAddress1}
        value={amount1}
        onValueChange={onValue1Change}
        balance={balance1?.formatted}
        error={
          isExceedingBalance1 ? t`Not enough ${token1?.symbol}` : undefined
        }
        onCurrencyChange={onToken1Select}
        rightElement={
          balance1 ? (
            <MaxButton
              borderRadius={12}
              balance={balance1.formatted}
              onClick={() => onValue1Change(balance1.formatted)}
            />
          ) : undefined
        }
      />

      {isPoolLowLiquidity && (
        <WarningOutlined
          message={t`This pool has low liquidity and may not have accurate market pricing. Please check prices carefully before adding liquidity`}
        />
      )}

      {!isToken0Approved &&
      approveToken0 &&
      token0 &&
      !isAddLiquidityDisabled ? (
        <ApproveTokenButton
          data-cy="add-liquidity-approve-button-0"
          amount={amount0}
          currencySymbol={token0.symbol}
          approvalType={approvalType0}
          onApprovalTypeSelect={setApprovalType0}
          isLoading={isApprovingToken0}
          onClick={() => approveToken0()}
        >
          {t`Approve ${token0.symbol}`}
        </ApproveTokenButton>
      ) : null}

      {!isToken1Approved &&
      approveToken1 &&
      token1 &&
      !isAddLiquidityDisabled ? (
        <ApproveTokenButton
          data-cy="add-liquidity-approve-button-1"
          amount={amount1}
          currencySymbol={token1.symbol}
          approvalType={approvalType1}
          onApprovalTypeSelect={setApprovalType1}
          isLoading={isApprovingToken1}
          onClick={() => approveToken1()}
        >
          {t`Approve ${token1.symbol}`}
        </ApproveTokenButton>
      ) : null}

      <Web3Button
        data-cy="add-liquidity-button"
        variant="primary"
        colorScheme={isPoolLowLiquidity ? 'red' : 'accent'}
        size="xl"
        w="full"
        isDisabled={isAddLiquidityDisabled || !addLiquidity}
        isLoading={!!loadingText || isAddingLiquidity}
        loadingText={loadingText || t`Adding liquidity`}
        onClick={() => addLiquidity?.()}
      >
        {isPoolLowLiquidity ? (
          <Trans>Add Liquidity Anyway</Trans>
        ) : (
          <Trans>Add Liquidity</Trans>
        )}
      </Web3Button>
    </VStack>
  )
}

export default AddLiquidityPanel
