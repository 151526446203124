import { Box } from '@chakra-ui/react'
import React from 'react'
import { formattedNum } from 'utils/format'

interface Payload {
  coordinate: number
  isShow: boolean
  offset: number
  tickCoord: number
  value: string
}

interface YTickProps {
  isUsd?: boolean
  payload?: Payload
  places?: number
  x?: number
  y?: number
}

const AnalyticsChartYTick = ({
  isUsd,
  payload,
  places = 2,
  x,
  y
}: YTickProps) => (
  <g transform={`translate(${x ?? 0 + 5},${y})`}>
    <Box as="text" textAnchor="end" fontSize="xs" fill="textSecondary">
      {formattedNum(payload?.value ?? 0, { places, usd: !!isUsd })}
    </Box>
  </g>
)

export default AnalyticsChartYTick
