import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Token } from '@traderjoe-xyz/sdk-core'
import {
  LB_FACTORY_V21_ADDRESS,
  LB_FACTORY_V22_ADDRESS,
  LBFactoryV21ABI
} from '@traderjoe-xyz/sdk-v2'
import { getBinIdFromPrice } from 'utils/bin'
import { getAddress, zeroAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

import useChainId from './useChainId'
import useTransactionToast from './useTransactionToast'
import useWaitForTransactionReceipt from './useWaitForTransactionReceipt'

interface UseCreateLBPairProps {
  activePrice?: number
  binStep?: number
  enabled?: boolean
  tokenX?: Token
  tokenY?: Token
}

const useCreateLBPair = ({
  activePrice,
  binStep,
  enabled = true,
  tokenX,
  tokenY
}: UseCreateLBPairProps) => {
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const chainId = useChainId()
  const { chain } = useAccount()
  const walletChainId = chain?.id

  const activeBinId =
    activePrice && binStep && tokenX && tokenY && activePrice > 0
      ? getBinIdFromPrice(activePrice.toString(), binStep, tokenX, tokenY)
      : undefined

  const factoryAddress =
    LB_FACTORY_V22_ADDRESS[chainId] !== zeroAddress
      ? LB_FACTORY_V22_ADDRESS[chainId]
      : LB_FACTORY_V21_ADDRESS[chainId]

  const { data: config, error: prepareConfigError } = useSimulateContract({
    abi: LBFactoryV21ABI,
    address: getAddress(factoryAddress),
    args:
      tokenX && tokenY && binStep && activeBinId
        ? [
            getAddress(tokenX.address),
            getAddress(tokenY.address),
            activeBinId,
            binStep
          ]
        : undefined,
    chainId,
    functionName: 'createLBPair',
    query: {
      enabled:
        !!tokenX &&
        !!tokenY &&
        !!binStep &&
        !!activeBinId &&
        enabled &&
        chainId === walletChainId,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    reset,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Create ${tokenX?.symbol} / ${tokenY?.symbol} pair (${binStep}bps)`
        addRecentTransaction({
          description,
          hash
        })
        addTransactionToast({ description, hash })
      }
    }
  })

  const createLBPair = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransactionReceipt({
      hash
    })

  return {
    createLBPair,
    isCreatingLBPair: isPending || isWaitingForTransaction,
    isLBPairCreated: receipt?.status === 'success',
    prepareConfigError,
    resetCreateLBPair: reset
  }
}

export default useCreateLBPair
