import {
  Box,
  CloseButton,
  Flex,
  Heading,
  Image,
  Link,
  Text
} from '@chakra-ui/react'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface Props {
  description: string
  onClose: () => void
  title: string
  image?: string | null
}

const Announcement = ({
  description,
  image,
  onClose,
  title
}: Props): JSX.Element => {
  return (
    <Box
      pos="relative"
      maxWidth="400px"
      bg="bgCard"
      borderColor="border"
      borderWidth={1}
      borderRadius="xl"
      p={4}
    >
      <CloseButton
        cursor="pointer"
        pos="absolute"
        size="md"
        top={1}
        right={1}
        onClick={onClose}
        onTouchEnd={onClose}
      />
      <Flex flexDir="row" alignItems="center" gap={4}>
        {image && (
          <Image
            src={image}
            boxSize="60px"
            objectFit="cover"
            borderRadius="lg"
          />
        )}
        <Flex flexDir="column">
          <Heading size="md">{title}</Heading>
          <ReactMarkdown
            components={{
              a: (props) => <Link isExternal color="textPrimary" {...props} />,
              p: (props) => (
                <Text color="textSecondary" fontSize="sm" {...props} />
              )
            }}
          >
            {description}
          </ReactMarkdown>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Announcement
