import {
  Box,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { JOE_ADDRESS } from '@traderjoe-xyz/sdk'
import Web3Button from 'components/Web3Button'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import React, { useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { claimVeJoe } from 'utils/measure'
import { formatUnits } from 'viem'

interface VeJoeClaimPanelProps {
  claim: () => void
  isClaiming: boolean
  isLoading: boolean
  userBalance: string
  veJoeBalance: string
  veJoePerDay: string
  pendingVeJoeBalance?: bigint
}

const VeJoeClaimPanel = ({
  claim,
  isClaiming,
  isLoading,
  pendingVeJoeBalance,
  userBalance,
  veJoeBalance,
  veJoePerDay
}: VeJoeClaimPanelProps) => {
  // get joe and reward tokens
  const chainId = useChainId()
  const joeTokenAddress = JOE_ADDRESS[chainId]
  const {
    isLoading: isLoadingCurrencies,
    tokens: [joeToken]
  } = useSdkCurrencies({
    addresses: [joeTokenAddress]
  })

  const fmtPendingVeJoeBalance = useMemo(() => {
    return pendingVeJoeBalance
      ? formattedNum(formatUnits(pendingVeJoeBalance, 18), { places: 2 })
      : '0'
  }, [pendingVeJoeBalance])

  return (
    <Box
      w="full"
      p={{ base: 4, md: 8 }}
      bg="bgCard"
      borderRadius="2xl"
      border="1px"
      borderColor="border"
    >
      <SimpleGrid gap={2} columns={2}>
        <Heading size="sm">
          <Trans>Staked JOE</Trans>
        </Heading>
        <Heading size="sm">
          <Trans>veJOE Balance</Trans>
        </Heading>
        <HStack align="flex-start">
          <VStack spacing={1} align="flex-start">
            <Skeleton isLoaded={!isLoading && !isLoadingCurrencies}>
              <Flex align="center" gap={2}>
                <Text fontSize="lg">
                  {userBalance} {joeToken?.symbol}
                </Text>
              </Flex>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Text fontSize="sm" color="textSecondary">
                {veJoePerDay}
              </Text>
            </Skeleton>
          </VStack>
        </HStack>
        <HStack align="flex-start">
          <VStack spacing={1} align="flex-start">
            <Skeleton isLoaded={!isLoading}>
              <Text fontSize="lg">
                <Trans>{veJoeBalance} veJOE</Trans>
              </Text>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Text fontSize="sm" color="textSecondary">
                <Trans>{fmtPendingVeJoeBalance} pending veJOE</Trans>
              </Text>
            </Skeleton>
          </VStack>
        </HStack>
      </SimpleGrid>
      <Web3Button
        colorScheme="accent"
        variant="primary"
        size="xl"
        w="full"
        marginTop={6}
        isDisabled={
          !claim || !pendingVeJoeBalance || pendingVeJoeBalance === BigInt(0)
        }
        isLoading={isClaiming}
        loadingText={t`Claiming veJOE`}
        onClick={() => {
          claim()
          claimVeJoe()
        }}
      >
        <Trans>Claim</Trans>
      </Web3Button>
    </Box>
  )
}

export default VeJoeClaimPanel
