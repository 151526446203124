import { Flex, Grid, HStack, Skeleton, Text } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MemeFarm } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

interface MemeFarmMobileRowProps {
  farm: MemeFarm
  isLoadingUserPositions: boolean
  memeTokenSupplyStakedPercentage?: number
}

const MemeFarmMobileRow = ({
  farm,
  isLoadingUserPositions,
  memeTokenSupplyStakedPercentage
}: MemeFarmMobileRowProps): JSX.Element => {
  const navigate = useNavigate()
  const href = `/avalanche/stek/${farm.memeToken.tokenAddress}`

  const columns = [
    {
      isLoading: false,
      title: 'Total Staked:',
      value: farm.staked
        ? `${formattedNum(farm.staked, { places: 2 })} ${farm.memeToken.symbol}`
        : undefined
    },
    {
      isLoading: false,
      title: 'Total Supply Staked:',
      value: memeTokenSupplyStakedPercentage
        ? `${formattedNum(memeTokenSupplyStakedPercentage, { places: 2 })}%`
        : '--'
    },
    {
      isLoading: isLoadingUserPositions,
      title: 'Your Position:',
      value: farm.userStaked
        ? `${formattedNum(farm.userStaked, { places: 2 })} ${
            farm.memeToken.symbol
          }`
        : '--'
    }
  ]

  return (
    <Flex
      aria-label={`${farm.memeToken.symbol} meme farm`}
      flexDir="column"
      bg="bgCard"
      borderColor="border"
      borderWidth={1}
      borderRadius="xl"
      p={4}
      cursor="pointer"
      w="full"
      onClick={() => navigate(href)}
    >
      <HStack spacing={2}>
        <CurrencyLogo
          address={farm.memeToken.tokenAddress}
          symbol={farm.memeToken.symbol}
          boxSize={7}
        />
        <Text fontWeight="semibold" fontSize="md">
          {farm.memeToken.symbol}
        </Text>
      </HStack>

      <Grid templateColumns="minmax(0, 1fr) minmax(0, 1.5fr)" rowGap={1} mt={4}>
        {columns.map((column, i) => (
          <React.Fragment key={i}>
            <Text color="textSecondary" fontSize="sm" mb={1}>
              {column.title}
            </Text>
            <Skeleton isLoaded={!column.isLoading}>
              <Text color="textPrimary" fontSize="sm" fontWeight="semibold">
                {column.value}
              </Text>
            </Skeleton>
          </React.Fragment>
        ))}
      </Grid>
    </Flex>
  )
}

export default MemeFarmMobileRow
