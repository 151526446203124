import {
  Button,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { shortenAddress } from 'utils/addresses'

import {
  useAddLiquidityDispatchContext,
  useAddLiquidityStateContext,
  usePoolDetailV2Context
} from './state'

const ImportedLiquiditySection = () => {
  const { balance0, balance1 } = usePoolDetailV2Context()
  const { importedDistribution } = useAddLiquidityStateContext()
  const dispatchAddLiquidityAction = useAddLiquidityDispatchContext()

  const [sliderValue, setSliderValue] = React.useState<number>(
    (importedDistribution?.percentOfFundsToUse ?? 0) * 100 || 0
  )

  if (!importedDistribution) {
    return null
  }

  return (
    <Flex
      data-cy="imported-liquidity-section"
      bg="bgPrimary"
      w="full"
      borderRadius="2xl"
      p={4}
      flexDir="column"
      gap={4}
    >
      <Flex align="center" gap={2} justify="space-between">
        <Text>
          Copy of {shortenAddress(importedDistribution.account)}&#39;s
          distribution. You can adjust the amounts of tokens to use:
        </Text>
        <Button
          flexShrink={0}
          data-cy="cancel-copy-distribution-button"
          onClick={() => {
            dispatchAddLiquidityAction({
              type: 'unset_imported_distribution'
            })
          }}
        >
          Cancel Copy
        </Button>
      </Flex>
      <Slider
        value={sliderValue}
        onChange={setSliderValue}
        onChangeEnd={(val) => {
          dispatchAddLiquidityAction({
            payload: {
              balance0,
              balance1,
              distribution: {
                ...importedDistribution,
                percentOfFundsToUse: val / 100
              }
            },
            type: 'set_imported_distribution'
          })
        }}
        aria-label="percent-of-funds-to-add"
        min={1}
        max={100}
        step={1}
        w="full"
        mb={4}
      >
        <SliderMark
          value={sliderValue}
          textAlign="center"
          mt={3.5}
          ml={-6}
          w={12}
        >
          {sliderValue.toFixed(0)}%
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Flex>
  )
}

export default ImportedLiquiditySection
