import { SimpleGrid, Skeleton, Text, useToken } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import AnalyticsChart from 'components/AnalyticsChart'
import usePoolHomeAnalytics from 'hooks/analytics/usePoolHomeAnalytics'
import React, { useMemo } from 'react'
import { formattedNum } from 'utils/format'

import { PoolVersion } from './constants'

interface PoolMainAnalyticsProps {
  poolVersion: PoolVersion
}

const PoolMainAnalytics = ({ poolVersion }: PoolMainAnalyticsProps) => {
  const [graphPurple, graphGreen] = useToken('colors', [
    'graphPurpleDark',
    'graphGreenDark'
  ])

  const { v1Analytics, v2Analytics } = usePoolHomeAnalytics()

  const analytics = useMemo(() => {
    switch (poolVersion) {
      case PoolVersion.V1:
        return v1Analytics
      case PoolVersion.V2:
        return v2Analytics
    }
  }, [v1Analytics, v2Analytics, poolVersion])

  const fmtTVL = formattedNum(analytics.tvl, { usd: true })
  const fmtVolume24H = formattedNum(analytics.volume24h, { usd: true })

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} px={4} pt={4} pb={6} gap={8}>
      <AnalyticsChart
        id="tvl"
        header={
          <Skeleton isLoaded={!analytics.isLoadingTVL}>
            <Text fontWeight="semibold" fontSize="2xl" minW="100px">
              {fmtTVL}
            </Text>
          </Skeleton>
        }
        tagline={t`TVL (Total Value Locked)`}
        tooltipTitle={t`TVL`}
        data={analytics.data.liquidity}
        fill={graphPurple}
        stroke="#894bf6"
        h={{ base: '80px', md: '200px' }}
      />
      <AnalyticsChart
        id="volume"
        header={
          <Skeleton isLoaded={!analytics.isLoadingVolume24H}>
            <Text fontWeight="semibold" fontSize="2xl" minW="100px">
              {fmtVolume24H}
            </Text>
          </Skeleton>
        }
        tagline={t`Volume (1D)`}
        tooltipTitle={t`Volume`}
        data={analytics.data.volume}
        fill={graphGreen}
        stroke="#5ec5a2"
        h={{ base: '80px', md: '200px' }}
      />
    </SimpleGrid>
  )
}

export default PoolMainAnalytics
