export const MEME_FARMS = [
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-16',
    memeToken: {
      decimals: 18,
      name: 'MAJIN',
      symbol: 'MAJIN',
      tokenAddress: '0x73f49d00ac1b520f94d11248808c40774aeb0802'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-9',
    memeToken: {
      decimals: 18,
      name: 'KINGSHIT',
      symbol: 'KINGSHIT',
      tokenAddress: '0x0cd741f007b417088ca7f4392e8d6b49b4f7a975'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-11',
    memeToken: {
      decimals: 18,
      name: 'Landwolf',
      symbol: 'WOLF',
      tokenAddress: '0x4f94b8aef08c92fefe416af073f1df1e284438ec'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-4',
    memeToken: {
      decimals: 18,
      name: 'MeowCat',
      symbol: 'MEOW',
      tokenAddress: '0x8ad25b0083c9879942a64f00f20a70d3278f6187'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-1',
    memeToken: {
      decimals: 18,
      name: 'Gecko Inu',
      symbol: 'GEC',
      tokenAddress: '0xe8385cecb013561b69beb63ff59f4d10734881f3'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-7',
    memeToken: {
      decimals: 18,
      name: 'Kimbo',
      symbol: 'KIMBO',
      tokenAddress: '0x184ff13b3ebcb25be44e860163a5d8391dd568c1'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-5',
    memeToken: {
      decimals: 18,
      name: 'NumberGoUpTech',
      symbol: 'TECH',
      tokenAddress: '0x5ac04b69bde6f67c0bd5d6ba6fd5d816548b066a'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-2',
    memeToken: {
      decimals: 18,
      name: 'AVAX HAS NO CHILL',
      symbol: 'NOCHILL',
      tokenAddress: '0xacfb898cff266e53278cc0124fc2c7c94c8cb9a5'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-12',
    memeToken: {
      decimals: 18,
      name: 'Kovin Segnocchi',
      symbol: 'KOVIN',
      tokenAddress: '0x694200a68b18232916353250955be220e88c5cbb'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-13',
    memeToken: {
      decimals: 18,
      name: 'Cat wif Hands',
      symbol: 'CATWIF',
      tokenAddress: '0x3377aca4c0bfd021be6bd762b5f594975e77f9cf'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-15',
    memeToken: {
      decimals: 9,
      name: 'Big Red',
      symbol: '$Td',
      tokenAddress: '0x87bbfc9dcb66caa8ce7582a3f17b60a25cd8a248'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-3',
    memeToken: {
      decimals: 18,
      name: 'Husky',
      symbol: 'HUSKY',
      tokenAddress: '0x65378b697853568da9ff8eab60c13e1ee9f4a654'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-14',
    memeToken: {
      decimals: 18,
      name: 'DOGwifCROCS',
      symbol: 'DWC',
      tokenAddress: '0xc06e17bdc3f008f4ce08d27d364416079289e729'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-10',
    memeToken: {
      decimals: 18,
      name: 'KINGSHIT X',
      symbol: 'KINGSHIT.x',
      tokenAddress: '0x05b0def5c00ba371683d7035934bcf82b737c364'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-8',
    memeToken: {
      decimals: 18,
      name: 'KONG',
      symbol: 'KONG',
      tokenAddress: '0xebb5d4959b2fba6318fbda7d03cd44ae771fc999'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-0',
    memeToken: {
      decimals: 18,
      name: 'COQINU',
      symbol: 'COQ',
      tokenAddress: '0x420fca0121dc28039145009570975747295f2329'
    }
  },
  {
    memeFarmId: '0xb22c6d1c2897b950fc1040913c0d84d788f24df2-6',
    memeToken: {
      decimals: 6,
      name: 'Meat',
      symbol: 'MEAT',
      tokenAddress: '0x47c3118ad183712acd42648e9e522e13690f29a0'
    }
  }
]
