import { Heading, HStack, IconButton, Skeleton, Text } from '@chakra-ui/react'
import { Currency } from '@traderjoe-xyz/sdk-core'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import React from 'react'
import { ArrowLeftRightIcon } from 'theme/icons'
import { formatPrice } from 'utils/format'

interface Props {
  inputCurrency: Currency
  isLoading: boolean
  latestPrice: number | undefined
  outputCurrency: Currency
  toggleInverted: () => void
}

const CandleChartHeader = ({
  inputCurrency,
  isLoading,
  latestPrice,
  outputCurrency,
  toggleInverted
}: Props): JSX.Element => {
  return (
    <HStack justify="space-between" p={2} w="full">
      <HStack fontWeight="semibold">
        <DoubleCurrencyLogo
          address0={inputCurrency.isToken ? inputCurrency.address : undefined}
          address1={outputCurrency.isToken ? outputCurrency.address : undefined}
          symbol0={inputCurrency.symbol}
          symbol1={outputCurrency.symbol}
          ml="-8px"
          boxSize={6}
        />
        <Text fontSize="lg" data-cy="candle-chart-currency-x">
          {inputCurrency.symbol}
        </Text>
        <Text fontSize="lg">/</Text>
        <Text fontSize="lg" data-cy="candle-chart-currency-y">
          {outputCurrency.symbol}
        </Text>
        <IconButton
          variant="ghost"
          aria-label="toggle candlechart"
          size="sm"
          icon={<ArrowLeftRightIcon boxSize="14px" fill="textPrimary" />}
          onClick={toggleInverted}
        />
      </HStack>

      <Skeleton isLoaded={!isLoading} minH={'35px'} minW="100px">
        <Heading size="lg" textAlign="right">
          {latestPrice ? formatPrice(latestPrice) : '--'}
        </Heading>
      </Skeleton>
    </HStack>
  )
}

export default CandleChartHeader
