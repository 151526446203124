import {
  Box,
  Center,
  Divider,
  Grid,
  Heading,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { ChainId, Token } from '@traderjoe-xyz/sdk-core'
import { MEME_COIN_FARM_REWARDER_ADDRESS } from 'constants/memeFarms'
import useGetMemeFarmByTokenAddress from 'hooks/memeFarm/useGetMemeFarmByTokenAddress'
import useLBPairRewards from 'hooks/pool/v2/useLBPairRewards'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import useChainId from 'hooks/useChainId'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import debounce from 'lodash.debounce'
import ClaimRewards from 'pages/PoolDetailV2/ClaimRewards'
import PoolRewardsBalance from 'pages/VaultDetail/APTPoolRewards/PoolRewardsBalance'
import PoolRewardsWithdraw from 'pages/VaultDetail/APTPoolRewards/PoolRewardsWithdraw'
import VaultInfoStats from 'pages/VaultDetail/VaultInfoStats'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { getFarmAndPoolId } from 'utils/farm'
import { formattedNum } from 'utils/format'

import MemeFarmDetailHeader from './MemeFarmDetailHeader'
import MemeFarmRewardsHistory from './MemeFarmRewardsHistory'

const MemeFarmDetail = () => {
  const chainId = useChainId()
  const { tokenAddress } = useParams()

  // get farm data
  const { data: memeFarm, refetchUserStakedAmount } =
    useGetMemeFarmByTokenAddress({
      tokenAddress
    })
  const debouncedRefetchUserFarmBalance = debounce(
    () => refetchUserStakedAmount(),
    2000
  )
  const memeToken = memeFarm?.memeToken

  // get deposited amount
  const userPosition = memeFarm?.userStaked
  const { data: tokenUsdPrices } = useTokenPriceUSD({
    tokens: memeToken ? [memeToken.tokenAddress] : []
  })
  const memeTokenUsdPrice = tokenUsdPrices?.[0]
  const userPositionUsd =
    userPosition && memeTokenUsdPrice
      ? Number(userPosition) * memeTokenUsdPrice
      : undefined

  // claim rewards
  const {
    claimableRewards = [],
    handleClaimRewards,
    isLoading: isRewardLoading,
    rewardsHistoryData
  } = useLBPairRewards({
    isRewarded: true,
    market: memeToken?.tokenAddress,
    rewarderAddress: MEME_COIN_FARM_REWARDER_ADDRESS[chainId]
  })

  if (!memeFarm || !memeToken) {
    return (
      <Center minH="80vh">
        <Text textColor="textSecondary">
          <Trans>Farm not found</Trans>
        </Text>
      </Center>
    )
  }

  const { farmAddress, poolId } = getFarmAndPoolId(memeFarm.memeFarmId)

  const farmToken = new Token(
    chainId,
    memeToken.tokenAddress,
    memeToken.decimals,
    memeToken.symbol,
    memeToken.name
  )

  const refetchFarmBalances = () => {
    debouncedRefetchUserFarmBalance()
  }

  return (
    <Box pt={4} bg="joeLight.400" _dark={{ bg: 'joeDark.600' }}>
      <MemeFarmDetailHeader
        tokenAddress={memeToken.tokenAddress}
        tokenSymbol={memeToken.symbol}
        farmAddress={memeToken.tokenAddress}
      />

      <Box bg="bgPrimary" minH="calc(100vh - 250px)" pb={24}>
        <Grid
          maxW={{ '2xl': '1600px', base: '1400px' }}
          margin="0 auto"
          alignItems="flex-start"
          templateColumns={{
            base: 'minmax(0, 1fr)',
            lg: 'minmax(0, 1fr) 600px'
          }}
          pt={{ base: 4, md: 8 }}
          px={4}
          gap={8}
        >
          <VStack spacing={4}>
            <VStack
              w="full"
              bg="bgCard"
              align="flex-start"
              borderRadius="2xl"
              border="1px"
              borderColor="border"
              p={{ base: 4, md: 8 }}
              spacing={{ base: 4, md: 6 }}
            >
              <Heading size="md">
                <Trans>Information</Trans>
              </Heading>
              <Text whiteSpace="pre-line" fontSize="sm">
                Stake to earn points, the more you stake the more points you
                accrue.
              </Text>
              <Divider />
              <SimpleGrid w="full" columns={{ base: 1, md: 2 }} gap={4}>
                <VaultInfoStats
                  title={t`Total Staked`}
                  value={
                    memeFarm.staked !== undefined
                      ? `${formattedNum(memeFarm.staked)} ${memeToken.symbol}`
                      : '--'
                  }
                />
              </SimpleGrid>
            </VStack>

            {rewardsHistoryData && rewardsHistoryData.length > 0 ? (
              <MemeFarmRewardsHistory rewardsHistoryData={rewardsHistoryData} />
            ) : null}
          </VStack>

          <Center flexDir="column" gap={4}>
            <Box
              w="full"
              bg="bgCard"
              borderRadius="2xl"
              border="1px solid"
              borderColor="border"
              p={{ base: 4, md: 8 }}
            >
              <Tabs
                isFitted
                w="full"
                variant="soft-rounded-outlined-white"
                tabIndex={1}
                defaultIndex={1}
              >
                <TabList w="full">
                  <Tab
                    _selected={{
                      bg: 'joeLight.400',
                      color: 'textPrimary'
                    }}
                  >
                    <Trans>Deposit</Trans>
                  </Tab>
                  <Tab
                    _selected={{
                      bg: 'joeLight.400',
                      color: 'textPrimary'
                    }}
                  >
                    <Trans>Withdraw</Trans>
                  </Tab>
                </TabList>
                <TabPanels pt={4}>
                  <TabPanel p={0}>
                    <Center
                      minH="100px"
                      fontSize="sm"
                      textColor="textSecondary"
                    >
                      This farm is not open for deposit.
                    </Center>
                  </TabPanel>
                  <TabPanel p={0}>
                    <PoolRewardsWithdraw
                      balance={userPosition}
                      currency={farmToken}
                      farmAddress={farmAddress}
                      poolId={poolId}
                      refetchFarmBalances={refetchFarmBalances}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>

            <Box
              bg="bgCard"
              w="full"
              borderRadius="2xl"
              border="1px solid"
              borderColor="border"
            >
              <PoolRewardsBalance
                balance={userPosition || '0'}
                balanceUSD={userPositionUsd}
                currency={farmToken}
              />

              {claimableRewards.length > 0 && <Divider />}

              {claimableRewards.length > 0 && (
                <ClaimRewards
                  claimableRewards={claimableRewards}
                  isLoading={isRewardLoading}
                  handleClaimRewards={handleClaimRewards}
                  poolSymbol={memeToken.symbol}
                />
              )}
            </Box>
          </Center>
        </Grid>
      </Box>
    </Box>
  )
}

const MemeFarmDetailWithRedirect = () => {
  const chain = useChainFromUrlParam()

  if (chain?.id === ChainId.AVALANCHE) {
    return <MemeFarmDetail />
  }

  return <Navigate to="/404" replace />
}

export default MemeFarmDetailWithRedirect
