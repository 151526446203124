import {
  Box,
  Flex,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import Pagination from 'components/Pagination'
import SortableTableHeader from 'components/SortableTableHeader'
import { ZERO_ADDRESS } from 'constants/tokens'
import dayjs from 'dayjs'
import useActiveChain from 'hooks/useActiveChain'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import React, { useCallback, useMemo, useState } from 'react'
import { RewardsHistoryEpoch } from 'types/rewards'
import { formattedNum } from 'utils/format'
import { getFormattedRewards, getTokensMap } from 'utils/rewards'

interface RewardsHistoryTableProps {
  rewardsHistoryData: RewardsHistoryEpoch[] | undefined
}
const RewardsHistoryTable = ({
  rewardsHistoryData
}: RewardsHistoryTableProps) => {
  const pageSize = 5
  const [page, setPage] = useState<number>(1)
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)

  const onHeaderClick = useCallback(
    () => setIsSortDescending((previous) => !previous),
    []
  )

  const sortedRewards = useMemo(() => {
    if (!rewardsHistoryData) {
      return []
    }
    const sortedAsc = rewardsHistoryData.sort((a, b) => a.epoch - b.epoch)
    return isSortDescending ? sortedAsc.reverse() : sortedAsc
  }, [isSortDescending, rewardsHistoryData])

  return (
    <Flex
      overflow="hidden"
      p={{ base: 4, md: 8 }}
      borderRadius="xl"
      borderColor="border"
      borderWidth={1}
      gap={{ base: 4, md: 10 }}
      flexDir="column"
      bg="bgCard"
    >
      <Heading size="md">My Rewards History</Heading>
      {sortedRewards.length === 0 ? (
        <Box py={8}>
          <Text
            fontSize="sm"
            color="textSecondary"
            fontWeight="semibold"
            textAlign="center"
          >
            You don&apos;t have any rewards history in this pool
          </Text>
        </Box>
      ) : (
        <TableContainer m={0}>
          <Table variant="simple" maxWidth="full">
            <Thead>
              <Tr>
                <SortableTableHeader
                  pl={0}
                  name="Epoch"
                  isSortActive={true}
                  isSortDescending={isSortDescending}
                  onClick={onHeaderClick}
                />
                <Th>Vesting</Th>
                <Th>Granted</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedRewards
                .slice((page - 1) * pageSize, page * pageSize)
                .map((rewardData) => (
                  <RewardRow key={rewardData.epoch} rewardData={rewardData} />
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {sortedRewards.length > pageSize ? (
        <Pagination
          selectedPage={page}
          totalPages={Math.ceil(sortedRewards.length / pageSize)}
          onPageChange={setPage}
        />
      ) : null}
    </Flex>
  )
}

const RewardRow = ({ rewardData }: { rewardData: RewardsHistoryEpoch }) => {
  const { nativeCurrency } = useActiveChain()
  const {
    epoch,
    epochEnd,
    epochStart,
    progress,
    rewards: rewardsData
  } = rewardData

  const tokenAddresses = useMemo(
    () => (rewardsData ? rewardsData.map((reward) => reward.tokenAddress) : []),
    [rewardsData]
  )

  const { tokens } = useSdkCurrencies({
    addresses: tokenAddresses.map((address) =>
      address === ZERO_ADDRESS ? nativeCurrency.symbol : address
    )
  })

  const rewards = useMemo(() => {
    if (
      !rewardsData ||
      rewardsData.length === 0 ||
      rewardsData.length !== tokens.length ||
      tokens.length !== tokenAddresses.length
    ) {
      return []
    }

    const tokensMap = getTokensMap(tokenAddresses, tokens)
    return getFormattedRewards(rewardsData, tokensMap)
  }, [tokens, rewardsData, tokenAddresses])

  const formatDate = (timestamp: number) =>
    dayjs.unix(timestamp).format('DD.MM.YYYY hh:mm')

  return (
    <Tr>
      <Td pl={0}>
        <VStack align="left" spacing={0}>
          <Text fontWeight="semibold" fontSize="16px">
            Epoch {epoch}
          </Text>
          <Text fontSize="sm" textColor="textSecondary">{`${formatDate(
            epochStart
          )} - ${formatDate(epochEnd)}`}</Text>
        </VStack>
      </Td>
      <Td
        color={progress < 1 ? 'textPrimary' : 'green.500'}
        fontWeight="normal"
      >
        {`${(progress * 100).toFixed(0)} %`}
      </Td>
      <Td pr={0}>
        <Flex flexWrap="wrap" gap={3}>
          {rewards.map((reward) => (
            <HStack key={reward.tokenAddress} spacing={2}>
              <CurrencyLogo
                address={reward.tokenAddress}
                symbol={reward.token?.symbol}
                boxSize={6}
              />
              <Text fontWeight="semibold" fontSize="16px">
                {`${formattedNum(reward.tokenAmount)} ${reward.token?.symbol}`}
              </Text>
            </HStack>
          ))}
        </Flex>
      </Td>
    </Tr>
  )
}

export default RewardsHistoryTable
