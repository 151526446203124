import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import useEpochRewards from 'hooks/useEpochRewards'
import { Pool as DexbarnPool } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolToPool } from 'utils/poolV2'

interface UsePoolV2Props {
  address?: string
}

const usePoolV2 = ({ address }: UsePoolV2Props) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const fetchPool = useDexbarnGet<DexbarnPool>(`/v1/pools/${chain}/${address}`)
  const result = useQuery<DexbarnPool>({
    enabled: !!address,
    queryFn: () => fetchPool(),
    queryKey: ['PoolV2Query', chain, address],
    staleTime: 10 * (60 * 1000) // 10 mins
  })

  const { epochRewards } = useEpochRewards()

  return {
    ...result,
    data: result.data
      ? convertDexbarnPoolToPool(result.data, epochRewards || [])
      : undefined
  }
}

export default usePoolV2
