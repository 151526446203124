import { Currency, Percent, TokenAmount } from '@traderjoe-xyz/sdk-core'
import { PairV2 } from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import JSBI from 'jsbi'
import { useMemo } from 'react'
import { useSlippageSettings } from 'state/settings/hooks'
import { LBPairUserBalances } from 'types/poolV2'
import { getMaxBinPerRemoveLiquidityBatch } from 'utils/getMaxBinPerBatch'
import { wrappedCurrency } from 'utils/wrappedCurrency'

import useLBPairBins from './useLBPairBins'
import useLBTokensSupply from './useLBTokensSupply'
import { RemoveLiquidityV2Option } from './useRemoveLiquidityV2'

export interface RemoveLiquidityBatch {
  amountsToRemove: {
    amountX: JSBI
    amountXMin: JSBI
    amountY: JSBI
    amountYMin: JSBI
  }
  batchIndex: number
  liquidityToRemove: string[]
  userPositionIds: number[]
}

export interface ParsedAmounts {
  amountX: TokenAmount
  amountXMin: TokenAmount
  amountY: TokenAmount
  amountYMin: TokenAmount
}

interface UseRemoveLiquidityInfoV2Props {
  currency0: Currency
  currency1: Currency
  range: number[]
  removeOption: RemoveLiquidityV2Option
  activeBinId?: number
  lbPairAddress?: string
  userBalances?: LBPairUserBalances
}

const useRemoveLiquidityInfoV2 = ({
  activeBinId,
  currency0,
  currency1,
  lbPairAddress,
  range,
  removeOption,
  userBalances
}: UseRemoveLiquidityInfoV2Props) => {
  const chainId = useChainId()
  const tokenA = wrappedCurrency(currency0, chainId)
  const tokenB = wrappedCurrency(currency1, chainId)
  const {
    slippageSettings: { liquidityAmountV2: allowedSlippage }
  } = useSlippageSettings()

  const { data: totalSupplies, refetch: refetchTotalSupplies } =
    useLBTokensSupply({
      binIds: userBalances?.positions,
      lbPairAddress
    })

  const { data: binsData, refetch: refetchBins } = useLBPairBins({
    binIds: userBalances?.positions,
    lbPairAddress
  })

  const withdrawalInfo = useMemo(() => {
    try {
      if (
        tokenA &&
        tokenB &&
        userBalances &&
        activeBinId &&
        binsData &&
        totalSupplies
      ) {
        // init user slippage tolerance
        const userSlippageTolerance = new Percent(
          JSBI.BigInt(allowedSlippage * 100),
          JSBI.BigInt(10000)
        )

        // init vars needed to calcuate amountsToRemove
        let userPositionIds = userBalances.positions
        let liquidityToRemove = userBalances.liquidity
        let finalBinData = binsData
        let finalTotalSupplies = totalSupplies

        // special handling for single token withdrawal
        const activeIdIndex = userPositionIds.findIndex(
          (id) => id === activeBinId
        )

        switch (removeOption) {
          case RemoveLiquidityV2Option.TOKEN_A:
            const sliceIndexA =
              activeIdIndex > -1
                ? activeIdIndex + 1
                : userPositionIds.findIndex((id) => id > activeBinId) // removing single token with no liquidity in active bin
            if (sliceIndexA > -1) {
              userPositionIds = userPositionIds.slice(sliceIndexA)
              liquidityToRemove = liquidityToRemove.slice(sliceIndexA)
              finalBinData = finalBinData.slice(sliceIndexA)
              finalTotalSupplies = finalTotalSupplies.slice(sliceIndexA)
            } else {
              // there is no token A to withdraw, only token B remains
              return undefined
            }
            break
          case RemoveLiquidityV2Option.TOKEN_B:
            const sliceIndexB =
              activeIdIndex > -1
                ? activeIdIndex
                : userPositionIds.findLastIndex((id) => id < activeBinId) + 1 // removing single token with no liquidity in active bin
            if (sliceIndexB > -1) {
              userPositionIds = userPositionIds.slice(0, sliceIndexB)
              liquidityToRemove = liquidityToRemove.slice(0, sliceIndexB)
              finalBinData = finalBinData.slice(0, sliceIndexB)
              finalTotalSupplies = finalTotalSupplies.slice(0, sliceIndexB)
            } else {
              // there is no token B to withdraw, only token A remains
              return undefined
            }
            break
          case RemoveLiquidityV2Option.BOTH_TOKENS:
            const indexes = userPositionIds
              .map((id, index) =>
                id >= range[0] && id <= range[1] ? index : undefined
              )
              .filter((i) => i !== undefined) as number[]
            userPositionIds = userPositionIds.filter((_, i) =>
              indexes.includes(i)
            )
            liquidityToRemove = liquidityToRemove.filter((_, i) =>
              indexes.includes(i)
            )
            finalBinData = finalBinData.filter((_, i) => indexes.includes(i))
            finalTotalSupplies = finalTotalSupplies.filter((_, i) =>
              indexes.includes(i)
            )
            break
        }

        const pair = new PairV2(tokenA, tokenB)
        const maxBinPerBatch = getMaxBinPerRemoveLiquidityBatch(chainId)
        const nbOfBatches = Math.ceil(userPositionIds.length / maxBinPerBatch)

        return [...Array(nbOfBatches).keys()].map((batchIndex) => {
          const start = maxBinPerBatch * batchIndex
          const end = start + maxBinPerBatch

          // get amounts from SDK
          const amountsToRemove = pair.calculateAmountsToRemove(
            userPositionIds.slice(start, end),
            activeBinId,
            finalBinData.slice(start, end),
            finalTotalSupplies.slice(start, end),
            liquidityToRemove.slice(start, end),
            userSlippageTolerance
          )

          return {
            amountsToRemove,
            batchIndex,
            liquidityToRemove: liquidityToRemove.slice(start, end),
            userPositionIds: userPositionIds.slice(start, end)
          }
        })
      }
    } catch (e) {
      console.debug('Error calculating amountsToRemove', e)
    }
    return undefined
  }, [
    tokenA,
    tokenB,
    userBalances,
    activeBinId,
    binsData,
    totalSupplies,
    allowedSlippage,
    removeOption,
    range,
    chainId
  ])

  //   parse amounts in TokenAmount
  const parsedAmounts = useMemo(() => {
    if (withdrawalInfo && withdrawalInfo.length > 0 && tokenA && tokenB) {
      return withdrawalInfo.map(({ amountsToRemove }) => ({
        amountX: new TokenAmount(tokenA, amountsToRemove.amountX),
        amountXMin: new TokenAmount(tokenA, amountsToRemove.amountXMin),
        amountY: new TokenAmount(tokenB, amountsToRemove.amountY.toString()),
        amountYMin: new TokenAmount(
          tokenB,
          amountsToRemove.amountYMin.toString()
        )
      }))
    }
    return undefined
  }, [withdrawalInfo, tokenA, tokenB])

  return {
    parsedAmounts,
    refetchBins,
    refetchTotalSupplies,
    withdrawalInfo
  }
}

export default useRemoveLiquidityInfoV2
