import { useQuery } from '@tanstack/react-query'
import { useAccount } from 'wagmi'

import { useDexbarnGet } from './useDexbarn'

interface UserRiskDexbarnResponse {
  address: string
  riskLevel: 'low' | 'medium' | 'high' | 'severe'
}

const useAccountRiskCheck = () => {
  const { address: account } = useAccount()

  const fetchUserRiskLevel = useDexbarnGet<UserRiskDexbarnResponse>(
    `/v1/user/risk/${account}`
  )

  return useQuery({
    enabled: !!account,
    queryFn: fetchUserRiskLevel,
    queryKey: ['GetUserRiskLevel', account]
  })
}

export default useAccountRiskCheck
