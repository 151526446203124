import { ChainId, Currency, WNATIVE } from '@traderjoe-xyz/sdk-core'
import axios from 'axios'
import { DEXBARN_URL } from 'constants/dexbarn'
import { getChainSlug } from 'utils/chains'

import { MAJOR_TOKENS, STABLECOINS } from './constants'
import { mergeDexCandleResults } from './helpers'

export type DexbarnCandle = {
  baseAsset: string
  close: number
  high: number
  low: number
  open: number
  quoteAsset: string
  timestamp: number
}

type DexbarnResolution = '1' | '5' | '15' | '60' | '240' | '720' | '1D'

interface GetCandlestickDataProps {
  baseCurrency: Currency
  chainId: Exclude<ChainId, ChainId.MANTLE>
  firstDataRequest: boolean
  from: number
  isInverted: boolean
  quoteCurrency: Currency
  resolution: DexbarnResolution
  to: number
}

const fetchCandlestickData = async (
  tokenA: string,
  tokenB: string,
  timeframe: string,
  aggregate: number,
  limit: number,
  to: number,
  chainId: Exclude<ChainId, ChainId.MANTLE>
) => {
  try {
    const response = await axios.get<DexbarnCandle[]>(
      `${DEXBARN_URL}/v1/candles/${getChainSlug(chainId)}/${timeframe}`,
      {
        params: {
          aggregate,
          beforeTimestamp: to,
          currency: 'token',
          limit,
          token: 'base',
          tokenA,
          tokenB
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching candlestick data', error)
    return []
  }
}

const shouldInvertCandles = (
  base: string,
  quote: string,
  chainId: Exclude<ChainId, ChainId.MANTLE>
) => {
  const stablecoins = STABLECOINS[chainId].map((address) =>
    address.toLowerCase()
  )
  const majors = MAJOR_TOKENS[chainId].map((address) => address.toLowerCase())
  const baseLower = base.toLowerCase()
  const quoteLower = quote.toLowerCase()

  if (stablecoins.includes(baseLower)) {
    return true
  }
  if (stablecoins.includes(quoteLower)) {
    return false
  }
  const baseIsMajor = majors.includes(baseLower)
  const quoteIsMajor = majors.includes(quoteLower)

  if (baseIsMajor && quoteIsMajor) {
    return majors.indexOf(baseLower) < majors.indexOf(quoteLower)
  }
  if (baseIsMajor) {
    return true
  }
  if (quoteIsMajor) {
    return false
  }
  return false
}

const invertCandles = (candles: DexbarnCandle[]) => {
  return candles.map((candle) => ({
    ...candle,
    baseAsset: candle.quoteAsset,
    close: 1 / candle.close,
    high: 1 / candle.high,
    low: 1 / candle.low,
    open: 1 / candle.open,
    quoteAsset: candle.baseAsset
  }))
}

export const getCandlestickData = async ({
  baseCurrency,
  chainId,
  firstDataRequest,
  from,
  isInverted,
  quoteCurrency,
  resolution,
  to
}: GetCandlestickDataProps) => {
  const tokenA = baseCurrency.isToken
    ? baseCurrency.address
    : WNATIVE[chainId].address
  const tokenB = quoteCurrency.isToken
    ? quoteCurrency.address
    : WNATIVE[chainId].address

  let timeframe: 'hour' | 'day' | 'minute'
  let aggregate: number
  let limit: number

  switch (resolution) {
    case '1':
      timeframe = 'minute'
      aggregate = 1
      limit = Math.ceil((to - from) / (aggregate * 60))
      break
    case '5':
      timeframe = 'minute'
      aggregate = 5
      limit = Math.ceil((to - from) / (aggregate * 60))
      break
    case '15':
      timeframe = 'minute'
      aggregate = 15
      limit = Math.ceil((to - from) / (aggregate * 60))
      break
    case '60':
      timeframe = 'hour'
      aggregate = 1
      limit = Math.ceil((to - from) / (60 * 60))
      break
    case '240':
      timeframe = 'hour'
      aggregate = 4
      limit = Math.ceil((to - from) / (4 * 60 * 60))
      break
    case '720':
      timeframe = 'hour'
      aggregate = 12
      limit = Math.ceil((to - from) / (12 * 60 * 60))
      break
    case '1D':
      timeframe = 'day'
      aggregate = 1
      limit = Math.ceil((to - from) / (24 * 60 * 60))
      break
  }

  const minLimit = 100
  const maxLimit = 1000
  limit = Math.min(maxLimit, Math.max(minLimit, limit))

  let data = await fetchCandlestickData(
    tokenA,
    tokenB,
    timeframe,
    aggregate,
    limit,
    to,
    chainId
  )

  if (data.length === 0 && firstDataRequest) {
    const dataA = await fetchCandlestickData(
      tokenA,
      WNATIVE[chainId].address,
      timeframe,
      aggregate,
      limit,
      to,
      chainId
    )

    const dataB = await fetchCandlestickData(
      tokenB,
      WNATIVE[chainId].address,
      timeframe,
      aggregate,
      limit,
      to,
      chainId
    )

    if (dataA.length > 0 && dataB.length > 0) {
      data = mergeDexCandleResults(dataA, dataB, WNATIVE[chainId].address)
    }
  }

  if (data.length > 0) {
    const base = data[0].baseAsset
    const quote = data[0].quoteAsset

    if (shouldInvertCandles(base, quote, chainId)) {
      data = invertCandles(data)
    }
  }

  if (isInverted) {
    data = invertCandles(data)
  }

  return data ? data.reverse() : []
}
