import { VaultWithUserPosition } from 'types/vault'
import { isAddress } from 'viem'

interface UseVaultSearchProps {
  query: string
  vaults: VaultWithUserPosition[]
}

const useVaultSearch = ({
  query,
  vaults
}: UseVaultSearchProps): VaultWithUserPosition[] => {
  const lowercasedQuery = query.toLowerCase()
  if (isAddress(query)) {
    return vaults.filter(
      (vault) =>
        vault.tokenX.address?.toLowerCase().startsWith(lowercasedQuery) ||
        vault.tokenY.address?.toLowerCase().startsWith(lowercasedQuery) ||
        vault.id?.toLowerCase().startsWith(lowercasedQuery)
    )
  }
  return vaults.filter(
    (vault) =>
      vault.tokenX.symbol.toLowerCase().includes(lowercasedQuery) ||
      vault.tokenY.symbol.toLowerCase().includes(lowercasedQuery)
  )
}

export default useVaultSearch
