import {
  Grid,
  Heading,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { FormattedLbPoolReward } from 'types/rewards'
import { formattedNum } from 'utils/format'

interface LbRewardsPopoverProps {
  rewards: FormattedLbPoolReward[]
}

const LbRewardsPopover = ({ rewards }: LbRewardsPopoverProps) => {
  return (
    <Popover trigger="hover" placement="top" variant="responsive">
      <PopoverTrigger>
        <Tag
          variant="solid"
          size="md"
          bg="green.400"
          textColor="white"
          _hover={{ opacity: 0.8 }}
        >
          <Trans>REWARDS</Trans>
        </Tag>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody>
            <VStack spacing={4} align="flex-start">
              <Heading size="sm">
                <Trans>Rewards</Trans>
              </Heading>

              <Grid
                templateColumns="minmax(0, 1fr) minmax(0, auto) minmax(0, auto)"
                fontSize="sm"
                rowGap={2}
                columnGap={4}
              >
                <Text textColor="textSecondary">
                  <Trans>Token</Trans>
                </Text>

                <Text textColor="textSecondary">
                  <Trans>Rewards per day</Trans>
                </Text>

                <Text textColor="textSecondary">
                  <Trans>Time remaining</Trans>
                </Text>

                {rewards.map((reward, index) => {
                  return (
                    <React.Fragment key={index}>
                      <HStack>
                        <CurrencyLogo
                          boxSize={4}
                          address={
                            reward.token.isToken
                              ? reward.token.address
                              : undefined
                          }
                          symbol={reward.token.symbol}
                        />
                        <Text fontWeight="semibold">{reward.token.symbol}</Text>
                      </HStack>
                      <Text fontWeight="semibold">
                        {formattedNum(reward.rewardsPerDay, { places: 2 })}{' '}
                        {reward.token.symbol}
                      </Text>
                      <Text fontWeight="semibold">
                        {reward.duration || 'N/A'}
                      </Text>
                    </React.Fragment>
                  )
                })}
              </Grid>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default LbRewardsPopover
