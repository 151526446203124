import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { DexAnalyticsDataV1, DexAnalyticsDataV2 } from 'types/analytics'
import { Pool as DexbarnPool } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { roundToNearest5Minutes } from 'utils/date'

dayjs.extend(utc)

interface ChartData {
  date: number
  value: number
}

interface PoolData {
  liquidity: ChartData[]
  volume: ChartData[]
}

const usePoolHomeAnalytics = () => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const DATA_LENGTH = 180
  const SECONDS_IN_DAY = 60 * 60 * 24

  const utcDataLengthDaysBack = roundToNearest5Minutes(
    dayjs()
      .utc()
      .startOf('day')
      .subtract(DATA_LENGTH - 1, 'day')
  ).unix()

  const fetchDexAnalyticsDayData = useDexbarnGet<DexAnalyticsDataV2[]>(
    `/v1/dex/analytics/${chain}?startTime=${utcDataLengthDaysBack}&aggregateBy=daily`
  )

  const fetchTopPoolsByVolume = useDexbarnGet<DexbarnPool[]>(
    `/v1/pools/${chain}?orderBy=volume&pageSize=100`
  )

  const fetchTopPoolsByLiquidity = useDexbarnGet<DexbarnPool[]>(
    `/v1/pools/${chain}?orderBy=liquidity&pageSize=100`
  )

  const { data: dayDatasV2, isLoading: dayDatasV2Loading } = useQuery({
    gcTime: 0,
    queryFn: () => fetchDexAnalyticsDayData(),
    queryKey: ['dayDataV2', chain]
  })

  const { data: topPoolsByVolume, isLoading: isLoadingTopPoolsByVolume } =
    useQuery<DexbarnPool[]>({
      gcTime: 0,
      queryFn: () => fetchTopPoolsByVolume(),
      queryKey: ['topPoolsByVolume', chain]
    })

  const { data: topPoolsByLiquidity, isLoading: isLoadingTopPoolsByLiquidity } =
    useQuery<DexbarnPool[]>({
      gcTime: 0,
      queryFn: () => fetchTopPoolsByLiquidity(),
      queryKey: ['topPoolsByLiquidity', chain]
    })

  const fetchDexAnalyticsDayDataV1 = useDexbarnGet<DexAnalyticsDataV1[]>(
    `/v1/joev1/dex/analytics/${chain}?startTime=${utcDataLengthDaysBack}&aggregateBy=daily`
  )

  const { data: dayDatasV1, isLoading: dayDatasV1Loading } = useQuery({
    gcTime: 0,
    queryFn: () => fetchDexAnalyticsDayDataV1(),
    queryKey: ['getDayDataV1', chain]
  })

  const v1Data = useMemo(() => {
    let currentDayTimestamp = utcDataLengthDaysBack

    const v1Data: PoolData = {
      liquidity: [],
      volume: []
    }

    if (!dayDatasV1) {
      return v1Data
    }

    let v1DataIndex = 0

    // create dummy array to sync length of filled data with DATA_LENGTH
    new Array(DATA_LENGTH).fill(0).forEach((_, index) => {
      // V1 DATA
      // condition if timestamp of current iteration is in data from dexbarn
      if (
        v1DataIndex < dayDatasV1.length &&
        dayDatasV1[v1DataIndex].timestamp === currentDayTimestamp
      ) {
        v1Data.liquidity.push({
          date: currentDayTimestamp,
          value: dayDatasV1[v1DataIndex].reserveUsd
        })
        v1Data.volume.push({
          date: currentDayTimestamp,
          value: dayDatasV1[v1DataIndex].volumeUsd
        })

        v1DataIndex++
      } else {
        try {
          // if the past day timestamp have a defined TVL, use it for current timestamp if data
          // for current timestamp is not found in the data from subgraph
          v1Data.liquidity.push({
            date: currentDayTimestamp,
            value: v1Data.liquidity[index - 1].value
          })
        } catch (e) {
          // default to zero if past day TVL is undefined (edge case when index = 0)
          v1Data.liquidity.push({
            date: currentDayTimestamp,
            value: 0
          })
        }

        // volume and fees for day timestamps not found in subgraph should default to zero
        v1Data.volume.push({
          date: currentDayTimestamp,
          value: 0
        })
      }

      currentDayTimestamp += SECONDS_IN_DAY
    })

    return v1Data
  }, [dayDatasV1, SECONDS_IN_DAY, utcDataLengthDaysBack])

  const v1Volume24h = useMemo(() => {
    return v1Data.volume.length > 1
      ? v1Data.volume[v1Data.volume.length - 1].value
      : 0
  }, [v1Data])

  const v1TVL = useMemo(() => {
    return v1Data.liquidity.length > 1
      ? v1Data.liquidity[v1Data.liquidity.length - 1].value
      : 0
  }, [v1Data])

  const v2Data = useMemo(() => {
    let currentDayTimestamp = utcDataLengthDaysBack

    const v2Data: PoolData = {
      liquidity: [],
      volume: []
    }

    if (!dayDatasV2) {
      return v2Data
    }

    let v2DataIndex = 0

    // create dummy array to sync length of filled data with DATA_LENGTH
    new Array(DATA_LENGTH).fill(0).forEach((_, index) => {
      // V2 DATA
      // condition if timestamp of current iteration is in data from dexbarn
      if (
        v2DataIndex < dayDatasV2.length &&
        dayDatasV2[v2DataIndex].timestamp === currentDayTimestamp
      ) {
        v2Data.liquidity.push({
          date: currentDayTimestamp,
          value: dayDatasV2[v2DataIndex].reserveUsd
        })
        v2Data.volume.push({
          date: currentDayTimestamp,
          value: dayDatasV2[v2DataIndex].volumeUsd
        })

        v2DataIndex++
      } else {
        try {
          // if the past day timestamp have a defined TVL, use it for current timestamp if data
          // for current timestamp is not found in the data from subgraph
          v2Data.liquidity.push({
            date: currentDayTimestamp,
            value: v2Data.liquidity[index - 1].value
          })
        } catch (e) {
          // default to zero if past day TVL is undefined (edge case when index = 0)
          v2Data.liquidity.push({
            date: currentDayTimestamp,
            value: 0
          })
        }

        // volume and fees for day timestamps not found in subgraph should default to zero
        v2Data.volume.push({
          date: currentDayTimestamp,
          value: 0
        })
      }

      currentDayTimestamp += SECONDS_IN_DAY
    })

    return v2Data
  }, [dayDatasV2, SECONDS_IN_DAY, utcDataLengthDaysBack])

  const v2Volume24h = useMemo(() => {
    return topPoolsByVolume?.reduce((acc, pool) => acc + pool.volumeUsd, 0) || 0
  }, [topPoolsByVolume])

  const v2TVL = useMemo(() => {
    if (v2Data && v2Data.liquidity.length > 0) {
      return v2Data.liquidity[v2Data.liquidity.length - 1].value
    } else if (topPoolsByLiquidity) {
      return topPoolsByLiquidity.reduce(
        (acc, pool) => acc + pool.liquidityUsd,
        0
      )
    } else {
      return 0
    }
  }, [v2Data, topPoolsByLiquidity])

  return {
    v1Analytics: {
      data: v1Data,
      isLoading: dayDatasV1Loading,
      isLoadingTVL: dayDatasV1Loading,
      isLoadingVolume24H: dayDatasV1Loading,
      tvl: v1TVL,
      volume24h: v1Volume24h
    },
    v2Analytics: {
      data: v2Data,
      isLoading: dayDatasV2Loading,
      isLoadingTVL: isLoadingTopPoolsByLiquidity,
      isLoadingVolume24H: isLoadingTopPoolsByVolume,
      tvl: v2TVL,
      volume24h: v2Volume24h
    }
  }
}

export default usePoolHomeAnalytics
