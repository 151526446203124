import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Hide,
  Image,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import JoepegLogo1x from 'assets/home/joepeg_illustration_1x.webp'
import JoepegLogo2x from 'assets/home/joepeg_illustration_2x.webp'
import JoepegLogo3x from 'assets/home/joepeg_illustration_3x.webp'
import { useGetPopularItems } from 'hooks/joebarn/useGetPopularItems'
import React from 'react'
import { Link } from 'react-router-dom'

import JoepegPreview from './JoepegPreview'

const JoepegSection = () => {
  const { data: collections, isLoading } = useGetPopularItems()

  return (
    <Box w="full">
      <Flex
        pos="relative"
        justify="space-between"
        bg="bgTertiary"
        borderRadius="2xl"
        zIndex={1}
        py={{ base: 4, md: 8 }}
        pl={{ base: '125px', md: '160px' }}
        pr={{ base: 2, md: 8 }}
      >
        <Image
          pos="absolute"
          top={{ base: 0, md: -10, sm: -2 }}
          left={{ base: -2, md: -4 }}
          fallbackSrc={JoepegLogo2x}
          srcSet={`${JoepegLogo1x} 1x, ${JoepegLogo2x} 2x, ${JoepegLogo3x} 3x`}
          w={{ base: '120px', md: '180px' }}
          h={{ base: '100px', md: '160px' }}
        />
        <VStack align="flex-start" spacing={1}>
          <Heading fontSize="2xl">
            <Trans>Popular on Joepegs</Trans>
          </Heading>
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>Enjoy our roundup of popular collections</Trans>
          </Text>
        </VStack>
        <Hide below="md">
          <Button
            as={Link}
            bg="white"
            _hover={{ bg: 'joeLight.300' }}
            _dark={{ _hover: { bg: 'joeDark.600' }, bg: 'joeDark.700' }}
            size="lg"
            px={6}
            borderRadius="full"
            rightIcon={<ArrowForwardIcon />}
            to={`https://joepegs.com`}
          >
            <Trans>Visit Joepegs</Trans>
          </Button>
        </Hide>
      </Flex>
      <Flex
        p={{ base: 4, md: 8 }}
        bottom="10px"
        bg="bgCard"
        alignItems="center"
        justifyContent="center"
        borderBottomRadius="2xl"
        boxShadow="joeLight"
        _dark={{ boxShadow: 'none' }}
        overflow="visible"
        position="relative"
      >
        {isLoading ? (
          <Center h="120px">
            <Spinner />
          </Center>
        ) : (
          <JoepegPreview collections={collections} />
        )}
      </Flex>
    </Box>
  )
}

export default JoepegSection
