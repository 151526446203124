import { FEE_RATE } from 'constants/pool'
import { useMemo } from 'react'

interface UsePoolAnalyticsChangeProps {
  pairDayDatas?: {
    reserveUsd: number
    volumeUsd: number
  }[]
}

const usePoolAnalyticsChange = ({
  pairDayDatas
}: UsePoolAnalyticsChangeProps) =>
  useMemo(() => {
    if (!pairDayDatas || pairDayDatas.length < 3) return undefined

    const pair = {
      ...pairDayDatas[pairDayDatas.length - 1],
      oneDay: pairDayDatas[pairDayDatas.length - 2],
      twoDay: pairDayDatas[pairDayDatas.length - 3]
    }

    const oneDayVolumeUSD = pair.oneDay.volumeUsd
    const oneDayReserveUSD = pair.oneDay.reserveUsd
    const twoDayVolumeUSD = pair.twoDay.volumeUsd
    const reserveUSD = pair.reserveUsd

    const volumeChange =
      twoDayVolumeUSD === 0
        ? undefined
        : ((oneDayVolumeUSD - twoDayVolumeUSD) / twoDayVolumeUSD) * 100

    const liquidityChange =
      oneDayReserveUSD === 0
        ? undefined
        : ((reserveUSD - oneDayReserveUSD) / oneDayReserveUSD) * 100

    const fees = oneDayVolumeUSD * FEE_RATE
    const feesYesterday = twoDayVolumeUSD * FEE_RATE

    const feeChange =
      feesYesterday === 0
        ? undefined
        : ((fees - feesYesterday) / feesYesterday) * 100

    return {
      feeChange,
      liquidityChange,
      volumeChange
    }
  }, [pairDayDatas])

export default usePoolAnalyticsChange
