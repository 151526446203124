import { HStack, Tag, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import PointsBadge from 'components/PointsBadge'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { LBPoolRewards, Point, Token } from 'types/pool'
import { getChain } from 'utils/chains'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

import EpochRewardPopover from './EpochRewardPopover'

interface PoolTableNameColumnProps {
  token0: Omit<Token, 'decimals'>
  token1: Omit<Token, 'decimals'>
  chainId?: ChainId
  extraTag?: JSX.Element
  extraTagLabel?: string
  fees?: string
  isPoolMigrated?: boolean
  isRewarded?: boolean
  points?: Point[]
  rewards?: LBPoolRewards[]
}

const PoolTableNameColumn = ({
  chainId,
  extraTag,
  extraTagLabel,
  fees,
  isPoolMigrated,
  isRewarded,
  points,
  rewards,
  token0,
  token1
}: PoolTableNameColumnProps) => {
  const defaultChainId = useChainId()
  const _chainId = chainId ?? defaultChainId

  const chain = getChain(_chainId)
  const nativeCurrency = chain?.nativeCurrency

  const isToken0WrappedNativeCurrency = isWrappedNativeCurrency(
    token0.address,
    _chainId
  )
  const isToken1WrappedNativeCurrency = isWrappedNativeCurrency(
    token1.address,
    _chainId
  )

  return (
    <HStack spacing={2}>
      <DoubleCurrencyLogo
        address0={isToken0WrappedNativeCurrency ? undefined : token0.address}
        symbol0={
          isToken0WrappedNativeCurrency ? nativeCurrency?.symbol : token0.symbol
        }
        address1={isToken1WrappedNativeCurrency ? undefined : token1.address}
        symbol1={
          isToken1WrappedNativeCurrency ? nativeCurrency?.symbol : token1.symbol
        }
        boxSize={6}
        ml="-2"
      />
      <HStack flexShrink={0}>
        <Text fontWeight="semibold" fontSize="sm">{`${
          isToken0WrappedNativeCurrency ? nativeCurrency?.symbol : token0.symbol
        } - ${
          isToken1WrappedNativeCurrency ? nativeCurrency?.symbol : token1.symbol
        }`}</Text>
        {fees ? (
          <Tag
            variant="solid"
            size="md"
            bg="bgTertiary"
            textColor="textPrimary"
          >
            {fees}
          </Tag>
        ) : null}
        {isRewarded ? (
          rewards ? (
            <EpochRewardPopover rewards={rewards} />
          ) : (
            <Tag variant="solid" size="md" bg="green.400" textColor="white">
              <Trans>REWARDS</Trans>
            </Tag>
          )
        ) : null}
        {isPoolMigrated ? (
          <Tag variant="solid" size="md" bg="yellowBar" textColor="black">
            <Trans>OUTDATED</Trans>
          </Tag>
        ) : null}
        {extraTagLabel ? (
          <Tag
            variant="solid"
            size="md"
            bg="bgTertiary"
            textColor="textPrimary"
          >
            {extraTagLabel}
          </Tag>
        ) : (
          extraTag
        )}
        {points && points.length > 0 && <PointsBadge points={points} />}
      </HStack>
    </HStack>
  )
}

export default PoolTableNameColumn
