import { ChainId } from '@traderjoe-xyz/sdk-core'

export const STABLECOINS: {
  [chainId in Exclude<ChainId, ChainId.MANTLE>]: string[]
} = {
  [ChainId.ARBITRUM_ONE]: [
    '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
    '0x64343594ab9b56e99087bfa6f2335db24c2d1f17',
    '0xFA5Ed56A203466CbBC2430a43c66b9D8723528E7',
    '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    '0x323665443CEf804A3b5206103304BD4872EA4253',
    '0x4CFA50B7Ce747e2D61724fcAc57f24B748FF2b2A',
    '0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65'
  ],
  [ChainId.AVALANCHE]: [
    '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    '0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64',
    '0xAEC8318a9a59bAEb39861d10ff6C7f7bf1F96C57',
    '0xC891EB4cbdEFf6e073e859e987815Ed1505c2ACD',
    '0xfaB550568C688d5D8A52C7d794cb93Edc26eC0eC',
    '0x323665443CEf804A3b5206103304BD4872EA4253',
    '0xDf3d57c3480951958Cef19905E4cf7FC1bA9ad42',
    '0xaBe7a9dFDA35230ff60D1590a929aE0644c47DC1'
  ],
  [ChainId.BNB_CHAIN]: [
    '0x55d398326f99059fF775485246999027B3197955',
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'
  ],
  [ChainId.BNB_TESTNET]: [],
  [ChainId.ETHEREUM]: [
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    '0x6B175474E89094C44Da98b954EedeAC495271d0F'
  ],
  [ChainId.FUJI]: [],
  [ChainId.ARB_GOERLI]: []
}

export const MAJOR_TOKENS: {
  [chainId in Exclude<ChainId, ChainId.MANTLE>]: string[]
} = {
  [ChainId.ARBITRUM_ONE]: [
    // btc
    '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
    '0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40',
    '0xeee18334c414a47fb886a7317e1885b2bfb8c2a6',
    '0x2297aEbD383787A160DD0d9F71508148769342E3',
    '0xD873627e9F2C4ED425d833152327e6a8f3a49b95',
    // eth
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    '0x2416092f143378750bb29b79eD961ab195CcEea5',
    '0xf7728582002ef82908c8242CF552E969BA863Ffa',
    '0xbc011A12Da28e8F0f528d9eE5E7039E22F91cf18',
    '0x35751007a407ca6FEFfE80b3cB397736D2cf4dbe',
    '0x0fBcbaEA96Ce0cF7Ee00A8c19c3ab6f5Dc8E1921',
    // avax
    '0x565609fAF65B92F7be02468acF86f8979423e514',
    // arb
    '0x912CE59144191C1204E64559FE8253a0e49E6548'
  ],
  [ChainId.AVALANCHE]: [
    // btc
    '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
    '0x50b7545627a5162f82a992c33b87adc75187b218',
    // eth
    '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
    '0x6D3B126ae28f3E39894070148B377624F6Ab4a45',
    '0x2018B0CA0eDcE80800851958bD094dD4a8DA1fc4',
    '0x12D8CE035c5DE3Ce39B1fDD4C1d5a745EAbA3b8C',
    // sol
    '0xFE6B19286885a4F7F55AdAD09C3Cd1f906D2478F',
    // avax
    '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    '0xa25eaf2906fa1a3a13edac9b9657108af7b703e3',
    '0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be',
    '0xF7D9281e8e363584973F946201b82ba72C965D27',
    '0xc3344870d52688874b06d844E0C36cc39FC727F6'
  ],
  [ChainId.BNB_CHAIN]: [
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    '0x3E14602186DD9dE538F729547B3918D24c823546',
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
  ],
  [ChainId.BNB_TESTNET]: [],
  [ChainId.ETHEREUM]: [
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'
  ],
  [ChainId.FUJI]: [],
  [ChainId.ARB_GOERLI]: []
}
