import { BAR_ADDRESS, JOE_ADDRESS, XJoeStakingABI } from '@traderjoe-xyz/sdk'
import { ZERO_ADDRESS } from 'constants/tokens'
import useChainId from 'hooks/useChainId'
import { erc20Abi, getAddress } from 'viem'
import { useAccount, useReadContract, useReadContracts } from 'wagmi'

/**
 * XJoe Staking Info. Returns:
 * totalStaked in 18 decimals
 * totalSupply in 18 decimals
 * ratio in float
 */
const useXJoeStaking = () => {
  const chainId = useChainId()
  const joeContract = {
    abi: erc20Abi,
    address: getAddress(JOE_ADDRESS[chainId]),
    chainId
  }
  const xJoeStakingContract = {
    abi: XJoeStakingABI,
    address: getAddress(BAR_ADDRESS[chainId]),
    chainId
  }

  const xJoeAddress = getAddress(BAR_ADDRESS[chainId])
  const enabled = !!xJoeAddress && xJoeAddress != ZERO_ADDRESS

  const reads = useReadContracts({
    contracts: [
      {
        ...joeContract,
        args: [xJoeAddress],
        functionName: 'balanceOf'
      },
      {
        ...xJoeStakingContract,
        functionName: 'totalSupply'
      }
    ],
    query: { enabled }
  })
  const totalStaked = reads.data?.[0].result
  const totalSupply = reads.data?.[1].result
  const ratio =
    totalStaked && totalSupply && totalSupply > BigInt(0)
      ? Number((totalStaked * BigInt(1000)) / totalSupply) / 1000
      : 0

  return {
    ...reads,
    ratio,
    totalStaked,
    totalSupply
  }
}

/**
 * contract methods for XJoe User Positions. Returns:
 * userBalance of xJOE tokens, in 18 decimals
 */
export const useXJoeUserPosition = () => {
  const chainId = useChainId()
  const { address: account } = useAccount()

  const xJoeAddress = getAddress(BAR_ADDRESS[chainId])
  const enabled = !!account && !!xJoeAddress && xJoeAddress != ZERO_ADDRESS
  const { data: userBalance } = useReadContract({
    abi: XJoeStakingABI,
    address: xJoeAddress,
    args: account ? [account] : undefined,
    chainId,
    functionName: 'balanceOf',
    query: { enabled }
  })

  return { userBalance }
}

export default useXJoeStaking
