import {
  LB_FACTORY_ADDRESS,
  LB_FACTORY_V21_ADDRESS,
  LB_FACTORY_V22_ADDRESS,
  LBFactoryV21ABI
} from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import { LBPoolVersion } from 'types/pool'
import { getAddress } from 'viem'
import { useReadContract } from 'wagmi'

interface UseGetAllLBPairsProps {
  version: LBPoolVersion
  tokenXAddress?: string
  tokenYAddress?: string
}

const useGetAllLBPairs = ({
  tokenXAddress,
  tokenYAddress,
  version
}: UseGetAllLBPairsProps) => {
  const chainId = useChainId()

  let factoryAddress: string
  switch (version) {
    case 'v2':
      factoryAddress = LB_FACTORY_ADDRESS[chainId]
      break
    case 'v21':
      factoryAddress = LB_FACTORY_V21_ADDRESS[chainId]
      break
    case 'v22':
      factoryAddress = LB_FACTORY_V22_ADDRESS[chainId]
      break
  }

  return useReadContract({
    abi: LBFactoryV21ABI,
    address: getAddress(factoryAddress),
    args:
      tokenXAddress && tokenYAddress
        ? [getAddress(tokenXAddress), getAddress(tokenYAddress)]
        : undefined,
    chainId,
    functionName: 'getAllLBPairs',
    query: { enabled: !!tokenXAddress && !!tokenYAddress }
  })
}

export default useGetAllLBPairs
