import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { VaultABI } from '@traderjoe-xyz/sdk-v2'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { getAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseVaultRedeemQueuedWithdrawalProps {
  isOneCurrencyNative: boolean
  round: number
  vaultAddress: string
  enabled?: boolean
  onSuccess?: () => void
}

const useVaultRedeemQueuedWithdrawal = ({
  enabled = true,
  isOneCurrencyNative,
  onSuccess,
  round,
  vaultAddress
}: UseVaultRedeemQueuedWithdrawalProps) => {
  const { address: account } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args: account ? [BigInt(round), account] : undefined,
    functionName: isOneCurrencyNative
      ? 'redeemQueuedWithdrawalNative'
      : 'redeemQueuedWithdrawal',
    query: { enabled: enabled && !!account, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Redeem queued withdrawal`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const redeemQueuedWithdrawal = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash: data,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    redeemQueuedWithdrawal
  }
}

export default useVaultRedeemQueuedWithdrawal
