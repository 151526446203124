import { SJoePayoutDatas } from 'types/stake'
import { formatEther } from 'viem'

export const calculateSJoeMetrics = (
  joeStakedAmount?: bigint,
  joePriceUsd?: number,
  payoutDayDatas?: SJoePayoutDatas[]
) => {
  if (!joeStakedAmount || !joePriceUsd || !payoutDayDatas) {
    return undefined
  }

  const totalStakedNum = joeStakedAmount
    ? Number(formatEther(joeStakedAmount))
    : 0
  const totalStakedUSD = totalStakedNum * joePriceUsd
  const totalRemittance = payoutDayDatas?.reduce((sum: number, current) => {
    return sum + Number(current.usdRemitted)
  }, 0)

  return {
    apr30D: ((365 / 30) * totalRemittance) / totalStakedUSD,
    totalRemittance,
    totalStakedUSD
  }
}
