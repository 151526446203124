import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Flex,
  Grid,
  Heading,
  Hide,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import { WNATIVE } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import useChainId from 'hooks/useChainId'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import React from 'react'
import { formattedNum } from 'utils/format'
import { zeroAddress } from 'viem'

interface CurrencyAmountProps {
  amount: number
  token: string
  isLoading?: boolean
  symbol?: string
  usdAmount?: number
}

export const CurrencyAmount = ({
  amount,
  isLoading,
  symbol,
  token,
  usdAmount
}: CurrencyAmountProps) => (
  <Flex
    key={token}
    alignItems="center"
    w="full"
    h="64px"
    borderRadius="2xl"
    bg="joeLight.300"
    _dark={{ bg: 'joeDark.500' }}
    py={3}
    px={4}
  >
    <HStack flex="1" spacing={2}>
      <CurrencyLogo address={token} symbol={symbol} boxSize={8} />
      <VStack spacing={0} align="flex-start">
        <Skeleton isLoaded={!isLoading}>
          <Text fontSize="sm" textColor="textSecondary">
            <Box as="b" textColor="textPrimary">
              {formattedNum(amount)}
            </Box>{' '}
            {symbol}
          </Text>
        </Skeleton>
        <Text fontSize="sm" color="textSecondary">
          {formattedNum(usdAmount ?? 0, { usd: true })}
        </Text>
      </VStack>
    </HStack>
  </Flex>
)

interface CurrencyAmountsPanelProps {
  title: string
  tokenAmounts: number[]
  tokens: string[]
  tokenSymbols: (string | undefined)[]
  balanceText?: string
  bottomContent?: React.ReactNode
  boxProps?: BoxProps
  isLoading?: boolean
}

const CurrencyAmountsPanel = ({
  balanceText = 'Balance',
  bottomContent,
  boxProps,
  isLoading,
  title,
  tokenAmounts,
  tokens,
  tokenSymbols
}: CurrencyAmountsPanelProps) => {
  const chainId = useChainId()
  const { data: tokensPricesUSD } = useTokenPriceUSD({
    tokens: tokens.map((token) =>
      token === zeroAddress ? WNATIVE[chainId].address : token
    )
  })

  const usdAmounts = tokenAmounts.map((tokenAmount: number, index: number) =>
    tokensPricesUSD ? tokenAmount * tokensPricesUSD[index] : undefined
  )

  const usdAmountTotal = usdAmounts.every((usdAmount) => !!usdAmount)
    ? usdAmounts.reduce(
        (acc: number, usdAmount: number | undefined) => acc + (usdAmount ?? 0),
        0
      )
    : undefined

  // convert tokens array into group of 2
  // e.g. [A, B, C, D] => [[A, B], [C, D]]
  const tokensGrouped = tokens.reduce(
    (acc: string[][], token: string, index: number) => {
      if (index % 2 === 0) {
        acc.push([token])
      } else {
        acc[acc.length - 1].push(token)
      }
      return acc
    },
    []
  )

  return (
    <Box w="full" py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }} {...boxProps}>
      <Flex align="center" justify="space-between" mb={4}>
        <Heading size="md">{title}</Heading>
        {usdAmountTotal ? (
          <Text fontSize="sm" color="textSecondary">
            {`${balanceText}: `}
            <Box as="b" color="textPrimary">
              {formattedNum(usdAmountTotal, { usd: true })}
            </Box>
          </Text>
        ) : null}
      </Flex>
      <VStack>
        <SimpleGrid columns={1} gap={4} w="full">
          {tokensGrouped.map((tokens, index) => (
            <Grid
              key={index}
              templateColumns={{ base: '1fr', sm: '1fr 14px 1fr' }}
              w="full"
              alignItems="center"
              gap={4}
            >
              <CurrencyAmount
                token={tokens[0]}
                symbol={tokenSymbols[index * 2]}
                amount={tokenAmounts[index * 2]}
                usdAmount={usdAmounts[index * 2]}
                isLoading={isLoading}
              />
              {tokens.length === 2 ? (
                <Hide below="sm">
                  <AddIcon boxSize="14px" />
                </Hide>
              ) : null}
              {tokens.length === 2 ? (
                <CurrencyAmount
                  token={tokens[1]}
                  symbol={tokenSymbols[index * 2 + 1]}
                  amount={tokenAmounts[index * 2 + 1]}
                  usdAmount={usdAmounts[index * 2 + 1]}
                  isLoading={isLoading}
                />
              ) : null}
            </Grid>
          ))}
        </SimpleGrid>
      </VStack>
      {bottomContent}
    </Box>
  )
}

export default CurrencyAmountsPanel
