import { Box, Flex, Grid, Heading, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { CurrencyAmount } from 'components/CurrencyAmountsPanel'
import React from 'react'
import { formattedNum } from 'utils/format'

interface PoolRewardsBalanceProps {
  balance: string
  balanceUSD: string | undefined | number
  currency?: Currency
}

const PoolRewardsBalance = ({
  balance,
  balanceUSD,
  currency
}: PoolRewardsBalanceProps) => {
  const tokenAddress = currency && currency.isToken ? currency.address : ''

  return (
    <Box w="full" py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }}>
      <Flex align="center" justify="space-between" mb={4}>
        <Heading size="md">
          <Trans>Deposit Balance</Trans>
        </Heading>
        {balanceUSD ? (
          <Text fontSize="sm" color="textSecondary">
            {`Balance: `}
            <Box as="b" color="textPrimary">
              {formattedNum(balanceUSD, { usd: true })}
            </Box>
          </Text>
        ) : null}
      </Flex>
      <VStack>
        <Grid
          templateColumns={{ base: '1fr', sm: '1fr 14px 1fr' }}
          w="full"
          alignItems="center"
          gap={4}
        >
          <CurrencyAmount
            token={tokenAddress}
            symbol={currency ? currency.symbol : ''}
            amount={Number(balance)}
            usdAmount={Number(balanceUSD)}
          />
        </Grid>
      </VStack>
    </Box>
  )
}

export default PoolRewardsBalance
