import { useToast, UseToastOptions } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import Popup from 'components/Popup'
import { useCallback } from 'react'
import React from 'react'
import { usePublicClient } from 'wagmi'

import useActiveChain from './useActiveChain'

interface TransactionToastProps {
  description: string
  hash: `0x${string}`
  confirmations?: number
}

const useTransactionToast = () => {
  const toast = useToast()
  const publicClient = usePublicClient()
  const { blockExplorers } = useActiveChain()

  return useCallback(
    ({ confirmations, description, hash }: TransactionToastProps) => {
      const renderPopup = (
        description: string,
        props: UseToastOptions,
        subtitle?: string
      ): React.ReactNode => (
        <Popup
          blockExplorer={blockExplorers?.default}
          summary={description}
          subtitle={subtitle}
          {...props}
        />
      )

      // initial loading toast
      const toastId = toast({
        duration: null,
        id: hash,
        position: 'top-right',
        render: (props) => renderPopup(description, props),
        status: 'loading'
      })

      publicClient
        ?.waitForTransactionReceipt({
          confirmations,
          hash
        })
        .then((receipt) => {
          switch (receipt.status) {
            case 'success':
              toast.update(toastId, {
                duration: 6500,
                render: (props) => renderPopup(description, props),
                status: 'success'
              })
              break
            case 'reverted':
              toast.update(toastId, {
                duration: 6500,
                render: (props) => renderPopup(description, props),
                status: 'error'
              })
              break
          }
        })
        .catch(() => {
          // show warning when RPC fails
          toast.update(toastId, {
            duration: 6500,
            render: (props) =>
              renderPopup(
                description,
                props,
                t`Unexpected Response: Your transaction was sent to the blockchain, but we didn't get a confirmation.`
              ),
            status: 'warning'
          })
        })
    },
    [toast, publicClient, blockExplorers]
  )
}

export default useTransactionToast
