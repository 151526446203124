import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { UserFarmPosition } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { useAccount } from 'wagmi'

interface UseGetUserFarmBalancesProps {
  enabled?: boolean
}

const useGetUserFarmBalances = ({ enabled }: UseGetUserFarmBalancesProps) => {
  const { address: userAddress } = useAccount()
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)
  const fetchUserFarmBalances = useDexbarnGet<UserFarmPosition[]>(
    `/v1/user/${chain}/${userAddress}/farms`
  )

  return useQuery<UserFarmPosition[]>({
    enabled: enabled && !!userAddress,
    queryFn: () => fetchUserFarmBalances(),
    queryKey: ['UserFarmBalances', chain, userAddress]
  })
}

export default useGetUserFarmBalances
