import { ChainId } from '@traderjoe-xyz/sdk-core'
import { supportedChains } from 'constants/chains'
import { Chain } from 'types/dexbarn'

export const getChain = (chainId: ChainId) => {
  return supportedChains.find((chain) => chain.id === chainId)
}

export const getChainSlug = (chainId: ChainId): string | undefined => {
  return getChain(chainId)?.slug
}

export const getDexbarnChainParam = (
  chainId: Exclude<ChainId, ChainId.MANTLE>
): Chain => {
  switch (chainId) {
    case ChainId.AVALANCHE:
    case ChainId.FUJI:
      return 'avalanche'
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARB_GOERLI:
      return 'arbitrum'
    case ChainId.BNB_CHAIN:
    case ChainId.BNB_TESTNET:
      return 'binance'
    case ChainId.ETHEREUM:
      return 'ethereum'
  }
}
