import {
  Box,
  Center,
  Flex,
  Grid,
  HStack,
  Tab,
  TabList,
  Tabs,
  Text
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { CNATIVE, Currency, Token } from '@traderjoe-xyz/sdk-core'
import {
  LB_ROUTER_V21_ADDRESS,
  LB_ROUTER_V22_ADDRESS,
  LIMIT_ORDER_MANAGER_ADDRESS,
  PoolVersion
} from '@traderjoe-xyz/sdk-v2'
import AddToWalletButton from 'components/AddToWalletButton'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CopyableError from 'components/CopyableError'
import MaxButton from 'components/MaxButton'
import PageHelmet from 'components/PageHelmet'
import SlippageSettingsPicker from 'components/SlippageSettingsPicker'
import TradingViewChart from 'components/TradingViewChart'
import Web3Button from 'components/Web3Button'
import { TRADE_HELMET_DESCRIPTION, TRADE_HELMET_TITLE } from 'constants/swap'
import useGetTradeRoutes from 'hooks/swap/useGetTradeRoutes'
import { useSwap } from 'hooks/swap/useSwap'
import useWrapUnwrapNativeCurrency from 'hooks/swap/useWrapUnwrapNativeCurrency'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensUsdPrice'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useIsSafeModeEnabled, useSlippageSettings } from 'state/settings/hooks'
import { TradeBestPath } from 'types/trade'
import { formattedNum } from 'utils/format'
import { tradeSwap } from 'utils/measure'
import { tryParseAmount } from 'utils/swap'
import {
  getCurrencyAddress,
  wrappedCurrency,
  wrappedCurrencyAmount
} from 'utils/wrappedCurrency'
import { zeroAddress } from 'viem'
import { useAccount } from 'wagmi'

import Erc404TradeCompleted from './Erc404TradeCompleted'
import PlaceOrder from './LimitOrder/PlaceOrder'
import TradeCurrencyInputs from './TradeCurrencyInputs'
import TradeCurrencyShortcuts from './TradeCurrencyShortcuts'
import TradeDetails from './TradeDetails'
import TradeRefetchView from './TradeRefetchView'

const Trade = () => {
  const { isConnected } = useAccount()
  const location = useLocation()
  const chainId = useChainId()

  // TODO: use getIsPlaceOrderEnabled when BNB completely sunset
  const isPlaceOrdersEnabled =
    LIMIT_ORDER_MANAGER_ADDRESS[chainId] !== zeroAddress

  // swap <-> order toggle
  const [isSwap, setIsSwap] = useState<boolean>(true)

  // Decode search params
  const [searchParams] = useSearchParams()
  const inputCurrencyAddr = searchParams.get('inputCurrency')
  const outputCurrencyAddr = searchParams.get('outputCurrency')
  const paramAddresses = useMemo(
    () => [inputCurrencyAddr, outputCurrencyAddr],
    [inputCurrencyAddr, outputCurrencyAddr]
  )
  const {
    tokens: [inputCurrencyParam, outputCurrencyParam]
  } = useSdkCurrencies({
    addresses: paramAddresses
  })

  // Input/output currencies
  const [isExactIn, setIsExactIn] = useState(true)
  const [selectedInputCurrency, setInputCurrency] = useState<
    Currency | undefined
  >(CNATIVE.onChain(chainId))
  const [selectedOutputCurrency, setOutputCurrency] = useState<
    Currency | undefined
  >()

  const handleSetCurrency = (currency: Currency, isInput: boolean) => {
    if (isInput) {
      setInputCurrency(currency)
    } else {
      setOutputCurrency(currency)
    }
  }

  const inputCurrency = useMemo(
    () =>
      isSwap
        ? selectedInputCurrency ?? inputCurrencyParam
        : selectedInputCurrency,
    [selectedInputCurrency, inputCurrencyParam, isSwap]
  )
  const outputCurrency = useMemo(
    () =>
      isSwap
        ? selectedOutputCurrency ?? outputCurrencyParam
        : selectedOutputCurrency,
    [selectedOutputCurrency, outputCurrencyParam, isSwap]
  )

  // Addresses
  const inputCurrencyAddress = getCurrencyAddress(inputCurrency)
  const outputCurrencyAddress = getCurrencyAddress(outputCurrency)

  // User balances
  const inputBalance = useTokenBalance({
    token: inputCurrencyAddress
  })
  const debouncedRefetchInputBalance = debounce(
    () => inputBalance.refetch(),
    2000
  )
  const outputBalance = useTokenBalance({
    token:
      outputCurrency instanceof Token
        ? (outputCurrency as Token).address
        : undefined
  })
  const debouncedRefetchOutputBalance = debounce(
    () => outputBalance.refetch(),
    2000
  )

  // User input
  const [typedValue, setTypedValue] = useState('')
  const userTypedCurrency = isExactIn ? inputCurrency : outputCurrency
  const typedTokenAmount = useMemo(
    () =>
      wrappedCurrencyAmount(
        tryParseAmount(typedValue, userTypedCurrency),
        chainId
      ),
    [typedValue, userTypedCurrency, chainId]
  )

  // Get token USD prices
  const inputWrappedCurrencyAddress = wrappedCurrency(inputCurrency, chainId)
    ?.address
  const outputWrappedCurrencyAddress = wrappedCurrency(outputCurrency, chainId)
    ?.address
  const { data: tokenUsdPrices } = useGetTokensUsdPrice({
    tokenAddresses:
      inputWrappedCurrencyAddress && outputWrappedCurrencyAddress
        ? [inputWrappedCurrencyAddress, outputWrappedCurrencyAddress]
        : []
  })
  const inputCurrencyUsdPrice =
    tokenUsdPrices?.[inputWrappedCurrencyAddress?.toLowerCase() || '']
  const outputCurrencyUsdPrice =
    tokenUsdPrices?.[outputWrappedCurrencyAddress?.toLowerCase() || '']

  // Fetch trades
  const refetchInterval = 30000 // 30 seconds
  const [isRefreshQuoteEnabled, setIsRefreshQuoteEnabled] = useState(true)
  const {
    data: tradeRoutes,
    isFetching: isFetchingBestPath,
    lastFetchTime,
    refetch: refetchTradeBestPath
  } = useGetTradeRoutes({
    inputCurrency,
    isExactIn,
    outputCurrency,
    refetchInterval: isRefreshQuoteEnabled ? refetchInterval : 0,
    typedTokenAmount
  })

  const [selectedTradeRoute, setSelectedTradeRoute] = useState<
    TradeBestPath | undefined
  >()

  useEffect(() => {
    setSelectedTradeRoute(tradeRoutes?.[0])
  }, [tradeRoutes])

  const isLiquidityInsufficient =
    !isFetchingBestPath && !selectedTradeRoute && !!typedValue

  // Get amounts usd prices
  const inputAmountUsdPrice =
    selectedTradeRoute && inputCurrencyUsdPrice
      ? Number(selectedTradeRoute.amountIn.formatted) * inputCurrencyUsdPrice
      : undefined
  const outputAmountUsdPrice =
    selectedTradeRoute && outputCurrencyUsdPrice
      ? Number(selectedTradeRoute.amountOut.formatted) * outputCurrencyUsdPrice
      : undefined
  const priceImpactInUsd =
    inputAmountUsdPrice && outputAmountUsdPrice
      ? ((outputAmountUsdPrice - inputAmountUsdPrice) / inputAmountUsdPrice) *
        100
      : undefined

  // Slippage
  const {
    slippageSettings: { swapV2: lbSlippage, v1: normalSlippage }
  } = useSlippageSettings()
  const allowedSlippage = useMemo(() => {
    if (selectedTradeRoute) {
      // use low slippage if only v2 pools are used in the trade
      const isAllV2Pools = selectedTradeRoute.path.every(
        (path) => path.version !== PoolVersion.V1
      )
      if (isAllV2Pools) {
        return Math.trunc(lbSlippage * 100)
      }
    }
    return Math.trunc(normalSlippage * 100)
  }, [selectedTradeRoute, normalSlippage, lbSlippage])

  // Price impact
  const { isSafeModeEnabled } = useIsSafeModeEnabled()
  const priceImpact = priceImpactInUsd
    ? priceImpactInUsd >= 0
      ? 0
      : Math.abs(priceImpactInUsd)
    : selectedTradeRoute
      ? Number(selectedTradeRoute.priceImpact.toFixed(2))
      : undefined
  const isPriceImpactHigh = priceImpact ? priceImpact >= 5 : false

  // Input error
  const hasEnoughInputCurrency =
    inputBalance.data && selectedTradeRoute
      ? Number(inputBalance.data.formatted) >=
        Number(selectedTradeRoute.amountIn.formatted)
      : true

  // Approval
  const routerAddress =
    LB_ROUTER_V22_ADDRESS[chainId] !== zeroAddress
      ? LB_ROUTER_V22_ADDRESS[chainId]
      : LB_ROUTER_V21_ADDRESS[chainId]
  const {
    approvalType,
    approveAsync,
    isApproved,
    isApproving,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: selectedTradeRoute?.amountIn.value,
    spender: routerAddress,
    token: inputCurrencyAddress,
    tokenSymbol: inputCurrency?.symbol
  })

  // Approve click handler
  const onApproveClick = approveAsync
    ? async () => {
        setIsRefreshQuoteEnabled(false)
        try {
          await approveAsync()
        } finally {
          setIsRefreshQuoteEnabled(true)
        }
      }
    : undefined

  // Refresh state on swap success
  const onSwapSuccess = useCallback(() => {
    resetApproval()
    setTypedValue('')
    setIsRefreshQuoteEnabled(true)
    debouncedRefetchInputBalance()
    debouncedRefetchOutputBalance()
  }, [
    debouncedRefetchInputBalance,
    debouncedRefetchOutputBalance,
    resetApproval
  ])

  // Reset approval on currency change
  useEffect(() => {
    resetApproval()
  }, [inputCurrency, resetApproval])

  // Swap
  const isSwapEnabled =
    hasEnoughInputCurrency &&
    !isFetchingBestPath &&
    (isApproved || (inputCurrency?.isNative ?? false))
  const {
    error: swapError,
    isSwapping,
    receipt: swapReceipt,
    resetSwap,
    swapAsync
  } = useSwap({
    allowedSlippage,
    currencyIn: inputCurrency,
    currencyOut: outputCurrency,
    enabled: isSwapEnabled,
    isExactIn,
    onSwapSuccess,
    trade: selectedTradeRoute
  })

  // Swap click handler
  const onSwapClick = async () => {
    setIsRefreshQuoteEnabled(false)
    try {
      await swapAsync?.()
      tradeSwap(
        inputCurrency?.symbol,
        outputCurrency?.symbol,
        selectedTradeRoute?.path,
        selectedTradeRoute?.type
      )
    } catch {
      setIsRefreshQuoteEnabled(true)
    }
  }

  // Wrap / Unwrap
  const {
    isLoading: isLoadingWrapUnwrap,
    state: wrapUnwrapState,
    write: wrapUnwrap
  } = useWrapUnwrapNativeCurrency({
    amount: typedTokenAmount?.toExact(),
    currency0: inputCurrency,
    currency1: outputCurrency,
    onSuccess: onSwapSuccess
  })

  // Update inputs and currencies when chain changes or when tab is changed
  useEffect(() => {
    setInputCurrency(!!inputCurrencyAddr ? undefined : CNATIVE.onChain(chainId))
    setOutputCurrency(undefined)
    setTypedValue('')
  }, [chainId, inputCurrencyAddr, isSwap])

  // Action handlers
  const onChangeSwapDirectionClick = useCallback(() => {
    const newInputCurrency = outputCurrency
    const newOutputCurrency = inputCurrency
    setIsExactIn((curr) => !curr)
    setInputCurrency(newInputCurrency)
    setOutputCurrency(newOutputCurrency)
  }, [inputCurrency, outputCurrency])

  return (
    <Flex
      mt={{ base: 6, md: 10 }}
      mb="200px"
      justify="center"
      minH="calc(100vh - 400px)"
    >
      <PageHelmet
        title={TRADE_HELMET_TITLE}
        description={TRADE_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <Grid
        gap={{ base: 0, lg: 8 }}
        w="full"
        maxW={{ '2xl': '1600px', base: '1400px' }}
        templateColumns={{
          base: 'minmax(0, 1fr)',
          lg: 'minmax(0, 7fr) minmax(0, 5fr)'
        }}
      >
        {inputCurrency && outputCurrency ? (
          <Box
            order={{ base: 1, md: 0 }}
            mt={{ base: 4, md: 0 }}
            px={{ base: 4, md: 0 }}
          >
            <Box
              w="full"
              border="1px solid"
              borderColor="border"
              borderRadius={{ base: 'md', md: '2xl' }}
              overflow="hidden"
              bg="white"
              _dark={{ bg: '#151822' }}
              p={1}
            >
              <TradingViewChart
                inputCurrency={inputCurrency}
                outputCurrency={outputCurrency}
              />
            </Box>
          </Box>
        ) : (
          <Box />
        )}
        <Flex width="full" flexDir="column" px={{ base: 4, xl: 0 }} gap={4}>
          <Flex
            flexDir="row"
            justifyContent={isPlaceOrdersEnabled ? 'space-between' : 'flex-end'}
            align="center"
            overflow={{ base: 'auto', md: 'initial' }}
            gap={2}
          >
            {isPlaceOrdersEnabled ? (
              <Tabs
                variant="soft-rounded"
                colorScheme="accent"
                index={isSwap ? 0 : 1}
                onChange={(index) => setIsSwap(index === 0)}
                flexShrink={0}
              >
                <TabList>
                  <Tab data-cy="swap-tab">
                    <Trans>Swap</Trans>
                  </Tab>
                  <Tab data-cy="place-order-tab">
                    <Trans>Place Order</Trans>
                  </Tab>
                </TabList>
              </Tabs>
            ) : null}
            <HStack spacing={1}>
              {!!selectedTradeRoute ? (
                <TradeRefetchView
                  isRefreshQuoteEnabled={isRefreshQuoteEnabled}
                  lastFetchTime={lastFetchTime}
                  refetchInterval={refetchInterval}
                  onRefetchClick={refetchTradeBestPath}
                />
              ) : null}
              <SlippageSettingsPicker
                type={isSwap ? 'swap' : 'poolV2'}
                iconButtonProps={{
                  'aria-label': 'open settings',
                  bg: 'transparent',
                  borderRadius: 'full',
                  flexShrink: 0
                }}
              />
            </HStack>
          </Flex>

          {isSwap ? (
            <TradeCurrencyInputs
              inputCurrencyProps={{
                amountUsd: inputAmountUsdPrice
                  ? '~' +
                    formattedNum(inputAmountUsdPrice, {
                      allowDecimalsOver1000: true,
                      places: 2,
                      usd: true
                    })
                  : undefined,
                balance: inputBalance.data?.formatted,
                currency: inputCurrency,
                currencyAddress: inputCurrencyAddress,
                heading: t`From`,
                isDisabled: !isExactIn && isFetchingBestPath,
                onCurrencyChange: (currency) => {
                  const cAddress = currency.isToken
                    ? currency.address
                    : undefined
                  const outputCurrencyAddress = outputCurrency?.isToken
                    ? outputCurrency.address
                    : undefined
                  currency.symbol === outputCurrency?.symbol &&
                  cAddress === outputCurrencyAddress
                    ? onChangeSwapDirectionClick()
                    : setInputCurrency(currency)
                },
                onValueChange: (value) => {
                  setIsExactIn(true)
                  setTypedValue(value)
                },
                rightElement: inputCurrency ? (
                  <MaxButton
                    data-cy="trade-currency-input-max-button"
                    borderRadius="full"
                    balance={inputBalance.data?.formatted}
                    onClick={() => {
                      setIsExactIn(true)
                      setTypedValue(inputBalance.data?.formatted ?? '')
                    }}
                  />
                ) : (
                  <TradeCurrencyShortcuts
                    otherSelectedCurrency={outputCurrency}
                    onCurrencyClick={setInputCurrency}
                  />
                ),
                value: wrapUnwrapState
                  ? typedValue
                  : isExactIn
                    ? typedValue
                    : selectedTradeRoute?.amountIn.formatted ?? '',
                wrapRightElementIfNeeded: !inputCurrency
              }}
              outputCurrencyProps={{
                amountUsd:
                  outputAmountUsdPrice && priceImpactInUsd
                    ? `~${formattedNum(outputAmountUsdPrice, {
                        allowDecimalsOver1000: true,
                        places: 2,
                        usd: true
                      })} (${priceImpactInUsd.toFixed(2)}%)`
                    : undefined,
                balance: outputBalance.data?.formatted,
                currency: outputCurrency,
                currencyAddress: outputCurrencyAddress,
                heading: t`To`,
                isDisabled: isExactIn && isFetchingBestPath,
                onCurrencyChange: (currency) => {
                  const cAddress = currency.isToken
                    ? currency.address
                    : undefined
                  const inputCurrencyAddress = inputCurrency?.isToken
                    ? inputCurrency.address
                    : undefined

                  currency.symbol === inputCurrency?.symbol &&
                  inputCurrencyAddress === cAddress
                    ? onChangeSwapDirectionClick()
                    : setOutputCurrency(currency)
                },
                onValueChange: (value) => {
                  setIsExactIn(false)
                  setTypedValue(value)
                },
                rightElement: !outputCurrency ? (
                  <TradeCurrencyShortcuts
                    otherSelectedCurrency={inputCurrency}
                    onCurrencyClick={setOutputCurrency}
                  />
                ) : undefined,
                value: wrapUnwrapState
                  ? typedValue
                  : isExactIn
                    ? selectedTradeRoute?.amountOut.formatted ?? ''
                    : typedValue,
                wrapRightElementIfNeeded: !outputCurrency
              }}
              onChangeSwapDirectionClick={onChangeSwapDirectionClick}
              isChangeSwapDirectionDisabled={isFetchingBestPath || isSwapping}
              bottomContent={
                <Box w="full" pt={selectedTradeRoute ? 4 : 0}>
                  {selectedTradeRoute ? (
                    <TradeDetails
                      trades={tradeRoutes}
                      selectedTrade={selectedTradeRoute}
                      allowedSlippage={allowedSlippage}
                      isExactIn={isExactIn}
                      onTradeSelected={setSelectedTradeRoute}
                      outputCurrencyUsdPrice={outputCurrencyUsdPrice}
                    />
                  ) : null}
                  <Flex flexDir="column" pt={4} gap={4}>
                    {onApproveClick &&
                    !isApproved &&
                    hasEnoughInputCurrency &&
                    !isFetchingBestPath ? (
                      <ApproveTokenButton
                        data-cy="approve-button"
                        amount={selectedTradeRoute?.amountIn.formatted}
                        currencySymbol={inputCurrency?.symbol}
                        approvalType={approvalType}
                        onApprovalTypeSelect={setApprovalType}
                        isLoading={isApproving}
                        onClick={onApproveClick}
                      />
                    ) : null}
                    {wrapUnwrapState && !!wrapUnwrap ? (
                      <Web3Button
                        data-cy="wrap-unwrap-button"
                        variant="primary"
                        colorScheme="accent"
                        w="full"
                        size="xl"
                        isLoading={isLoadingWrapUnwrap}
                        loadingText={wrapUnwrapState}
                        onClick={() => wrapUnwrap?.()}
                      >
                        {wrapUnwrapState}
                      </Web3Button>
                    ) : (
                      <Web3Button
                        data-cy="swap-button"
                        variant="primary"
                        colorScheme={
                          (isPriceImpactHigh && isConnected) || swapError
                            ? 'red'
                            : 'accent'
                        }
                        w="full"
                        size="xl"
                        isLoading={isFetchingBestPath || isSwapping}
                        loadingText={
                          isFetchingBestPath
                            ? t`Fetching best trade`
                            : t`Waiting for confirmation`
                        }
                        isDisabled={
                          !typedTokenAmount ||
                          !isSwapEnabled ||
                          isLiquidityInsufficient ||
                          (isPriceImpactHigh && isSafeModeEnabled)
                        }
                        onClick={onSwapClick}
                      >
                        {isLiquidityInsufficient ? (
                          <Trans>Insufficient liquidity for this trade</Trans>
                        ) : hasEnoughInputCurrency ? (
                          isPriceImpactHigh ? (
                            isSafeModeEnabled ? (
                              <Trans>Safe Mode Activated</Trans>
                            ) : (
                              <Trans>Swap Anyway</Trans>
                            )
                          ) : swapError ? (
                            <Trans>Swap Anyway</Trans>
                          ) : (
                            <Trans>Swap</Trans>
                          )
                        ) : (
                          <Trans>Not enough {inputCurrency?.symbol}</Trans>
                        )}
                      </Web3Button>
                    )}
                    {swapError ? (
                      <CopyableError
                        textProps={{ mt: -2 }}
                        error={swapError.message}
                        summary={swapError.summary}
                      />
                    ) : null}

                    {isPriceImpactHigh && isSafeModeEnabled ? (
                      <Text
                        fontSize="sm"
                        textColor="textSecondary"
                        textAlign="center"
                        mt={-2}
                      >
                        <Trans>
                          Safe mode prevents high price impact trade. It can be
                          accessed in Settings.
                        </Trans>
                      </Text>
                    ) : null}
                  </Flex>
                </Box>
              }
            />
          ) : (
            <PlaceOrder
              inputCurrency={inputCurrency}
              outputCurrency={outputCurrency}
              handleSetCurrency={handleSetCurrency}
              onChangeSwapDirectionClick={onChangeSwapDirectionClick}
            />
          )}

          {outputCurrency && outputCurrency.isToken && isSwap ? (
            <Center w="full">
              <AddToWalletButton token={outputCurrency} />
            </Center>
          ) : null}

          {isSwap && swapReceipt ? (
            <Erc404TradeCompleted receipt={swapReceipt} onDismiss={resetSwap} />
          ) : null}
        </Flex>
      </Grid>
    </Flex>
  )
}

export default Trade
