import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react'
import { supportedChains } from 'constants/chains'
import React from 'react'
import { useShowTestnets } from 'state/settings/hooks'

interface ChainSelectorProps {
  onChainSelect: (chainId: number) => void
  selectedChainId: number
  additionalMenuItems?: JSX.Element[]
  menuButtonProps?: MenuButtonProps
}

const ChainSelector = ({
  additionalMenuItems,
  menuButtonProps,
  onChainSelect,
  selectedChainId
}: ChainSelectorProps) => {
  const { showTestnets } = useShowTestnets()
  const chains = showTestnets
    ? supportedChains.filter((chain) => !chain.isDeprecated)
    : supportedChains.filter((chain) => !chain.testnet && !chain.isDeprecated)
  const chain = supportedChains.find((chain) => chain.id === selectedChainId)
  return (
    <Menu id="chain-selector">
      <MenuButton
        minW="76px"
        h="48px"
        as={Button}
        variant="outline"
        flexShrink={0}
        rightIcon={<ChevronDownIcon />}
        {...menuButtonProps}
      >
        <HStack>
          <Image alt="chain-icon" src={chain?.iconUrl} boxSize={5} />
          <Text>{chain?.name}</Text>
        </HStack>
      </MenuButton>
      <MenuList>
        {chains.map((chain, i) => (
          <MenuItem
            key={i}
            className={chain.slug}
            h="48px"
            icon={
              <Image
                alt="chain-selector-option-icon"
                src={chain.iconUrl}
                boxSize={5}
              />
            }
            onClick={() => onChainSelect(chain.id)}
          >
            {chain.name}
          </MenuItem>
        ))}
        {additionalMenuItems ? additionalMenuItems : null}
      </MenuList>
    </Menu>
  )
}

export default ChainSelector
