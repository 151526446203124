import { Box, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Pair } from '@traderjoe-xyz/sdk'
import { Currency, TokenAmount } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { wrappedCurrency } from 'utils/wrappedCurrency'

interface CreatePoolPriceDetailProps {
  currency0: Currency
  currency1: Currency
  currency0Amount?: bigint
  currency1Amount?: bigint
}

const CreatePoolPriceDetail = ({
  currency0,
  currency0Amount,
  currency1,
  currency1Amount
}: CreatePoolPriceDetailProps) => {
  const chainId = useChainId()

  const areInputsGreaterThanZero =
    currency0Amount &&
    currency1Amount &&
    currency0Amount > 0 &&
    currency1Amount > 0

  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)
  const pair =
    wrappedCurrency0 && wrappedCurrency1 && currency0Amount && currency1Amount
      ? new Pair(
          new TokenAmount(wrappedCurrency0, currency0Amount.toString()),
          new TokenAmount(wrappedCurrency1, currency1Amount.toString()),
          chainId
        )
      : undefined

  return (
    <Box
      data-cy="create-pool-price-detail"
      p={4}
      border="1px"
      borderColor="border"
      borderRadius="xl"
    >
      <Heading size="sm">Initial prices and pool share</Heading>
      <SimpleGrid columns={3} mt={4}>
        <VStack spacing={0}>
          <Text fontWeight="semibold" fontSize="lg">
            {wrappedCurrency1 && pair && areInputsGreaterThanZero
              ? pair.priceOf(wrappedCurrency1).toSignificant(6)
              : '-'}
          </Text>
          <Text
            color="textSecondary"
            fontSize="sm"
          >{`${currency0.symbol} per ${currency1.symbol}`}</Text>
        </VStack>
        <VStack spacing={0}>
          <Text fontWeight="semibold" fontSize="lg">
            {wrappedCurrency0 && pair && areInputsGreaterThanZero
              ? pair.priceOf(wrappedCurrency0).toSignificant(6)
              : '-'}
          </Text>
          <Text
            color="textSecondary"
            fontSize="sm"
          >{`${currency1.symbol} per ${currency0.symbol}`}</Text>
        </VStack>
        <VStack spacing={0}>
          <Text fontWeight="semibold" fontSize="lg">
            {pair && areInputsGreaterThanZero ? '100%' : '-'}
          </Text>
          <Text color="textSecondary" fontSize="sm">
            <Trans>Share of Pool</Trans>
          </Text>
        </VStack>
      </SimpleGrid>
    </Box>
  )
}

export default CreatePoolPriceDetail
