import {
  Box,
  Center,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { CNATIVE, Token } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import Web3Button from 'components/Web3Button'
import useAllMarketsClaimable from 'hooks/pool/v2/useAllMarketsClaimable'
import useLBPairClaimRewards, {
  UseLBPairClaimRewardsArg
} from 'hooks/pool/v2/useLBPairClaimRewards'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import React, { useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'
import { useAccount } from 'wagmi'

import { MARKETS, REWARDER_ADDRESS } from './constants'

const ClaimRewardsV1 = () => {
  const { isConnected } = useAccount()
  const chainId = useChainId()

  // get all claimable rewards
  const { data: claimableRewards, isLoading: isLoadingRewards } =
    useAllMarketsClaimable({
      markets: MARKETS[chainId]
    })

  // get tokens info
  const tokenAddresses = useMemo(() => {
    return (
      claimableRewards
        ?.map((reward) =>
          reward.claimableRewards.map((reward) => reward.tokenAddress)
        )
        .flat() || []
    )
  }, [claimableRewards])
  const { tokens } = useSdkCurrencies({
    addresses: tokenAddresses
  })

  // build displayable rewards
  const displayableRewards = useMemo(() => {
    if (!claimableRewards) return undefined

    const rewards: {
      amount: string
      token: CNATIVE | Token
    }[] = []

    claimableRewards.forEach(({ claimableRewards }) => {
      claimableRewards.forEach((reward) => {
        const index = rewards.findIndex(
          (r) => r.token.isToken && r.token.address === reward.tokenAddress
        )

        if (index >= 0) {
          rewards[index].amount = (
            BigInt(rewards[index].amount) + BigInt(reward.amount)
          ).toString()
        } else {
          const token = tokens?.find(
            (token) =>
              token?.isToken &&
              token.address.toLowerCase() === reward.tokenAddress.toLowerCase()
          )
          if (token) {
            rewards.push({
              amount: reward.amount,
              token
            })
          }
        }
      })
    })

    return rewards
  }, [tokens, claimableRewards])

  // build args for batch claim
  const claimRewardsArgs = useMemo(() => {
    if (!claimableRewards) return undefined

    const args: UseLBPairClaimRewardsArg[] = []

    claimableRewards.forEach(({ claimableRewards, epoch, market }) => {
      claimableRewards.forEach((reward) => {
        args.push({
          amount: reward.amount,
          epoch,
          market,
          tokenAddress: reward.tokenAddress
        })
      })
    })

    return args
  }, [claimableRewards])

  // claim rewards
  const { batchClaimRewards, isLoading: isClaimingRewards } =
    useLBPairClaimRewards({
      claimableRewards: claimRewardsArgs,
      rewarderAddress: REWARDER_ADDRESS[chainId]
    })

  return (
    <Box
      pt={{ base: 6, md: 12 }}
      maxW="1200px"
      margin="0 auto"
      minH="calc(100vh - 80px)"
      px={4}
    >
      <Heading size="lg">Claim Rewards</Heading>

      <Text mt={2}>
        Joe emissions officially ended on Nov 2023. Check your unclaimed JOE
        rewards balance and claim as soon as possible.
      </Text>

      {!isConnected ? (
        <Center mt="150px">
          <Web3Button variant="primary" colorScheme="accent">
            Connect Wallet
          </Web3Button>
        </Center>
      ) : isLoadingRewards ? (
        <Center mt="150px">
          <Spinner />
        </Center>
      ) : displayableRewards && displayableRewards.length > 0 ? (
        <SimpleGrid columns={4} mt={12} spacing={4}>
          {displayableRewards.map((reward) => {
            return (
              <Center
                key={
                  reward.token.isToken
                    ? reward.token.address
                    : reward.token.symbol
                }
                border="1px solid"
                borderColor="border"
                borderRadius="lg"
                bg="bgCard"
                p={4}
              >
                <VStack>
                  <CurrencyLogo
                    address={
                      reward.token.isToken ? reward.token.address : undefined
                    }
                    symbol={reward.token.symbol}
                    boxSize={7}
                  />
                  <Text fontWeight="bold">{`${formattedNum(
                    formatUnits(BigInt(reward.amount), reward.token.decimals)
                  )} ${reward.token.symbol}`}</Text>
                </VStack>
              </Center>
            )
          })}
        </SimpleGrid>
      ) : claimableRewards && claimableRewards.length === 0 ? (
        <Center mt="150px">
          <Text textColor="textSecondary">You have no rewards to claim.</Text>
        </Center>
      ) : null}

      {batchClaimRewards ? (
        <Center w="full">
          <Web3Button
            variant="primary"
            colorScheme="accent"
            minW="150px"
            mt={10}
            isLoading={isClaimingRewards}
            onClick={() => batchClaimRewards()}
          >
            Claim All
          </Web3Button>
        </Center>
      ) : null}
    </Box>
  )
}

export default ClaimRewardsV1
