import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency } from '@traderjoe-xyz/sdk-core'
import useActiveChain from 'hooks/useActiveChain'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useSlippageSettings } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { getCurrencyAddress } from 'utils/wrappedCurrency'
import { formatUnits, TransactionReceipt } from 'viem'
import { useWriteContract } from 'wagmi'

import useAddLiquidityConfig from './useAddLiquidityConfig'
import useAddLiquidityNativeConfig from './useAddLiquidityNativeConfig'

interface UseAddLiquidityProps {
  amountADesired?: bigint
  amountBDesired?: bigint
  enabled?: boolean
  onSuccess?: (data: TransactionReceipt) => void
  tokenA?: Currency
  tokenB?: Currency
}

/**  A hook to add liquidity to v1 pools
 * @tokenA : a token to add, can be undefined to use native currency
 * @tokenB : a token to add, can be undefined to use native currency
 * @amountADesired : amount of token A to add to the pool
 * @amountBDesired : amount of token B to add to the pool
 * @enabled : can be set to false if not all conditions are met for this call (tokens not approved for example)
 * @onSuccess : success callback
 */
const useAddLiquidity = ({
  amountADesired,
  amountBDesired,
  enabled,
  onSuccess,
  tokenA,
  tokenB
}: UseAddLiquidityProps) => {
  const { nativeCurrency } = useActiveChain()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const {
    slippageSettings: { v1: slippage }
  } = useSlippageSettings()
  const amountAMin = amountADesired
    ? (amountADesired * BigInt(10000 - slippage * 100)) / BigInt(10000)
    : undefined
  const amountBMin = amountBDesired
    ? (amountBDesired * BigInt(10000 - slippage * 100)) / BigInt(10000)
    : undefined

  const tokenAddressA = getCurrencyAddress(tokenA)
  const tokenAddressB = getCurrencyAddress(tokenB)

  const shouldUseAddLiquidityNative =
    tokenA && tokenB && (tokenA.isNative || tokenB.isNative)

  const { data: addLiquidityNativeConfig } = useAddLiquidityNativeConfig({
    amountADesired,
    amountAMin,
    amountBDesired,
    amountBMin,
    enabled: enabled && shouldUseAddLiquidityNative,
    tokenA: tokenAddressA,
    tokenB: tokenAddressB
  })

  const { data: addLiquidityConfig } = useAddLiquidityConfig({
    amountADesired,
    amountAMin,
    amountBDesired,
    amountBMin,
    enabled: enabled && !shouldUseAddLiquidityNative,
    tokenA: tokenAddressA,
    tokenB: tokenAddressB
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary =
          amountADesired && amountBDesired
            ? t`Add ${formattedNum(
                formatUnits(amountADesired, tokenA?.decimals ?? 18)
              )} ${tokenA?.symbol ?? nativeCurrency?.symbol} and ${formattedNum(
                formatUnits(amountBDesired, tokenB?.decimals ?? 18)
              )} ${tokenB?.symbol ?? nativeCurrency?.symbol}`
            : undefined
        if (!transactionSummary) return
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const addLiquidity = shouldUseAddLiquidityNative
    ? addLiquidityNativeConfig?.request
      ? () => writeContract(addLiquidityNativeConfig?.request)
      : undefined
    : addLiquidityConfig?.request
      ? () => writeContract(addLiquidityConfig?.request)
      : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash: data,
      onTransactionSuccess: onSuccess
    })

  return {
    addLiquidity,
    isLoading: isPending || isWaitingForTransaction,
    isSuccess
  }
}

export default useAddLiquidity
