import { Box, HStack, Tag, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Link } from 'react-router-dom'
import { LBPool, Pool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { searchClickMarket } from 'utils/measure'
import { getPoolDetailUrl } from 'utils/poolUrl'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface HeaderSearchResultRowProps {
  onClick: () => void
  pair: Pool | LBPool
  id?: string
  isHighlighted?: boolean
  onMouseEnter?: () => void
}

const HeaderSearchResultRow = ({
  id,
  isHighlighted,
  onClick,
  onMouseEnter,
  pair
}: HeaderSearchResultRowProps) => {
  const chainId = useChainId()
  const { nativeCurrency } = useActiveChain()

  const isToken0NativeWrapped = isWrappedNativeCurrency(
    pair.tokenX.address,
    chainId
  )
  const isToken1NativeWrapped = isWrappedNativeCurrency(
    pair.tokenY.address,
    chainId
  )
  const symbol0 = isToken0NativeWrapped
    ? nativeCurrency.symbol
    : pair.tokenX.symbol
  const symbol1 = isToken1NativeWrapped
    ? nativeCurrency.symbol
    : pair.tokenY.symbol

  const isRewarded = 'rewards' in pair && !!pair.rewards

  return (
    <HStack
      id={id}
      as={Link}
      aria-label={`Link to ${symbol0}-${symbol1} pool`}
      to={getPoolDetailUrl(pair, chainId)}
      spacing={4}
      px={4}
      py={3}
      borderRadius="lg"
      bg={isHighlighted ? 'hover' : undefined}
      _hover={{ bg: 'hover' }}
      onClick={() => {
        searchClickMarket(pair.name)
        onClick()
      }}
      onMouseEnter={onMouseEnter}
    >
      <DoubleCurrencyLogo
        address0={isToken0NativeWrapped ? undefined : pair.tokenX.address}
        symbol0={symbol0}
        address1={isToken1NativeWrapped ? undefined : pair.tokenY.address}
        symbol1={symbol1}
        boxSize={{ base: 6, md: 8 }}
      />
      <VStack spacing={0} align="flex-start">
        <HStack>
          <Text fontWeight="bold">{`${symbol0} - ${symbol1}`}</Text>
          {isRewarded ? (
            <Tag variant="solid" bg="green.400" textColor="white" size="sm">
              <Trans>REWARDS</Trans>
            </Tag>
          ) : null}
        </HStack>
        <HStack>
          <Text fontSize="sm">
            <Box as="span" textColor="textSecondary">
              <Trans>Volume (24H)</Trans>:
            </Box>{' '}
            {formattedNum(pair.volumeUsd, { usd: true })}
          </Text>
          <Text fontSize="sm">
            <Box as="span" textColor="textSecondary">
              <Trans>TVL</Trans>:
            </Box>{' '}
            {formattedNum(pair.liquidityUsd, { usd: true })}
          </Text>
          <Text fontSize="sm">
            <Box as="span" textColor="textSecondary">
              <Trans>Fees (24H)</Trans>:
            </Box>{' '}
            {formattedNum(pair.feesUsd, { usd: true })}
          </Text>
        </HStack>
      </VStack>
    </HStack>
  )
}

export default HeaderSearchResultRow
