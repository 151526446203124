import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Collapse,
  Flex,
  Grid,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import SearchBar from 'components/SearchBar'
import useSortedPools, { PoolSortMethod } from 'hooks/pool/useSortedPools'
import useActiveChain from 'hooks/useActiveChain'
import React, { useCallback, useState } from 'react'
import { ArrowUpDownIcon, SearchIcon } from 'theme/icons'

import PoolMobileCard from './PoolMobileCard'
import { PoolsTableProps } from './PoolsTable'

enum PoolsMobileSort {
  LeastAPR,
  LeastLiquidity,
  LeastVolume,
  MostAPR,
  MostLiquidity,
  MostVolume
}

const getPoolsSortTitle = (sort: PoolsMobileSort) => {
  switch (sort) {
    case PoolsMobileSort.MostLiquidity:
      return t`Highest Liquidity`
    case PoolsMobileSort.LeastLiquidity:
      return t`Lowest Liquidity`
    case PoolsMobileSort.MostVolume:
      return t`Highest Volume`
    case PoolsMobileSort.LeastVolume:
      return t`Lowest Volume`
    case PoolsMobileSort.MostAPR:
      return t`Highest APR`
    case PoolsMobileSort.LeastAPR:
      return t`Lowest APR`
  }
}

const PoolsMobileList = ({
  hasNextPage,
  isFetchingPage,
  isLoadingPools,
  onLoadMoreClick,
  onPoolsStatusChange,
  onQueryChange,
  pools,
  query,
  selectedPoolStatus,
  setSortMethod,
  sortMethod
}: PoolsTableProps): JSX.Element => {
  const chain = useActiveChain()

  const [showSearchBar, setShowSearchBar] = useState(false)
  const toggleSetShowSearchBar = () => setShowSearchBar(!showSearchBar)

  // Sorting
  const sorts = [
    PoolsMobileSort.MostVolume,
    PoolsMobileSort.LeastVolume,
    PoolsMobileSort.MostLiquidity,
    PoolsMobileSort.LeastLiquidity,
    PoolsMobileSort.MostAPR,
    PoolsMobileSort.LeastAPR
  ]
  const [sort, setSort] = useState(PoolsMobileSort.MostVolume)
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)

  const handleChangeSort = useCallback(
    (s: PoolsMobileSort) => {
      setSort(s)
      switch (s) {
        case PoolsMobileSort.MostLiquidity:
        case PoolsMobileSort.LeastLiquidity:
          setSortMethod(PoolSortMethod.LIQUIDITY)
          break
        case PoolsMobileSort.MostVolume:
        case PoolsMobileSort.LeastVolume:
          setSortMethod(PoolSortMethod.VOLUME)
          break
        case PoolsMobileSort.MostAPR:
        case PoolsMobileSort.LeastAPR:
          setSortMethod(PoolSortMethod.APR)
          break
      }

      switch (s) {
        case PoolsMobileSort.MostLiquidity:
        case PoolsMobileSort.MostVolume:
        case PoolsMobileSort.MostAPR:
          setIsSortDescending(true)
          break
        case PoolsMobileSort.LeastLiquidity:
        case PoolsMobileSort.LeastVolume:
        case PoolsMobileSort.LeastAPR:
          setIsSortDescending(false)
          break
      }
    },
    [setSortMethod]
  )

  const sortedPools = useSortedPools({
    isSortDescending,
    pools,
    sortMethod
  })

  return (
    <Flex flexDir="column" gap={2}>
      <Tabs
        isFitted={true}
        variant="soft-rounded-outlined-white"
        flexShrink={0}
        index={
          selectedPoolStatus === 'main'
            ? 0
            : selectedPoolStatus === 'all'
              ? 1
              : 2
        }
        onChange={(index) =>
          onPoolsStatusChange(
            index === 0 ? 'main' : index === 1 ? 'all' : 'rewarded'
          )
        }
      >
        <TabList borderRadius="md" h={10} whiteSpace="nowrap">
          <Tab borderRadius="md">
            <Trans>Manual</Trans>
          </Tab>
          <Tab borderRadius="md">
            <Trans>All</Trans>
          </Tab>
          <Tab borderRadius="md">
            <Trans>Has Rewards</Trans>
          </Tab>
        </TabList>
      </Tabs>
      <Collapse in={!showSearchBar} animateOpacity>
        <Grid templateColumns="40px 1fr" gap={2}>
          <IconButton
            aria-label="open search bar"
            boxSize="40px"
            variant="outline"
            size="sm"
            icon={<SearchIcon boxSize={4} />}
            onClick={toggleSetShowSearchBar}
          />
          <Menu>
            <MenuButton
              w="full"
              variant="outline"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              borderColor="border"
              borderWidth={1}
            >
              <HStack>
                <Show above="md">
                  <Text color="textSecondary" fontWeight="normal">
                    <Trans>Sorting:</Trans>
                  </Text>
                </Show>
                <Text color="textPrimary" fontWeight="semibold">
                  {getPoolsSortTitle(sort)}
                </Text>
              </HStack>
            </MenuButton>
            <MenuList>
              {sorts.map((s, i) => (
                <MenuItem key={i} h="48px" onClick={() => handleChangeSort(s)}>
                  {getPoolsSortTitle(s)}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Grid>
      </Collapse>
      <Collapse in={showSearchBar} animateOpacity>
        <Grid p="2px" w="full" templateColumns="40px 1fr" gap={2}>
          <IconButton
            aria-label="close search bar"
            boxSize="40px"
            variant="outline"
            size="sm"
            icon={<ArrowUpDownIcon boxSize={4} />}
            onClick={toggleSetShowSearchBar}
          />
          <SearchBar
            placeholder={t`Search by name, symbol or address`}
            value={query}
            onValueChange={onQueryChange}
          />
        </Grid>
      </Collapse>
      {isLoadingPools ? (
        <Center mt={8}>
          <Spinner />
        </Center>
      ) : selectedPoolStatus === 'rewarded' && sortedPools.length === 0 ? (
        <Center mt={10}>
          <Text textColor="textSecondary">{`Reward Pools have ended on ${chain.name}`}</Text>
        </Center>
      ) : (
        <>
          <Flex flexDir="column" gap={2} mb={4}>
            {sortedPools.map((pool, idx) => (
              <PoolMobileCard key={idx} pool={pool} />
            ))}
          </Flex>
          {!isLoadingPools &&
          query === '' &&
          sortedPools.length > 0 &&
          hasNextPage ? (
            <Button
              variant="outline-bold"
              size="lg"
              alignSelf="center"
              w="160px"
              isLoading={isFetchingPage}
              loadingText={t`Loading`}
              onClick={onLoadMoreClick}
            >
              <Trans>Load More</Trans>
            </Button>
          ) : sortedPools.length > 0 && !hasNextPage ? (
            <Center mt={4}>
              <Text color="textSecondary" fontSize="sm">
                <Trans>All pools have been loaded</Trans>
              </Text>
            </Center>
          ) : null}
        </>
      )}
    </Flex>
  )
}

export default PoolsMobileList
