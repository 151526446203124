import { gql } from 'graphql-request'

// For sJOE Analytics
const SJoeAnalyticsQuery = gql`
  query stableJoeDayDatasQuery($first: Int! = 100, $dateAfter: Int!) {
    stableJoeDayDatas(
      first: $first
      orderBy: date
      orderDirection: asc
      where: { date_gte: $dateAfter }
    ) {
      id
      date
      totalJoeStaked
    }
  }
`

const VeJoeActiveUserCount = gql`
  query veJoeActiveUserCount {
    veJoes {
      activeUserCount
    }
  }
`

const VeJoeMedianStakerBalanceQuery = gql`
  query veJoeMedianStakerBalanceQuery($first: Int!, $skip: Int!) {
    users(
      where: { veJoeBalance_gt: "0" }
      first: $first
      skip: $skip
      orderBy: veJoeBalance
      orderDirection: desc
    ) {
      id
      veJoeBalance
    }
  }
`

const FeeBankSwapsQuery = gql`
  query feeBankSwapsQuery($first: Int! = 100) {
    swaps(first: $first, orderBy: timestamp, orderDirection: desc) {
      id
      tokenInSymbol
      tokenOutSymbol
      amountIn
      amountOutUsd
      timestamp
    }
  }
`

const FeeBankDayDatasQuery = gql`
  query feeBankDayDatasQuery($first: Int! = 100, $dateAfter: Int!) {
    dayDatas(
      first: $first
      orderBy: date
      orderDirection: desc
      where: { date_gte: $dateAfter }
    ) {
      id
      date
      usdRemitted
    }
  }
`

export {
  FeeBankDayDatasQuery,
  FeeBankSwapsQuery,
  SJoeAnalyticsQuery,
  VeJoeActiveUserCount,
  VeJoeMedianStakerBalanceQuery
}
