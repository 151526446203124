import { Vault } from 'types/vault'

export const getFarmAndPoolId = (
  farmId: string
): { farmAddress: string; poolId: string } => {
  const [farmAddress, poolId] = farmId?.split('-') || []
  return { farmAddress, poolId }
}

export const vaultHasFarm = (vault: Vault): boolean => {
  return (
    vault.farm !== null &&
    !(Object.keys(vault.farm).length === 0) &&
    vault.farm.rewardsPerSec > 0
  )
}
