import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Divider, Flex, HStack, Text } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import useActiveChain from 'hooks/useActiveChain'
import useGetRecentTransactions from 'hooks/useGetRecentTransactions'
import React from 'react'

import WalletMenuItem from '../ConnectedScreen/WalletMenuItem'

type TransactionStatus = 'confirmed' | 'failed' | 'pending'

const getTransactionStatusTranslation = (status: TransactionStatus) => {
  switch (status) {
    case 'confirmed':
      return t`Confirmed`
    case 'failed':
      return t`Failed`
    case 'pending':
      return t`Pending`
  }
}

const ActivityList = ({ walletAddress }: { walletAddress: string }) => {
  const { blockExplorers, id: chainId } = useActiveChain()
  const transactions = useGetRecentTransactions(walletAddress, chainId) || []

  const latestTransactions = transactions.slice(0, 6)

  const CONFIRMATION_COLOR_MAP = {
    ['confirmed']: 'green.500',
    ['failed']: 'red.500',
    ['pending']: 'yellow.500'
  } as { [key: string]: string }

  return (
    <Box>
      {latestTransactions.length === 0 ? (
        <HStack px={6} pt={4} w="full">
          <Text fontWeight="normal" fontSize="sm">
            <Trans>Most recent transactions will appear here...</Trans>
          </Text>
        </HStack>
      ) : (
        latestTransactions.map((transaction) => {
          return (
            <HStack
              key={transaction.hash}
              px={{ base: 0, lg: 3 }}
              pt={4}
              w="full"
            >
              <WalletMenuItem
                onClick={() =>
                  window.open(
                    `${blockExplorers?.default.url}/tx/${transaction.hash}`
                  )
                }
              >
                <Flex
                  flexDir="column"
                  justifyContent="flex-start"
                  fontWeight="semibold"
                  fontSize="sm"
                >
                  <Text textAlign="left">{transaction.description}</Text>
                  <Text
                    textAlign="left"
                    fontWeight="normal"
                    textTransform="capitalize"
                    color={CONFIRMATION_COLOR_MAP[transaction.status] ?? {}}
                  >
                    {getTransactionStatusTranslation(
                      transaction.status as TransactionStatus
                    )}
                  </Text>
                </Flex>
                <ChevronRightIcon
                  textColor="textSecondary"
                  ml="auto"
                  boxSize="20px"
                />
              </WalletMenuItem>
            </HStack>
          )
        })
      )}

      <Divider pt={4} />
      <HStack px={{ base: 0, lg: 3 }} align="center" pt={4}>
        <WalletMenuItem
          onClick={() =>
            window.open(
              `${blockExplorers?.default.url}/address/${walletAddress}`
            )
          }
        >
          <Flex
            flexDir="column"
            justifyContent="flex-start"
            fontWeight="semibold"
          >
            <Text
              textAlign="left"
              fontWeight="semibold"
              fontSize="sm"
              textColor="textSecondaryLight"
            >
              <Trans>View more on explorer</Trans>
            </Text>
          </Flex>
          <ChevronRightIcon
            textColor="textSecondary"
            ml="auto"
            boxSize="20px"
          />
        </WalletMenuItem>
      </HStack>
    </Box>
  )
}

export default ActivityList
