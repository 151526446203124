import { LBPairABI, LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import { LBPoolVersion } from 'types/pool'
import { getAddress } from 'viem'
import { useReadContract, useReadContracts } from 'wagmi'

interface UseLBPairFeeParametersProps {
  lbPairAddress?: string
  version?: LBPoolVersion
}

const useLBPairFeeParameters = ({
  lbPairAddress,
  version
}: UseLBPairFeeParametersProps) => {
  const chainId = useChainId()

  const { data: feeParameters, isLoading: isLoadingV2 } = useReadContract({
    abi: LBPairABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    chainId,
    functionName: 'feeParameters',
    query: { enabled: !!lbPairAddress && version === 'v2' }
  })

  const contractV21 = {
    abi: LBPairV21ABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    chainId
  }
  const { data: readsV21, isLoading: isLoadingV21 } = useReadContracts({
    contracts: [
      {
        ...contractV21,
        functionName: 'getStaticFeeParameters'
      },
      { ...contractV21, functionName: 'getBinStep' }
    ],
    query: { enabled: !!lbPairAddress && version !== 'v2' }
  })
  const binStepV21 = readsV21?.[1].result as number | undefined
  const feeParametersV21 = readsV21?.[0]?.result as unknown[] | undefined

  const binStep = version !== 'v2' ? binStepV21 : feeParameters?.binStep
  const baseFactor =
    version !== 'v2'
      ? (feeParametersV21?.[0] as number | undefined)
      : feeParameters?.baseFactor
  const baseFee =
    binStep && baseFactor ? (binStep * baseFactor) / 10000 : undefined

  return {
    data: {
      baseFactor,
      baseFee,
      binStep
    },
    isLoading:
      (isLoadingV2 && version === 'v2') || (isLoadingV21 && version !== 'v2')
  }
}

export default useLBPairFeeParameters
