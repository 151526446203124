import useActiveChain from 'hooks/useActiveChain'
import { TokenInfo, TokenInfoTag } from 'types/tokensList'
import { formatUnits } from 'viem'
import { useAccount, useBalance } from 'wagmi'

const useNativeTokenInfo = ({
  enabled
}: {
  enabled: boolean
}): { data: TokenInfo; isFetching: boolean } => {
  const chain = useActiveChain()
  const { address } = useAccount()
  const { data, isFetching } = useBalance({
    address,
    chainId: chain.id,
    query: { enabled }
  })
  return {
    data: {
      balance: data ? formatUnits(data.value, data.decimals) : undefined,
      chainId: chain.id,
      decimals: chain.nativeCurrency.decimals,
      name: chain.nativeCurrency.name,
      symbol: chain.nativeCurrency.symbol,
      tags: [TokenInfoTag.TOP]
    },
    isFetching
  }
}

export default useNativeTokenInfo
