import { ChainId, CNATIVE, Token as TokenSdk } from '@traderjoe-xyz/sdk-core'
import { formatDuration, intervalToDuration } from 'date-fns'
import { ExtraHooksRewarderData, HooksRewarderData } from 'types/lbHooksLens'
import { FormattedLbPoolReward, FormattedReward } from 'types/rewards'
import { formatUnits, zeroAddress } from 'viem'

import { getPriceFromBinId } from './bin'

export const getLbPoolRewards = ({
  chainId,
  currency0Decimals,
  currency1Decimals,
  extraRewarderData,
  lbBinStep,
  rewarderData
}: {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  currency0Decimals: number
  currency1Decimals: number
  extraRewarderData: ExtraHooksRewarderData
  lbBinStep: number
  rewarderData: HooksRewarderData
}) => {
  const rewards: FormattedLbPoolReward[] = []

  if (
    rewarderData.hooksParameters.hooks !== zeroAddress &&
    !rewarderData.parameters.isEnded
  ) {
    const rewardToken = rewarderData.parameters.rewardToken

    const rewardsPerSecond = Number(
      formatUnits(
        rewarderData.parameters.rewardPerSecond,
        Number(rewardToken.decimals)
      )
    )
    const rewardsPerDay = rewardsPerSecond * 60 * 60 * 24

    const duration = !rewarderData.parameters.isStarted
      ? `Starts in ${formatDuration(
          intervalToDuration({
            end: new Date(
              Number(rewarderData.parameters.lastUpdateTimestamp) * 1000
            ),
            start: new Date()
          }),
          {
            format: ['days', 'hours', 'minutes']
          }
        )}`
      : undefined

    rewards.push({
      duration: duration,
      range: getRewardRange({
        activeBinId: rewarderData.activeId,
        currency0Decimals,
        currency1Decimals,
        lbBinStep,
        rangeEnd: rewarderData.parameters.rangeEnd,
        rangeStart: rewarderData.parameters.rangeStart
      }),
      rewardsPerDay,
      token:
        rewardToken.token === zeroAddress
          ? CNATIVE.onChain(chainId)
          : new TokenSdk(
              chainId,
              rewardToken.token,
              Number(rewardToken.decimals),
              rewardToken.symbol
            ),
      type: 'base'
    })
  }

  if (
    extraRewarderData.hooksParameters.hooks !== zeroAddress &&
    !extraRewarderData.parameters.isEnded
  ) {
    const rewardToken =
      extraRewarderData.parameters.rewardToken.token === zeroAddress
        ? CNATIVE.onChain(chainId)
        : new TokenSdk(
            chainId,
            extraRewarderData.parameters.rewardToken.token,
            Number(extraRewarderData.parameters.rewardToken.decimals),
            extraRewarderData.parameters.rewardToken.symbol
          )

    const rewardsPerSecond = Number(
      formatUnits(
        extraRewarderData.parameters.rewardPerSecond,
        rewardToken.decimals
      )
    )
    const rewardsPerDay = rewardsPerSecond * 60 * 60 * 24

    const duration = extraRewarderData.parameters.isStarted
      ? intervalToDuration({
          end: new Date(
            Number(extraRewarderData.parameters.endTimestamp) * 1000
          ),
          start: new Date(
            Number(extraRewarderData.parameters.lastUpdateTimestamp) * 1000
          )
        })
      : intervalToDuration({
          end: new Date(
            Number(extraRewarderData.parameters.lastUpdateTimestamp) * 1000
          ),
          start: new Date()
        })

    rewards.push({
      duration: extraRewarderData.parameters.isEnded
        ? 'Ended'
        : extraRewarderData.parameters.isStarted
          ? formatDuration(duration, { format: ['months', 'days', 'hours'] })
          : `Starts in ${formatDuration(duration, {
              format: ['days', 'hours', 'minutes']
            })}`,
      range: getRewardRange({
        activeBinId: extraRewarderData.activeId,
        currency0Decimals,
        currency1Decimals,
        lbBinStep,
        rangeEnd: extraRewarderData.parameters.rangeEnd,
        rangeStart: extraRewarderData.parameters.rangeStart
      }),
      rewardsPerDay,
      token: rewardToken,
      type: 'extra'
    })
  }

  return rewards.filter((reward) => reward.rewardsPerDay > 0)
}

const getRewardRange = ({
  activeBinId,
  currency0Decimals,
  currency1Decimals,
  lbBinStep,
  rangeEnd: _rangeEnd,
  rangeStart: _rangeStart
}: {
  activeBinId: bigint
  currency0Decimals: number
  currency1Decimals: number
  lbBinStep: number
  rangeEnd: bigint
  rangeStart: bigint
}) => {
  const activeId = Number(activeBinId)
  const rangeStart = Number(_rangeStart)
  const rangeEnd = Number(_rangeEnd) - 1 // right range is not inclusive

  const activePrice = Number(
    getPriceFromBinId(
      activeId,
      lbBinStep,
      currency0Decimals,
      currency1Decimals,
      10
    )
  )
  const minPrice = Number(
    getPriceFromBinId(
      rangeStart,
      lbBinStep,
      currency0Decimals,
      currency1Decimals,
      10
    )
  )
  const maxPrice = Number(
    getPriceFromBinId(
      rangeEnd,
      lbBinStep,
      currency0Decimals,
      currency1Decimals,
      10
    )
  )

  return {
    end: rangeEnd,
    maxPrice,
    minPrice,
    numBins: rangeEnd - rangeStart + 1,
    spread: {
      max: ((maxPrice - activePrice) / activePrice) * 100,
      min: ((activePrice - minPrice) / activePrice) * 100
    },
    start: rangeStart
  }
}

export const getUserClaimableRewards = ({
  chainId,
  extraRewarderData,
  rewarderData
}: {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  extraRewarderData: ExtraHooksRewarderData
  rewarderData: HooksRewarderData
}) => {
  const userClaimableRewards: FormattedReward[] = []

  if (rewarderData.hooksParameters.hooks !== zeroAddress) {
    if (rewarderData.parameters.pendingRewards > 0) {
      const rewardToken =
        rewarderData.parameters.rewardToken.token === zeroAddress
          ? CNATIVE.onChain(chainId)
          : new TokenSdk(
              chainId,
              rewarderData.parameters.rewardToken.token,
              Number(rewarderData.parameters.rewardToken.decimals),
              rewarderData.parameters.rewardToken.symbol
            )
      userClaimableRewards.push({
        token: rewardToken,
        tokenAddress: rewarderData.parameters.rewardToken.token,
        tokenAmount: Number(
          formatUnits(
            rewarderData.parameters.pendingRewards,
            rewardToken.decimals
          )
        )
      })
    }
  }

  if (extraRewarderData.hooksParameters.hooks !== zeroAddress) {
    if (extraRewarderData.parameters.pendingRewards > 0) {
      const rewardToken =
        extraRewarderData.parameters.rewardToken.token === zeroAddress
          ? CNATIVE.onChain(chainId)
          : new TokenSdk(
              chainId,
              extraRewarderData.parameters.rewardToken.token,
              Number(extraRewarderData.parameters.rewardToken.decimals),
              extraRewarderData.parameters.rewardToken.symbol
            )
      userClaimableRewards.push({
        token: rewardToken,
        tokenAddress: extraRewarderData.parameters.rewardToken.token,
        tokenAmount: Number(
          formatUnits(
            extraRewarderData.parameters.pendingRewards,
            rewardToken.decimals
          )
        )
      })
    }
  }

  return userClaimableRewards
}
