import {
  Box,
  Center,
  HStack,
  SimpleGrid,
  SkeletonCircle,
  SkeletonText,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { Token } from 'types/dexbarn'
import { LBPool, Pool } from 'types/pool'

import HeaderSearchResultRow from './HeaderSearchResultRow'
import TokenSearchResultRow from './TokenSearchResultRow'

interface SearchResultsProps {
  isLoadingPools: boolean
  isLoadingTokens: boolean
  onRowClick: () => void
  pairs: (Pool | LBPool)[]
  tokens: Token[]
  activeResultIndex?: number
  containerRef?: React.RefObject<HTMLDivElement>
  onMouseEnter?: (index: number) => void
}

const SearchResults = ({
  activeResultIndex,
  containerRef,
  isLoadingPools,
  isLoadingTokens,
  onMouseEnter,
  onRowClick,
  pairs,
  tokens
}: SearchResultsProps) => {
  return pairs.length > 0 ||
    tokens.length > 0 ||
    isLoadingPools ||
    isLoadingTokens ? (
    <Box ref={containerRef}>
      {tokens.length > 0 || isLoadingTokens ? (
        <VStack align="flex-start" spacing={0} p={2}>
          <Text size="sm" pl={4} textColor="textSecondary">
            <Trans>Tokens</Trans>
          </Text>
          {isLoadingTokens ? (
            <SearchResultRowSkeleton />
          ) : (
            <SimpleGrid columns={1} gap={1} w="full">
              {tokens.map((token, i) => (
                <TokenSearchResultRow
                  key={token.tokenAddress}
                  id={`search-result-${i}`}
                  isHighlighted={activeResultIndex === i}
                  token={token}
                  onClick={onRowClick}
                  onMouseEnter={() => onMouseEnter?.(i)}
                />
              ))}
            </SimpleGrid>
          )}
        </VStack>
      ) : null}
      {pairs.length > 0 || isLoadingPools ? (
        <VStack align="flex-start" spacing={0} p={2}>
          <Text size="sm" pl={4} textColor="textSecondary">
            Pools
          </Text>
          {isLoadingPools ? (
            <SearchResultRowSkeleton />
          ) : (
            <SimpleGrid columns={1} gap={1} w="full">
              {pairs.map((pair, i) => (
                <HeaderSearchResultRow
                  id={`search-result-${i + (tokens.length || 0)}`}
                  isHighlighted={
                    activeResultIndex !== undefined
                      ? activeResultIndex - (tokens?.length || 0) === i
                      : undefined
                  }
                  key={i}
                  pair={pair}
                  onClick={onRowClick}
                  onMouseEnter={() => onMouseEnter?.(i + (tokens.length || 0))}
                />
              ))}
            </SimpleGrid>
          )}
        </VStack>
      ) : null}
    </Box>
  ) : (
    <Center p={8}>
      <Text color="textSecondary">
        <Trans>No results found</Trans>
      </Text>
    </Center>
  )
}

export default SearchResults

const SearchResultRowSkeleton = () => {
  return (
    <SimpleGrid columns={{ '2xl': 2, base: 1 }} gap={4} w="full" p={4}>
      <HStack spacing={4}>
        <SkeletonCircle boxSize={8} />
        <SkeletonText w="300px" noOfLines={2} />
      </HStack>
      <HStack spacing={4}>
        <SkeletonCircle boxSize={8} />
        <SkeletonText w="300px" noOfLines={2} />
      </HStack>
    </SimpleGrid>
  )
}
