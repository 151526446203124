import { ChainId, Token } from '@traderjoe-xyz/sdk-core'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const JOE_TOKEN: {
  [chainId in Exclude<ChainId, ChainId.MANTLE>]: Token | undefined
} = {
  [ChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0xcc14EcF814aAC6015bb38bc87296F28f8470eE13',
    18,
    'JOE',
    'JoeToken'
  ),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    18,
    'JOE',
    'JoeToken'
  ),
  [ChainId.ARBITRUM_ONE]: new Token(
    ChainId.ARBITRUM_ONE,
    '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07',
    18,
    'JOE',
    'JoeToken'
  ),
  [ChainId.ARB_GOERLI]: new Token(
    ChainId.ARB_GOERLI,
    '0x0cCd82C5E94F93913b3227B8069F598E2708d442',
    18,
    'JOE',
    'JoeToken'
  ),
  [ChainId.BNB_CHAIN]: new Token(
    ChainId.BNB_CHAIN,
    '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07',
    18,
    'JOE',
    'JoeToken'
  ),
  [ChainId.BNB_TESTNET]: new Token(
    ChainId.BNB_TESTNET,
    '0x845C442d3B6B5cCC32Fd0192b196b8e155Db508B',
    18,
    'JOE',
    'JoeToken'
  ),
  [ChainId.ETHEREUM]: undefined
}
