import { WNATIVE } from '@traderjoe-xyz/sdk-core'
import { DEXLENS_ADDRESS, DexLensABI } from '@traderjoe-xyz/sdk-v2'
import { formatUnits, getAddress, parseEther } from 'viem'
import { useReadContracts } from 'wagmi'

import useChainId from './useChainId'

interface UseTokenPriceUSDProps {
  tokens: string[]
  enabled?: boolean
}

const useTokenPriceUSD = ({
  enabled = true,
  tokens
}: UseTokenPriceUSDProps) => {
  const chainId = useChainId()
  const contract = {
    abi: DexLensABI,
    address: getAddress(DEXLENS_ADDRESS[chainId])
  }
  const args: [`0x${string}`[]] = [tokens.map((token) => getAddress(token))]
  const reads = useReadContracts({
    contracts: [
      { ...contract, args, chainId, functionName: 'getTokensPricesNative' },
      { ...contract, args, chainId, functionName: 'getTokensPricesUSD' },
      {
        ...contract,
        args: [getAddress(WNATIVE[chainId].address)],
        chainId,
        functionName: 'getTokenPriceUSD'
      }
    ],
    query: { enabled: tokens.length > 0 && chainId && enabled }
  })

  const tokensPricesNative = reads.data?.[0].result
  const tokensPricesUSD = reads.data?.[1].result
  const nativeCurrencyPriceUSD = reads.data?.[2].result

  const usdPriceDecimals = WNATIVE[chainId].decimals
  const convertUsdPrice = (price: bigint): number =>
    parseFloat(formatUnits(price, usdPriceDecimals))

  // we use getTokensPriceNative(token) * getTokensPricesUSD(nativeCurrency) in priority
  // and fallback to getTokenPricesUSD
  const prices =
    tokensPricesNative && nativeCurrencyPriceUSD
      ? tokensPricesNative
          .map(
            (nativePrice) =>
              (nativePrice * nativeCurrencyPriceUSD) / parseEther('1')
          )
          .map(convertUsdPrice)
      : tokensPricesUSD?.map(convertUsdPrice)

  return {
    ...reads,
    data: prices
  }
}

export default useTokenPriceUSD
