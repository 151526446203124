import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Chain, Vault as DexbarnVault } from 'types/dexbarn'
import { convertDexbarnVaultIntoVault } from 'utils/vault'

interface FetchVaultsParameters {
  userAddress?: string
}

interface UseVaultsProps {
  chain: Chain | 'all'
  enabled?: boolean
  userAddress?: string
}

const useVaults = ({ chain, enabled = true, userAddress }: UseVaultsProps) => {
  const fetchVaults = useDexbarnGet<DexbarnVault[], FetchVaultsParameters>(
    chain === 'all' ? 'v1/vaults' : `v1/vaults/${chain}`
  )
  return useQuery({
    enabled,
    queryFn: () => fetchVaults({ params: { userAddress } }),
    queryKey: ['VaultsQuery', chain, userAddress],
    select: (vaults) =>
      vaults.map((vault) => convertDexbarnVaultIntoVault(vault))
  })
}

export default useVaults
