import {
  JOE_ADDRESS,
  ROCKET_JOE_STAKING_ADDRESS,
  RocketJoeStakingABI
} from '@traderjoe-xyz/sdk'
import useChainId from 'hooks/useChainId'
import { erc20Abi, getAddress } from 'viem'
import { useAccount, useReadContract, useReadContracts } from 'wagmi'

/**
 * RocketJoe Staking Info. Returns:
 * totalStaked
 */
const useRJoeStaking = () => {
  const chainId = useChainId()
  const rJoeAddress = getAddress(ROCKET_JOE_STAKING_ADDRESS[chainId])
  const { data: totalStaked } = useReadContract({
    abi: erc20Abi,
    address: getAddress(JOE_ADDRESS[chainId]),
    args: [rJoeAddress],
    chainId,
    functionName: 'balanceOf'
  })

  return { totalStaked }
}

/**
 * contract methods for VeJoe User Positions. Returns:
 * userBalance
 * pendingBalance
 */
export const useRJoeUserPosition = () => {
  const chainId = useChainId()
  const { address: account } = useAccount()

  const rJoeStakingContract = {
    abi: RocketJoeStakingABI,
    address: getAddress(ROCKET_JOE_STAKING_ADDRESS[chainId]),
    chainId
  }

  const reads = useReadContracts({
    contracts: [
      {
        ...rJoeStakingContract,
        args: account ? [account] : undefined,
        functionName: 'userInfo'
      },
      {
        ...rJoeStakingContract,
        args: account ? [account] : undefined,
        functionName: 'pendingRJoe'
      }
    ],
    query: { enabled: !!account }
  })

  // user JOE balance, in 18 decimals
  const userBalance = reads.data?.[0]?.result?.[0]

  // pending veJOE in 18 decimals
  const pending = reads.data?.[1]

  return {
    ...reads,
    pending,
    userBalance
  }
}

export default useRJoeStaking
