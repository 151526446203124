import { useEffect, useRef } from 'react'

const useScrollTabIntoView = (index: number) => {
  const tablistRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const selectedTab = tablistRef?.current?.querySelector(
      '[role=tab][aria-selected="true"]'
    )
    selectedTab?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [index])

  return tablistRef
}

export default useScrollTabIntoView
