import { Button } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import useAddTokenToWallet from 'hooks/useAddTokenToWallet'
import React from 'react'
import { tradeAddToWallet } from 'utils/measure'

interface AddToWalletButtonProps {
  token: Token
}

const AddToWalletButton = ({ token }: AddToWalletButtonProps) => {
  const addTokenToWallet = useAddTokenToWallet()
  return (
    <Button
      variant="ghost"
      colorScheme="accent"
      size="sm"
      color="accent.500"
      _hover={{ bg: 'bgTertiary' }}
      onClick={() => {
        addTokenToWallet(token)
        tradeAddToWallet(token.symbol)
      }}
    >
      <Trans>Add {token.symbol} to Wallet</Trans>
    </Button>
  )
}

export default AddToWalletButton
