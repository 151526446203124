import { APTFarmABI } from '@traderjoe-xyz/sdk-v2'
import { useMemo } from 'react'
import { getAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

interface UserUserFarmClaimableRewardsProps {
  farmAddress: string
  poolId: string
}

const useUserFarmClaimableRewards = ({
  farmAddress,
  poolId
}: UserUserFarmClaimableRewardsProps) => {
  const { address } = useAccount()

  const {
    data,
    isLoading: isLoadingUserClaimableRewards,
    refetch: refetchUserClaimableRewards
  } = useReadContract({
    abi: APTFarmABI,
    address: getAddress(farmAddress),
    args: address ? [BigInt(poolId), address] : undefined,
    functionName: 'pendingTokens',
    query: { enabled: !!poolId && !!address && !!farmAddress, gcTime: 0 }
  })

  const parseData = useMemo(() => {
    const pendingJoeRewards = data?.[0] || BigInt(0)
    const bonusTokenAddress = data?.[1] || ''
    const bonusTokenSymbol = data?.[2] || ''
    const pendingBonusTokenRewards = data?.[3] || BigInt(0)

    return {
      bonusTokenAddress,
      bonusTokenSymbol,
      pendingBonusTokenRewards,
      pendingJoeRewards
    }
  }, [data])

  return {
    data: parseData,
    isLoading: isLoadingUserClaimableRewards,
    refetch: refetchUserClaimableRewards
  }
}

export default useUserFarmClaimableRewards
