import { JoePairABI, Pair } from '@traderjoe-xyz/sdk'
import { Currency, TokenAmount } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

interface UsePairProps {
  currency0?: Currency
  currency1?: Currency
  pairAddress?: string
}

const usePair = ({ currency0, currency1, pairAddress }: UsePairProps) => {
  const chainId = useChainId()

  const results = useReadContracts({
    contracts: [
      {
        abi: JoePairABI,
        address: pairAddress ? getAddress(pairAddress) : undefined,
        chainId,
        functionName: 'token0'
      },
      {
        abi: JoePairABI,
        address: pairAddress ? getAddress(pairAddress) : undefined,
        chainId,
        functionName: 'token1'
      },
      {
        abi: JoePairABI,
        address: pairAddress ? getAddress(pairAddress) : undefined,
        chainId,
        functionName: 'getReserves'
      }
    ],
    query: { enabled: !!pairAddress }
  })

  const token0 = results.data?.[0].result
  const token1 = results.data?.[1].result
  const reserves = results.data?.[2].result

  const reserve0 = reserves?.[0]
  const reserve1 = reserves?.[1]

  const wrappedCurrency0 = currency0
    ? wrappedCurrency(currency0, chainId)
    : undefined

  const wrappedCurrency1 = currency1
    ? wrappedCurrency(currency1, chainId)
    : undefined

  const tokenAmount0 =
    reserve0 !== undefined && wrappedCurrency0 && wrappedCurrency1
      ? new TokenAmount(
          token0 === wrappedCurrency0.address
            ? wrappedCurrency0
            : wrappedCurrency1,
          reserve0.toString()
        )
      : undefined

  const tokenAmount1 =
    reserve1 !== undefined && wrappedCurrency0 && wrappedCurrency1
      ? new TokenAmount(
          token1 === wrappedCurrency1.address
            ? wrappedCurrency1
            : wrappedCurrency0,
          reserve1.toString()
        )
      : undefined

  return {
    data:
      tokenAmount0 && tokenAmount1
        ? new Pair(tokenAmount0, tokenAmount1, chainId)
        : undefined,
    isLoading: results.isLoading
  }
}

export default usePair
