import { InfoOutlineIcon } from '@chakra-ui/icons'
import { HStack, Text, Th, Tooltip } from '@chakra-ui/react'
import SortableTableHeader from 'components/SortableTableHeader'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import React from 'react'

interface PoolTableHeaderProps {
  isSortDescending: boolean
  name: string
  onClick: () => void
  selectedSortMethod: PoolSortMethod
  isNumeric?: boolean
  sortMethod?: PoolSortMethod
  tooltipLabel?: string
}

const PoolTableHeader = ({
  isNumeric,
  isSortDescending,
  name,
  onClick,
  selectedSortMethod,
  sortMethod,
  tooltipLabel
}: PoolTableHeaderProps) => {
  return sortMethod ? (
    <SortableTableHeader
      name={name}
      isSortActive={selectedSortMethod === sortMethod}
      isNumeric={isNumeric}
      isSortDescending={isSortDescending}
      onClick={onClick}
    />
  ) : (
    <Th isNumeric={isNumeric} textAlign={isNumeric ? 'right' : 'left'}>
      {tooltipLabel ? (
        <Tooltip label={tooltipLabel}>
          <HStack justify={isNumeric ? 'flex-end' : 'flex-start'}>
            <Text textColor="textSecondary">{name}</Text>
            <InfoOutlineIcon />
          </HStack>
        </Tooltip>
      ) : (
        name
      )}
    </Th>
  )
}

export default PoolTableHeader
