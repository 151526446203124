import { Hide, Tab, TabProps } from '@chakra-ui/react'
import React from 'react'

interface TabWithIconProps {
  icon: JSX.Element
  title: string
}

const TabWithIcon = ({
  icon,
  title,
  ...props
}: TabWithIconProps & TabProps) => (
  <Tab px={4} {...props}>
    <Hide below="sm">{icon}</Hide>
    {title}
  </Tab>
)

export default TabWithIcon
