import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { Link } from 'react-router-dom'
import { Token } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

interface TokenSearchResultRowProps {
  onClick: () => void
  token: Token
  id?: string
  isHighlighted?: boolean
  onMouseEnter?: () => void
}

const TokenSearchResultRow = ({
  id,
  isHighlighted,
  onClick,
  onMouseEnter,
  token
}: TokenSearchResultRowProps) => {
  return (
    <HStack
      id={id}
      as={Link}
      aria-label={`Link to trade ${token.symbol}`}
      to={`/${token.chain}/trade?outputCurrency=${token.tokenAddress}`}
      px={4}
      py={3}
      spacing={4}
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      borderRadius="lg"
      bg={isHighlighted ? 'hover' : undefined}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <CurrencyLogo
        address={token.tokenAddress}
        symbol={token.symbol}
        boxSize={{ base: 6, md: 8 }}
      />

      <VStack align="flex-start" spacing={0}>
        <HStack spacing={2} align="flex-start">
          <Text fontWeight="bold">{token.name}</Text>
          <Text textColor="textSecondary">{token.symbol}</Text>
        </HStack>
        <HStack>
          <Text fontSize="sm">
            <Box as="span" textColor="textSecondary">
              Price:
            </Box>{' '}
            {formattedNum(token.priceUsd, {
              usd: true
            })}
          </Text>
          <Text
            fontSize="sm"
            textColor={token.pctChange > 0 ? 'green.400' : 'red.400'}
          >
            {token.pctChange > 0 ? '+' : ''}
            {token.pctChange.toFixed(2)}%
          </Text>
        </HStack>
      </VStack>
    </HStack>
  )
}

export default TokenSearchResultRow
