import {
  Box,
  Center,
  Flex,
  HStack,
  Spinner,
  Switch,
  Text,
  useMediaQuery
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import SearchBar from 'components/SearchBar'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import useActiveChain from 'hooks/useActiveChain'
import useSortedVaults from 'hooks/vault/useSortedVaults'
import useVaultSearch from 'hooks/vault/useVaultSearch'
import useVaultWithUserPositions from 'hooks/vault/useVaultWithUserPositions'
import React, { useState } from 'react'
import { vaultHasFarm } from 'utils/farm'

import VaultTable from './VaultsTable/VaultTable'

interface VaultsTableProps {
  isTabSelected: boolean
}

const Vault = ({ isTabSelected }: VaultsTableProps) => {
  const chain = useActiveChain()

  // Workaround for Chakra issue #6927 regarding responsive sizes in InputGroup
  // https://github.com/chakra-ui/chakra-ui/issues/6927
  const [isLargerThanMd] = useMediaQuery(['(min-width: 48em)'])
  function getInputGroupSize() {
    return isLargerThanMd ? 'lg' : 'md'
  }

  const [hasRewards, setHasRewards] = useState(false)

  const { data: vaultsWithUserPositions, isLoadingVaults } =
    useVaultWithUserPositions({ enabled: isTabSelected })

  const vaultResults = hasRewards
    ? vaultsWithUserPositions.filter((vault) => vaultHasFarm(vault))
    : vaultsWithUserPositions

  const [queryVaults, setQueryVaults] = useState('')
  const [sortMethodVaults, setSortMethodVaults] = useState<PoolSortMethod>(
    PoolSortMethod.LIQUIDITY
  )
  const [isSortDescendingVaults, setIsSortDescendingVaults] =
    useState<boolean>(true)

  const filteredVaults = useVaultSearch({
    query: queryVaults,
    vaults: vaultResults
  })

  const sortedVaults = useSortedVaults({
    isSortDescending: isSortDescendingVaults,
    sortMethod: sortMethodVaults,
    vaults: filteredVaults
  })

  if (isLoadingVaults) {
    return (
      <Center mt={8}>
        <Spinner />
      </Center>
    )
  }

  return (
    <Flex flexDir="column" gap={4} pt={{ base: 0, md: 4 }}>
      {vaultsWithUserPositions.length === 0 ? (
        <Center py={8}>
          <Text size="sm" color="textSecondary">
            <Trans>There is no automated pools on {chain.name} yet.</Trans>
          </Text>
        </Center>
      ) : (
        <>
          <Flex
            flexDir={{ base: 'column', sm: 'row' }}
            alignItems="center"
            gap={6}
          >
            <HStack columnGap={2}>
              <Switch
                colorScheme="accent"
                size="lg"
                isChecked={hasRewards}
                onChange={() => setHasRewards((previous) => !previous)}
              />
              <Box
                as="span"
                textColor="textPrimary"
                fontWeight="bold"
                w="max-content"
              >
                <Trans>Has Rewards</Trans>
              </Box>
            </HStack>
            <SearchBar
              placeholder={t`Search by name, symbol or address`}
              value={queryVaults}
              onValueChange={setQueryVaults}
              size={getInputGroupSize()}
            />
          </Flex>
          <VaultTable
            isSortDescending={isSortDescendingVaults}
            onHeaderClick={(method) => {
              if (!method) return
              sortMethodVaults !== method
                ? setSortMethodVaults(method)
                : setIsSortDescendingVaults((previous) => !previous)
            }}
            sortMethod={sortMethodVaults}
            vaults={sortedVaults}
            filteredVaults={filteredVaults}
          />
        </>
      )}
    </Flex>
  )
}

export default Vault
