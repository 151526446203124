import { Box } from '@chakra-ui/react'
import React from 'react'

interface Payload {
  coordinate: number
  isShow: boolean
  offset: number
  tickCoord: number
  value: string
}

interface XTickProps {
  payload?: Payload
  x?: number
  y?: number
}

const AnalyticsChartXTick = ({ payload, x, y }: XTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <Box
      as="text"
      x={0}
      y={0}
      dy={16}
      textAnchor="middle"
      fontSize="xs"
      fill="textSecondary"
    >
      {payload?.value}
    </Box>
  </g>
)

export default AnalyticsChartXTick
