import useChainId from 'hooks/useChainId'
import { getDexbarnChainParam } from 'utils/chains'
import { useAccount } from 'wagmi'

import useVaults from './useVaults'

const useUserVaults = () => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const result = useVaults({
    chain: getDexbarnChainParam(chainId),
    enabled: !!account,
    userAddress: account
  })
  return {
    ...result,
    isLoading: result.isLoading
  }
}

export default useUserVaults
