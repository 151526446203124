import {
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { format } from 'date-fns'
import React from 'react'
import { SJoeRemittances } from 'types/stake'
import { formattedNum } from 'utils/format'

interface RemittancesTableProps {
  remittanceDatas: SJoeRemittances[]
}

const RemittancesTable = ({ remittanceDatas }: RemittancesTableProps) => {
  return (
    <VStack w="full" alignItems="left" spacing={4}>
      <Heading size="md">
        <Trans>Remittances</Trans>
      </Heading>
      <TableContainer>
        <Table maxWidth={{ md: 'full', sm: '90%' }} size="sm">
          <Thead>
            <Tr>
              <Th px={0}>
                <Trans>Timestamp</Trans>
              </Th>
              <Th px={0}>
                <Trans>Fees Collected</Trans>
              </Th>
              <Th px={0} textAlign="right">
                <Trans>USD Remitted</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {remittanceDatas.map((data, key) =>
              RemittanceRow({ ...data, key })
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  )
}

interface RemittanceRowInterface extends SJoeRemittances {
  key: number
}

const RemittanceRow = ({
  key,
  timestamp,
  tokens,
  usdRemitted
}: RemittanceRowInterface) => {
  const description = tokens
    .map(
      (token) => `${formattedNum(token.amount, { places: 2 })} ${token.symbol}`
    )
    .join(' / ')
  const fmtTimestamp = format(Number(timestamp) * 1000, 'yyyy-MM-dd hh:mm a')
  const fmtUsdRemitted = formattedNum(usdRemitted, { places: 2, usd: true })
  return (
    <Tr maxWidth="100%" key={key}>
      <Td px={0} fontWeight="normal" fontSize={{ base: 10, lg: 14 }}>
        {fmtTimestamp}
      </Td>
      <Td
        px={0}
        w="fit-content"
        fontSize={{ base: 10, lg: 14 }}
        fontWeight="normal"
      >
        {description}
      </Td>
      <Td
        px={0}
        fontWeight="normal"
        fontSize={{ base: 10, lg: 14 }}
        textAlign="right"
      >
        {fmtUsdRemitted}
      </Td>
    </Tr>
  )
}

export default RemittancesTable
