import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import SimpleModal from 'components/SimpleModal'
import React from 'react'

interface SimpleCurrencyPickerModalProps {
  currencies: Currency[]
  isOpen: boolean
  onClose: () => void
  onCurrencyChange: (currency: Currency) => void
}

const SimpleCurrencyPickerModal = ({
  currencies,
  isOpen,
  onClose,
  onCurrencyChange
}: SimpleCurrencyPickerModalProps) => {
  return (
    <SimpleModal
      header={t`Select a token`}
      isOpen={isOpen}
      onClose={onClose}
      rows={currencies.map((currency, i) => (
        <Flex
          key={i}
          w="full"
          align="center"
          justify="space-between"
          cursor="pointer"
          px={4}
          py={3}
          borderRadius="xl"
          _hover={{ bg: 'hover' }}
          onClick={() => {
            onCurrencyChange(currency)
            onClose()
          }}
        >
          <HStack spacing={4}>
            <CurrencyLogo
              address={currency.isToken ? currency.address : undefined}
              symbol={currency.symbol}
              boxSize={8}
            />
            <VStack align="flex-start" spacing={0.5}>
              <HStack>
                <Text fontWeight="semibold">{currency.symbol}</Text>
                <Text fontSize="sm">{currency.name}</Text>
              </HStack>
            </VStack>
          </HStack>
        </Flex>
      ))}
    />
  )
}

export default SimpleCurrencyPickerModal
