import {
  Flex,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import useActiveChain from 'hooks/useActiveChain'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import React, { useMemo } from 'react'
import { RewardsHistoryEpoch } from 'types/rewards'
import { formattedNum } from 'utils/format'
import { getFormattedRewards, getTokensMap } from 'utils/rewards'
import { zeroAddress } from 'viem'

interface MemeFarmRewardsHistoryProps {
  rewardsHistoryData: RewardsHistoryEpoch[]
}
const MemeFarmRewardsHistory = ({
  rewardsHistoryData
}: MemeFarmRewardsHistoryProps) => {
  return (
    <Flex
      overflow="hidden"
      p={{ base: 4, md: 8 }}
      borderRadius="xl"
      borderColor="border"
      borderWidth={1}
      gap={{ base: 4, md: 6 }}
      flexDir="column"
      bg="bgCard"
      w="full"
    >
      <Heading size="md">My Rewards History</Heading>

      <TableContainer m={0}>
        <Table variant="simple" maxWidth="full">
          <Thead>
            <Tr>
              <Th pl={0}>Epoch</Th>
              <Th pl={0}>Granted</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rewardsHistoryData.map((rewardData) => (
              <RewardRow key={rewardData.epoch} rewardData={rewardData} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}

const RewardRow = ({ rewardData }: { rewardData: RewardsHistoryEpoch }) => {
  const { nativeCurrency } = useActiveChain()
  const { epoch, rewards: rewardsData } = rewardData

  const tokenAddresses = useMemo(
    () => (rewardsData ? rewardsData.map((reward) => reward.tokenAddress) : []),
    [rewardsData]
  )

  const { tokens } = useSdkCurrencies({
    addresses: tokenAddresses.map((address) =>
      address === zeroAddress ? nativeCurrency.symbol : address
    )
  })

  const rewards = useMemo(() => {
    if (
      !rewardsData ||
      rewardsData.length === 0 ||
      rewardsData.length !== tokens.length ||
      tokens.length !== tokenAddresses.length
    ) {
      return []
    }

    const tokensMap = getTokensMap(tokenAddresses, tokens)
    return getFormattedRewards(rewardsData, tokensMap)
  }, [tokens, rewardsData, tokenAddresses])

  return (
    <Tr>
      <Td pl={0}>
        <Text fontWeight="semibold" fontSize="16px">
          Epoch {epoch + 1}
        </Text>
      </Td>

      <Td pl={0}>
        <Flex flexWrap="wrap" gap={3}>
          {rewards.map((reward) => (
            <HStack key={reward.tokenAddress} spacing={2}>
              <CurrencyLogo
                address={reward.tokenAddress}
                symbol={reward.token?.symbol}
                boxSize={6}
              />
              <Text fontWeight="semibold" fontSize="16px">
                {`${formattedNum(reward.tokenAmount)} ${reward.token?.symbol}`}
              </Text>
            </HStack>
          ))}
        </Flex>
      </Td>
    </Tr>
  )
}

export default MemeFarmRewardsHistory
