import { ChainId } from '@traderjoe-xyz/sdk-core'
import { zeroAddress } from 'viem'

export const REWARDER_ADDRESS = {
  [ChainId.FUJI]: '0x94c67F6722B120D76Fa471DACaEBF29D98fe76f8',
  [ChainId.AVALANCHE]: '0x238626A38d19Bf63b1Ef0EdDc93E28148E6844Fb',
  [ChainId.ARBITRUM_ONE]: zeroAddress,
  [ChainId.ARB_GOERLI]: zeroAddress,
  [ChainId.BNB_CHAIN]: zeroAddress,
  [ChainId.BNB_TESTNET]: zeroAddress,
  [ChainId.ETHEREUM]: zeroAddress
}

export const MARKETS = {
  [ChainId.FUJI]: [
    '0x0000000000000000000000000000000000000001',
    '0x0000000000000000000000000000000000000002'
  ],
  [ChainId.AVALANCHE]: [
    '0x0000000000000000000000000000000000000011',
    '0x0000000000000000000000000000000000000012',
    '0x0000000000000000000000000000000000000013',
    '0x0000000000000000000000000000000000000014',
    '0x0000000000000000000000000000000000000015',
    '0x0000000000000000000000000000000000000016',
    '0x0000000000000000000000000000000000000017',
    '0x0000000000000000000000000000000000000018',
    '0x0000000000000000000000000000000000000019',
    '0x000000000000000000000000000000000000001a',
    '0x000000000000000000000000000000000000001b',
    '0x000000000000000000000000000000000000001c',
    '0x000000000000000000000000000000000000001d',
    '0x000000000000000000000000000000000000001e',
    '0x000000000000000000000000000000000000001f',
    '0x0000000000000000000000000000000000000020',
    '0x0000000000000000000000000000000000000021',
    '0x0000000000000000000000000000000000000022',
    '0x0000000000000000000000000000000000000023',
    '0x0000000000000000000000000000000000000024',
    '0x0000000000000000000000000000000000000025',
    '0x0000000000000000000000000000000000000026',
    '0x0000000000000000000000000000000000000027',
    '0x0000000000000000000000000000000000000028',
    '0x0000000000000000000000000000000000000029',
    '0x000000000000000000000000000000000000002a',
    '0x000000000000000000000000000000000000002b',
    '0x000000000000000000000000000000000000002c',
    '0x000000000000000000000000000000000000002d',
    '0x000000000000000000000000000000000000002e',
    '0x000000000000000000000000000000000000002f',
    '0x0000000000000000000000000000000000000030',
    '0x0000000000000000000000000000000000000031',
    '0x0000000000000000000000000000000000000032',
    '0x0000000000000000000000000000000000000033',
    '0x0000000000000000000000000000000000000034',
    '0x0000000000000000000000000000000000000035',
    '0x0000000000000000000000000000000000000036',
    '0x0000000000000000000000000000000000000037',
    '0x0000000000000000000000000000000000000038',
    '0x0000000000000000000000000000000000000039',
    '0x000000000000000000000000000000000000003a'
  ],
  [ChainId.ARBITRUM_ONE]: [],
  [ChainId.ARB_GOERLI]: [],
  [ChainId.BNB_CHAIN]: [],
  [ChainId.BNB_TESTNET]: [],
  [ChainId.ETHEREUM]: []
}
