import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Link,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { format, formatDistance } from 'date-fns'
import useActiveChain from 'hooks/useActiveChain'
import useVaultRecentActivities from 'hooks/vault/useVaultRecentActivities'
import React from 'react'
import { VaultRecentActivity } from 'types/dexbarn'
import { Vault } from 'types/vault'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

interface VaultRecentActivityTableProps {
  vault: Vault
  currency0?: Currency
  currency1?: Currency
}

const VaultRecentActivityTable = ({
  currency0,
  currency1,
  vault
}: VaultRecentActivityTableProps) => {
  const { blockExplorers } = useActiveChain()
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useVaultRecentActivities({
      vaultAddress: vault.id
    })
  const activities = data?.pages.flat() || []

  if (!currency0 || !currency1) {
    return null
  }

  return (
    <Flex flexDir="column" gap={4} alignItems="center">
      <Heading size="md" w="full" mb={{ base: 0, md: 4 }}>
        <Trans>Recent Activity</Trans>
      </Heading>
      {isLoading ? (
        <Center py={8} w="full">
          <Spinner />
        </Center>
      ) : (
        <TableContainer w="full">
          <Table maxWidth="100%" size="sm">
            <Thead>
              <Tr borderBottom="1px" borderBottomColor="border">
                <Th>
                  <Trans>Withdrawn</Trans>
                </Th>
                <Th>
                  <Trans>Deposited</Trans>
                </Th>
                <Th>
                  <Trans>Position Change</Trans>
                </Th>
                <Th textAlign="right">
                  <Trans>Timestamp</Trans>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {activities.length > 0 &&
                activities.map((activity, i) => (
                  <VaultRecentActivityTableRow
                    key={i}
                    currency0={currency0}
                    currency1={currency1}
                    activity={activity}
                    blockExplorerUrl={blockExplorers?.default.url}
                  />
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {hasNextPage ? (
        <Button
          px={8}
          variant="outline"
          isLoading={isFetchingNextPage}
          onClick={() => fetchNextPage()}
        >
          <Trans>Load More</Trans>
        </Button>
      ) : undefined}
    </Flex>
  )
}

interface VaultRecentActivityTableRowProps {
  activity: VaultRecentActivity
  currency0: Currency
  currency1: Currency
  blockExplorerUrl?: string
}

const VaultRecentActivityTableRow = ({
  activity,
  blockExplorerUrl,
  currency0,
  currency1
}: VaultRecentActivityTableRowProps) => {
  const timestamp = new Date(activity.timestamp * 1000)

  const sumDepositedX = activity.deposits.reduce(
    (acc, deposit) => acc + deposit.amountX,
    BigInt(0)
  )
  const sumDepositedY = activity.deposits.reduce(
    (acc, deposit) => acc + deposit.amountY,
    BigInt(0)
  )
  const sumWithdrawnX = activity.withdrawals.reduce(
    (acc, withdrawal) => acc + withdrawal.amountX,
    BigInt(0)
  )
  const sumWithdrawnY = activity.withdrawals.reduce(
    (acc, withdrawal) => acc + withdrawal.amountY,
    BigInt(0)
  )

  const fmtSumDepositedX = formattedNum(
    formatUnits(sumDepositedX, currency0.decimals),
    { places: 2 }
  )
  const fmtSumDepositedY = formattedNum(
    formatUnits(sumDepositedY, currency1.decimals),
    { places: 2 }
  )
  const fmtSumWithdrawnX = formattedNum(
    formatUnits(sumWithdrawnX, currency0.decimals),
    { places: 2 }
  )
  const fmtSumWithdrawnY = formattedNum(
    formatUnits(sumWithdrawnY, currency1.decimals),
    { places: 2 }
  )

  const fmtDifferenceX = formattedNum(
    formatUnits(sumDepositedX - sumWithdrawnX, currency0.decimals),
    { places: 2 }
  )
  const fmtDifferenceY = formattedNum(
    formatUnits(sumDepositedY - sumWithdrawnY, currency1.decimals),
    { places: 2 }
  )

  return (
    <Tr borderBottom="1px" borderBottomColor="border">
      <Td>{`${fmtSumWithdrawnX} ${currency0.symbol} / ${fmtSumWithdrawnY} ${currency1.symbol}`}</Td>
      <Td>{`${fmtSumDepositedX} ${currency0.symbol} / ${fmtSumDepositedY} ${currency1.symbol}`}</Td>
      <Td>{`${fmtDifferenceX} ${currency0.symbol} / ${fmtDifferenceY} ${currency1.symbol}`}</Td>
      <Td verticalAlign="top" textAlign="right">
        <Flex flexDir="column" align="flex-end">
          <Link
            aria-label="Link to rebalance on explorer"
            isExternal
            href={`${blockExplorerUrl}/tx/${activity.transactionHash}`}
          >
            <HStack spacing={1}>
              <Text>{format(timestamp, 'yyyy-MM-dd HH:mm:ss')}</Text>
              <ExternalLinkIcon />
            </HStack>
          </Link>
          <Text color="textSecondary" fontSize="xs" fontWeight="semibold">
            {formatDistance(timestamp, Date.now(), {
              addSuffix: true,
              includeSeconds: true
            })}
          </Text>
        </Flex>
      </Td>
    </Tr>
  )
}

export default VaultRecentActivityTable
