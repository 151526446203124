import { ChainId } from '@traderjoe-xyz/sdk-core'

export const FEE_RATE = 0.0025 // 0.25%
export const POOL_SHARE_PRECISION = 100000000

export const POOL_HELMET_TITLE =
  'Trader Joe XYZ | Leading Decentralized Exchange | Pool'
export const POOL_HELMET_DESCRIPTION =
  'Join Trader Joe, your go-to DEX, for a seamless DeFi experience. Trade or explore thousands of tokens, provide liquidity to earn fees. Trader Joe makes DeFi easy.'

export const POOL_DISTRIBUTION_CHART_RADIUS = {
  initial: 60,
  max: 400,
  min: 20,
  step: 40
}

// allow to override new pool url for migrated pools
export const MIGRATED_POOL_URL_BY_ADDRESS: {
  [chainId in Exclude<ChainId, ChainId.MANTLE>]: { [address: string]: string }
} = {
  [ChainId.FUJI]: {},
  [ChainId.AVALANCHE]: {},
  [ChainId.ARBITRUM_ONE]: {
    // JIMBO v1 / ETH
    '0xe9a7637F7F17aA1EE1c180c6d261Ca86f72A2DD4':
      '/arbitrum/pool/v21/0xc3813645ad2ea0ac9d4d72d77c3755ac3b819e38/ETH/100'
  },
  [ChainId.ARB_GOERLI]: {},
  [ChainId.BNB_CHAIN]: {},
  [ChainId.BNB_TESTNET]: {},
  [ChainId.ETHEREUM]: {}
}
