import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import CurrencyPickerModal from 'components/CurrencyPickerModal'
import SimpleCurrencyPickerModal from 'components/SimpleCurrencyPickerModal'
import React from 'react'

interface CreatePoolTokenSectionProps {
  onCurrencyChange: (currency?: Currency) => void
  title: string
  currency?: Currency
  selectableCurrencies?: Currency[]
}

const CreatePoolTokenSection = ({
  currency,
  onCurrencyChange,
  selectableCurrencies,
  title
}: CreatePoolTokenSectionProps) => {
  const {
    isOpen: isCurrencyPickerOpen,
    onClose: onCurrencyPickerClose,
    onOpen: onCurrencyPickerOpen
  } = useDisclosure()

  return (
    <>
      {selectableCurrencies ? (
        <SimpleCurrencyPickerModal
          currencies={selectableCurrencies}
          isOpen={isCurrencyPickerOpen}
          onClose={onCurrencyPickerClose}
          onCurrencyChange={onCurrencyChange}
        />
      ) : (
        <CurrencyPickerModal
          isOpen={isCurrencyPickerOpen}
          onClose={onCurrencyPickerClose}
          onCurrencyChange={onCurrencyChange}
        />
      )}
      <VStack align="flex-start">
        <Text>{title}</Text>
        <Button
          px={4}
          variant="outline"
          bg="transparent"
          size="lg"
          w="full"
          borderColor="border"
          justifyContent="flex-start"
          onClick={onCurrencyPickerOpen}
        >
          <HStack w="full" justifyContent="space-between">
            <HStack>
              {currency ? (
                <CurrencyLogo
                  address={currency.isToken ? currency.address : undefined}
                  symbol={currency.symbol}
                  boxSize={6}
                />
              ) : null}
              <Text textColor={currency ? 'textPrimary' : 'textSecondary'}>
                {currency?.symbol ?? t`Select token`}
              </Text>
              {currency ? (
                <Text textColor="textSecondary">{currency.name}</Text>
              ) : null}
            </HStack>
            <ChevronDownIcon alignSelf="flex-end" boxSize="22px" />
          </HStack>
        </Button>
      </VStack>
    </>
  )
}

export default CreatePoolTokenSection
