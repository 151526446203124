import { DISABLED_VAULTS } from 'constants/vaults'
import { Vault as DexbarnVault } from 'types/dexbarn'
import { LBPoolVersion } from 'types/pool'
import { Vault, VaultWithUserPosition } from 'types/vault'
import { formatUnits } from 'viem'

import { formattedNum } from './format'

export const convertDexbarnVaultIntoVault = (vault: DexbarnVault): Vault => {
  let lbPairVersion: LBPoolVersion
  switch (vault.pair.version) {
    case 'v2.0':
      lbPairVersion = 'v2'
      break
    case 'v2.1':
      lbPairVersion = 'v21'
      break
    case 'v2.2':
      lbPairVersion = 'v22'
      break
  }

  return {
    address: vault.address,
    apr1D: vault.apr1d,
    apt1dPriceChange: vault.apt1dPriceChange,
    aptPrice: vault.aptPrice,
    chainId: vault.chainId,
    farm: vault.farm,
    feesUsd: vault.feesUsd,
    hodl30Day5050Performance: vault.hodl30Day5050Performance,
    hodl5050Performance: vault.hodl5050Performance,
    id: vault.address,
    isDisabled: DISABLED_VAULTS[vault.chainId].includes(vault.address),
    isRewarded: vault.farm
      ? vault.farm.rewardsPerSec > 0 ||
        (vault.farm.rewarder
          ? vault.farm.rewarder.rewarderTokenPerSec > 0
          : false)
      : false,
    lbPair: {
      baseFeePct: Math.round(vault.pair.baseFeePct * 200) / 200, // round to nearest 0.005%
      binStep: vault.pair.binStep,
      id: vault.pair.address,
      version: lbPairVersion
    },
    managementFeePct: vault.strategy?.aumAnnualFeePct || 0,
    name: vault.name,
    strategy: vault.strategy
      ? {
          id: vault.strategy.address
        }
      : undefined,
    tokenX: vault.tokenX,
    tokenX30DayPerformance: vault.tokenX30DayPerformance,
    tokenX7DayPerformance: vault.tokenX7DayPerformance,
    tokenY: vault.tokenY,
    tokenY30DayPerformance: vault.tokenY30DayPerformance,
    tokenY7DayPerformance: vault.tokenY7DayPerformance,
    totalValueLockedUSD: vault.tvlUsd
  }
}

export const getUserVaultBalanceUSD = (
  vault: VaultWithUserPosition
): number | undefined => {
  const userAmountX = vault.userPositions.vaultBalance?.amountRawX
    ? formatUnits(
        vault.userPositions.vaultBalance.amountRawX,
        vault.tokenX.decimals
      )
    : undefined
  const userAmountY = vault.userPositions.vaultBalance?.amountRawY
    ? formatUnits(
        vault.userPositions.vaultBalance.amountRawY,
        vault.tokenY.decimals
      )
    : undefined
  const userBalanceUSD =
    vault.userPositions.vaultBalance?.tokenXUsdPrice &&
    vault.userPositions.vaultBalance.tokenYUsdPrice &&
    userAmountX &&
    userAmountY
      ? Number(userAmountX) * vault.userPositions.vaultBalance.tokenXUsdPrice +
        Number(userAmountY) * vault.userPositions.vaultBalance.tokenYUsdPrice
      : undefined
  return userBalanceUSD
}

export const getVaultRowFormattedValues = (vault: VaultWithUserPosition) => {
  const userBalanceUSD = getUserVaultBalanceUSD(vault)
  return {
    apr1D: `${formattedNum(vault.apr1D * 100, { places: 2 })}%`,
    apt1dPriceChange: `${vault.apt1dPriceChange >= 0 ? '+' : ''}${formattedNum(
      vault.apt1dPriceChange,
      { places: 2 }
    )}%`,
    apt1dPriceChangeColor:
      vault.apt1dPriceChange >= 0 ? 'green.400' : 'red.500',
    aptPrice: `${formattedNum(vault.aptPrice, { places: 2 })} ${
      vault.tokenY.symbol
    }`,
    feesUsd1D: formattedNum(vault.feesUsd, { usd: true }),
    liquidity: formattedNum(vault.totalValueLockedUSD, { usd: true }),
    myFunds: userBalanceUSD ? userBalanceUSD : 0
  }
}

export const getFarmRowFormattedValues = (vault: VaultWithUserPosition) => {
  const farmApr1D = vault.farm
    ? `${formattedNum(
        (vault.farm.apr1d + (vault.farm.rewarder?.rewarderApr1d || 0)) * 100,
        { places: 2 }
      )}%`
    : ''

  const farmLiquidity = vault.farm
    ? formattedNum(vault.farm?.liquidityUsd, { usd: true })
    : ''

  const pendingJoeRewards =
    vault?.userPositions?.farmBalance?.pendingJoe &&
    vault?.farm?.reward?.decimals
      ? formatUnits(
          BigInt(vault.userPositions.farmBalance.pendingJoe),
          vault.farm.reward.decimals
        )
      : ''

  const myFarmRewards =
    vault.farm && pendingJoeRewards
      ? formattedNum(pendingJoeRewards, {
          places: 2,
          usd: false
        })
      : ''

  const myFarmDepositUSD =
    vault.userPositions.farmBalance?.userPositionUsd && vault.farm
      ? vault.userPositions.farmBalance?.userPositionUsd
      : 0

  return {
    farmApr1D,
    farmLiquidity,
    myFarmDepositUSD,
    myFarmRewards
  }
}
