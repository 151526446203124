import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { getDexbarnChainParam } from 'utils/chains'
import { roundToNearest5Minutes } from 'utils/date'

dayjs.extend(utc)

interface LBPairIntervalData {
  date: string
  feesNative: number
  feesTokenX: number
  feesTokenY: number
  feesUsd: number
  reserveNative: number
  reserveUsd: number
  reserveX: number
  reserveY: number
  timestamp: number
  volumeNative: number
  volumeUsd: number
  avgFeesBp?: number
  maxFeesBp?: number
  minFeesBp?: number
}

interface ChartData {
  date: number
  value: number
}

interface FeesBpsChartData {
  avgFeesBp: number
  date: number
  maxFeesBp: number
  minFeesBp: number
}

interface LBPairAnalyticsChartData {
  feesBpsDayDatas: FeesBpsChartData[]
  feesBpsHourDatas: FeesBpsChartData[]
  feesDatas: ChartData[]
  tvlDatas: ChartData[]
  volumeDatas: ChartData[]
}

interface LBPairAnalyticsData extends LBPairAnalyticsChartData {
  apr7D: number
  fees24H: number
  fees24HDelta: number
  tvl: number
  tvlDelta: number
  volume24H: number
  volume24HDelta: number
}

interface UseLBPairDetailAnalyticsProps {
  enabled: boolean
  lbPairAddress?: string
}

const useLBPairDetailAnalytics = ({
  enabled,
  lbPairAddress
}: UseLBPairDetailAnalyticsProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const DATA_LENGTH = 180

  const now = roundToNearest5Minutes(dayjs())

  const yesterday = roundToNearest5Minutes(now.subtract(1, 'day'))
  const dayBeforeYesterday = roundToNearest5Minutes(
    yesterday.subtract(1, 'day')
  )
  const sixMonthsAgo = roundToNearest5Minutes(now.subtract(DATA_LENGTH, 'day'))
  const thirtyDaysAgo = roundToNearest5Minutes(yesterday.subtract(30, 'day'))

  const fetchPairDaily = useDexbarnGet<LBPairIntervalData[]>(
    `/v1/pools/analytics/${chain}/${lbPairAddress}?aggregateBy=daily&endTime=${now.unix()}&startTime=${sixMonthsAgo.unix()}`
  )

  const fetchTodayHourly = useDexbarnGet<LBPairIntervalData[]>(
    `/v1/pools/analytics/${chain}/${lbPairAddress}?aggregateBy=hourly&endTime=${now.unix()}&startTime=${yesterday.unix()}`
  )

  const fetchYesterdayHourly = useDexbarnGet<LBPairIntervalData[]>(
    `/v1/pools/analytics/${chain}/${lbPairAddress}?aggregateBy=hourly&endTime=${yesterday.unix()}&startTime=${dayBeforeYesterday.unix()}`
  )

  const fetchPairHourly = useDexbarnGet<LBPairIntervalData[]>(
    `/v1/pools/analytics/${chain}/${lbPairAddress}?aggregateBy=hourly&endTime=${now.unix()}&startTime=${thirtyDaysAgo.unix()}`
  )

  const { data: dayDataFetchResult, isLoading: isLoadingDayDatas } = useQuery<
    LBPairIntervalData[]
  >({
    enabled: !!lbPairAddress && enabled,
    queryFn: () => fetchPairDaily(),
    queryKey: ['LBPairDayData', lbPairAddress]
  })

  const { data: todayDataFetchResult, isLoading: isLoadingTodayData } =
    useQuery<LBPairIntervalData[]>({
      enabled: !!lbPairAddress && enabled,
      queryFn: () => fetchTodayHourly(),
      queryKey: ['LBPairLast24h', lbPairAddress]
    })

  const { data: yesterdayDataFetchResult, isLoading: isLoadingYesterdayData } =
    useQuery<LBPairIntervalData[]>({
      enabled: !!lbPairAddress && enabled,
      queryFn: () => fetchYesterdayHourly(),
      queryKey: ['LBPairPriorToLast24h', lbPairAddress]
    })

  const { data: hourlyDataFetchResult, isLoading: isLoadingHourlyDats } =
    useQuery<LBPairIntervalData[]>({
      enabled: !!lbPairAddress && enabled,
      queryFn: () => fetchPairHourly(),
      queryKey: ['LBPairHourlyData', lbPairAddress]
    })

  const data = useMemo(() => {
    if (!dayDataFetchResult) return undefined

    const tvl: ChartData[] = []
    const volume: ChartData[] = []
    const fees: ChartData[] = []
    const feesBpsDayDatas: FeesBpsChartData[] = []

    const utcDataLengthDaysBack = dayjs()
      .utc()
      .startOf('day')
      .subtract(DATA_LENGTH - 1, 'day')
      .unix()

    let dataIndex = 0
    let currentDayTimestamp = utcDataLengthDaysBack

    // filter out data that is older than DATA_LENGTH days
    let dayDatas = dayDataFetchResult
    if (dayDataFetchResult.length > DATA_LENGTH) {
      dayDatas = dayDatas.slice(dayDataFetchResult.length - DATA_LENGTH)
    }

    // create dummy array to sync length of filled data with DATA_LENGTH
    new Array(DATA_LENGTH).fill(0).forEach((value, index) => {
      // condition if timestamp of current iteration is in data from subgraph
      if (
        dataIndex < dayDatas.length &&
        dayDatas[dataIndex].timestamp === currentDayTimestamp
      ) {
        tvl.push({
          date: currentDayTimestamp,
          value: dayDatas[dataIndex].reserveUsd
        })
        volume.push({
          date: currentDayTimestamp,
          value: dayDatas[dataIndex].volumeUsd
        })
        fees.push({
          date: currentDayTimestamp,
          value: dayDatas[dataIndex].feesUsd
        })
        feesBpsDayDatas.push({
          avgFeesBp: dayDatas[dataIndex].avgFeesBp || 0,
          date: currentDayTimestamp,
          maxFeesBp: dayDatas[dataIndex].maxFeesBp || 0,
          minFeesBp: dayDatas[dataIndex].minFeesBp || 0
        })

        dataIndex++
      } else {
        // if the past day timestamp have a defined TVL, use it for current timestamp if data
        // for current timestamp is not found in the data from subgraph
        try {
          tvl.push({
            date: currentDayTimestamp,
            value: tvl[index - 1].value
          })
        } catch (e) {
          // default to zero if past day TVL is undefined (edge case when index = 0)
          tvl.push({ date: currentDayTimestamp, value: 0 })
        }

        // volume and fees for day timestamps not found in subgraph should default to zero
        volume.push({ date: currentDayTimestamp, value: 0 })
        fees.push({ date: currentDayTimestamp, value: 0 })
        feesBpsDayDatas.push({
          avgFeesBp: 0,
          date: currentDayTimestamp,
          maxFeesBp: 0,
          minFeesBp: 0
        })
      }

      const SECONDS_IN_DAY = 60 * 60 * 24
      currentDayTimestamp += SECONDS_IN_DAY
    })

    return {
      feesBpsDayDatas,
      feesDatas: fees,
      tvlDatas: tvl,
      volumeDatas: volume
    }
  }, [dayDataFetchResult])

  const hourlyData = useMemo(() => {
    if (!todayDataFetchResult || !yesterdayDataFetchResult) return undefined
    return {
      todayData: {
        feesDatas: todayDataFetchResult.map((el) => ({
          date: el.timestamp,
          value: el.feesUsd
        })),
        tvlDatas: todayDataFetchResult.map((el) => ({
          date: el.timestamp,
          value: el.reserveUsd
        })),
        volumeDatas: todayDataFetchResult.map((el) => ({
          date: el.timestamp,
          value: el.volumeUsd
        }))
      },
      yesterdayData: {
        feesDatas: yesterdayDataFetchResult.map((el) => ({
          date: el.timestamp,
          value: el.feesUsd
        })),
        tvlDatas: yesterdayDataFetchResult.map((el) => ({
          date: el.timestamp,
          value: el.reserveUsd
        })),
        volumeDatas: yesterdayDataFetchResult.map((el) => ({
          date: el.timestamp,
          value: el.volumeUsd
        }))
      }
    }
  }, [todayDataFetchResult, yesterdayDataFetchResult])

  const feesBpsHourDatas = useMemo(() => {
    if (!hourlyDataFetchResult || hourlyDataFetchResult.length === 0) {
      return []
    }

    const data: FeesBpsChartData[] = hourlyDataFetchResult.map((interval) => ({
      avgFeesBp: interval.avgFeesBp ?? 0,
      date: interval.timestamp,
      maxFeesBp: interval.maxFeesBp ?? 0,
      minFeesBp: interval.minFeesBp ?? 0
    }))

    return data
  }, [hourlyDataFetchResult])

  const cummData = useMemo((): LBPairAnalyticsData | undefined => {
    if (!data || !hourlyData) return undefined

    const { feesDatas, tvlDatas } = data
    const {
      todayData: {
        feesDatas: feesDatasToday,
        tvlDatas: tvlDatasToday,
        volumeDatas: volumeDatasToday
      },
      yesterdayData: {
        feesDatas: feesDatasYesterday,
        tvlDatas: tvlDatasYesterday,
        volumeDatas: volumeDatasYesterday
      }
    } = hourlyData

    const tvl = tvlDatas.length > 0 ? tvlDatas[tvlDatas.length - 1].value : 0
    const volume24H = volumeDatasToday.reduce(
      (accu: number, currData: ChartData) => accu + currData.value,
      0
    )
    const fees24H = feesDatasToday.reduce(
      (accu: number, currData: ChartData) => accu + currData.value,
      0
    )

    let apr7D = 0
    if (feesDatas.length >= 7 && tvlDatas.length > 0) {
      const last7DFees = feesDatas
        .slice(feesDatas.length - 7)
        .reduce((sum: number, current: ChartData) => sum + current.value, 0)
      apr7D =
        (last7DFees * 365 * 100) / (7 * tvlDatas[tvlDatas.length - 1].value)
    }

    const todayData = {
      fees: feesDatasToday.reduce(
        (accu: number, currData: ChartData) => accu + currData.value,
        0
      ),
      tvl: tvlDatasToday.length > 0 ? tvlDatasToday[0].value : 0,
      volume: volumeDatasToday.reduce(
        (accu: number, currData: ChartData) => accu + currData.value,
        0
      )
    }
    const yesterdayData = {
      fees: feesDatasYesterday.reduce(
        (accu: number, currData: ChartData) => accu + currData.value,
        0
      ),
      tvl: tvlDatasYesterday.length > 0 ? tvlDatasYesterday[0].value : 0,
      volume: volumeDatasYesterday.reduce(
        (accu: number, currData: ChartData) => accu + currData.value,
        0
      )
    }

    const tvlDelta =
      yesterdayData.tvl !== 0
        ? ((todayData.tvl - yesterdayData.tvl) / yesterdayData.tvl) * 100
        : 0
    const volume24HDelta =
      yesterdayData.volume !== 0
        ? ((todayData.volume - yesterdayData.volume) / yesterdayData.volume) *
          100
        : 0
    const fees24HDelta =
      yesterdayData.fees !== 0
        ? ((todayData.fees - yesterdayData.fees) / yesterdayData.fees) * 100
        : 0

    return {
      ...data,
      apr7D,
      fees24H,
      fees24HDelta,
      feesBpsHourDatas,
      tvl,
      tvlDelta,
      volume24H,
      volume24HDelta
    }
  }, [hourlyData, data, feesBpsHourDatas])

  return {
    data: cummData,
    isLoading:
      isLoadingDayDatas ||
      isLoadingTodayData ||
      isLoadingYesterdayData ||
      isLoadingHourlyDats
  }
}

export default useLBPairDetailAnalytics
