import { t } from '@lingui/macro'
import { ROCKET_JOE_STAKING_ADDRESS } from '@traderjoe-xyz/sdk'
import rJoeImage from 'assets/stake/rJoe.webp'
import { ZERO_ADDRESS } from 'constants/tokens'
import useRJoeStaking, { useRJoeUserPosition } from 'hooks/stake/useRJoeStaking'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { formattedNum } from 'utils/format'
import { formatEther } from 'viem'

import { CurrencyTab } from '.'
import { JoeUsdProps } from './SJoeCard'
import StakeCard from './StakeCard'

const RJoeCard = ({ currencyTab, joeUsd }: JoeUsdProps) => {
  const { userBalance } = useRJoeUserPosition()
  const { totalStaked } = useRJoeStaking()

  // check if not available on selected chain
  const chainId = useChainId()
  const isNotAvailable = ROCKET_JOE_STAKING_ADDRESS[chainId] === ZERO_ADDRESS

  // user balance
  const balanceNum = userBalance ? Number(formatEther(userBalance)) : 0
  const fmtBalance = formattedNum(balanceNum, { places: 2 })
  const fmtBalanceUsd = formattedNum(balanceNum * (joeUsd ?? 0), {
    places: 2,
    usd: true
  })

  // total staked
  const totalStakedNum = totalStaked ? Number(formatEther(totalStaked)) : 0
  const totalStakedUSD = totalStakedNum * (joeUsd ?? 0)
  const fmtTotalStaked = formattedNum(totalStakedNum, { places: 2 })
  const fmtTotalStakedUSD = formattedNum(totalStakedUSD, {
    places: 2,
    usd: true
  })

  return isNotAvailable ? null : (
    <StakeCard
      isExternal
      image={rJoeImage}
      linkUrl="https://v1.traderjoexyz.com/stake/rjoe"
      title="rJOE"
      tag={t`Withdraw Only`}
      label1={t`Total Staked (${currencyTab})`}
      text1={
        currencyTab == CurrencyTab.JOE ? fmtTotalStaked : fmtTotalStakedUSD
      }
      label2={t`Your Stake (${currencyTab})`}
      text2={currencyTab == CurrencyTab.JOE ? fmtBalance : fmtBalanceUsd}
      label3={t`rJOE Per Day`}
      text3={t`N/A`}
    />
  )
}

export default RJoeCard
