import { VaultABI } from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import { useMemo } from 'react'
import { UserVaultBalances, Vault } from 'types/vault'
import { formattedNum } from 'utils/format'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UseUserVaultBalancesProps {
  vaults: Vault[]
  enabled?: boolean
}

const useUserVaultBalances = ({
  enabled = true,
  vaults
}: UseUserVaultBalancesProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  // get amount of shares for all vaults
  const { data: balances, refetch } = useReadContracts({
    contracts: vaults.map(
      (vault) =>
        ({
          abi: VaultABI,
          address: getAddress(vault.id),
          args: account ? [account] : undefined,
          chainId,
          functionName: 'balanceOf'
        }) as const
    ),
    query: {
      enabled: !!account && vaults.length > 0 && enabled,
      select: (data) => data.map((d) => d.result)
    }
  })

  // get amounts for all vaults
  const reads = useReadContracts({
    contracts: vaults.map(
      (vault, i) =>
        ({
          abi: VaultABI,
          address: getAddress(vault.id),
          args: [balances?.[i] || BigInt(0)],
          chainId,
          functionName: 'previewAmounts'
        }) as const
    ),
    query: { enabled: !!balances && balances.length > 0 }
  })

  // get usd prices for all tokens
  const tokens = useMemo(
    () =>
      vaults
        .map((vault) => [vault.tokenX.address, vault.tokenY.address])
        .flat()
        .filter(Boolean) as string[],
    [vaults]
  )
  const { data: usdPrices } = useTokenPriceUSD({
    tokens
  })

  // convert data
  const data = reads.data
  const userVaultPositions: UserVaultBalances[] | undefined = useMemo(
    () =>
      data?.map((read, i) => {
        const result = read.result as [bigint, bigint] | undefined
        const amountRawX = result?.[0]
        const amountRawY = result?.[1]

        const amountX =
          amountRawX !== undefined
            ? Number(formatUnits(amountRawX, vaults[i].tokenX.decimals))
            : undefined
        const amountY =
          amountRawY !== undefined
            ? Number(formatUnits(amountRawY, vaults[i].tokenY.decimals))
            : undefined

        return {
          amountRawX,
          amountRawY,
          amountX,
          amountY,
          fmtAmountX: amountX !== undefined ? formattedNum(amountX) : undefined,
          fmtAmountY: amountY !== undefined ? formattedNum(amountY) : undefined,
          tokenXUsdPrice: usdPrices?.[i * 2],
          tokenYUsdPrice: usdPrices?.[i * 2 + 1]
        }
      }),
    [data, usdPrices, vaults]
  )

  return {
    data: userVaultPositions,
    isLoading: reads.isLoading,
    refetch
  }
}

export default useUserVaultBalances
