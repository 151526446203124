import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer
} from 'react'

import {
  AddLiquidityAction,
  AddLiquidityState,
  addLiquidityStateReducer,
  DEFAULT_DISTRIBUTION,
  getDefaultBinRange
} from './AddLiquidityStateReducer'

const AddLiquidityStateContext = createContext<AddLiquidityState>(
  {} as AddLiquidityState
)

const AddLiquidityDispatchContext = createContext<
  React.Dispatch<AddLiquidityAction>
>({} as React.Dispatch<AddLiquidityAction>)

export const AddLiquidityStateContextProvider = ({
  activeBinId,
  children
}: { activeBinId?: number } & PropsWithChildren) => {
  const [addLiquidityState, dispatchAddLiquidityAction] = useReducer(
    addLiquidityStateReducer,
    {
      activeBinId,
      amount0: '',
      amount1: '',
      binRange: getDefaultBinRange(activeBinId),
      distribution: DEFAULT_DISTRIBUTION
    }
  )

  useEffect(() => {
    dispatchAddLiquidityAction({
      payload: activeBinId,
      type: 'set_active_bin_id'
    })
  }, [dispatchAddLiquidityAction, activeBinId])

  return (
    <AddLiquidityStateContext.Provider value={addLiquidityState}>
      <AddLiquidityDispatchContext.Provider value={dispatchAddLiquidityAction}>
        {children}
      </AddLiquidityDispatchContext.Provider>
    </AddLiquidityStateContext.Provider>
  )
}

export const useAddLiquidityStateContext = () =>
  useContext(AddLiquidityStateContext)
export const useAddLiquidityDispatchContext = () =>
  useContext(AddLiquidityDispatchContext)
