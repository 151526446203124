import { Flex, Heading } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import PageHelmet from 'components/PageHelmet'
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from 'constants/pool'
import usePoolV1LiquidityPanel from 'hooks/pool/v1/usePoolV1LiquidityPanel'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import AddLiquidityPanel from 'pages/PoolDetail/AddLiquidityPanel'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import CreatePoolPriceDetail from './CreatePoolPriceDetail'

const CreatePool = () => {
  const { token0Address, token1Address } = useParams()
  const location = useLocation()

  const { isLoading, onToken0Select, onToken1Select, pair, token0, token1 } =
    usePoolV1LiquidityPanel({
      token0Address,
      token1Address
    })

  const amountInput0 = useCurrencyInputAmount({ currency: token0 })
  const amountInput1 = useCurrencyInputAmount({ currency: token1 })

  return (
    <>
      <PageHelmet
        title={POOL_HELMET_TITLE}
        description={POOL_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <Flex
        justify="center"
        pt={{ base: 6, md: 12 }}
        maxW="1200px"
        margin="0 auto"
        minH="calc(100vh - 80px)"
        px={4}
      >
        <Flex
          flexDir="column"
          w={{ base: 'full', md: '400px' }}
          h="fit-content"
          gap={4}
          p={4}
          borderRadius="xl"
          border="1px"
          borderColor="border"
          bg="bgCard"
        >
          <Heading size="md">
            <Trans>Add Liquidity</Trans>
          </Heading>
          <AddLiquidityPanel
            isPoolLowLiquidity={false}
            pair={pair}
            token0={token0}
            token1={token1}
            amountInput0={amountInput0}
            amountInput1={amountInput1}
            onToken0Select={onToken0Select}
            onToken1Select={onToken1Select}
            loadingText={isLoading ? t`Checking Pair` : undefined}
          />
          {token0 && token1 ? (
            <CreatePoolPriceDetail
              currency0={token0}
              currency1={token1}
              currency0Amount={amountInput0.amountBN}
              currency1Amount={amountInput1.amountBN}
            />
          ) : null}
        </Flex>
      </Flex>
    </>
  )
}

export default CreatePool
