import { t } from '@lingui/macro'
import { STABLE_JOE_STAKING_ADDRESS } from '@traderjoe-xyz/sdk'
import sJoeImage from 'assets/stake/sJoe.webp'
import { ZERO_ADDRESS } from 'constants/tokens'
import { useSJoePayouts } from 'hooks/stake/useSJoeAnalytics'
import { useSJoeStaking } from 'hooks/stake/useSJoeStaking'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { calculateSJoeMetrics } from 'utils/stake'
import { formatEther } from 'viem'

import { CurrencyTab } from '.'
import NAStakeCard from './NotAvailableStakeCard'
import StakeCard from './StakeCard'

export interface JoeUsdProps {
  currencyTab: CurrencyTab
  joeUsd: number | undefined
}

const SJoeCard = ({ currencyTab, joeUsd }: JoeUsdProps) => {
  const { depositFee, sJoeUserBalance, totalStaked } = useSJoeStaking()
  const remittanceDayDatas = useSJoePayouts()

  // check if not available on selected chain
  const chainId = useChainId()
  const isNotAvailable = STABLE_JOE_STAKING_ADDRESS[chainId] === ZERO_ADDRESS

  // user balance
  const balanceNum = sJoeUserBalance ? Number(formatEther(sJoeUserBalance)) : 0
  const fmtBalance = formattedNum(balanceNum, { places: 2 })
  const fmtBalanceUsd = formattedNum(balanceNum * (joeUsd ?? 0), {
    places: 2,
    usd: true
  })

  // total staked
  const totalStakedNum = totalStaked ? Number(formatEther(totalStaked)) : 0
  const fmtTotalStaked = formattedNum(totalStakedNum, { places: 2 })

  // deposit fee
  const fmtDepositFee =
    depositFee !== undefined
      ? `${formattedNum(Number(formatEther(depositFee)) * 100, { places: 2 })}%`
      : ''

  // calculate metrics
  const metrics = calculateSJoeMetrics(totalStaked, joeUsd, remittanceDayDatas)

  // format metrics
  const fmtApr30D = metrics
    ? `${formattedNum(metrics.apr30D * 100, { places: 2 })}%`
    : ''
  const fmtTotalStakedUSD = metrics
    ? formattedNum(metrics.totalStakedUSD, { places: 2, usd: true })
    : ''

  // link
  const link = `${getChainSlug(chainId)}/stake/sjoe`

  return isNotAvailable ? (
    <NAStakeCard image={sJoeImage} title="sJOE" />
  ) : (
    <StakeCard
      image={sJoeImage}
      linkUrl={link}
      title="sJOE"
      tag={t`Earn USD Stablecoins`}
      label1={t`Total Staked (${currencyTab})`}
      text1={
        currencyTab == CurrencyTab.JOE ? fmtTotalStaked : fmtTotalStakedUSD
      }
      label2={t`Your Stake (${currencyTab})`}
      text2={currencyTab == CurrencyTab.JOE ? fmtBalance : fmtBalanceUsd}
      label3={t`APR (30D)`}
      text3={fmtApr30D}
      label4={t`Deposit Fee`}
      text4={fmtDepositFee}
    />
  )
}

export default SJoeCard
