import { Box, Button, HStack, IconButton, Tag, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Currency, Token } from '@traderjoe-xyz/sdk-core'
import React from 'react'
import { ArrowLeftRightIcon } from 'theme/icons'

import TradeCurrencyInput from '../TradeCurrencyInput'
import PlaceOrderDisclaimer from './PlaceOrderDisclaimer'
import PriceShortcutButton from './PriceShortcutButton'

interface PlaceOrderPriceInputProps {
  activePrice: number
  arePairTokensInversed: boolean
  isIncorrectPrice: boolean
  isPriceRatioInversed: boolean
  onResetPriceClick: () => void
  onTogglePriceRatio: () => void
  onValueChange: (value: string) => void
  typedPrice: string
  inputCurrency?: Currency
  isDisabled?: boolean
  outputCurrency?: Currency
  tokenX?: Token
  tokenY?: Token
}

const PlaceOrderPriceInput = ({
  activePrice,
  arePairTokensInversed,
  inputCurrency,
  isDisabled,
  isIncorrectPrice,
  isPriceRatioInversed,
  onResetPriceClick,
  onTogglePriceRatio,
  onValueChange,
  outputCurrency,
  tokenX,
  tokenY,
  typedPrice
}: PlaceOrderPriceInputProps) => {
  const pricePercentShortcuts = [1, 2, 5, 10]

  // calculate the difference between typed price and market price in percent
  const priceRatio =
    !isPriceRatioInversed && !arePairTokensInversed
      ? Number(typedPrice) / activePrice
      : isPriceRatioInversed && !arePairTokensInversed
        ? 1 / Number(typedPrice) / activePrice
        : !isPriceRatioInversed && arePairTokensInversed
          ? Number(typedPrice) / (1 / activePrice)
          : 1 / Number(typedPrice) / (1 / activePrice)
  const priceRate = isFinite(priceRatio)
    ? Number((priceRatio * 100).toFixed(2)) - 100
    : 0

  // the market price displayed in the input
  const displayedMarketPrice =
    (!isPriceRatioInversed && !arePairTokensInversed) ||
    (isPriceRatioInversed && arePairTokensInversed)
      ? activePrice
      : 1 / activePrice

  // whether the price should be above the market price
  const shouldPriceBeAboveMarket =
    (arePairTokensInversed && !isPriceRatioInversed) ||
    (!arePairTokensInversed && !isPriceRatioInversed)

  // whether the price is tokenX per tokenY or tokenY per tokenX
  const isTokenXPerY =
    (arePairTokensInversed && !isPriceRatioInversed) ||
    (!arePairTokensInversed && isPriceRatioInversed)

  return (
    <Box mt={4}>
      <TradeCurrencyInput
        isDisabled={isDisabled}
        data-cy="place-order-price-input"
        heading={
          !inputCurrency || !outputCurrency
            ? t`Price`
            : isTokenXPerY
              ? t`Price (${tokenX?.symbol} per ${tokenY?.symbol})`
              : t`Price (${tokenY?.symbol} per ${tokenX?.symbol})`
        }
        headingRightElement={
          !!inputCurrency && !!outputCurrency ? (
            <HStack spacing={2} h="24px">
              <Tag
                size="sm"
                borderRadius="full"
                data-cy="place-order-price-rate-label"
                colorScheme={
                  priceRate >= 0
                    ? shouldPriceBeAboveMarket
                      ? 'green'
                      : 'gray'
                    : 'red'
                }
                ml={1}
                fontWeight="semibold"
              >
                {`${
                  priceRate < 0 ? '' : shouldPriceBeAboveMarket ? '+' : '-'
                }${priceRate.toFixed(0)}%`}
              </Tag>
              <IconButton
                data-cy="place-order-toggle-price-ratio-button"
                aria-label="toggle price ratio"
                variant="ghost"
                size="sm"
                icon={<ArrowLeftRightIcon />}
                onClick={onTogglePriceRatio}
              />
            </HStack>
          ) : undefined
        }
        placeholder="0.0"
        value={typedPrice}
        onValueChange={onValueChange}
      />

      {isIncorrectPrice ? (
        <Text
          w="full"
          data-cy="place-order-price-input-error-label"
          color="red.500"
        >
          {arePairTokensInversed && isPriceRatioInversed
            ? t`Price must be lower than ${activePrice.toFixed(
                8
              )} ${inputCurrency?.symbol}`
            : arePairTokensInversed && !isPriceRatioInversed
              ? t`Price must be greater than ${(1 / activePrice).toFixed(
                  8
                )} ${outputCurrency?.symbol}`
              : !arePairTokensInversed && !isPriceRatioInversed
                ? t`Price must be greater than ${activePrice.toFixed(
                    8
                  )} ${outputCurrency?.symbol}`
                : t`Price must be lower than ${(1 / activePrice).toFixed(
                    8
                  )} ${inputCurrency?.symbol}`}
        </Text>
      ) : undefined}

      {!!tokenX && !!tokenY ? (
        <HStack data-cy="place-order-price-shortcuts" w="full" mt={2}>
          <Button
            data-cy="place-order-set-market-price-button"
            onClick={onResetPriceClick}
          >
            Market
          </Button>
          {pricePercentShortcuts.map((percent) => (
            <PriceShortcutButton
              key={percent}
              marketPrice={displayedMarketPrice}
              percent={shouldPriceBeAboveMarket ? percent : percent * -1}
              onValueChange={onValueChange}
            />
          ))}
        </HStack>
      ) : null}

      {!!tokenX && !!tokenY ? <PlaceOrderDisclaimer /> : null}
    </Box>
  )
}

export default PlaceOrderPriceInput
