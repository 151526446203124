import { Flex, HStack, Text } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'

const CurrencyLabel = ({
  balance,
  balanceUsd,
  currencyAddress,
  currencyName,
  currencySymbol
}: {
  balance: string | number
  balanceUsd: string | number
  currencyAddress?: string
  currencyName?: string
  currencySymbol?: string
}) => {
  return (
    <HStack
      align="center"
      gap={4}
      w="full"
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      borderRadius="lg"
      py={2}
      px={4}
    >
      <CurrencyLogo
        address={currencyAddress}
        symbol={currencySymbol}
        boxSize="24px"
      />
      <Flex flexDir="column" alignItems="flex-start">
        <Text fontSize="sm" fontWeight="semibold">
          {currencySymbol}
        </Text>
        <Text fontSize="sm" textColor="textSecondary">
          {currencyName}
        </Text>
      </Flex>
      <Flex flexDir="column" ml="auto">
        <Text fontSize="sm" fontWeight="semibold" textAlign="right">
          {balance}
        </Text>
        <Text fontSize="sm" textColor="textSecondary" textAlign="right">
          {balanceUsd}
        </Text>
      </Flex>
    </HStack>
  )
}

export default CurrencyLabel
