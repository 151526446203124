import { useQuery } from '@tanstack/react-query'
import {
  FEE_COLLECTOR_SUBGRAPH_ID,
  SJOE_SUBGRAPH_ID
} from 'constants/subgraphs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  FeeBankDayDatasQuery,
  FeeBankSwapsQuery,
  SJoeAnalyticsQuery
} from 'graphql/stake'
import request from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { FeeBankSwap, SJoeDayDatas, SJoePayoutDatas } from 'types/stake'
import { SJoeRemittances } from 'types/stake'
import { buildSubgraphUrl } from 'utils/subgraph'

dayjs.extend(utc)

type SJoeAnalyticsQueryResponse = { stableJoeDayDatas: SJoeDayDatas[] }
type SJoePayoutsQueryResponse = { dayDatas: SJoePayoutDatas[] }
type FeeBankSwapsQueryResponse = { swaps: FeeBankSwap[] }

interface ChartData {
  date: number
  value: number
}

// Returns sJOE chart data
const useSJoeAnalytics = () => {
  const chainId = useChainId()

  const subgraphId = SJOE_SUBGRAPH_ID[chainId]
  const subgraphUrl = subgraphId ? buildSubgraphUrl(subgraphId) : ''

  const utcThirtyDayBack = dayjs()
    .utc()
    .startOf('day')
    .subtract(30, 'day')
    .unix()

  const result = useQuery({
    enabled: !!subgraphUrl,
    queryFn: () =>
      request<SJoeAnalyticsQueryResponse>(subgraphUrl, SJoeAnalyticsQuery, {
        dateAfter: utcThirtyDayBack
      }),
    queryKey: ['SJoeAnalyticsQuery', subgraphUrl]
  })
  const chartData: ChartData[] | undefined = result.data?.stableJoeDayDatas.map(
    (data) => {
      return { date: data.date, value: parseFloat(data.totalJoeStaked) }
    }
  )
  return chartData
}

// Returns sJOE payout data
export const useSJoePayouts = () => {
  const chainId = useChainId()

  const utcThirtyDayBack = dayjs()
    .utc()
    .startOf('day')
    .subtract(30, 'day')
    .unix()

  const feeBankSubgraphId = FEE_COLLECTOR_SUBGRAPH_ID[chainId]
  const feeBankSubgraphUrl = feeBankSubgraphId
    ? buildSubgraphUrl(feeBankSubgraphId)
    : ''
  const { data: { dayDatas: feeBankDayDatas } = { dayDatas: [] } } = useQuery({
    queryFn: () =>
      request<SJoePayoutsQueryResponse>(
        feeBankSubgraphUrl,
        FeeBankDayDatasQuery,
        { dateAfter: utcThirtyDayBack }
      ),
    queryKey: ['FeeBankDayDatasQuery', feeBankSubgraphUrl]
  })

  return feeBankDayDatas
}

// Returns sJOE remittance data
export const useSJoeRemittances = (first = 15): SJoeRemittances[] => {
  const chainId = useChainId()

  const feeBankSubgraphId = FEE_COLLECTOR_SUBGRAPH_ID[chainId]
  const feeBankSubgraphUrl = feeBankSubgraphId
    ? buildSubgraphUrl(feeBankSubgraphId)
    : ''
  const { data: { swaps } = { swaps: [] } } = useQuery({
    queryFn: () =>
      request<FeeBankSwapsQueryResponse>(
        feeBankSubgraphUrl,
        FeeBankSwapsQuery,
        { first }
      ),
    queryKey: ['FeeBankSwapsQuery', feeBankSubgraphUrl, first]
  })

  return swaps
    .map((swap) => ({
      timestamp: swap.timestamp,
      tokens: [
        { amount: parseFloat(swap.amountIn), symbol: swap.tokenInSymbol }
      ],
      usdRemitted: parseFloat(swap.amountOutUsd)
    }))
    .sort((a, b) => b.timestamp - a.timestamp)
}

export default useSJoeAnalytics
