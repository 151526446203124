import useGetUserFarmBalances from 'hooks/farm/useGetUserFarmBalances'
import useActiveChain from 'hooks/useActiveChain'
import { useCallback, useMemo } from 'react'
import { UserFarmPosition } from 'types/dexbarn'
import { UserBalancePerVault, Vault } from 'types/vault'
import { getDexbarnChainParam } from 'utils/chains'

import useUserVaultBalances from './useUserVaultBalances'
import useVaults from './useVaults'

interface useVaultWithUserPositionProps {
  enabled: boolean
}

const useVaultWithUserPosition = ({
  enabled
}: useVaultWithUserPositionProps) => {
  const chain = useActiveChain()

  const {
    data: vaults = [],
    isLoading: isLoadingVaults,
    refetch: refetchVaults
  } = useVaults({
    chain: getDexbarnChainParam(chain.id),
    enabled
  })

  const {
    data: userFarmBalances,
    isLoading: isLoadingUserFarmPositions,
    refetch: refetchUserFarmBalances
  } = useGetUserFarmBalances({
    enabled
  })

  const {
    data: userVaultsBalances,
    isLoading: isLoadingUserVaultPositions,
    refetch: refetchUserVaultBalances
  } = useUserVaultBalances({
    enabled,
    vaults
  })

  const userBalancesPerVault = useMemo(
    () =>
      vaults.reduce((acc, { id }, index) => {
        acc[id] = userVaultsBalances?.[index]
        return acc
      }, {} as UserBalancePerVault),
    [vaults, userVaultsBalances]
  )

  const userFarmBalanceByFarmId = useMemo(
    () =>
      userFarmBalances
        ? userFarmBalances.reduce(
            (
              acc: { [key: string]: UserFarmPosition },
              balance: UserFarmPosition
            ) => {
              acc[balance.farmId] = balance
              return acc
            },
            {}
          )
        : {},
    [userFarmBalances]
  )

  const vaultWithUserBalanceAndFarmPositions = useMemo(() => {
    return vaults.map((vault: Vault) => {
      const farmBalance = vault?.farm?.farmId
        ? userFarmBalanceByFarmId[vault?.farm?.farmId]
        : undefined
      const vaultBalance = userBalancesPerVault[vault?.address]
      const userPositions = { farmBalance, vaultBalance }

      return {
        ...vault,
        userPositions
      }
    })
  }, [userFarmBalanceByFarmId, userBalancesPerVault, vaults])

  const refetchAll = useCallback(() => {
    refetchVaults()
    refetchUserFarmBalances()
    refetchUserVaultBalances()
  }, [refetchVaults, refetchUserFarmBalances, refetchUserVaultBalances])

  return {
    data: vaultWithUserBalanceAndFarmPositions,
    isLoadingVaults: isLoadingVaults,
    isLoadingVaultsWithPositions:
      isLoadingUserFarmPositions || isLoadingUserVaultPositions,
    refetch: refetchAll
  }
}

export default useVaultWithUserPosition
