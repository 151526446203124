import { VaultABI } from '@traderjoe-xyz/sdk-v2'
import { Vault } from 'types/vault'
import { getAddress } from 'viem'
import { useReadContract, useReadContracts } from 'wagmi'

interface UseVaultBalancesProps {
  vault: Vault
}

const useVaultBalances = ({ vault }: UseVaultBalancesProps) => {
  const vaultContract = {
    abi: VaultABI,
    address: getAddress(vault.id)
  } as const

  const strategyContract = {
    abi: [
      {
        inputs: [],
        name: 'getIdleBalances',
        outputs: [
          { internalType: 'uint256', name: 'amountX', type: 'uint256' },
          { internalType: 'uint256', name: 'amountY', type: 'uint256' }
        ],
        stateMutability: 'view',
        type: 'function'
      }
    ] as const,
    address: vault.strategy ? getAddress(vault.strategy.id) : undefined
  } as const

  const { data, isLoading } = useReadContracts({
    contracts: [
      {
        ...vaultContract,
        chainId: vault.chainId,
        functionName: 'getBalances'
      },
      {
        ...vaultContract,
        chainId: vault.chainId,
        functionName: 'getCurrentTotalQueuedWithdrawal'
      },
      {
        ...strategyContract,
        chainId: vault.chainId,
        functionName: 'getIdleBalances'
      }
    ] as const
  })

  const balance = data?.[0].result as [bigint, bigint] | undefined
  const currentTotalQueuedWithdrawalShares = data?.[1].result as
    | bigint
    | undefined
  const idleBalances = data?.[2].result as [bigint, bigint] | undefined

  const {
    data: currentTotalQueuedWithdrawalAmounts,
    isLoading: isLoadingQueuedSharesAmounts
  } = useReadContract({
    abi: VaultABI,
    address: getAddress(vault.id),
    args: currentTotalQueuedWithdrawalShares
      ? [currentTotalQueuedWithdrawalShares]
      : undefined,
    chainId: vault.chainId,
    functionName: 'previewAmounts',
    query: { enabled: !!currentTotalQueuedWithdrawalShares }
  })

  const totalBalanceX = balance?.[0] || BigInt(0)
  const totalBalanceY = balance?.[1] || BigInt(0)

  const idleAmountX = idleBalances?.[0] || BigInt(0)
  const idleAmountY = idleBalances?.[1] || BigInt(0)

  const activeAmountX = totalBalanceX - idleAmountX
  const activeAmountY = totalBalanceY - idleAmountY

  return {
    isLoading: isLoading || isLoadingQueuedSharesAmounts,
    tokenBalancesX: {
      inPool: activeAmountX,
      inVault: idleAmountX,
      queued: currentTotalQueuedWithdrawalAmounts?.[0] || BigInt(0)
    },
    tokenBalancesY: {
      inPool: activeAmountY,
      inVault: idleAmountY,
      queued: currentTotalQueuedWithdrawalAmounts?.[1] || BigInt(0)
    }
  }
}

export default useVaultBalances
