import { Grid, HStack, Link, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { formattedNum } from 'utils/format'
import { TokenRow } from 'utils/tokensRanking'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

const TokensRankingMobileRow = ({
  address,
  chainId,
  href,
  pctChange,
  price,
  symbol,
  volume
}: TokenRow) => {
  return (
    <Link as={RouterLink} w="full" to={href} _hover={{ textDecor: 'none' }}>
      <VStack align="flex-start" spacing={6}>
        <HStack>
          <CurrencyLogo
            address={
              isWrappedNativeCurrency(address, chainId) ? undefined : address
            }
            symbol={symbol}
            boxSize={6}
          />
          <Text fontWeight="semibold">{symbol}</Text>
        </HStack>
        <Grid gap={4} templateColumns="1fr 1.2fr 1fr" w="full">
          <VStack spacing={0} align="flex-start">
            <Text fontSize="xs" textColor="textSecondary">
              <Trans>Price</Trans>
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {formattedNum(price, { usd: true })}
            </Text>
          </VStack>
          <VStack spacing={0} align="flex-start">
            <Text fontSize="xs" textColor="textSecondary">
              <Trans>Volume (24H)</Trans>
            </Text>
            <Text fontSize="sm" fontWeight="semibold">
              {formattedNum(volume, { usd: true })}
            </Text>
          </VStack>
          <VStack spacing={0} align="flex-start">
            <Text fontSize="xs" textColor="textSecondary">
              <Trans>Change (24H)</Trans>
            </Text>
            <Text
              fontSize="sm"
              fontWeight="semibold"
              textColor={
                pctChange
                  ? pctChange < 0
                    ? 'red.400'
                    : 'green.400'
                  : 'textPrimary'
              }
            >
              {pctChange ? `${pctChange.toFixed(2)}%` : '--'}
            </Text>
          </VStack>
        </Grid>
      </VStack>
    </Link>
  )
}

export default TokensRankingMobileRow
