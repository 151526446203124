import {
  LiquidityDistributionParams,
  normalizeDist
} from '@traderjoe-xyz/sdk-v2'
import { parseEther, parseUnits } from 'viem'

interface ConvertPositionToAddLiquidityParamsArgs {
  activeBinId: number
  amountsX: number[]
  amountsY: number[]
  decimalsX: number
  decimalsY: number
  positions: number[]
}

// This function is used when importing liquidity distribution from the leaderboard
// to the add liquidity panel
export const convertPositionToAddLiquidityParams = ({
  activeBinId,
  amountsX,
  amountsY,
  decimalsX,
  decimalsY,
  positions
}: ConvertPositionToAddLiquidityParamsArgs): {
  amountXPerYRatio: number
  distributionParams: LiquidityDistributionParams
} => {
  if (
    positions.length !== amountsX.length ||
    positions.length !== amountsY.length
  ) {
    throw new Error('Amounts and positions must be the same length')
  }

  const totalAmountX = amountsX.reduce((acc, amountX) => acc + amountX, 0)
  const totalAmountY = amountsY.reduce((acc, amountY) => acc + amountY, 0)

  const deltaIds: number[] = []
  const distributionX: bigint[] = []
  const distributionY: bigint[] = []
  positions.forEach((position, index) => {
    deltaIds.push(position - activeBinId)
    try {
      distributionX.push(
        totalAmountX > 0
          ? parseUnits(`${amountsX[index] / totalAmountX}`, decimalsX)
          : BigInt(0)
      )
    } catch {
      distributionX.push(BigInt(0))
    }
    try {
      distributionY.push(
        totalAmountY > 0
          ? parseUnits(`${amountsY[index] / totalAmountY}`, decimalsY)
          : BigInt(0)
      )
    } catch {
      distributionY.push(BigInt(0))
    }
  })

  const amountXPerYRatio = totalAmountY > 0 ? totalAmountX / totalAmountY : 0

  return {
    amountXPerYRatio,
    distributionParams: {
      deltaIds,
      distributionX: normalizeDist(
        distributionX,
        parseEther('1'),
        parseEther('1')
      ),
      distributionY: normalizeDist(
        distributionY,
        parseEther('1'),
        parseEther('1')
      )
    }
  }
}
