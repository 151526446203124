import { createReducer } from '@reduxjs/toolkit'

import { addToken, removeToken } from './actions'

export interface LocalToken {
  chainId: number
  decimals: number
  name: string
  address?: string
  symbol?: string
}

interface TokensState {
  readonly savedTokens: LocalToken[]
}

const initialState: TokensState = {
  savedTokens: []
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addToken, (state, action) => {
      state.savedTokens = [...state.savedTokens, action.payload]
    })
    .addCase(removeToken, (state, action) => {
      state.savedTokens = state.savedTokens.filter(
        (token) => token.address !== action.payload
      )
    })
)
