import {
  Flex,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber
} from '@chakra-ui/react'
import React from 'react'

interface AnalyticsStatProps {
  title: string
  value: string
  change?: number
}

const AnalyticsStat = ({ change, title, value }: AnalyticsStatProps) => {
  const statVariant = !change
    ? undefined
    : change && change >= 0
      ? 'positive'
      : 'negative'
  return (
    <Stat variant={statVariant}>
      <StatLabel>{title}</StatLabel>
      <Flex
        flexDir={{ base: 'column', sm: 'row' }}
        gap={{ base: 0, sm: 2 }}
        align={{ base: 'flex-start', sm: 'center' }}
      >
        <StatNumber>{value}</StatNumber>
        {change ? (
          <StatHelpText m={0}>{`${change.toFixed(2)}%`}</StatHelpText>
        ) : null}
      </Flex>
    </Stat>
  )
}

export default AnalyticsStat
