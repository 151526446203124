import { ChainId, WNATIVE } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensUsdPrice'
import { ExtraHooksRewarderData, HooksRewarderData } from 'types/lbHooksLens'
import { formatUnits, zeroAddress } from 'viem'

interface UseGetLbPoolsRewardAPRsProps {
  liquidityDepthMinus: number
  liquidityDepthPlus: number
  extraRewarderData?: ExtraHooksRewarderData
  lbPairAddress?: string
  rewarderData?: HooksRewarderData
}

const getLbPoolsRewardAPRs = ({
  chainId,
  extraRewarderData,
  lbPairAddress,
  liquidityDepthMinus,
  liquidityDepthPlus,
  priceUSDPerToken,
  rewarderData
}: UseGetLbPoolsRewardAPRsProps & {
  chainId: Exclude<ChainId, ChainId.MANTLE>
  priceUSDPerToken?: Record<string, number>
}) => {
  if (
    !lbPairAddress ||
    !rewarderData ||
    !extraRewarderData ||
    !priceUSDPerToken
  ) {
    return undefined
  }

  const hasRewarder = rewarderData.hooksParameters.hooks !== zeroAddress
  const hasExtraRewarder =
    extraRewarderData.hooksParameters.hooks !== zeroAddress

  if (!hasRewarder && !hasExtraRewarder) {
    return undefined
  }

  const rewardTokenAddress =
    rewarderData.parameters.rewardToken.token.toLowerCase()
  const rewardTokenPriceUsd =
    priceUSDPerToken[
      rewardTokenAddress === zeroAddress
        ? WNATIVE[chainId].address.toLowerCase()
        : rewardTokenAddress
    ]
  const extraRewardTokenAddress =
    extraRewarderData.parameters.rewardToken.token.toLowerCase()
  const extraRewardTokenPriceUsd =
    priceUSDPerToken[
      extraRewardTokenAddress === zeroAddress
        ? WNATIVE[chainId].address.toLowerCase()
        : extraRewardTokenAddress
    ]

  const rewarderAprData = hasRewarder
    ? getRewarderApr({
        liquidityDepthMinus,
        liquidityDepthPlus,
        rewardPerSecond: Number(
          formatUnits(
            rewarderData.parameters.rewardPerSecond,
            Number(rewarderData.parameters.rewardToken.decimals)
          )
        ),
        rewardTokenPriceUsd
      })
    : undefined

  const extraRewarderAprData = hasExtraRewarder
    ? getRewarderApr({
        liquidityDepthMinus,
        liquidityDepthPlus,
        rewardPerSecond: Number(
          formatUnits(
            extraRewarderData.parameters.rewardPerSecond,
            Number(extraRewarderData.parameters.rewardToken.decimals)
          )
        ),
        rewardTokenPriceUsd: extraRewardTokenPriceUsd
      })
    : undefined

  return {
    extraRewarderAprData,
    lbPairAddress,
    rewarderAprData
  }
}

const getRewarderApr = ({
  liquidityDepthMinus,
  liquidityDepthPlus,
  rewardPerSecond,
  rewardTokenPriceUsd
}: {
  liquidityDepthMinus: number
  liquidityDepthPlus: number
  rewardPerSecond: number
  rewardTokenPriceUsd: number
}) => {
  const rangeLiquidityUsd = liquidityDepthMinus + liquidityDepthPlus

  const nbSecondsInDay = 60 * 60 * 24
  const rewardsUsdPerYear = rewardTokenPriceUsd
    ? rewardPerSecond * rewardTokenPriceUsd * nbSecondsInDay * 365
    : undefined

  const apr =
    rewardsUsdPerYear && rangeLiquidityUsd
      ? rewardsUsdPerYear / rangeLiquidityUsd
      : 0

  return { apr, rangeLiquidityUsd }
}

const useGetLbPoolsRewardAPRs = ({
  calls
}: {
  calls: UseGetLbPoolsRewardAPRsProps[]
}) => {
  const chainId = useChainId()

  const tokens = calls
    .map((call) => [
      call.rewarderData?.parameters.rewardToken.token.toLowerCase(),
      call.extraRewarderData?.parameters.rewardToken.token.toLowerCase()
    ])
    .flat()
    .map((token) => (token === zeroAddress ? WNATIVE[chainId].address : token))
    .filter(Boolean) as string[]
  const uniqueTokens = Array.from(new Set(tokens))

  const { data: priceUSDPerToken, isLoading: isLoadingPrices } =
    useGetTokensUsdPrice({
      enabled: uniqueTokens.length > 0,
      tokenAddresses: uniqueTokens
    })

  return {
    data: calls.map((call) => {
      return getLbPoolsRewardAPRs({
        chainId,
        priceUSDPerToken,
        ...call
      })
    }),
    isLoading: isLoadingPrices
  }
}

export default useGetLbPoolsRewardAPRs
