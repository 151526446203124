import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import AppChainSelector from 'components/AppChainSelector'
import {
  joeBridge,
  joeFarm,
  joeHome,
  joeLend,
  joeMemeRush,
  joePool,
  joeStake,
  joeTrade,
  SubmenuItem
} from 'constants/urls'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HamburgerIcon } from 'theme/icons'
import { getChainSlug } from 'utils/chains'

const MobileNavigationMenu = () => {
  const chainId = useChainId()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const navigate = useNavigate()
  const submenuItems: SubmenuItem[] =
    chainId === ChainId.AVALANCHE
      ? [
          joeHome,
          joeTrade,
          joePool,
          joeStake,
          joeMemeRush,
          joeBridge,
          joeFarm,
          joeLend
        ]
      : [joeHome, joeTrade, joePool, joeStake, joeBridge, joeFarm, joeLend]

  return (
    <>
      <Button
        aria-label="Click for links to Bridge, Farm, Lend and Stake on Trader Joe"
        variant="ghost"
        color="textPrimary"
        _hover={{ bg: 'bgTertiary' }}
        _active={{ bg: 'bgTertiary', color: 'textPrimary' }}
        onClick={onOpen}
      >
        <HamburgerIcon boxSize={6} fill="textPrimary" />
      </Button>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          borderTopLeftRadius={24}
          borderTopRightRadius={24}
          bg="bgPrimary"
        >
          <DrawerCloseButton />
          <DrawerBody>
            <Flex
              gap={4}
              w="full"
              align="center"
              flexDir="column"
              justifyContent="center"
              p={4}
            >
              {submenuItems.map((item, i) => {
                return item.external ? (
                  <Flex
                    _hover={{ cursor: 'pointer' }}
                    key={i}
                    align="center"
                    justifyContent="center"
                    w="full"
                    h={10}
                    onClick={() => window.open(item.url, '_blank')}
                  >
                    <Flex
                      w="full"
                      gap={2}
                      justifyContent="center"
                      align="center"
                    >
                      <Text textAlign="center" fontWeight="semibold">
                        {item.name}
                      </Text>
                      <ExternalLinkIcon />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    key={i}
                    w="full"
                    h={10}
                    justifyContent="center"
                    align="center"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/${getChainSlug(chainId)}/${item.url}`)
                      onClose()
                    }}
                  >
                    <Text textAlign="center" w="full" fontWeight="semibold">
                      {item.name}
                    </Text>
                  </Flex>
                )
              })}
            </Flex>
            <Divider />
            <Flex py={4}>
              <AppChainSelector menuButtonProps={{ flex: 1 }} />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileNavigationMenu
