import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { LiquidityDistributionParams } from '@traderjoe-xyz/sdk-v2'
import CopyableError from 'components/CopyableError'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import Web3Button from 'components/Web3Button'
import useAddLiquidityV2 from 'hooks/pool/v2/useAddLiquidityV2'
import React, { useState } from 'react'
import { LBPoolVersion } from 'types/pool'
import { LBPairDistribution } from 'types/poolV2'
import { getPriceFromBinId } from 'utils/bin'
import { formattedNum } from 'utils/format'
import { AddUniformLiquidityBatch } from 'utils/getAddUniformLiquidityBatches'
import { formatUnits, TransactionReceipt } from 'viem'
import { BaseError, usePublicClient } from 'wagmi'

interface BatchAddLiquidityModalProps {
  activeBinId: number
  amount0: bigint
  amount1: bigint
  batches: AddUniformLiquidityBatch[]
  binStep: number
  currency0: Currency
  currency1: Currency
  debouncedBinRange: number[]
  distributionParams: LiquidityDistributionParams
  inversePriceRatios: boolean
  isOpen: boolean
  isPoolLowLiquidity: boolean
  lbPoolVersion: LBPoolVersion
  onClose: () => void
  simulatedLiquidityDistribution: LBPairDistribution[]
}

const BatchAddLiquidityModal = ({
  activeBinId,
  batches,
  binStep,
  currency0,
  currency1,
  inversePriceRatios,
  isOpen,
  isPoolLowLiquidity,
  lbPoolVersion,
  onClose,
  simulatedLiquidityDistribution
}: BatchAddLiquidityModalProps) => {
  const publicClient = usePublicClient()

  const [selectedBatchIndex, setSelectedBatchIndex] = useState<
    number | undefined
  >()

  const [receiptsPerBatch, setReceiptsPerBatch] = useState<{
    [index: number]: TransactionReceipt
  }>({})

  const selectedBatch =
    selectedBatchIndex !== undefined ? batches?.[selectedBatchIndex] : undefined

  const {
    addLiquidity,
    isLoading: isAddingLiquidity,
    resetAddLiquidity,
    writeError: addLiquidityError
  } = useAddLiquidityV2({
    activeBinId: activeBinId ? BigInt(activeBinId) : undefined,
    amount0: selectedBatch?.amount0,
    amount1: selectedBatch?.amount1,
    binStep,
    currency0,
    currency1,
    distributionParams: selectedBatch?.distributionParams,
    lbPoolVersion
  })

  const onAddLiquidityClick = async () => {
    if (!addLiquidity || !publicClient) return
    try {
      const hash = await addLiquidity()

      const receipt = await publicClient.waitForTransactionReceipt({ hash })

      if (selectedBatchIndex !== undefined) {
        setReceiptsPerBatch((prev) => ({
          ...prev,
          [selectedBatchIndex]: receipt
        }))
      }
      setSelectedBatchIndex(undefined)
    } catch (err) {
      console.error(err)
    }
  }

  const chartData = simulatedLiquidityDistribution.map((entry) => ({
    ...entry,
    barOpacity: selectedBatch
      ? entry.binId >= selectedBatch.binRange[0] &&
        entry.binId <= selectedBatch.binRange[1]
        ? 1
        : 0.25
      : 0.25
  }))

  const allBatchesSuccess = batches.every(
    (_, index) => receiptsPerBatch[index]?.status === 'success'
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Add Liquidity</Trans>
        </ModalHeader>
        <ModalCloseButton data-cy="batch-add-liquidity-modal-close-button" />
        <ModalBody overflowY="auto" maxHeight="75svh">
          <Box pt={4}>
            <LBPairDistributionChart
              currency0={currency0}
              currency1={currency1}
              activeBinId={activeBinId}
              data={chartData}
              isPriceRatioInversed={inversePriceRatios}
              title={t`Simulated Liquidity Distribution`}
            />
          </Box>

          <SimpleGrid w="full" spacing={4} columns={{ base: 1, md: 2 }} mt={4}>
            {batches.map((batch, batchIndex) => {
              const minPrice = getPriceFromBinId(
                batch.binRange[0],
                binStep,
                currency0.decimals,
                currency1.decimals,
                18
              )
              const maxPrice = getPriceFromBinId(
                batch.binRange[1],
                binStep,
                currency0.decimals,
                currency1.decimals,
                18
              )

              const formattingOptions = { places: 5 }

              const fmtMinPrice = formattedNum(
                inversePriceRatios ? 1 / Number(maxPrice) : minPrice,
                formattingOptions
              )
              const fmtMaxPrice = formattedNum(
                inversePriceRatios ? 1 / Number(minPrice) : maxPrice,
                formattingOptions
              )

              const fmtAmounts =
                batch.amount0 > BigInt(0) && batch.amount1 > BigInt(0)
                  ? `${formattedNum(
                      formatUnits(batch.amount0, currency0?.decimals),
                      formattingOptions
                    )} ${currency0?.symbol} + ${formattedNum(
                      formatUnits(batch.amount1, currency1?.decimals),
                      formattingOptions
                    )} ${currency1?.symbol}`
                  : batch.amount0 > 0
                    ? `${formattedNum(
                        formatUnits(batch.amount0, currency0?.decimals),
                        formattingOptions
                      )} ${currency0?.symbol}`
                    : `${formattedNum(
                        formatUnits(batch.amount1, currency1?.decimals),
                        formattingOptions
                      )} ${currency1?.symbol}`

              const isSelected = selectedBatchIndex === batchIndex
              const receipt = receiptsPerBatch[batchIndex]
              const isSuccess = receipt?.status === 'success'
              const isError = receipt?.status === 'reverted'

              return (
                <Flex
                  key={batch.binRange.toString()}
                  data-cy={`batch-add-liquidity-${batchIndex}`}
                  w="full"
                  flexDir="column"
                  gap={4}
                  p={4}
                  bg={isSelected ? 'bgHighlight' : 'bgCard'}
                  border="1px solid"
                  borderColor={isSelected ? 'accent.500' : 'transparent'}
                  borderRadius="xl"
                  cursor="pointer"
                  onClick={() => {
                    setSelectedBatchIndex(batchIndex)
                    resetAddLiquidity()
                  }}
                  _hover={{ bg: 'bgHighlight' }}
                  pointerEvents={
                    isAddingLiquidity || isSuccess ? 'none' : 'auto'
                  }
                  opacity={isAddingLiquidity || isSuccess ? 0.5 : 1}
                >
                  <HStack>
                    <Heading size="xs">Batch {batchIndex + 1}</Heading>
                    {isSuccess ? (
                      <CheckCircleIcon color="green.400" />
                    ) : isError ? (
                      <WarningIcon color="yellow.400" />
                    ) : null}
                  </HStack>
                  <VStack w="full">
                    <Value
                      title={t`Range`}
                      value={t`${fmtMinPrice} - ${fmtMaxPrice} ${
                        inversePriceRatios ? currency0.symbol : currency1.symbol
                      } per ${
                        inversePriceRatios ? currency1.symbol : currency0.symbol
                      }`}
                    />
                    <Value title={t`Amounts to add`} value={fmtAmounts} />
                  </VStack>
                </Flex>
              )
            })}
          </SimpleGrid>
        </ModalBody>

        <ModalFooter>
          <VStack w="full">
            <Web3Button
              data-cy="batch-add-liquidity-button"
              variant="primary"
              colorScheme={isPoolLowLiquidity ? 'red' : 'accent'}
              size="xl"
              w="full"
              isDisabled={!selectedBatch || !addLiquidity}
              isLoading={isAddingLiquidity}
              loadingText={t`Waiting for confirmation`}
              onClick={onAddLiquidityClick}
            >
              {allBatchesSuccess ? (
                <Trans>Liquidity Added!</Trans>
              ) : selectedBatch ? (
                <Trans>Add Liquidity</Trans>
              ) : (
                <Trans>Select batch</Trans>
              )}
            </Web3Button>

            {addLiquidityError ? (
              <CopyableError
                error={addLiquidityError.message}
                summary={(addLiquidityError as BaseError).shortMessage}
              />
            ) : null}
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default BatchAddLiquidityModal

interface ValueProps {
  title: string
  value: string
}

const Value = ({ title, value }: ValueProps) => (
  <Flex justify="space-between" w="full" flexDir="column">
    <Text textColor="textSecondary" fontSize="sm">
      {title}
    </Text>
    <Text fontWeight="semibold" fontSize="sm">
      {value}
    </Text>
  </Flex>
)
