import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  CloseButton,
  HStack,
  Image,
  Link,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import useErc404TokenImageUrls from 'hooks/swap/useErc404TokenImageUrls'
import React from 'react'
import { TransactionReceipt } from 'viem'
import { useAccount } from 'wagmi'

interface Erc404TradeCompletedProps {
  receipt: TransactionReceipt
  onDismiss?: () => void
}

const Erc404TradeCompleted = ({
  onDismiss,
  receipt
}: Erc404TradeCompletedProps) => {
  const { address: account } = useAccount()
  const { contractAddress, imageUrls, symbol } = useErc404TokenImageUrls({
    receipt
  })

  if (!imageUrls || imageUrls.length === 0 || !contractAddress || !symbol) {
    return null
  }

  const joepegsUrl = `https://joepegs.com/profile/${account}?collectionChain=avalanche&collections=%5B"${contractAddress}"%5D`

  return (
    <HStack
      w="full"
      border="1px solid"
      borderColor="border"
      borderRadius="2xl"
      p={4}
      spacing={4}
      bg="bgCard"
    >
      <HStack pos="relative">
        {imageUrls.slice(0, 3).map((url, i) => (
          <Box key={i} ml={i === 0 ? 0 : -10}>
            <Image src={url} boxSize="48px" borderRadius="lg" />
          </Box>
        ))}

        {imageUrls.length > 3 && (
          <Box
            position="absolute"
            right="0"
            top="0"
            bottom="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            borderRadius="lg"
            boxSize="48px"
          >
            <Text color="white" fontWeight="semibold">{`${
              imageUrls.length - 3
            }+`}</Text>
          </Box>
        )}
      </HStack>

      <VStack spacing={1} align="flex-start">
        <Text fontWeight="semibold">
          You minted {imageUrls.length} {symbol} NFTs
        </Text>
        <Link isExternal color="textSecondary" href={joepegsUrl}>
          <HStack>
            <Text textColor="textSecondary">
              <Trans>View on Joepegs</Trans>
            </Text>
            <ExternalLinkIcon color="textSecondary" />
          </HStack>
        </Link>
      </VStack>

      <Spacer />

      <CloseButton onClick={onDismiss} />
    </HStack>
  )
}

export default Erc404TradeCompleted
