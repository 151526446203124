import { CNATIVE, Token } from '@traderjoe-xyz/sdk-core'
import { ClaimableRewardsEpoch, FormattedReward, Reward } from 'types/rewards'
import { formatUnits } from 'viem'
/**
 * Returns the unique token addresses from t
 * @param {ClaimableRewardsEpoch} epochs
 * @returns {string[]}
 */
export const getClaimableTokenAddresses = (
  epochs: ClaimableRewardsEpoch[] | undefined
) => {
  if (!epochs) {
    return []
  }

  const addresses = new Set<string>()

  Object.values(epochs).forEach((epoch) => {
    const { claimableRewards } = epoch
    claimableRewards.forEach((reward) => {
      addresses.add(reward.tokenAddress)
    })
  })

  return Array.from(addresses)
}

/**
 * Returns map of tokenAddr: Token
 *
 * @param {string[]} addresses
 * @param {(Token | CNATIVE | undefined)[]} tokens
 * @returns {Object}
 */
export const getTokensMap = (
  addresses: string[],
  tokens: (Token | CNATIVE | undefined)[]
) => {
  if (addresses.length !== tokens.length) {
    return {}
  }

  return addresses.reduce(
    (prev, address, index) => ({
      ...prev,
      [address]: tokens[index]
    }),
    {}
  )
}

/**
 * Returns the rewards formatted by the token's decimals
 *
 * @param {ClaimableRewardsEpoch | undefined} rewardEpoch
 * @param { { [k: string]: Token | CNATIVE | undefined }} tokensMap
 * @returns
 */
export const getFormattedRewards = (
  rewards: Reward[] | undefined,
  tokensMap: { [address: string]: Token | CNATIVE | undefined }
): FormattedReward[] => {
  if (!rewards) {
    return []
  }

  try {
    return rewards.map((reward) => {
      const tokenAddress = reward.tokenAddress
      const rewardToken = tokensMap[reward.tokenAddress]
      const tokenAmount = rewardToken
        ? Number(formatUnits(BigInt(reward.amount), rewardToken.decimals))
        : 0
      return {
        token: rewardToken,
        tokenAddress,
        tokenAmount
      }
    })
  } catch (e) {
    console.debug('Error getFormattedRewards() ', e)
    return []
  }
}

/**
 * Returns the aggregate sum of rewards from all claimable epochs
 *
 * @param {FormattedReward[][]} rewardEpochs
 * @returns {FormattedReward[]}
 */
export const getAggregatedClaimableRewards = (
  rewardEpochs: FormattedReward[][]
): FormattedReward[] => {
  const aggRewards: { [address: string]: FormattedReward } = {}

  rewardEpochs.forEach((rewardEpoch) => {
    rewardEpoch.forEach((reward) => {
      const { token, tokenAddress, tokenAmount } = reward
      if (!aggRewards[tokenAddress]) {
        aggRewards[tokenAddress] = {
          token,
          tokenAddress,
          tokenAmount: 0
        }
      }
      aggRewards[tokenAddress].tokenAmount += tokenAmount
    })
  })

  return Object.values(aggRewards)
}
