import {
  Center,
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import useIsChainSunset from 'hooks/useIsChainSunset'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import React from 'react'
import { LBPoolVersion } from 'types/pool'
import { LBPairUserBalances } from 'types/poolV2'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useChainId } from 'wagmi'
import { GetBalanceData } from 'wagmi/query'

import AddLiquidityPanelV2 from './AddLiquidityPanelV2'
import RemoveLiquidityPanelV2 from './RemoveLiquidityPanelV2'

interface AddRemoveLiquidityPanelV2Props {
  currency0: Currency
  currency1: Currency
  isPoolMigrated: boolean
  lbPairAddress: string
  lbPoolVersion: LBPoolVersion
  onAddLiquiditySuccess: () => void
  onRemoveLiquidityConfigError: () => void
  onRemoveLiquiditySuccess: () => void
  activeBinId?: number
  balance0?: GetBalanceData
  balance1?: GetBalanceData
  binStep?: string
  inversePriceRatios?: boolean
  poolLiquidityUsd?: number
  rewardedRange?: number[]
  togglePriceRatiosClicked?: () => void
  userBalances?: LBPairUserBalances
}

const AddRemoveLiquidityPanelV2 = (props: AddRemoveLiquidityPanelV2Props) => {
  const chainId = useChainId()
  const isChainSunset = useIsChainSunset()

  const wrappedCurrency0 = wrappedCurrency(props.currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(props.currency1, chainId)

  const { isPoolMigrated: isAddLiquidityDisabled } = props

  const { data: usdPrices } = useTokenPriceUSD({
    tokens:
      wrappedCurrency0 && wrappedCurrency1
        ? [wrappedCurrency0.address, wrappedCurrency1.address]
        : []
  })
  const [currencyPrice0, currencyPrice1] = usdPrices ?? [undefined, undefined]

  return (
    <Tabs
      isFitted
      variant="soft-rounded-outlined-white"
      w="full"
      defaultIndex={isAddLiquidityDisabled ? 1 : 0}
    >
      <TabList my={4} mx={{ base: 4, md: 8 }}>
        <Tab
          data-cy="add-liquidity-tab"
          _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}
          isDisabled={isAddLiquidityDisabled}
        >
          <Trans>Add Liquidity</Trans>
        </Tab>
        <Tab
          data-cy="remove-liquidity-tab"
          _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}
        >
          <Trans>Remove Liquidity</Trans>
        </Tab>
      </TabList>
      <Divider />
      <TabPanels py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }}>
        <TabPanel data-cy="add-liquidity-panel" p={0}>
          {isChainSunset ? (
            <Center minH="160px">
              <Text fontSize="sm" textColor="textSecondary">
                This pool will be closing
              </Text>
            </Center>
          ) : !isAddLiquidityDisabled ? (
            <AddLiquidityPanelV2
              {...props}
              currencyPrice0={currencyPrice0}
              currencyPrice1={currencyPrice1}
            />
          ) : null}
        </TabPanel>
        <TabPanel data-cy="remove-liquidity-panel" p={0}>
          <RemoveLiquidityPanelV2
            {...props}
            currencyPrice0={currencyPrice0}
            currencyPrice1={currencyPrice1}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default AddRemoveLiquidityPanelV2
