import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { VaultTvlHistoryData } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface UseVaultTvlHistoryProps {
  vaultAddress: string
  enabled?: boolean
}

const useVaultTvlHistory = ({
  enabled = true,
  vaultAddress
}: UseVaultTvlHistoryProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const now = dayjs()
  const thirtyDaysAgo = now.subtract(29, 'day')

  const startTime = thirtyDaysAgo.unix()
  const endTime = now.unix()

  const fetchTvlHistory = useDexbarnGet<VaultTvlHistoryData[]>(
    `/v1/vaults/${chain}/${vaultAddress}/tvl-history`
  )
  return useQuery({
    enabled,
    queryFn: () => fetchTvlHistory({ params: { endTime, startTime } }),
    queryKey: ['UseVaultTvlHistoryQuery', vaultAddress]
  })
}

export default useVaultTvlHistory
