import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { APTFarmABI } from '@traderjoe-xyz/sdk-v2'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useMemo } from 'react'
import { getAddress } from 'viem'
import { useSimulateContract, useWriteContract } from 'wagmi'

interface UseFarmDepositProps {
  farmAddress: string
  formattedRewards: { amount: string; symbol: string }[]
  poolId: string
  enabled?: boolean
  onSuccess?: () => void
}

const useFarmHarvest = ({
  enabled = true,
  farmAddress,
  formattedRewards,
  onSuccess,
  poolId
}: UseFarmDepositProps) => {
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const _enabled = enabled && !!poolId && !!farmAddress

  const { data: depositConfig } = useSimulateContract({
    abi: APTFarmABI,
    address: getAddress(farmAddress),
    args: [[BigInt(poolId)]],
    functionName: 'harvestRewards',
    query: {
      enabled: _enabled,
      gcTime: 0
    },
    value: BigInt(0) as any
  })

  const transactionSummary = useMemo(() => {
    const rewardString = formattedRewards
      .map((reward) => `${reward.amount} ${reward.symbol}`)
      .join(', ')
    return t`Harvested ${rewardString} rewards`
  }, [formattedRewards])

  const {
    data: harvestTxHash,
    isPending: isLoadingHarvest,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        if (!transactionSummary) return
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const harvest = depositConfig?.request
    ? () => writeContract(depositConfig.request)
    : undefined

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    hash: harvestTxHash,
    onTransactionSuccess: onSuccess
  })

  return {
    harvest,
    isLoading: isWaitingForTransaction || isLoadingHarvest
  }
}

export default useFarmHarvest
