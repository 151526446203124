import { useMemo } from 'react'
import { LBPool, Pool } from 'types/pool'

export enum PoolSortMethod {
  APR = 'apr',
  FEE = 'fee',
  LIQUIDITY = 'liquidity',
  MY_FUNDS = 'myFunds',
  MY_REWARDS = 'myRewards',
  VOLUME = 'volume'
}

interface UseSortedPoolsProps {
  isSortDescending: boolean
  pools: (Pool | LBPool)[]
  sortMethod: PoolSortMethod
}

const useSortedPools = ({
  isSortDescending,
  pools,
  sortMethod
}: UseSortedPoolsProps) => {
  return useMemo(() => {
    let sortedPools = pools
    switch (sortMethod) {
      case PoolSortMethod.LIQUIDITY:
        sortedPools = sortedPools.sort(
          (a, b) => (b?.liquidityUsd ?? 0) - (a?.liquidityUsd ?? 0)
        )
        break
      case PoolSortMethod.VOLUME:
        sortedPools = sortedPools.sort(
          (a, b) => (b?.volumeUsd ?? 0) - (a?.volumeUsd ?? 0)
        )
        break
      case PoolSortMethod.FEE:
        sortedPools = sortedPools.sort(
          (a, b) => (b?.feesUsd ?? 0) - (a?.feesUsd ?? 0)
        )
        break
      case PoolSortMethod.APR:
        sortedPools = sortedPools.sort((a, b) => {
          const epochRewardsAprA =
            'rewards' in a
              ? a.rewards?.find((reward) => reward.epoch.status === 'ongoing')
                  ?.apr || 0
              : 0
          const epochRewardsAprB =
            'rewards' in b
              ? b.rewards?.find((reward) => reward.epoch.status === 'ongoing')
                  ?.apr || 0
              : 0

          const lbRewardsAprA =
            'lbRewards' in a ? a.lbRewards?.[0]?.rewardsApr || 0 : 0
          const lbRewardsAprB =
            'lbRewards' in b ? b.lbRewards?.[0]?.rewardsApr || 0 : 0

          const totalRewardsA = epochRewardsAprA + lbRewardsAprA + a.apr
          const totalRewardsB = epochRewardsAprB + lbRewardsAprB + b.apr

          return totalRewardsB - totalRewardsA
        })
        break
      case PoolSortMethod.MY_FUNDS:
        break
    }
    return isSortDescending ? sortedPools : sortedPools.reverse()
  }, [pools, sortMethod, isSortDescending])
}

export default useSortedPools
