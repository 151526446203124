import { ChainId } from '@traderjoe-xyz/sdk-core'

import useChainId from './useChainId'

const useIsChainSunset = () => {
  const chainId = useChainId()
  return chainId === ChainId.BNB_CHAIN || chainId === ChainId.BNB_TESTNET
}

export default useIsChainSunset
