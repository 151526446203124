import { Tag } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import PoolCard from 'components/PoolCard'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { VaultWithUserPosition } from 'types/vault'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'
import {
  getFarmRowFormattedValues,
  getVaultRowFormattedValues
} from 'utils/vault'

interface VaultMobileRowProps {
  vault: VaultWithUserPosition
}

const VaultMobileRow = ({ vault }: VaultMobileRowProps) => {
  const chainId = useChainId()
  const {
    apt1dPriceChange,
    apt1dPriceChangeColor,
    aptPrice,
    feesUsd1D,
    liquidity,
    myFunds
  } = getVaultRowFormattedValues(vault)
  const { farmApr1D, myFarmDepositUSD } = getFarmRowFormattedValues(vault)

  const totalDeposit = myFunds + myFarmDepositUSD

  const fmtTotalFundsDeposit =
    totalDeposit > 0
      ? formattedNum(totalDeposit, {
          usd: true
        })
      : '--'

  const tags = [
    <Tag
      key="fees-tag"
      variant="solid"
      size="md"
      bg="bgTertiary"
      textColor="textSecondary"
    >
      {`${vault.lbPair.baseFeePct}%`}
    </Tag>
  ]
  if (vault.isRewarded) {
    tags.push(
      <Tag variant="solid" size="md" bg="green.400" textColor="white">
        <Trans>REWARDS</Trans>
      </Tag>
    )
  }

  return (
    <PoolCard
      w="full"
      href={`/${getChainSlug(chainId)}/vault/${vault.id}`}
      token0={vault.tokenX}
      token1={vault.tokenY}
      tags={tags}
      isLabelsColumn
      columns={[
        {
          title: t`Liquidity`,
          value: liquidity
        },
        {
          title: t`My Funds`,
          value: fmtTotalFundsDeposit
        },
        {
          title: t`Price`,
          value: aptPrice
        },
        {
          title: t`Pct Change (24H)`,
          value: apt1dPriceChange,
          valueTextColor: apt1dPriceChangeColor
        },
        {
          title: t`Fees (24H)`,
          value: feesUsd1D
        },
        {
          title: t`Rewards APR`,
          value: farmApr1D
        }
      ]}
    />
  )
}

export default VaultMobileRow
