import { keepPreviousData, useQuery } from '@tanstack/react-query'
import {
  LIQUIDITY_AMOUNTS_HELPER_ADDRESS,
  LiquidityAmountsHelperABI
} from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { UserLBPosition } from 'types/poolV2'
import { getDexbarnChainParam } from 'utils/chains'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

const useUserLBPositions = ({
  enabled
}: {
  enabled: boolean
}): {
  data: UserLBPosition[]
  isLoading: boolean
} => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const fetchUserPoolIds = useDexbarnGet<UserLBPosition[]>(
    `/v1/user/pool-ids/${account?.toLowerCase()}/${chain}?pageSize=50`
  )
  const result = useQuery<UserLBPosition[]>({
    enabled: !!account && enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchUserPoolIds(),
    queryKey: ['UserLBPositionsWithBinIds', chain, account]
  })

  // fetch total amounts X/Y for each position
  const positions = result.data ?? []
  const contract = {
    abi: LiquidityAmountsHelperABI,
    address: LIQUIDITY_AMOUNTS_HELPER_ADDRESS[chainId]
  } as const
  const { data: totalAmounts, isLoading } = useReadContracts({
    contracts: positions.map((position) => ({
      ...contract,
      args: account
        ? [account, position.binIds, getAddress(position.poolAddress)]
        : undefined,
      chainId,
      functionName: 'getTotalAmountsOf'
    })),
    query: { enabled: !!account && positions.length > 0 }
  })

  const userLBPositions = positions.map((position, i) => {
    const amounts = totalAmounts?.[i]?.result as [bigint, bigint] | undefined
    const totalAmountX = amounts?.[0] ?? BigInt(0)
    const totalAmountY = amounts?.[1] ?? BigInt(0)
    return {
      ...position,
      totalAmountX: {
        formatted: formatUnits(totalAmountX, position.tokenX.decimals),
        value: totalAmountX
      },
      totalAmountY: {
        formatted: formatUnits(totalAmountY, position.tokenY.decimals),
        value: totalAmountY
      }
    }
  })

  return {
    data: userLBPositions,
    isLoading: isLoading || result.isLoading
  }
}

export default useUserLBPositions
