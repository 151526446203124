import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { LBPairABI } from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import debounce from 'lodash.debounce'
import { useMemo } from 'react'
import { getConfigWithGasLimitIncreased } from 'utils/gas'
import { getAddress } from 'viem'
import {
  useAccount,
  useReadContract,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

interface UseLBPairClaimFeesProps {
  lbPairAddress?: string
  poolName?: string
  userPositions?: number[]
}

const useLBPairClaimFees = ({
  lbPairAddress,
  poolName,
  userPositions
}: UseLBPairClaimFeesProps) => {
  const { address: account, chain: walletChain } = useAccount()
  const chainId = useChainId()
  const walletChainId = walletChain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const userBins = useMemo(
    () =>
      userPositions ? userPositions.map((position) => BigInt(position)) : [],
    [userPositions]
  )

  const { data: pendingFees, refetch: refetchPendingFees } = useReadContract({
    abi: LBPairABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    args: account && userBins ? [account, userBins] : undefined,
    chainId,
    functionName: 'pendingFees',
    query: {
      enabled: !!account
    }
  })

  const { data: config } = useSimulateContract({
    abi: LBPairABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    args: account && userBins ? [account, userBins] : undefined,
    chainId,
    functionName: 'collectFees',
    query: { enabled: !!account && chainId === walletChainId, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Claim fees for ${poolName} pool`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  const configWithGasLimitIncreased = getConfigWithGasLimitIncreased({
    config,
    percentageIncrease: 10
  })
  const claimFees = configWithGasLimitIncreased?.request
    ? () => writeContract(configWithGasLimitIncreased.request)
    : undefined

  const debouncedRefetchPendingFees = debounce(() => refetchPendingFees(), 4000)
  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    hash: data,
    onTransactionSuccess: () => debouncedRefetchPendingFees()
  })

  return {
    claimFees,
    isLoading: isPending || isWaitingForTransaction,
    pendingFees
  }
}

export default useLBPairClaimFees
