import {
  Box,
  BoxProps,
  Divider,
  Flex,
  HStack,
  StackProps,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import ColorModeButton from 'components/Header/ColorModeButton'
import React from 'react'
import { useShowTestnets } from 'state/settings/hooks'
import { ArrowLeftIcon } from 'theme/icons'

import WalletMenuItem from '../ConnectedScreen/WalletMenuItem'
import LanguageMenu from './LanguageMenu'

interface SettingsScreenProps {
  boxProps: BoxProps
  headerStackProps: StackProps
  onBackClick?: () => void
}

const SettingsScreen = ({
  boxProps,
  headerStackProps,
  onBackClick
}: SettingsScreenProps) => {
  const { showTestnets, updateShowTestnets } = useShowTestnets()
  return (
    <>
      <HStack align="center" {...headerStackProps}>
        {onBackClick ? (
          <WalletMenuItem columnGap={4} onClick={onBackClick}>
            <ArrowLeftIcon />
            <Flex flexDir="column" alignItems="flex-start">
              <Text fontWeight="bold" fontSize="lg" textColor="textPrimary">
                <Trans>Settings</Trans>
              </Text>
            </Flex>
          </WalletMenuItem>
        ) : (
          <WalletMenuItem pointerEvents="none">
            <Text fontWeight="bold" fontSize="lg" textColor="textPrimary">
              <Trans>Settings</Trans>
            </Text>
          </WalletMenuItem>
        )}
      </HStack>
      <Divider />
      <Box {...boxProps}>
        <HStack pt={4} pb={2} w="full">
          <VStack w="full" alignItems="flex-start">
            <Text fontWeight="bold">
              <Trans>Theme</Trans>
            </Text>
          </VStack>
          <VStack w="full" alignItems="flex-end">
            <ColorModeButton />
          </VStack>
        </HStack>
        <HStack py={4} w="full">
          <VStack w="full" alignItems="flex-start">
            <Text fontWeight="bold">
              <Trans>Language</Trans>
            </Text>
          </VStack>
          <LanguageMenu />
        </HStack>
        <HStack py={4} w="full">
          <VStack w="full" alignItems="flex-start">
            <Text fontWeight="bold">
              <Trans>Show testnets</Trans>
            </Text>
          </VStack>
          <VStack w="full" alignItems="flex-end">
            <Switch
              size="lg"
              isChecked={showTestnets}
              onChange={(e) => updateShowTestnets(e.target.checked)}
            />
          </VStack>
        </HStack>
      </Box>
    </>
  )
}

export default SettingsScreen
