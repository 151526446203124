import { Box, Center, Grid, Spinner } from '@chakra-ui/react'
import PageHelmet from 'components/PageHelmet'
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from 'constants/pool'
import usePairUserBalance from 'hooks/pool/v1/usePairUserBalance'
import usePoolV1LiquidityPanel from 'hooks/pool/v1/usePoolV1LiquidityPanel'
import debounce from 'lodash.debounce'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import AddRemoveLiquidityPanel from './AddRemoveLiquidityPanel'
import PoolDetailBalancesPanelV1 from './PoolDetailBalancesPanelV1'
import PoolDetailHeader from './PoolDetailHeader'
import PoolDetailOverview from './PoolDetailOverview'

const PoolDetail = () => {
  const { token0Address, token1Address } = useParams()
  const location = useLocation()

  const {
    isLoading,
    onToken0Select,
    onToken1Select,
    pair,
    pool,
    token0,
    token1
  } = usePoolV1LiquidityPanel({
    token0Address,
    token1Address
  })
  const pairAddress = pair?.liquidityToken.address

  const {
    data: userBalanceData,
    isLoading: isLoadingUserBalance,
    refetchUserBalance
  } = usePairUserBalance({
    address: pairAddress,
    pair
  })
  const debouncedRefetchUserBalance = debounce(() => refetchUserBalance(), 4000)

  if (isLoading || !token0 || !token1 || !pairAddress) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box pt={4} bg="joeLight.400" _dark={{ bg: 'joeDark.600' }}>
      <PageHelmet
        title={POOL_HELMET_TITLE}
        description={POOL_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <PoolDetailHeader
        address={pairAddress}
        token0={token0}
        token1={token1}
        tag="v1"
        slippageSettingsPickerType="poolV1"
        px={4}
        h={{ base: '180px', md: '110px' }}
        settingsBottom={4}
      />
      {pair && token0 && token1 ? (
        <Box bg="bgPrimary" minH="calc(100vh - 250px)" pb={24}>
          <Grid
            maxW={{ '2xl': '1600px', base: '1400px' }}
            margin="0 auto"
            alignItems="flex-start"
            templateColumns={{
              base: 'minmax(0, 1fr)',
              lg: 'minmax(0, 1fr) 600px'
            }}
            pt={{ base: 4, md: 8 }}
            px={4}
            gap={8}
          >
            <PoolDetailOverview pool={pool} />
            <Center flexDir="column" gap={4}>
              {userBalanceData ? (
                <Box
                  w="full"
                  bg="bgCard"
                  borderRadius="2xl"
                  border="1px solid"
                  borderColor="border"
                >
                  <AddRemoveLiquidityPanel
                    pair={pair}
                    token0={token0}
                    token1={token1}
                    liquidityUsd={pool?.liquidityUsd}
                    onToken0Select={onToken0Select}
                    onToken1Select={onToken1Select}
                    userBalanceData={userBalanceData}
                    onAddLiquiditySuccess={debouncedRefetchUserBalance}
                    onRemoveLiquiditySuccess={debouncedRefetchUserBalance}
                  />
                </Box>
              ) : null}
              <Box
                w="full"
                bg="bgCard"
                borderRadius="2xl"
                border="1px solid"
                borderColor="border"
              >
                {userBalanceData ? (
                  <PoolDetailBalancesPanelV1
                    userBalanceData={userBalanceData}
                    isLoadingUserBalance={isLoadingUserBalance}
                  />
                ) : null}
              </Box>
            </Center>
          </Grid>
        </Box>
      ) : null}
    </Box>
  )
}

export default PoolDetail
