import { Flex, Text, VStack } from '@chakra-ui/react'
import { Pair, ROUTER_ADDRESS } from '@traderjoe-xyz/sdk'
import ApproveTokenButton from 'components/ApproveTokenButton'
import WithdrawSharesPanel from 'components/WithdrawSharesPanel'
import { UsePairUserBalanceData } from 'hooks/pool/v1/usePairUserBalance'
import useRemoveLiquidity from 'hooks/pool/v1/useRemoveLiquidity'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import React, { useState } from 'react'
import { convertStringToBN } from 'utils/format'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits } from 'viem'

interface RemoveLiquidityPanelProps {
  pair: Pair
  userBalanceData: UsePairUserBalanceData
  onRemoveLiquiditySuccess?: () => void
}

const RemoveLiquidityPanel = ({
  onRemoveLiquiditySuccess,
  pair,
  userBalanceData
}: RemoveLiquidityPanelProps) => {
  const chainId = useChainId()
  const { balance, decimals, pooledToken0, pooledToken1, token0, token1 } =
    userBalanceData

  // Input
  const [percentageToWithdraw, setPercentageToWithdraw] = useState('')
  const [isPercentageInvalid, setIsPercentageInvalid] = useState(false)

  // Tokens
  const wrappedCurrency0 = wrappedCurrency(token0, chainId)
  const wrappedCurrency1 = wrappedCurrency(token1, chainId)

  // Amounts
  const tokenAmountBN = balance
    ? (balance * BigInt(Math.trunc(Number(percentageToWithdraw)))) / BigInt(100)
    : undefined

  // Approval
  const {
    approvalType,
    approve,
    isApproved,
    isApproving,
    isLoadingAllowance,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: tokenAmountBN,
    spender: ROUTER_ADDRESS[chainId],
    token: pair.liquidityToken.address,
    tokenSymbol: pair.liquidityToken.symbol
  })

  // Preview amounts
  const amountDesired0 = percentageToWithdraw
    ? parseFloat(
        ((pooledToken0 * Number(percentageToWithdraw)) / 100).toFixed(
          token0.decimals
        )
      )
    : 0
  const amountDesired1 = percentageToWithdraw
    ? parseFloat(
        ((pooledToken1 * Number(percentageToWithdraw)) / 100).toFixed(
          token1.decimals
        )
      )
    : 0

  // Remove liquidity
  const { isLoading: isRemovingLiquidity, removeLiquidity } =
    useRemoveLiquidity({
      amountDesired0: convertStringToBN(
        amountDesired0.toFixed(token0.decimals),
        token0.decimals
      ),
      amountDesired1: convertStringToBN(
        amountDesired1.toFixed(token1.decimals),
        token1.decimals
      ),
      enabled: isApproved && !isPercentageInvalid,
      onSuccess: () => {
        setPercentageToWithdraw('')
        onRemoveLiquiditySuccess?.()
      },
      poolTokenAmount: tokenAmountBN,
      token0,
      token1
    })

  return (
    <WithdrawSharesPanel
      amountDesired0={amountDesired0}
      amountDesired1={amountDesired1}
      percentage={percentageToWithdraw}
      bodyContent={
        <>
          {wrappedCurrency0 && wrappedCurrency1 ? (
            <Flex w="full" justify="space-between">
              <Text fontSize="sm" color="textSecondary">
                Price:
              </Text>
              <VStack spacing={0.5} align="flex-end">
                <Text fontSize="sm">{`1 ${token0.symbol} = ${pair
                  .priceOf(wrappedCurrency0)
                  .toSignificant(6)} ${token1.symbol}`}</Text>
                <Text fontSize="sm">{`1 ${token1.symbol} = ${pair
                  .priceOf(wrappedCurrency1)
                  .toSignificant(6)} ${token0.symbol}`}</Text>
              </VStack>
            </Flex>
          ) : null}
          {!isApproved &&
          !!tokenAmountBN &&
          !!decimals &&
          !isLoadingAllowance &&
          !!approve ? (
            <ApproveTokenButton
              amount={formatUnits(tokenAmountBN, decimals)}
              currencySymbol="JLP"
              approvalType={approvalType}
              onApprovalTypeSelect={setApprovalType}
              isDisabled={isApproved || isPercentageInvalid}
              isLoading={isApproving}
              onClick={approve}
            />
          ) : null}
        </>
      }
      isRemoving={isRemovingLiquidity}
      onRemoveClick={removeLiquidity}
      tokenSymbol0={token0.symbol}
      tokenSymbol1={token1.symbol}
      onPercentageChange={(pct, isInvalid) => {
        setPercentageToWithdraw(pct)
        setIsPercentageInvalid(isInvalid)
      }}
    />
  )
}

export default RemoveLiquidityPanel
