import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useEffect } from 'react'
import { useAreTermsAccepted } from 'state/settings/hooks'

const useDisableConnectOptionsIfTermsNotAccepted = () => {
  const { connectModalOpen } = useConnectModal()
  const { areTermsAccepted } = useAreTermsAccepted()

  useEffect(() => {
    // disable wallet buttons
    const mobileDiv = document.querySelector(
      '.iekbcc0.ju367vbq.ju367va.ju367v6q.ju367v8a._1am14410'
    ) as HTMLElement | null
    if (mobileDiv) {
      mobileDiv.style.pointerEvents = areTermsAccepted ? 'auto' : 'none'
      mobileDiv.style.opacity = areTermsAccepted ? '1' : '0.5'
    }
    const desktopDiv = document.querySelector(
      '.iekbcc0.ju367v6p._1vwt0cg2.ju367v7a.ju367v7v'
    ) as HTMLElement | null
    if (desktopDiv) {
      desktopDiv.style.pointerEvents = areTermsAccepted ? 'auto' : 'none'
      desktopDiv.style.opacity = areTermsAccepted ? '1' : '0.5'
    }

    // hide info button
    const button = document.querySelector(
      '.iekbcc0.iekbcc9.ju367v4.ju367v9z.ju367vc5.ju367vs.ju367vt.ju367vv.ju367vfe.ju367va.ju367v2b.ju367v2q.ju367v8t.ju367v93._12cbo8i3.ju367v8r._12cbo8i5._12cbo8i7'
    ) as HTMLElement
    if (button) {
      button.style.display = 'none'
    }
  }, [connectModalOpen, areTermsAccepted])
}

export default useDisableConnectOptionsIfTermsNotAccepted
