import { Currency, WNATIVE } from '@traderjoe-xyz/sdk-core'
import { LB_HOOKS_LENS_ADDRESS, LBHooksLensABI } from '@traderjoe-xyz/sdk-v2'
import { useGetBins } from 'hooks/analytics/useBinAnalytics'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensUsdPrice'
import { getLbPoolRewards, getUserClaimableRewards } from 'utils/lbRewards'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { getAddress, zeroAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

interface UseGetLbRewarderDataProps {
  binStep: number
  ids: number[]
  currency0?: Currency
  currency1?: Currency
  enabled?: boolean
  pairAddress?: string
}

const useGetLbRewarderData = ({
  binStep,
  currency0,
  currency1,
  enabled = true,
  ids,
  pairAddress
}: UseGetLbRewarderDataProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()

  const { data, isLoading, refetch } = useReadContract({
    abi: LBHooksLensABI,
    address: LB_HOOKS_LENS_ADDRESS[chainId],
    args: pairAddress
      ? [
          getAddress(pairAddress),
          account || zeroAddress,
          ids.map((id) => BigInt(id))
        ]
      : undefined,
    chainId,
    functionName: 'getHooksData',
    query: {
      enabled: enabled && !!pairAddress && !!currency0 && !!currency1,
      select: (data) => {
        if (!currency0 || !currency1) return

        const rewarderData = data[0]
        const extraRewarderData = data[1]

        const hasRewarder = rewarderData.hooksParameters.hooks !== zeroAddress
        const hasExtraRewarder =
          extraRewarderData.hooksParameters.hooks !== zeroAddress

        const rewards = getLbPoolRewards({
          chainId,
          currency0Decimals: currency0.decimals,
          currency1Decimals: currency1.decimals,
          extraRewarderData,
          lbBinStep: binStep,
          rewarderData
        })

        const userClaimableRewards = getUserClaimableRewards({
          chainId,
          extraRewarderData,
          rewarderData
        })

        const rewardedRange = hasRewarder
          ? hasExtraRewarder
            ? [
                Math.min(
                  Number(rewarderData.parameters.rangeStart),
                  Number(extraRewarderData.parameters.rangeStart)
                ),
                Math.max(
                  Number(rewarderData.parameters.rangeEnd),
                  Number(extraRewarderData.parameters.rangeEnd)
                ) - 1
              ]
            : [
                Number(rewarderData.parameters.rangeStart),
                Number(rewarderData.parameters.rangeEnd) - 1
              ]
          : undefined

        return {
          extraRewarderData,
          hasExtraRewarder,
          hasRewarder,
          rewardedRange,
          rewarderData,
          rewards,
          userClaimableRewards
        }
      }
    }
  })

  const activeId = data?.hasRewarder
    ? Number(data.rewarderData.activeId)
    : undefined

  const radius =
    data?.hasRewarder && data.hasExtraRewarder && activeId
      ? Math.max(
          activeId - Number(data.rewarderData.parameters.rangeStart),
          Number(data.rewarderData.parameters.rangeEnd) - activeId,
          activeId - Number(data.extraRewarderData.parameters.rangeStart),
          Number(data.extraRewarderData.parameters.rangeEnd) - activeId
        )
      : data?.hasRewarder && activeId
        ? Math.max(
            activeId - Number(data.rewarderData.parameters.rangeStart),
            Number(data.rewarderData.parameters.rangeEnd) - activeId
          )
        : 0

  const { data: bins } = useGetBins({
    activeBinId: activeId,
    lbPairAddress: pairAddress,
    radius,
    timeFilter: '1d'
  })

  const tokenAddress0 = wrappedCurrency(currency0, chainId)?.address
  const tokenAddress1 = wrappedCurrency(currency1, chainId)?.address
  const { data: tokenPricesUsd } = useGetTokensUsdPrice({
    enabled: data && data.hasRewarder && data.rewards.length > 0,
    tokenAddresses:
      tokenAddress0 && tokenAddress1 && data
        ? [
            tokenAddress0,
            tokenAddress1,
            ...data.rewards.map((reward) =>
              reward.token.isNative
                ? WNATIVE[chainId].address
                : reward.token.address
            )
          ]
        : []
  })
  const token0PriceUsd = tokenPricesUsd?.[tokenAddress0?.toLowerCase() || '']
  const token1PriceUsd = tokenPricesUsd?.[tokenAddress1?.toLowerCase() || '']

  return {
    data: data
      ? {
          ...data,
          rewardedBins: bins?.filter(
            (bin) =>
              data.rewardedRange &&
              data.rewardedRange[0] <= bin.binId &&
              bin.binId <= data.rewardedRange[1]
          ),
          rewards: data.rewards.map((reward) => {
            if (!bins) return reward

            const reserveXInRange = bins.reduce((acc, bin) => {
              if (
                reward.range.start <= bin.binId &&
                bin.binId <= reward.range.end
              ) {
                return acc + bin.reserveX
              } else {
                return acc
              }
            }, 0)

            const reserveYInRange = bins.reduce((acc, bin) => {
              if (
                reward.range.start <= bin.binId &&
                bin.binId <= reward.range.end
              ) {
                return acc + bin.reserveY
              } else {
                return acc
              }
            }, 0)

            const rangeLiquidityUsd =
              token0PriceUsd && token1PriceUsd
                ? reserveXInRange * token0PriceUsd +
                  reserveYInRange * token1PriceUsd
                : 0

            const rewardTokenKey = reward.token.isNative
              ? WNATIVE[chainId].address
              : reward.token.address
            const rewardTokenUsdPrice =
              tokenPricesUsd?.[rewardTokenKey.toLowerCase()]

            const rewardsUsd = rewardTokenUsdPrice
              ? reward.rewardsPerDay * rewardTokenUsdPrice * 365
              : undefined
            const rewardsApr =
              rewardsUsd && rangeLiquidityUsd
                ? (rewardsUsd / rangeLiquidityUsd) * 100
                : 0

            return {
              ...reward,
              rangeLiquidityUsd,
              rewardsApr
            }
          })
        }
      : undefined,
    isLoading,
    refetch
  }
}

export default useGetLbRewarderData
