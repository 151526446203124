import { LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import { UserRewardRowProps } from 'pages/Pool/UserPositions/UserRewardRow'
import { useMemo } from 'react'
import { erc20Abi, getAddress, Hex } from 'viem'
import { useReadContracts } from 'wagmi'

import useGetUserLbPendingRewardsForAllPairs from './v2/useGetUserLbPendingRewardsForAllPairs'

interface UseUserMakerRewardRowsProps {
  enabled: boolean
}

const useUserLbRewardRows = ({ enabled }: UseUserMakerRewardRowsProps) => {
  const chainId = useChainId()

  // fetch claimable rewards for all v22 pools
  const { data: userRewardsDatas = [], isLoading: isLoadingAllMarkets } =
    useGetUserLbPendingRewardsForAllPairs()

  // fetch LB pair info for each claimable reward
  const { data, isLoading: isLoadingLbPairInfo } = useReadContracts({
    contracts: [
      ...userRewardsDatas.map((data) => ({
        abi: LBPairV21ABI,
        address: getAddress(data.pairAddress),
        chainId,
        functionName: 'getTokenX'
      })),
      ...userRewardsDatas.map((data) => ({
        abi: LBPairV21ABI,
        address: getAddress(data.pairAddress),
        chainId,
        functionName: 'getTokenY'
      })),
      ...userRewardsDatas.map((data) => ({
        abi: LBPairV21ABI,
        address: getAddress(data.pairAddress),
        chainId,
        functionName: 'getBinStep'
      }))
    ],
    query: {
      enabled: enabled && userRewardsDatas.length > 0,
      select: (data) => {
        const tokenXAddresses = data
          .slice(0, userRewardsDatas.length)
          .map(({ result }) => result as Hex | undefined)
        const tokenYAddresses = data
          .slice(userRewardsDatas.length, userRewardsDatas.length * 2)
          .map(({ result }) => result as Hex | undefined)
        const binSteps = data
          .slice(userRewardsDatas.length * 2)
          .map(({ result }) => result as number | undefined)

        return {
          binSteps,
          tokenXAddresses,
          tokenYAddresses
        }
      }
    }
  })
  const { binSteps, tokenXAddresses, tokenYAddresses } = data || {}

  // fetch symbols and decimals
  const { data: tokenInfo, isLoading: isLoadingTokenInfo } = useReadContracts({
    contracts: [
      ...(tokenXAddresses?.map((address) => ({
        abi: erc20Abi,
        address,
        chainId,
        functionName: 'symbol'
      })) || []),
      ...(tokenYAddresses?.map((address) => ({
        abi: erc20Abi,
        address,
        chainId,
        functionName: 'symbol'
      })) || [])
    ],
    query: {
      enabled:
        enabled &&
        !!tokenXAddresses &&
        tokenXAddresses.length > 0 &&
        !!tokenYAddresses &&
        tokenYAddresses.length > 0,
      select: (data) => {
        const tokenXLength = tokenXAddresses?.length || 0
        const tokenYLength = tokenYAddresses?.length || 0

        const tokenXSymbols = data
          .slice(0, tokenXLength)
          .map(({ result }) => result as string | undefined)

        const tokenYSymbols = data
          .slice(tokenXLength, tokenXLength + tokenYLength)
          .map(({ result }) => result as string | undefined)

        return {
          tokenXSymbols,
          tokenYSymbols
        }
      }
    }
  })
  const { tokenXSymbols, tokenYSymbols } = tokenInfo || {}

  // convert to rows
  const rows: UserRewardRowProps[] = useMemo(() => {
    if (!userRewardsDatas) {
      return []
    }

    return userRewardsDatas.map((data, i) => {
      return {
        lbPair: {
          address: data.pairAddress,
          binStep: binSteps?.[i] || 0,
          version: 'v22'
        },
        rewards: data.userClaimableRewards.map((claimableReward) => {
          return {
            amount: claimableReward.tokenAmount,
            tokenAddress: claimableReward.token?.isToken
              ? claimableReward.token?.address
              : '',
            tokenDecimals: claimableReward.token!.decimals,
            tokenSymbol: claimableReward.token?.symbol || ''
          }
        }),
        tokenX: {
          address: tokenXAddresses?.[i] || '',
          symbol: tokenXSymbols?.[i] || ''
        },
        tokenY: {
          address: tokenYAddresses?.[i] || '',
          symbol: tokenYSymbols?.[i] || ''
        }
      }
    })
  }, [
    userRewardsDatas,
    tokenXAddresses,
    tokenYAddresses,
    tokenXSymbols,
    tokenYSymbols,
    binSteps
  ])

  return {
    isLoading: isLoadingAllMarkets || isLoadingLbPairInfo || isLoadingTokenInfo,
    rows
  }
}

export default useUserLbRewardRows
