import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Center, HStack, Text } from '@chakra-ui/react'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import { PoolVersion } from '@traderjoe-xyz/sdk-v2'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface RouteDetailsStepProps {
  pair: {
    pairId: string
    pairName: string
    tokenInId: string
    tokenInSymbol: string
    tokenOutId: string
    tokenOutSymbol: string
    version: PoolVersion
  }
  showForwardArrow: boolean
}

const RouteDetailsStep = ({
  pair,
  showForwardArrow
}: RouteDetailsStepProps) => {
  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)

  // let version: LBPoolVersion | undefined
  // switch (pair.version) {
  //   case PoolVersion.V1:
  //     break
  //   case PoolVersion.V2:
  //     version = 'v2'
  //     break
  //   case PoolVersion.V2_1:
  //     version = 'v21'
  //     break
  //   case PoolVersion.V2_2:
  //     version = 'v22'
  //     break
  // }

  // // get pool fee
  // const {
  //   data: { baseFee }
  // } = useLBPairFeeParameters({
  //   lbPairAddress: pair.version === PoolVersion.V1 ? undefined : pair.pairId,
  //   version
  // })
  // const fmtFee =
  //   pair.version === PoolVersion.V1
  //     ? '0.3%'
  //     : baseFee
  //       ? `${formattedNum(baseFee / 100, { places: 2 })}%`
  //       : ''

  const tokenOutSymbol = isWrappedNativeCurrency(pair.tokenOutId, chainId)
    ? nativeCurrency.symbol
    : pair.tokenOutSymbol

  return (
    <HStack spacing={1}>
      <DoubleCurrencyLogo
        address0={
          isWrappedNativeCurrency(pair.tokenInId, chainId)
            ? undefined
            : pair.tokenInId
        }
        symbol0={
          isWrappedNativeCurrency(pair.tokenInId, chainId)
            ? nativeCurrency.symbol
            : pair.tokenInSymbol
        }
        address1={
          isWrappedNativeCurrency(pair.tokenOutId, chainId)
            ? undefined
            : pair.tokenOutId
        }
        symbol1={tokenOutSymbol}
        boxSize={5}
      />
      <Text fontSize="sm" fontWeight="semibold">
        {tokenOutSymbol}
      </Text>
      {/* <Tag size="md" rounded="full" fontWeight="semibold">
        {fmtFee}
      </Tag> */}
      {showForwardArrow ? (
        <Center>
          <ArrowForwardIcon boxSize="14px" color="textSecondary" ml={1} />
        </Center>
      ) : null}
    </HStack>
  )
}

export default RouteDetailsStep
