import { ChainId } from '@traderjoe-xyz/sdk-core'

export interface Announcement {
  description: string
  durationMillis: number | null
  id: number
  imageUrl: string | null
  title: string
}

export type Chain = 'avalanche' | 'binance' | 'arbitrum' | 'ethereum'

export interface Token {
  chain: Chain
  decimals: number
  name: string
  pctChange: number
  priceNative: number
  priceUsd: number
  reserve: number
  symbol: string
  tokenAddress: string
  volume: number
}

interface BaseToken {
  address: string
  decimals: number
  name: string
  symbol: string
}

type PoolStatus = 'main' | 'old'
type PoolVersion = 'v2.0' | 'v2.1' | 'v2.2'

export interface Pool {
  chain: Chain
  feesUsd: number
  lbBaseFeePct: number
  lbBinStep: number
  lbMaxFeePct: number
  liquidityDepthMinus: number
  liquidityDepthPlus: number
  liquidityDepthTokenX: number
  liquidityDepthTokenY: number
  liquidityUsd: number
  name: string
  pairAddress: string
  protocolSharePct: number | null
  reserveX: number
  reserveY: number
  status: PoolStatus
  tokenX: BaseToken
  tokenY: BaseToken
  version: PoolVersion
  volumeUsd: number
}

interface UserLBPositionToken extends BaseToken {
  priceUsd: number
}

export interface UserLBPosition {
  binIds: number[]
  chain: string
  lbBaseFeePct: number
  lbBinStep: number
  lbMaxFeePct: number
  liquidity: number
  pairName: string
  poolAddress: string
  status: PoolStatus
  tokenX: UserLBPositionToken
  tokenY: UserLBPositionToken
  version: PoolVersion
}

export namespace PoolQueryParam {
  export type FilterBy = '1h' | '1d' | '7d' | '14d' | '30d'
  export type OrderBy = 'liquidity' | 'volume' | 'name'
  export type Status = 'main' | 'old' | 'all'
  export type Version = 'v2.0' | 'v2.1' | 'v2.2' | 'all'
}

export interface BinAnalyticsData {
  binId: number
  feesNative: number
  feesUsd: number
  priceXY: number
  priceYX: number
  reserveX: number
  reserveXRaw: string
  reserveY: number
  reserveYRaw: string
  totalSupply: string
  volumeNative: number
  volumeUsd: number
  volumeX: number
  volumeY: number
}

export interface UserFeesIntervalData {
  accruedFeesL: number
  accruedFeesX: number
  accruedFeesY: number
  date: Date
  timestamp: number
}

export interface UserFeesBinData {
  accruedFeesL: number
  accruedFeesX: number
  accruedFeesY: number
  binId: number
  mostRecentDepositTime: Date
  priceXY: number
  priceYX: number
  timestamp: number
}

export interface SwapEvent {
  amountIn: number
  amountOut: number
  binId: number
  blockNumber: number
  logIndex: number
  pairId: string
  priceXY: number
  priceYX: number
  swapForY: boolean
  timestamp: string
  transactionHash: string
  userId: string
}

export interface Vault {
  address: string
  apr1d: number
  apt1dPriceChange: number
  aptPrice: number
  chain: Chain
  chainId: Exclude<ChainId, ChainId.MANTLE>
  farm: Farm | null
  feesUsd: number
  hodl30Day5050Performance: number
  hodl5050Performance: number
  name: string
  pair: {
    address: string
    baseFeePct: number
    binStep: number
    version: PoolVersion
  }
  tokenX: {
    address: string
    decimals: number
    symbol: string
  }
  tokenX7DayPerformance: number
  tokenX30DayPerformance: number
  tokenY: {
    address: string
    decimals: number
    symbol: string
  }
  tokenY7DayPerformance: number
  tokenY30DayPerformance: number
  tvlUsd: number
  strategy?: {
    address: string
    aumAnnualFeePct: number
  }
}

export interface VaultQueuedWithdrawal {
  chain: Chain
  round: number
  shares: number
  userAddress: string
  vaultAddress: string
}

export enum OrderEventType {
  CANCELLED = 'cancelled',
  CLAIMED = 'claimed',
  EXECUTED = 'executed',
  PLACED = 'placed'
}

export enum OrderType {
  ASK = 'ask',
  BID = 'bid'
}

interface OrderToken {
  amount: number
  amountRaw: string
  decimals: number
  name: string
  symbol: string
  tokenId: string
}

export interface LimitOrder {
  binId: number
  chain: string
  orderPlacedTimestamp: Date
  orderType: OrderType
  orderUpdatedTimestamp: Date
  pairId: string
  pairLbBinStep: number
  pairName: string
  positionId: number
  status: OrderEventType
  tokenIn: OrderToken
  tokenOut: {
    tokenX: OrderToken
    tokenY: OrderToken
  }
}

export interface VaultTvlHistoryData {
  amountX: number
  amountXUsd: number
  amountY: number
  amountYUsd: number
  date: string
  timestamp: number
  tvlUsd: number
}

export interface VaultSharePriceData {
  blockNumber: number
  chain: Chain
  sharePrice: string
  timestamp: string
  vaultAddress: string
}

export interface VaultRecentActivity {
  date: string
  deposits: {
    amountX: bigint
    amountY: bigint
    bidId: number
  }[]
  timestamp: number
  transactionHash: string
  withdrawals: {
    amountX: bigint
    amountY: bigint
    bidId: number
  }[]
}

export interface FarmRewardToken {
  decimals: number
  id: string
  name: string
  symbol: string
}

interface FarmRewarder {
  rewarderApr1d: number
  rewarderContract: string
  rewarderTokenDecimals: number
  rewarderTokenId: string
  rewarderTokenName: string
  rewarderTokenPerSec: number
  rewarderTokenSymbol: string
}

export interface Farm {
  apr1d: number
  aptDecimals: number
  farmId: string
  liquidity: string
  liquidityRaw: number
  liquidityUsd: number
  reward: FarmRewardToken
  rewarder: FarmRewarder | null
  rewarderContract: string
  rewardsPerSec: number
  vaultId: string
}

export interface UserFarmPosition {
  farmId: string
  pendingJoe: number
  userPosition: string
  userPositionRaw: number
  userPositionUsd: number
}

export interface MemeFarm {
  memeFarmId: string
  memeToken: {
    decimals: number
    name: string
    symbol: string
    tokenAddress: string
  }
  staked?: string
  stakedRaw?: bigint
  userStaked?: string
  userStakedRaw?: bigint
}

export interface TokenPrice {
  chain: Chain
  priceUsd: string
  tokenAddress: string
}

interface TokenV1 {
  address: string
  decimals: number
  name: string
  priceNative: string
  priceUsd: number
  symbol: string
}

export interface DexbarnPoolV1 {
  chain: string
  farmPid: number | null
  liquidityNative: string
  liquidityUsd: number
  name: string
  pairAddress: string
  reserveX: string
  reserveY: string
  tokenX: TokenV1
  tokenY: TokenV1
  volumeNative: string
  volumeUsd: number
}

export interface DexbarnUserPoolPositionV1 {
  chain: string
  liquidity: string
  pairId: string
  tokenX: TokenV1
  tokenY: TokenV1
}
