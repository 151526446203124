import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { Locale } from '@rainbow-me/rainbowkit'
import React, { PropsWithChildren, useEffect } from 'react'

import { messages } from '../../locales/en/messages'

i18n.load('en', messages)
i18n.activate('en')

const DEFAULT_LOCALE = 'en'

export const SUPPORTED_LANGUAGES = ['en', 'fr', 'tr', 'zh', 'vi', 'ko']
export const LANG_MAP: { [languageCode: string]: string } = {
  en: 'English',
  fr: 'Français',
  ko: '한국어',
  tr: 'Türkçe',
  vi: 'Tiếng Việt',
  zh: '中文'
}
export const RAINBOWKIT_LOCALE_MAP: { [languageCode: string]: Locale } = {
  en: 'en-US',
  fr: 'fr-FR',
  ko: 'ko-KR',
  tr: 'tr-TR',
  vi: 'en-US',
  zh: 'zh-CN'
}

export async function dynamicActivateLanguage(locale: string) {
  const { messages } = await import(`../../locales/${locale}/messages.js`)
  i18n.load(locale, messages)
  i18n.activate(locale)
  localStorage.setItem('LOCALE', locale)
}

export const LanguageProvider = (props: PropsWithChildren): JSX.Element => {
  useEffect(() => {
    const savedLocale = localStorage.getItem('LOCALE') || DEFAULT_LOCALE
    if (i18n.locale !== savedLocale) dynamicActivateLanguage(savedLocale)
  }, [])

  return <I18nProvider i18n={i18n}>{props.children}</I18nProvider>
}
