import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Announcement } from 'types/dexbarn'

type QueryData = Announcement | null

export const useGetLatestAnnouncement = (): {
  data: QueryData | undefined
  isLoading: boolean
} => {
  const fetchLatestAnnoucement = useDexbarnGet<QueryData>(
    '/v1/announcement/latest'
  )
  const { data, isLoading } = useQuery({
    queryFn: () => fetchLatestAnnoucement(),
    queryKey: ['LatestAnnouncement']
  })
  return { data, isLoading }
}
