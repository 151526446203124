import { useQuery } from '@tanstack/react-query'
import { FACTORY_ADDRESS } from '@traderjoe-xyz/sdk'
import { ClassicAMMFactoryAbi } from 'constants/abi/classicAmmFactoryAbi'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { DexbarnPoolV1 } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolV1ToPool } from 'utils/poolV1'
import { getAddress } from 'viem'
import { useReadContract } from 'wagmi'

dayjs.extend(utc)

interface UsePoolV1ByTokensProps {
  enabled?: boolean
  tokens?: [string, string]
}

const usePoolV1ByTokens = ({
  enabled = true,
  tokens
}: UsePoolV1ByTokensProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const { data: pairAddress, isLoading: isLoadingPairAddress } =
    useReadContract({
      abi: ClassicAMMFactoryAbi,
      address: FACTORY_ADDRESS[chainId],
      args: tokens
        ? ([getAddress(tokens[0]), getAddress(tokens[1])] as const)
        : undefined,
      chainId,
      functionName: 'getPair',
      query: {
        enabled: !!tokens
      }
    })

  const fetchPoolV1ByTokens = useDexbarnGet<DexbarnPoolV1>(
    `/v1/joev1/pools/${chain}/${pairAddress?.toLowerCase()}`
  )

  const result = useQuery({
    enabled: !!pairAddress && enabled,
    queryFn: () => fetchPoolV1ByTokens(),
    queryKey: ['GetClassicAMMPool', chain, pairAddress],
    select: (data) => convertDexbarnPoolV1ToPool({ dexbarnPool: data })
  })

  return {
    data: result.data,
    isLoading: result.isLoading || isLoadingPairAddress,
    pairAddress
  }
}

export default usePoolV1ByTokens
