import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import {
  JOE_ADDRESS,
  VEJOE_STAKING_ADDRESS,
  VEJOE_TOKEN_ADDRESS,
  VeJoeABI,
  VeJoeStakingABI
} from '@traderjoe-xyz/sdk'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { erc20Abi, formatUnits, getAddress } from 'viem'
import {
  useAccount,
  useReadContracts,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

/**
 * VeJoe Staking Info. Returns:
 * totalSupply
 * totalStaked
 */
const useVeJoeStaking = () => {
  const chainId = useChainId()
  const veJoeContract = {
    abi: VeJoeABI,
    address: getAddress(VEJOE_TOKEN_ADDRESS[chainId]),
    chainId
  }
  const joeContract = {
    abi: erc20Abi,
    address: getAddress(JOE_ADDRESS[chainId]),
    chainId
  }
  const veJoeAddress = getAddress(VEJOE_STAKING_ADDRESS[chainId])

  const reads = useReadContracts({
    contracts: [
      { ...veJoeContract, functionName: 'totalSupply' },
      {
        ...joeContract,
        args: [veJoeAddress],
        functionName: 'balanceOf'
      }
    ]
  })

  // total supply, in 18 decimals
  const totalSupply = reads.data?.[0]?.result

  // total joe staked, in 18 decimals
  const totalStaked = reads.data?.[1]?.result

  return {
    ...reads,
    totalStaked,
    totalSupply
  }
}

interface UseVeJoeWithdrawProps {
  amount?: bigint
  approved?: boolean
  onSuccess?: () => void
}

export const useVeJoeWithdraw = ({
  amount,
  onSuccess
}: UseVeJoeWithdrawProps) => {
  const chainId = useChainId()
  const walletChainId = useAccount().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: VeJoeStakingABI,
    address: getAddress(VEJOE_STAKING_ADDRESS[chainId]),
    args: amount ? [amount] : undefined,
    chainId,
    functionName: 'withdraw',
    query: {
      enabled: !!amount && walletChainId === chainId,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  // Note: assume JOE token is always 18 decimals
  const JOE_TOKEN_DECIMALS = 18

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        if (!amount) return
        const transactionSummary = t`Unstaked ${formattedNum(
          formatUnits(amount, JOE_TOKEN_DECIMALS)
        )} JOE`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const withdraw = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash: data,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    withdraw
  }
}

/**
 * contract methods for VeJoe User Positions. Returns:
 * userBalance
 * pendingBalance
 * claim
 */
export const useVeJoeUserPosition = () => {
  const chainId = useChainId()
  const walletChainId = useAccount().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { address: account } = useAccount()

  const veJoeContract = {
    abi: VeJoeStakingABI,
    address: getAddress(VEJOE_STAKING_ADDRESS[chainId]),
    chainId
  }

  const veJoeTokenContract = {
    abi: VeJoeABI,
    address: getAddress(VEJOE_TOKEN_ADDRESS[chainId]),
    chainId
  }

  const reads = useReadContracts({
    contracts: [
      {
        ...veJoeContract,
        args: account ? [account] : undefined,
        functionName: 'userInfos'
      },
      {
        ...veJoeContract,
        args: account ? [account] : undefined,
        functionName: 'getPendingVeJoe'
      },
      {
        ...veJoeContract,
        functionName: 'veJoePerSharePerSec'
      },
      {
        ...veJoeContract,
        functionName: 'speedUpVeJoePerSharePerSec'
      },
      {
        ...veJoeTokenContract,
        args: account ? [account] : undefined,
        functionName: 'balanceOf'
      }
    ],
    query: { enabled: !!account }
  })

  // user JOE balance, in 18 decimals
  const userInfos = reads.data?.[0]?.result
  const userBalance = userInfos?.[0]

  // pending veJOE in 18 decimals
  const pending = reads?.data?.[1]?.result

  const veJoePerSharePerSec = reads.data?.[2]?.result
  const speedUpVeJoePerSharePerSec = reads.data?.[3]?.result
  const userVeJoeBalance = reads?.data?.[4]?.result
  const speedUpEndTimestamp = userInfos?.[3]
    ? Number(userInfos?.[3])
    : undefined

  const veJoePerDayForUser = useMemo(() => {
    if (!userBalance || userBalance === BigInt(0)) {
      return BigInt(0)
    }

    const finalVeJoePerSharePerSec =
      speedUpEndTimestamp !== 0 &&
      veJoePerSharePerSec &&
      speedUpVeJoePerSharePerSec
        ? veJoePerSharePerSec + speedUpVeJoePerSharePerSec
        : speedUpEndTimestamp !== 0 && veJoePerSharePerSec
          ? veJoePerSharePerSec
          : BigInt(0)

    return (
      (userBalance * finalVeJoePerSharePerSec * BigInt(60 * 60 * 24)) /
      BigInt(10) ** BigInt(36)
    )
  }, [
    speedUpEndTimestamp,
    speedUpVeJoePerSharePerSec,
    userBalance,
    veJoePerSharePerSec
  ])

  const { data: config } = useSimulateContract({
    abi: VeJoeStakingABI,
    address: getAddress(VEJOE_STAKING_ADDRESS[chainId]),
    chainId,
    functionName: 'claim',
    query: {
      enabled:
        !!userBalance && userBalance > BigInt(0) && walletChainId === chainId,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data,
    isPending: isClaiming,
    isSuccess: isClaimed,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Claimed veJOE tokens`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const claim = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash: data
    })

  return {
    ...reads,
    claim,
    isClaimed,
    isClaiming: isClaiming || isWaitingForTransaction,
    isSuccess,
    pending,
    userBalance,
    userVeJoeBalance,
    veJoePerDayForUser
  }
}

export default useVeJoeStaking
