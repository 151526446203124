import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { CNATIVE, Token } from '@traderjoe-xyz/sdk-core'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import SimpleModal from 'components/SimpleModal'
import useOrderableList from 'hooks/limitOrder/useOrderableList'
import React from 'react'

export type Pair = {
  tokenX: Token | CNATIVE
  tokenY: Token | CNATIVE
}

interface PairPickerModalProps {
  isOpen: boolean
  onClose: () => void
  onPairSelected: (pair: Pair) => void
}

const PairPickerModal = ({
  isOpen,
  onClose,
  onPairSelected
}: PairPickerModalProps) => {
  const pairs = useOrderableList()

  return (
    <SimpleModal
      header={t`Select a pair`}
      isOpen={isOpen}
      onClose={onClose}
      rows={pairs.map((pair, i) => (
        <Flex
          key={i}
          data-cy={`pair-picker-item-${pair.tokenX.symbol}-${pair.tokenY.symbol}`}
          w="full"
          align="center"
          justify="space-between"
          cursor="pointer"
          px={4}
          py={3}
          borderRadius="xl"
          _hover={{ bg: 'hover' }}
          onClick={() => {
            onPairSelected(pair)
            onClose()
          }}
        >
          <HStack spacing={4}>
            <DoubleCurrencyLogo
              address0={pair.tokenX.isToken ? pair.tokenX.address : undefined}
              address1={pair.tokenY.isToken ? pair.tokenY.address : undefined}
              symbol0={pair.tokenX.symbol}
              symbol1={pair.tokenY.symbol}
              boxSize={7}
            />
            <VStack align="flex-start" spacing={0.5}>
              <HStack>
                <Text fontWeight="semibold">
                  {pair.tokenX.symbol}-{pair.tokenY.symbol}
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </Flex>
      ))}
    />
  )
}

export default PairPickerModal
