import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useMemo } from 'react'
import {
  LimitOrder as DexbarnLimitOrder,
  OrderType as DexbarnOrderType
} from 'types/dexbarn'
import { OrderType } from 'types/limitOrder'
import { getAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseBatchClaimMakeOrdersProps {
  limitOrders: DexbarnLimitOrder[] | undefined
  enabled?: boolean
  onSuccess?: () => void
}

const useBatchClaimMakeOrders = ({
  enabled = true,
  limitOrders,
  onSuccess
}: UseBatchClaimMakeOrdersProps) => {
  const chainId = useChainId()
  const walletChainId = useAccount().chain?.id

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // prepare contract write
  const args = useMemo(
    () =>
      limitOrders && limitOrders?.length > 0
        ? ([
            limitOrders.map(
              ({
                binId,
                orderType,
                pairLbBinStep: binStep,
                tokenOut: { tokenX, tokenY }
              }) => ({
                binId: binId,
                binStep: binStep,
                orderType:
                  orderType === DexbarnOrderType.BID
                    ? OrderType.BID
                    : OrderType.ASK,
                tokenX: getAddress(tokenX.tokenId),
                tokenY: getAddress(tokenY.tokenId)
              })
            )
          ] as const)
        : undefined,
    [limitOrders]
  )

  const { data: config } = useSimulateContract({
    abi: LimitOrderManagerABI,
    address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId]),
    args,
    functionName: 'batchClaimOrders',
    query: {
      enabled: enabled && !!args && chainId === walletChainId,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Claim limit orders`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  const batchClaimOrders = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    hash: data,
    onTransactionSuccess: onSuccess
  })

  return { batchClaimOrders, isLoading: isPending || isWaitingForTransaction }
}

export default useBatchClaimMakeOrders
