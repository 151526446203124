import {
  Box,
  BoxProps,
  Flex,
  Hide,
  Tab,
  TabList,
  Tabs,
  Text,
  useMediaQuery,
  useToken,
  VStack
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useMemo, useState } from 'react'
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import {
  ninetyDays,
  oneDay,
  oneHundredEightyDays,
  sevenDays,
  thirtyDays
} from 'utils/date'
import { formattedNum } from 'utils/format'

import AnalyticsChartTooltip from './AnalyticsChartTooltip'
import AnalyticsChartXTick from './AnalyticsChartXTick'
import AnalyticsChartYTick from './AnalyticsChartYTick'

export enum ChartRange {
  D1 = '1D',
  D7 = '7D',
  D30 = '30D',
  D90 = '90D',
  D180 = '180D'
}

const defaultChartRanges = [
  ChartRange.D7,
  ChartRange.D30,
  ChartRange.D90,
  ChartRange.D180
]

const skeleton = [
  { date: 1628467200, value: 0 },
  { date: 1628467300, value: 5 },
  { date: 1628467400, value: 4 },
  { date: 1628467500, value: 7 }
]

interface AnalyticsChartData {
  date: number
  value: number
}

interface AnalyticsChartProps {
  data: AnalyticsChartData[]
  fill: string
  header: React.ReactNode
  id: string
  stroke: string
  chartRanges?: ChartRange[]
  hideTab?: boolean
  isLoading?: boolean
  showXAxis?: boolean
  showYAxis?: boolean
  subtitle?: string
  tabsVariant?: 'soft-rounded-outlined' | 'soft-rounded-outlined-white'
  tagline?: string
  tooltipTitle?: string
  usdValue?: boolean
  valueSymbol?: string
  yAxisMinValue?: 'auto' | number
}

const AnalyticsChart = ({
  chartRanges = defaultChartRanges,
  data,
  fill,
  header,
  hideTab,
  id,
  isLoading,
  showXAxis,
  showYAxis,
  stroke,
  subtitle,
  tabsVariant = 'soft-rounded-outlined',
  tagline,
  tooltipTitle,
  usdValue = true,
  valueSymbol,
  yAxisMinValue = 0,
  ...props
}: AnalyticsChartProps & BoxProps) => {
  const loading = data.length === 0 || isLoading
  const [bgTertiary] = useToken('colors', ['bgTertiary'])
  const [isLargerThan500] = useMediaQuery('(min-width: 500px)')

  const [chartRange, setChartRange] = useState<ChartRange>(ChartRange.D30)
  const timespan = useMemo(() => {
    switch (chartRange) {
      case ChartRange.D1:
        return oneDay()
      case ChartRange.D7:
        return sevenDays()
      case ChartRange.D30:
        return thirtyDays()
      case ChartRange.D90:
        return ninetyDays()
      case ChartRange.D180:
        return oneHundredEightyDays()
    }
  }, [chartRange])

  const filteredData = data
    .sort((a, b) => (a.date > b.date ? 1 : -1))
    .filter((d: AnalyticsChartData) => timespan <= d.date)
    .map((d: AnalyticsChartData) => ({
      ...d,
      fmtDate: format(
        new Date(d.date * 1000),
        chartRange === ChartRange.D1 ? 'LLL d, p' : 'LLL d, y'
      ),
      fmtValue:
        formattedNum(d.value, { usd: usdValue }) +
        (valueSymbol ? ` ${valueSymbol}` : ''),
      title: tooltipTitle ?? tagline
    }))

  const filteredValues = filteredData.map((data) => data.value)
  const yAxisMaxValue = Math.max(...filteredValues)
  const yAxisDomain = yAxisMaxValue
    ? [
        yAxisMinValue === 'auto' ? Math.min(...filteredValues) : yAxisMinValue,
        yAxisMaxValue
      ]
    : undefined

  return (
    <Box pos="relative">
      <Flex justify="space-between" align="center" mb={{ base: 0, sm: 8 }}>
        <VStack align="flex-start" spacing={0.25}>
          {tagline ? (
            <Text color="textSecondary" fontSize="sm">
              {tagline}
            </Text>
          ) : null}
          {header}
          {subtitle ? (
            <Text color="textSecondary" fontSize="sm" mt={1}>
              {subtitle}
            </Text>
          ) : null}
        </VStack>
        {!hideTab && (
          <Hide below="md">
            <Tabs
              variant={tabsVariant}
              colorScheme="accent"
              index={chartRanges.indexOf(chartRange)}
              onChange={(index) => setChartRange(chartRanges[index])}
            >
              <TabList>
                {chartRanges.map((range, i) => (
                  <Tab key={i}>{range}</Tab>
                ))}
              </TabList>
            </Tabs>
          </Hide>
        )}
      </Flex>
      <Box {...props}>
        <ResponsiveContainer>
          <AreaChart data={loading ? skeleton : filteredData}>
            {showYAxis === true && isLargerThan500 ? (
              <YAxis
                width={80}
                axisLine={false}
                tickLine={false}
                tickSize={0}
                tickMargin={20}
                domain={yAxisDomain}
                tick={<AnalyticsChartYTick isUsd={usdValue} />}
              />
            ) : null}
            {showXAxis === true && isLargerThan500 ? (
              <XAxis
                tickLine={false}
                axisLine={false}
                dataKey="fmtDate"
                tick={<AnalyticsChartXTick />}
              />
            ) : null}
            <defs>
              <linearGradient
                id={`colorGradient-${id}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor={loading ? bgTertiary : fill}
                  stopOpacity={1}
                  style={{
                    transition: loading ? undefined : 'all 3s ease-out'
                  }}
                />
                <stop
                  offset="95%"
                  stopColor={loading ? bgTertiary : fill}
                  stopOpacity={0.01}
                  style={{
                    transition: loading ? undefined : 'all 3s ease-out'
                  }}
                />
              </linearGradient>
            </defs>
            <Area
              dataKey="value"
              stroke={loading ? bgTertiary : stroke}
              fill={`url(#colorGradient-${id})`}
            />
            {!loading ? (
              <Tooltip
                wrapperStyle={{ outline: 'none' }}
                content={<AnalyticsChartTooltip />}
              />
            ) : null}
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default AnalyticsChart
