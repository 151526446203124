export interface TokenInfo {
  chainId: number
  decimals: number
  name: string
  symbol: string
  tags: TokenInfoTag[]
  address?: `0x${string}`
  balance?: string
  logoURI?: string
  tokenPriceUsd?: number
  totalValueUsd?: number
}

export enum TokenInfoTag {
  ALL = 'All',
  DEFI = 'DeFi',
  GAMEFI = 'GameFi',
  MEME = 'Meme',
  MY_TOKENS = 'My Tokens',
  NFT = 'NFT',
  STABLECOINS = 'Stablecoins',
  TOP = 'Top'
}
