import { ChainId, CNATIVE, Currency, Token } from '@traderjoe-xyz/sdk-core'
import { BTCB, BUSD, USDC, USDCe, USDT } from 'utils/swap'

export const CREATE_POOL_BIN_STEPS = [
  { baseFee: 10, binStep: 10 },
  { baseFee: 25, binStep: 25 },
  { baseFee: 40, binStep: 50 },
  { baseFee: 80, binStep: 100 }
]

export const CREATE_POOL_QUOTE_TOKENS_PER_CHAIN: {
  [chainId in Exclude<ChainId, ChainId.MANTLE>]: Currency[]
} = {
  [ChainId.AVALANCHE]: [
    CNATIVE.onChain(ChainId.AVALANCHE),
    USDC[ChainId.AVALANCHE],
    USDT[ChainId.AVALANCHE],
    BTCB[ChainId.AVALANCHE]
  ],
  [ChainId.FUJI]: [
    CNATIVE.onChain(ChainId.FUJI),
    USDC[ChainId.FUJI],
    USDT[ChainId.FUJI],
    new Token(
      ChainId.FUJI,
      '0x5425890298aed601595a70AB815c96711a31Bc65',
      6,
      'USDC',
      'Circle USDC'
    )
  ],
  [ChainId.ARBITRUM_ONE]: [
    CNATIVE.onChain(ChainId.ARBITRUM_ONE),
    USDC[ChainId.ARBITRUM_ONE],
    USDCe[ChainId.ARBITRUM_ONE],
    USDT[ChainId.ARBITRUM_ONE]
  ],
  [ChainId.ARB_GOERLI]: [
    CNATIVE.onChain(ChainId.ARB_GOERLI),
    USDC[ChainId.ARB_GOERLI],
    USDT[ChainId.ARB_GOERLI]
  ],
  [ChainId.BNB_CHAIN]: [
    CNATIVE.onChain(ChainId.BNB_CHAIN),
    USDT[ChainId.BNB_CHAIN],
    BUSD[ChainId.BNB_CHAIN]
  ],
  [ChainId.BNB_TESTNET]: [
    CNATIVE.onChain(ChainId.BNB_TESTNET),
    USDT[ChainId.BNB_TESTNET],
    BUSD[ChainId.BNB_TESTNET]
  ],
  [ChainId.ETHEREUM]: [
    CNATIVE.onChain(ChainId.ETHEREUM),
    USDC[ChainId.ETHEREUM]
  ]
}
