import {
  Box,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { CNATIVE, Currency, Token } from '@traderjoe-xyz/sdk-core'
import SearchBar from 'components/SearchBar'
import useTokensList from 'hooks/tokensList/useTokensList'
import useChainId from 'hooks/useChainId'
import useScrollTabIntoView from 'hooks/useScrollTabIntoView'
import React, { useState } from 'react'
import { TokenInfoTag } from 'types/tokensList'
import { formattedNum } from 'utils/format'

import CurrencyRow from './CurrencyRow'
import SaveTokenButton from './SaveTokenButton'

interface CurrencyPickerModalProps {
  isOpen: boolean
  onClose: () => void
  onCurrencyChange: (currency: Currency) => void
}

const CurrencyPickerModal = ({
  isOpen,
  onClose,
  onCurrencyChange
}: CurrencyPickerModalProps) => {
  const chainId = useChainId()
  const [query, setQuery] = useState<string>('')

  const [tabIndex, setTabIndex] = useState(0)
  const handleChange = (nextTabIndex: number) => {
    if (nextTabIndex !== tabIndex) {
      setTabIndex(nextTabIndex)
    }
  }

  const tags = [
    TokenInfoTag.ALL,
    TokenInfoTag.TOP,
    TokenInfoTag.DEFI,
    TokenInfoTag.STABLECOINS,
    TokenInfoTag.MEME,
    TokenInfoTag.GAMEFI,
    TokenInfoTag.NFT,
    TokenInfoTag.MY_TOKENS
  ]

  const { isLoadingBalances, tokenFoundByAddress, tokens } = useTokensList({
    activeTag: tags[tabIndex],
    query
  })
  const visibleTokens = tokenFoundByAddress ? [tokenFoundByAddress] : tokens

  const tablistRef = useScrollTabIntoView(tabIndex)

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent
        bg="bgSecondary"
        minH="80vh"
        overflow="hidden"
        mb={{ base: 0, sm: 50 }}
      >
        <ModalHeader>
          <Trans>Select a token</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Box m={4} mt={0}>
            <SearchBar
              size="lg"
              data-cy="currency-picker-search-bar"
              placeholder={t`Search by name, symbol or address`}
              value={query}
              onValueChange={setQuery}
              bg="bgPrimary"
            />
          </Box>
          {tags.length > 0 ? (
            <Tabs
              variant="enclosed"
              index={tabIndex}
              onChange={handleChange}
              px={4}
            >
              <TabList
                ref={tablistRef}
                overflowX="scroll"
                sx={{
                  '::-webkit-scrollbar': {
                    display: 'none'
                  },
                  scrollbarWidth: 'none'
                }}
              >
                {tags.map((tag, i) => (
                  <Tab key={i} flexShrink={0} isDisabled={query.length > 0}>
                    {tag}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          ) : null}
          <Box bg="bgPrimary" h="66vh">
            <VStack
              w="full"
              minH="280px"
              bg="bgPrimary"
              maxH="66vh"
              p={4}
              overflow="auto"
            >
              {visibleTokens.map((token, i) => (
                <CurrencyRow
                  key={i}
                  balance={
                    token.balance ? formattedNum(token.balance) : undefined
                  }
                  usdValue={
                    token.totalValueUsd
                      ? formattedNum(token.totalValueUsd, { usd: true })
                      : undefined
                  }
                  subtitle={
                    token.address &&
                    (tokenFoundByAddress ||
                      token.tags.includes(TokenInfoTag.MY_TOKENS)) ? (
                      <HStack>
                        <Text fontSize="sm" color="textSecondary">
                          <Trans>Found by address</Trans>
                        </Text>
                        <Divider orientation="vertical" h="16px" />
                        <SaveTokenButton token={token} />
                      </HStack>
                    ) : undefined
                  }
                  isLoadingBalance={isLoadingBalances}
                  tokenInfo={token}
                  shouldShowTags={true}
                  onClick={() => {
                    if (!token.address) {
                      onCurrencyChange(CNATIVE.onChain(chainId))
                      onClose()
                      return
                    }
                    onCurrencyChange(
                      new Token(
                        token.chainId,
                        token.address,
                        token.decimals,
                        token.symbol,
                        token.name
                      )
                    )
                    onClose()
                  }}
                  onTagClick={(tag) => setTabIndex(tags.indexOf(tag))}
                />
              ))}
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CurrencyPickerModal
