import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { JOE_ADDRESS } from '@traderjoe-xyz/sdk'
import CurrencyInput from 'components/CurrencyInput'
import Web3Button from 'components/Web3Button'
import { useVeJoeWithdraw } from 'hooks/stake/useVeJoeStaking'
import useChainId from 'hooks/useChainId'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import React from 'react'
import { unstakeVeJoe } from 'utils/measure'
import { formatEther } from 'viem'

interface VeJoeUnstakePanelProps {
  onSuccess: () => void
  userBalance: bigint
}

const VeJoeUnstakePanel = ({
  onSuccess,
  userBalance
}: VeJoeUnstakePanelProps) => {
  // get joe token
  const chainId = useChainId()
  const joeAddress = JOE_ADDRESS[chainId]
  const {
    tokens: [joeToken]
  } = useSdkCurrencies({
    addresses: [joeAddress]
  })

  // amount
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: joeToken
  })

  // get veJOE userBalance
  const fmtBalance = userBalance ? formatEther(userBalance) : undefined

  const { isLoading, withdraw } = useVeJoeWithdraw({
    amount: amountBN,
    onSuccess: () => {
      setAmount('')
      onSuccess()
    }
  })

  const isExceedingBalance =
    amountBN && userBalance ? userBalance < amountBN : false

  return (
    <VStack align="flex-start" spacing={4}>
      <CurrencyInput
        data-cy="vejoe-unstake-input"
        currency={joeToken}
        currencyAddress={joeAddress}
        value={amount}
        onValueChange={(value) => {
          setAmount(value)
        }}
        balance={fmtBalance}
        error={isExceedingBalance ? t`Exceeded your veJOE balance` : undefined}
      />
      <Web3Button
        data-cy="vejoe-unstake-button"
        colorScheme="accent"
        variant="primary"
        size="xl"
        w="full"
        isDisabled={isExceedingBalance || !withdraw}
        isLoading={isLoading}
        loadingText={t`Unstaking Tokens`}
        onClick={() => {
          withdraw?.()
          unstakeVeJoe()
        }}
      >
        <Trans>Unstake Tokens</Trans>
      </Web3Button>
    </VStack>
  )
}

export default VeJoeUnstakePanel
