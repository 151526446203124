import { Box, Flex, Tab, TabList, Tabs, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency, Token } from '@traderjoe-xyz/sdk-core'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LiquidityDistribution
} from '@traderjoe-xyz/sdk-v2'
import {
  getBidAskDistributionFromBinRange,
  getCurveDistributionFromBinRange,
  getUniformDistributionFromBinRange
} from '@traderjoe-xyz/sdk-v2'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CurrencyInput from 'components/CurrencyInput'
import MaxButton from 'components/MaxButton'
import Web3Button from 'components/Web3Button'
import useBatchPlaceLimitOrders from 'hooks/limitOrder/useBatchPlaceLimitOrders'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import React, { useCallback, useMemo, useState } from 'react'
import { OrderType } from 'types/limitOrder'
import { poolSelectDistributionShape } from 'utils/measure'
import { getCurrencyAmount } from 'utils/swap'
import { GetBalanceData } from 'wagmi/query'

import LiquidityShapePicker from '../LiquidityShapePicker'
import PriceRangeSelection from '../PriceRangeSelection'

interface AddOrdersPanelProps {
  isPriceRatioInversed: boolean
  onPlaceOrdersSuccess: () => void
  activeBinId?: number
  balance0?: GetBalanceData
  balance1?: GetBalanceData
  binStep?: string
  currency0?: Currency
  currency1?: Currency
  togglePriceRatiosClicked?: () => void
  tokenX?: Token
  tokenY?: Token
}

const AddOrdersPanel = ({
  activeBinId,
  balance0,
  balance1,
  binStep,
  currency0,
  currency1,
  isPriceRatioInversed,
  onPlaceOrdersSuccess,
  togglePriceRatiosClicked,
  tokenX,
  tokenY
}: AddOrdersPanelProps) => {
  const chainId = useChainId()

  const [orderType, setOrderType] = useState<OrderType>(OrderType.ASK)
  const isTokenXInput = orderType === OrderType.ASK

  const { data: usdPrices } = useTokenPriceUSD({
    tokens: tokenX && tokenY ? [tokenX.address, tokenY.address] : []
  })
  const [currencyPrice0, currencyPrice1] = usdPrices ?? [undefined, undefined]

  const {
    amount: amount0,
    amountBN: amount0BN,
    setAmount: setAmount0
  } = useCurrencyInputAmount({ currency: currency0 })
  const {
    amount: amount1,
    amountBN: amount1BN,
    setAmount: setAmount1
  } = useCurrencyInputAmount({ currency: currency1 })

  const [distribution, setDistribution] = useState<LiquidityDistribution>(
    LiquidityDistribution.SPOT
  )

  const defaultBinRange = useMemo(
    () => getDefaultBinRange(activeBinId, orderType),
    [activeBinId, orderType]
  )
  const [_binRange, setBinRange] = useState<number[] | undefined>()
  const binRange = useMemo(
    () => _binRange ?? defaultBinRange,
    [_binRange, defaultBinRange]
  )

  const isExceedingBalance0 = balance0
    ? Number(balance0.formatted) < Number(amount0)
    : false
  const isExceedingBalance1 = balance1
    ? Number(balance1.formatted) < Number(amount1)
    : false

  const isPriceRangeEnabled =
    activeBinId && currency0 && currency1 && binStep && binRange

  const isPriceRangeValid =
    activeBinId && binRange
      ? orderType === OrderType.ASK
        ? binRange[0] > activeBinId
        : binRange[1] < activeBinId
      : false

  const onBinRangeChange = useCallback(
    (range: number[]) => {
      setBinRange(range)
    },
    [setBinRange]
  )

  const currency = isTokenXInput ? currency0 : currency1
  const amountBN = isTokenXInput ? amount0BN : amount1BN
  const token = isTokenXInput ? tokenX : tokenY
  const isExceedingBalance = isTokenXInput
    ? isExceedingBalance0
    : isExceedingBalance1

  const {
    approvalType: approvalType,
    approve: approveToken,
    isApproved: isTokenApproved,
    isApproving: isApprovingToken,
    reset,
    setApprovalType: setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: amountBN,
    spender: LIMIT_ORDER_MANAGER_ADDRESS[chainId],
    token: token?.address,
    tokenSymbol: currency?.symbol
  })

  const { amountsBN, binIds } = useMemo(() => {
    if (
      !amountBN ||
      !binRange ||
      binRange.length === 0 ||
      !activeBinId ||
      !isPriceRangeValid
    ) {
      return {}
    }

    const currencyAmount0 = isTokenXInput
      ? getCurrencyAmount(currency, amountBN)
      : getCurrencyAmount(currency, BigInt(0))
    const currencyAmount1 = isTokenXInput
      ? getCurrencyAmount(currency, BigInt(0))
      : getCurrencyAmount(currency, amountBN)

    if (!currencyAmount0 || !currencyAmount1) {
      return {}
    }

    try {
      const { deltaIds, distributionX, distributionY } =
        distribution === LiquidityDistribution.SPOT
          ? getUniformDistributionFromBinRange(activeBinId, binRange)
          : distribution === LiquidityDistribution.CURVE
            ? getCurveDistributionFromBinRange(activeBinId, binRange, [
                currencyAmount0,
                currencyAmount1
              ])
            : getBidAskDistributionFromBinRange(activeBinId, binRange, [
                currencyAmount0,
                currencyAmount1
              ])

      const _distribution = isTokenXInput ? distributionX : distributionY

      // 'distributionSum' needed because the returned values from sdk functions above do not sum up to 10^18
      const distributionSum = _distribution.reduce(
        (prev, curr) => prev + curr,
        BigInt(0)
      )

      const binIds = deltaIds.map((delta) => activeBinId + delta)
      const amountsBN = binIds.map(
        (_, index) => (amountBN * _distribution[index]) / distributionSum
      )

      return { amountsBN, binIds }
    } catch {
      return {}
    }
  }, [
    isTokenXInput,
    activeBinId,
    currency,
    amountBN,
    binRange,
    distribution,
    isPriceRangeValid
  ])

  const { batchPlaceOrdersSamePair, isLoading } = useBatchPlaceLimitOrders({
    amounts: amountsBN,
    binIds,
    binStep: Number(binStep),
    enabled:
      (isTokenApproved || currency?.isNative) &&
      !isExceedingBalance &&
      isPriceRangeValid,
    isNative: currency?.isNative,
    onOrdersSuccess: () => {
      reset()
      onPlaceOrdersSuccess()
    },
    orderType,
    tokenX,
    tokenY,
    totalAmount: amountBN
  })

  return (
    <Box>
      <Flex flexDir="row" justifyContent="space-between">
        <Tabs
          variant="soft-rounded-outlined-white"
          index={orderType === OrderType.ASK ? 0 : 1}
          onChange={(index) => {
            const newOrderType = index === 0 ? OrderType.ASK : OrderType.BID
            setOrderType(newOrderType)
            setBinRange(getDefaultBinRange(activeBinId, newOrderType))
          }}
        >
          <TabList>
            <Tab
              data-cy={`${currency0?.symbol}-to-${currency1?.symbol}-tab`}
              _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}
            >
              {`${currency0?.symbol} → ${currency1?.symbol}`}
            </Tab>
            <Tab
              data-cy={`${currency1?.symbol}-to-${currency0?.symbol}-tab`}
              _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}
            >
              {`${currency1?.symbol} → ${currency0?.symbol}`}
            </Tab>
          </TabList>
        </Tabs>
      </Flex>
      <VStack align="flex-start" spacing={12} mt={4}>
        <VStack align="flex-start" w="full">
          {isTokenXInput ? (
            <CurrencyInput
              data-cy="place-limit-order-input-token-x"
              currency={currency0}
              currencyAddress={tokenX?.address}
              value={amount0}
              onValueChange={setAmount0}
              balance={balance0?.formatted}
              error={
                isExceedingBalance0
                  ? `Not enough ${currency0?.symbol}`
                  : undefined
              }
              rightElement={
                balance0 ? (
                  <MaxButton
                    balance={balance0.formatted}
                    onClick={() => setAmount0(balance0.formatted)}
                  />
                ) : undefined
              }
            />
          ) : (
            <CurrencyInput
              data-cy="place-limit-order-input-token-y"
              currency={currency1}
              currencyAddress={tokenY?.address}
              value={amount1}
              onValueChange={setAmount1}
              balance={balance1?.formatted}
              error={
                isExceedingBalance1
                  ? `Not enough ${currency1?.symbol}`
                  : undefined
              }
              rightElement={
                balance1 ? (
                  <MaxButton
                    balance={balance1.formatted}
                    onClick={() => setAmount1(balance1.formatted)}
                  />
                ) : undefined
              }
            />
          )}
        </VStack>
        <LiquidityShapePicker
          distribution={distribution}
          onDistributionChange={(dist) => {
            setDistribution(dist)
            poolSelectDistributionShape(dist.toString())
          }}
        />
        {isPriceRangeEnabled ? (
          <PriceRangeSelection
            min={orderType === OrderType.ASK ? activeBinId + 1 : undefined}
            max={orderType === OrderType.BID ? activeBinId - 1 : undefined}
            canUseRadiusInput={false}
            currencyPrice0={currencyPrice0}
            currencyPrice1={currencyPrice1}
            currency0={currency0}
            currency1={currency1}
            binStep={Number(binStep)}
            activeBinId={activeBinId}
            binRange={binRange}
            onBinRangeChange={onBinRangeChange}
            inversePriceRatios={isPriceRatioInversed}
            togglePriceRatiosClick={togglePriceRatiosClicked}
            maxBinPerBatch={Infinity}
            resetBinRange={() => {
              setBinRange(getDefaultBinRange(activeBinId, orderType))
            }}
          />
        ) : null}
        <VStack w="full" spacing={4}>
          {!isExceedingBalance &&
          !isTokenApproved &&
          !currency?.isNative &&
          approveToken ? (
            <ApproveTokenButton
              data-cy="place-order-approve-button"
              amount={amount0}
              currencySymbol={currency?.symbol}
              approvalType={approvalType}
              onApprovalTypeSelect={setApprovalType}
              isLoading={isApprovingToken}
              onClick={() => approveToken()}
            >
              {`Approve ${currency?.symbol}`}
            </ApproveTokenButton>
          ) : null}
          <Web3Button
            data-cy="add-limit-orders-button"
            variant="primary"
            colorScheme="accent"
            size="xl"
            w="full"
            isDisabled={isExceedingBalance || !batchPlaceOrdersSamePair}
            isLoading={isLoading}
            loadingText="Placing Orders"
            onClick={() => {
              batchPlaceOrdersSamePair?.()
            }}
          >
            {isExceedingBalance ? (
              <Trans>Not enough balance</Trans>
            ) : !isPriceRangeValid ? (
              <Trans>Invalid Price Range</Trans>
            ) : (
              <Trans>Add Limit Orders</Trans>
            )}
          </Web3Button>
        </VStack>
      </VStack>
    </Box>
  )
}

// calculate default bin range
const getDefaultBinRange = (
  activeBinId?: number | undefined,
  orderType?: OrderType
) => {
  if (!activeBinId || orderType === undefined) return undefined
  switch (orderType) {
    case OrderType.ASK:
      return [activeBinId + 1, activeBinId + 10]
    case OrderType.BID:
      return [activeBinId - 10, activeBinId - 1]
  }
}

export default AddOrdersPanel
