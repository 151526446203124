import useGetUserFarmBalances from 'hooks/farm/useGetUserFarmBalances'
import { useMemo } from 'react'
import { UserFarmPosition } from 'types/dexbarn'
import { UserBalancePerVault, Vault } from 'types/vault'

import useUserVaultBalances from './useUserVaultBalances'
import useUserVaults from './useUserVaults'

interface useVaultWithUserPositionProps {
  enabled: boolean
}

const useUserVaultsWithPosition = ({
  enabled
}: useVaultWithUserPositionProps) => {
  const { data: userVaults = [], isLoading: isLoadingUserVaults } =
    useUserVaults()

  const { data: userFarmBalances, isLoading: isLoadingUserFarmPositions } =
    useGetUserFarmBalances({
      enabled
    })

  const { data: userVaultsBalances, isLoading: isLoadingUserVaultPositions } =
    useUserVaultBalances({
      enabled,
      vaults: userVaults
    })

  const userBalancesPerVault = useMemo(
    () =>
      userVaults.reduce((acc, { id }, index) => {
        acc[id] = userVaultsBalances?.[index]
        return acc
      }, {} as UserBalancePerVault),
    [userVaults, userVaultsBalances]
  )

  const userFarmBalanceByFarmId = useMemo(
    () =>
      userFarmBalances
        ? userFarmBalances.reduce(
            (
              acc: { [key: string]: UserFarmPosition },
              balance: UserFarmPosition
            ) => {
              acc[balance.farmId] = balance
              return acc
            },
            {}
          )
        : {},
    [userFarmBalances]
  )

  const vaultWithUserBalanceAndFarmPositions = useMemo(() => {
    return userVaults.map((vault: Vault) => {
      const farmBalance = vault?.farm?.farmId
        ? userFarmBalanceByFarmId[vault?.farm?.farmId]
        : undefined
      const vaultBalance = userBalancesPerVault[vault?.address]
      const userPositions = { farmBalance, vaultBalance }

      return {
        ...vault,
        userPositions
      }
    })
  }, [userFarmBalanceByFarmId, userBalancesPerVault, userVaults])

  return {
    data: vaultWithUserBalanceAndFarmPositions,
    isLoading:
      isLoadingUserVaults ||
      isLoadingUserFarmPositions ||
      isLoadingUserVaultPositions
  }
}

export default useUserVaultsWithPosition
