import { defineStyleConfig } from '@chakra-ui/react'

const bronze = {
  filledTrack: {
    background: 'yellow.600'
  }
}

const silver = {
  filledTrack: {
    background: 'silver'
  }
}

const gold = {
  filledTrack: {
    background: 'gold'
  }
}

const diamond = {
  filledTrack: {
    background: 'cyan.400'
  }
}

export const progressTheme = defineStyleConfig({
  variants: { bronze, diamond, gold, silver }
})
