import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import useAccountRiskCheck from 'hooks/useAccountRiskCheck'
import React from 'react'
import { WarningIcon } from 'theme/icons'

const AccountBlockedModal = () => {
  const { data } = useAccountRiskCheck()
  const isBlocked = data?.riskLevel === 'severe'

  return (
    <Modal isOpen={isBlocked} onClose={Function.prototype()}>
      <ModalOverlay />
      <ModalContent textAlign="center" m={4}>
        <ModalBody>
          <VStack spacing={4} align="center" m={8}>
            <WarningIcon boxSize={6} fill="yellowBar" />
            <Heading size="lg" my={2}>
              <Trans>Blocked Address</Trans>
            </Heading>
            <Text color="gray.500" fontSize="sm" mb={3}>
              {data?.address}
            </Text>
            <Text fontSize="md" mb={3}>
              <Trans>
                This address is blocked on the www.traderjoexyz.com interface
                because it is associated with one or more blocked activities.
              </Trans>
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AccountBlockedModal
