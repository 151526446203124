import { useQuery } from '@tanstack/react-query'
import { useJoebarnGet } from 'hooks/useJoebarn'

const useGetUserProfile = (address?: string | null) => {
  const fetchProfile = useJoebarnGet(`/v3/users/${address?.toLowerCase()}`)

  return useQuery({
    enabled: !!address,
    queryFn: () => fetchProfile(),
    queryKey: ['UserProfile', address]
  })
}

export default useGetUserProfile
