import { CNATIVE, Token } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import { useMemo } from 'react'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

import useFetchOrderableList from './useFetchOrderableList'

const useOrderableList = () => {
  const chainId = useChainId()
  const { data: orderablePairs } = useFetchOrderableList()

  return useMemo(() => {
    if (!orderablePairs) return []
    return orderablePairs.pairs
      .filter((pair) => pair.chainId === chainId)
      .map((pair) => {
        return {
          ...pair,
          tokenX: isWrappedNativeCurrency(pair.tokenX.address, chainId)
            ? CNATIVE.onChain(chainId)
            : new Token(
                pair.chainId,
                pair.tokenX.address,
                pair.tokenX.decimals,
                pair.tokenX.symbol
              ),
          tokenY: isWrappedNativeCurrency(pair.tokenY.address, chainId)
            ? CNATIVE.onChain(chainId)
            : new Token(
                pair.chainId,
                pair.tokenY.address,
                pair.tokenY.decimals,
                pair.tokenY.symbol
              )
        }
      })
  }, [chainId, orderablePairs])
}

export default useOrderableList
