import {
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  LinkProps,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import React from 'react'
import {
  CoinMarketCapIcon,
  DiscordIcon,
  GithubIcon,
  SubstackIcon,
  TelegramIcon,
  TwitterIcon
} from 'theme/icons'

import FooterSection from './FooterSection'

const SocialNetworkLink = (props: LinkProps) => (
  <Link
    isExternal
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderRadius="full"
    bg="accent.400"
    boxSize="40px"
    _hover={{ bg: 'accent.500' }}
    _active={{ bg: 'accent.500' }}
    {...props}
  />
)

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <Box width="full" bgColor="bgPrimary">
      <Box
        maxW={{ '2xl': '1600px', base: '1400px' }}
        margin="0 auto"
        p={4}
        pt={{ base: 4, md: 12 }}
        pb={{ base: 4, md: 8 }}
      >
        <Flex justify="space-between" flexWrap="wrap" gap={8}>
          <Flex flexDir="column" justify="space-between" gap={8}>
            <VStack align="flex-start" spacing={4}>
              <Heading size="sm">
                <Trans>Need help?</Trans>
              </Heading>
              <Text noOfLines={0}>
                <Trans>
                  Visit{' '}
                  <Link
                    isExternal
                    href="https://support.traderjoexyz.com/en/"
                    color="accent.500"
                  >
                    our support centre
                  </Link>{' '}
                  for troubleshooting
                </Trans>
              </Text>
              <Text noOfLines={0}>
                <Trans>
                  Join{' '}
                  <Link
                    isExternal
                    href="https://support.traderjoexyz.com/en/"
                    color="accent.500"
                  >
                    our official Discord
                  </Link>{' '}
                  for dedicated support
                </Trans>
              </Text>
            </VStack>
            <HStack
              spacing={4}
              w="full"
              justify="space-between"
              flexWrap="wrap"
            >
              <SocialNetworkLink
                aria-label="Trader Joe on Discord"
                href="https://discord.gg/traderjoe"
              >
                <DiscordIcon boxSize="28px" fill="white" />
              </SocialNetworkLink>
              <SocialNetworkLink
                aria-label="Trader Joe on Twitter"
                href="https://twitter.com/traderjoe_xyz"
              >
                <TwitterIcon boxSize="28px" fill="white" />
              </SocialNetworkLink>
              <SocialNetworkLink
                aria-label="Trader Joe on Substack"
                href="https://joecontent.substack.com/"
              >
                <SubstackIcon boxSize="28px" fill="white" />
              </SocialNetworkLink>
              <SocialNetworkLink
                aria-label="Trader Joe on CoinMarketCap"
                href="https://coinmarketcap.com/community/profile/TraderJoe"
              >
                <CoinMarketCapIcon boxSize="22px" fill="white" />
              </SocialNetworkLink>
              <SocialNetworkLink
                aria-label="Trader Joe on Github"
                href="https://github.com/traderjoe-xyz/"
              >
                <GithubIcon boxSize="28px" fill="white" />
              </SocialNetworkLink>
              <Link
                isExternal
                aria-label="Trader Joe on Telegram"
                href="https://t.me/traderjoeofficial_xyz"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="full"
                boxSize="40px"
              >
                <TelegramIcon
                  boxSize="40px"
                  fill="accent.400"
                  _hover={{ fill: 'accent.500' }}
                  _active={{ fill: 'accent.500' }}
                />
              </Link>
            </HStack>
            <HStack spacing={4} flexWrap="wrap">
              <Link
                isExternal
                aria-label="Audits of Trader Joe's smart contracts"
                href="https://support.traderjoexyz.com/en/articles/6881007-audits"
                color="accent.500"
              >
                <Trans>Security</Trans>
              </Link>
              <Link
                isExternal
                aria-label="Trader Joe developer documentation"
                href="https://docs.traderjoexyz.com/"
                color="accent.500"
              >
                <Trans>Developers</Trans>
              </Link>
              <Link
                aria-label="Trader Joe Privacy Policy"
                href="/privacy"
                color="accent.500"
              >
                <Trans>Privacy</Trans>
              </Link>
              <Link
                aria-label="Trader Joe Terms of Service"
                href="/tos"
                color="accent.500"
              >
                <Trans>Terms of Service</Trans>
              </Link>
            </HStack>
            <Text fontSize="sm">© {year} Joemart Ltd</Text>
          </Flex>
          <VStack align="flex-start" spacing={8}>
            <FooterSection
              title={t`JOE Token`}
              items={[
                {
                  href: 'https://www.coingecko.com/en/coins/joe',
                  name: 'Coingecko'
                },
                {
                  href: 'https://coinmarketcap.com/currencies/joe/',
                  name: 'CoinMarketCap'
                }
              ]}
            />
            <FooterSection
              title={t`Analytics`}
              items={[
                {
                  href: 'https://www.tokenterminal.com/terminal/projects/trader-joe',
                  name: 'TokenTerminal'
                },
                {
                  href: 'https://defillama.com/protocol/trader-joe',
                  name: 'Defi Llama'
                },
                {
                  href: 'https://www.geckoterminal.com/avax/traderjoe/pools',
                  name: 'Gecko Terminal'
                }
              ]}
            />
          </VStack>
          <FooterSection
            title={t`Exchanges`}
            items={[
              {
                href: 'https://www.binance.com/en/trade/JOE_USDT?layout=pro',
                name: 'Binance'
              },
              {
                href: 'https://crypto.com/price/joe',
                name: 'Crypto.com'
              },
              {
                href: 'https://www.gate.io/trade/JOE_USDT',
                name: 'Gate.io'
              },
              {
                href: 'https://www.mexc.com/exchange/JOE_USDT',
                name: 'MEXC'
              },
              {
                href: 'https://www.okx.com/markets/prices/joe-joe',
                name: 'OKX'
              }
            ]}
          />
          <FooterSection
            title={t`Business`}
            items={[
              {
                href: 'https://docs.google.com/forms/d/e/1FAIpQLSezM85MhNQaogZltoxXCz2vP8R9EuX1RjeeRGuaMYJcMdUwiA/viewform',
                name: t`Apply for Partnership`
              },
              {
                href: 'https://github.com/traderjoe-xyz/joe-tokenlists',
                name: t`Token Listing`
              },
              {
                href: 'https://forms.gle/GAJL7GN4BSfHcvKc8',
                name: t`NFT Listing`
              },
              {
                href: 'https://twitter.com/traderjoe_xyz',
                name: t`Contact Us`
              }
            ]}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default Footer
