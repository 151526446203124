import { HStack, LinkBox, LinkOverlay, Td, Text, Tr } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { formattedNum } from 'utils/format'
import { homeClickToken } from 'utils/measure'
import { TokenRow } from 'utils/tokensRanking'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

const TokensRankingRow = ({
  address,
  chainId,
  href,
  pctChange,
  position,
  price,
  symbol,
  volume
}: TokenRow) => {
  return (
    <LinkBox
      as={Tr}
      transform="scale(1)"
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      onClick={() => {
        homeClickToken(symbol ?? '')
      }}
    >
      <Td fontWeight="normal">{position}</Td>
      <Td>
        <LinkOverlay
          aria-label={`Link to trade page for ${address}`}
          href={href}
        />
        <HStack>
          <CurrencyLogo
            address={
              isWrappedNativeCurrency(address, chainId) ? undefined : address
            }
            symbol={symbol}
            boxSize={6}
          />
          <Text>{symbol}</Text>
        </HStack>
      </Td>
      <Td textAlign="right">{formattedNum(volume, { usd: true })}</Td>
      <Td textAlign="right">{formattedNum(price, { usd: true })}</Td>
      <Td
        textAlign="right"
        color={
          pctChange ? (pctChange < 0 ? 'red.400' : 'green.400') : 'textPrimary'
        }
      >
        {pctChange ? `${pctChange.toFixed(2)}%` : '--'}
      </Td>
    </LinkBox>
  )
}

export default TokensRankingRow
