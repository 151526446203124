import { UseQueryResult } from '@tanstack/react-query'
import { getTokenLogoURL } from 'components/CurrencyLogo'
import useChainId from 'hooks/useChainId'
import { TokenInfo } from 'types/tokensList'
import { erc20Abi, formatUnits, getAddress, isAddress } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UseTokenSearchProps {
  query?: string
}

const useTokenSearch = ({
  query
}: UseTokenSearchProps): UseQueryResult<
  (TokenInfo & { balance?: string }) | undefined
> => {
  const chainId = useChainId()
  const { address: account } = useAccount()
  const tokenAddress = query && isAddress(query) ? getAddress(query) : undefined

  return useReadContracts({
    allowFailure: true,
    contracts: [
      {
        abi: erc20Abi,
        address: tokenAddress,
        chainId,
        functionName: 'decimals'
      },
      {
        abi: erc20Abi,
        address: tokenAddress,
        chainId,
        functionName: 'name'
      },
      {
        abi: erc20Abi,
        address: tokenAddress,
        chainId,
        functionName: 'symbol'
      },
      {
        abi: erc20Abi,
        address: tokenAddress,
        args: account ? [account] : undefined,
        chainId,
        functionName: 'balanceOf'
      }
    ],
    query: {
      enabled: !!tokenAddress,
      select: (data) => {
        const decimals = data[0].result
        const name = data[1].result
        const symbol = data[2].result
        const userBalance = data[3].result

        if (!decimals || !symbol || !tokenAddress || !name) return undefined

        return {
          address: tokenAddress,
          balance:
            userBalance && decimals
              ? formatUnits(userBalance, decimals)
              : undefined,
          chainId,
          decimals,
          logoURI: getTokenLogoURL(tokenAddress),
          name,
          symbol,
          tags: []
        }
      }
    }
  })
}

export default useTokenSearch
