import { Button, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import React from 'react'
import { useSetUKDisclaimerHidden } from 'state/settings/hooks'

import UKDisclaimerModal from './UKDisclaimerModal'

export const bannerText = t`
  This web application is provided as a tool for users to interact with the JOE Protocol on
  their own initiative, with no endorsement or recommendation of cryptocurrency trading activities. In doing so,
  Joemart Ltd is not recommending that users or potential users engage in cryptoasset trading activity, and users or
  potential users of the web application should not regard this webpage or its contents as involving any form of
  recommendation, invitation or inducement to deal in cryptoassets.
`

const UKDisclaimerBanner = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { isUKDisclaimerHidden, setUKDisclaimerHidden } =
    useSetUKDisclaimerHidden()
  const hideBanner = () => setUKDisclaimerHidden(true)

  if (isUKDisclaimerHidden) {
    return null
  }

  return (
    <>
      <Flex
        data-cy="uk-disclaimer-banner"
        px={4}
        py={3}
        borderBottom="1px solid"
        borderColor="border"
        gap={{ base: 0, md: 2 }}
        flexDir={{ base: 'column', md: 'row' }}
        align={{ base: 'flex-start', md: 'center' }}
      >
        <Text
          noOfLines={{ base: 3, md: 1 }}
          textColor="textSecondary"
          wordBreak="break-all"
        >
          <Trans>UK disclaimer: {bannerText}</Trans>
        </Text>
        <HStack spacing={0} ml={{ base: -3, md: 0 }}>
          <Button
            data-cy="uk-disclaimer-banner-read-more-button"
            variant="unstyled"
            _hover={{ opacity: 0.5 }}
            flexShrink={0}
            onClick={onOpen}
            size={{ base: 'sm', md: 'md' }}
          >
            <Trans>Read More</Trans>
          </Button>
          <Button
            flexShrink={0}
            variant="unstyled"
            _hover={{ opacity: 0.5 }}
            onClick={hideBanner}
            size={{ base: 'sm', md: 'md' }}
          >
            <Trans>Close</Trans>
          </Button>
        </HStack>
      </Flex>
      <UKDisclaimerModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default UKDisclaimerBanner
