import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack
} from '@chakra-ui/react'
import React from 'react'

interface SimpleModalProps {
  header: string
  isOpen: boolean
  onClose: () => void
  rows: JSX.Element[]
}

const SimpleModal = ({ header, isOpen, onClose, rows }: SimpleModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent
        bg="bgSecondary"
        minH="80vh"
        overflow="hidden"
        mb={{ base: 0, sm: 50 }}
      >
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton mt={1} />
        <ModalBody p={0}>
          <Box bg="bgPrimary" h="73vh">
            <VStack
              w="full"
              minH="280px"
              bg="bgPrimary"
              maxH="66vh"
              p={4}
              overflow="auto"
            >
              {rows}
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SimpleModal
