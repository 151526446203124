import { t } from '@lingui/macro'
import { BAR_ADDRESS } from '@traderjoe-xyz/sdk'
import xJoeImage from 'assets/stake/xJoe.webp'
import { ZERO_ADDRESS } from 'constants/tokens'
import useXJoeStaking, { useXJoeUserPosition } from 'hooks/stake/useXJoeStaking'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { formattedNum } from 'utils/format'
import { formatEther } from 'viem'

import { CurrencyTab } from '.'
import { JoeUsdProps } from './SJoeCard'
import StakeCard from './StakeCard'

const XJoeCard = ({ currencyTab, joeUsd }: JoeUsdProps) => {
  const { userBalance } = useXJoeUserPosition()
  const { ratio, totalStaked } = useXJoeStaking()

  // check if not available on selected chain
  const chainId = useChainId()
  const isNotAvailable = BAR_ADDRESS[chainId] === ZERO_ADDRESS

  // user balance
  const xJoeBalanceNum = userBalance ? Number(formatEther(userBalance)) : 0
  const balanceNum = xJoeBalanceNum * ratio
  const fmtBalance = formattedNum(balanceNum, { places: 2 })
  const fmtBalanceUsd = formattedNum(balanceNum * (joeUsd ?? 0), {
    places: 2,
    usd: true
  })

  // total staked
  const totalStakedNum = totalStaked ? Number(formatEther(totalStaked)) : 0
  const totalStakedUSD = totalStakedNum * (joeUsd ?? 0)
  const fmtTotalStaked = formattedNum(totalStakedNum, { places: 2 })
  const fmtTotalStakedUSD = formattedNum(totalStakedUSD, {
    places: 2,
    usd: true
  })

  return isNotAvailable ? null : (
    <StakeCard
      isExternal
      image={xJoeImage}
      linkUrl="https://v1.traderjoexyz.com/stake/xjoe"
      title="xJOE"
      tag={t`Withdraw Only`}
      label1={t`Total Staked (${currencyTab})`}
      text1={
        currencyTab == CurrencyTab.JOE ? fmtTotalStaked : fmtTotalStakedUSD
      }
      label2={t`Your Stake (${currencyTab})`}
      text2={currencyTab == CurrencyTab.JOE ? fmtBalance : fmtBalanceUsd}
      label3={t`APR (30D)`}
      text3={t`N/A`}
    />
  )
}

export default XJoeCard
