import { supportedChains } from 'constants/chains'
import { useSelectedChainId } from 'state/settings/hooks'
import { Chain } from 'viem'
import { useAccount, useConfig } from 'wagmi'

import useChainFromUrlParam from './useChainFromUrlParam'

interface UseActiveChainProps {
  overridesChainSlug?: string
}

const useActiveChain = ({
  overridesChainSlug
}: UseActiveChainProps = {}): Chain => {
  const chainInUrlParam = useChainFromUrlParam({ overridesChainSlug })

  const { selectedChainId } = useSelectedChainId()
  const selectedChain = supportedChains.filter(
    (chain) => chain.id === selectedChainId
  )[0]

  const { chain } = useAccount()
  const { chains } = useConfig()
  const activeChain =
    chain && chains.includes(chain) ? chain : (selectedChain as Chain)

  return chainInUrlParam ?? activeChain
}

export default useActiveChain
