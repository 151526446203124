import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import { LBPoolVersion } from 'types/pool'
import { erc721Abi, getAddress } from 'viem'
import {
  useAccount,
  useReadContract,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

import useChainId from './useChainId'
import useTransactionToast from './useTransactionToast'
import useWaitForTransactionReceipt from './useWaitForTransactionReceipt'

interface UseApproveForAllIfNeededProps {
  lbPoolVersion: LBPoolVersion
  spender: string
  token?: string
  tokenSymbol?: string
}

const useApproveForAllIfNeeded = ({
  lbPoolVersion,
  spender,
  token,
  tokenSymbol
}: UseApproveForAllIfNeededProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: isApprovedForAll, isLoading: isLoadingIsApprovedForAll } =
    useReadContract({
      abi: erc721Abi,
      address: token ? getAddress(token) : undefined,
      args: account ? [account, getAddress(spender)] : undefined,
      chainId,
      functionName: 'isApprovedForAll',
      query: {
        enabled: !!account
      }
    })

  const { data: configV2 } = useSimulateContract({
    abi: erc721Abi,
    address: token ? getAddress(token) : undefined,
    args: [getAddress(spender), true],
    functionName: 'setApprovalForAll',
    query: {
      enabled: isApprovedForAll === false && lbPoolVersion === 'v2',
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data: configV21 } = useSimulateContract({
    abi: LBPairV21ABI,
    address: token ? getAddress(token) : undefined,
    args: [getAddress(spender), true],
    functionName: 'approveForAll',
    query: {
      enabled: isApprovedForAll === false && lbPoolVersion !== 'v2',
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract: writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Approve ${tokenSymbol}`
        addRecentTransaction({
          description,
          hash
        })
        addTransactionToast({ description, hash })
      }
    }
  })

  const config = lbPoolVersion === 'v2' ? configV2 : configV21
  const isRequestReady = Boolean(config?.request)
  const approve = isRequestReady
    ? () => {
        writeContract(config!.request as any)
      }
    : undefined

  const {
    data: receipt,
    isLoading: isWaitingForTransaction,
    isSuccess
  } = useWaitForTransactionReceipt({
    hash
  })

  return {
    approve,
    isApproved: isApprovedForAll === true || receipt?.status === 'success',
    isApproving: isPending || isWaitingForTransaction,
    isLoadingIsApprovedForAll,
    isSuccess
  }
}

export default useApproveForAllIfNeeded
