import {
  LB_FACTORY_ADDRESS,
  LB_FACTORY_V21_ADDRESS,
  LB_FACTORY_V22_ADDRESS,
  LBFactoryABI,
  LBPairABI,
  LBPairV21ABI
} from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import { LBPoolVersion } from 'types/pool'
import { getAddress } from 'viem'
import { useReadContract, useReadContracts } from 'wagmi'

import useLBPairFeeParameters from './useLBPairFeeParameters'

interface UseLBPairDataProps {
  binStep?: string
  token0Address?: string
  token1Address?: string
  version?: LBPoolVersion
}

const useLBPairData = ({
  binStep,
  token0Address,
  token1Address,
  version
}: UseLBPairDataProps) => {
  const chainId = useChainId()

  let factoryAddress: string | undefined
  switch (version) {
    case 'v2':
      factoryAddress = LB_FACTORY_ADDRESS[chainId]
      break
    case 'v21':
      factoryAddress = LB_FACTORY_V21_ADDRESS[chainId]
      break
    case 'v22':
      factoryAddress = LB_FACTORY_V22_ADDRESS[chainId]
      break
  }

  const { data: lbPairInfo, isLoading: isLoadingPairInfo } = useReadContract({
    abi: LBFactoryABI, // getLBPairInformation is the same for all versions
    address: factoryAddress ? getAddress(factoryAddress) : undefined,
    args:
      token0Address && token1Address && binStep
        ? [
            getAddress(token0Address),
            getAddress(token1Address),
            BigInt(binStep)
          ]
        : undefined,
    chainId,
    functionName: 'getLBPairInformation',
    query: { enabled: !!token0Address && !!token1Address && !!binStep }
  })

  const {
    data: reservesAndIdV2,
    isLoading: isLoadingV2ReservesAndId,
    refetch: refetchReservesAndActiveBinIdV2
  } = useReadContract({
    abi: LBPairABI,
    address: lbPairInfo?.LBPair,
    chainId,
    functionName: 'getReservesAndId',
    query: { enabled: !!lbPairInfo && version === 'v2' }
  })

  const lbPairContractV21 = {
    abi: LBPairV21ABI,
    address: lbPairInfo?.LBPair,
    chainId
  }
  const {
    data: reservesAndIdV21,
    isLoading: isLoadingV21ReservesAndId,
    refetch: refetchReservesAndActiveBinIdV21
  } = useReadContracts({
    contracts: [
      {
        ...lbPairContractV21,
        functionName: 'getReserves'
      },
      {
        ...lbPairContractV21,
        functionName: 'getActiveId'
      }
    ],
    query: { enabled: !!lbPairInfo && version !== 'v2' }
  })
  const reservesV21 = reservesAndIdV21?.[0].result as
    | [bigint, bigint]
    | undefined

  const { data: feeParameters, isLoading: isLoadingFeeParameters } =
    useLBPairFeeParameters({ lbPairAddress: lbPairInfo?.LBPair, version })

  const activeBinId: number | undefined =
    version !== 'v2'
      ? reservesAndIdV21 && Number(reservesAndIdV21[1].result)
      : reservesAndIdV2 && Number(reservesAndIdV2[2])

  return {
    activeBinId,
    feeParameters,
    isLoading:
      isLoadingPairInfo ||
      (version === 'v2' && isLoadingV2ReservesAndId) ||
      (version !== 'v2' && isLoadingV21ReservesAndId) ||
      isLoadingFeeParameters,
    lbPairInfo,
    refetchReservesAndActiveBinId:
      version !== 'v2'
        ? refetchReservesAndActiveBinIdV21
        : refetchReservesAndActiveBinIdV2,
    reserveX: version !== 'v2' ? reservesV21?.[0] : reservesAndIdV2?.[0],
    reserveY: version !== 'v2' ? reservesV21?.[1] : reservesAndIdV2?.[1]
  }
}

export default useLBPairData
