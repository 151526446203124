import { Token, TokenAmount } from '@traderjoe-xyz/sdk-core'
import { Bin } from '@traderjoe-xyz/sdk-v2'

/**
 * Computes the price for the given binId,
 * parses the decimals given tokens A and B
 * and finally formats the output given the number of decimals
 * @param {number} binId
 * @param {number} binStep
 * @param {Token} tokenA
 * @param {Token} tokenB
 * @param {number} decimals
 * @returns
 */
export const computeAndParsePriceFromBin = (
  binId: number,
  binStep: number,
  tokenA: Token,
  tokenB: Token,
  decimals = 6
) => {
  const price = Bin.getPriceFromId(binId, binStep)
  const tokenAmount = new TokenAmount(
    tokenB,
    BigInt(Math.trunc(price * 10 ** tokenA.decimals))
  )
  return tokenAmount.toSignificant(decimals).toString()
}
