import { Currency } from '@traderjoe-xyz/sdk-core'
import { VaultABI } from '@traderjoe-xyz/sdk-v2'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import Web3Button from 'components/Web3Button'
import useChainId from 'hooks/useChainId'
import useVaultCancelQueuedWithdrawal from 'hooks/vault/useVaultCancelQueuedWithdrawal'
import useVaultRedeemQueuedWithdrawal from 'hooks/vault/useVaultRedeemQueuedWithdrawal'
import React from 'react'
import { VaultQueuedWithdrawal } from 'types/vault'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

interface VaultQueuedWithdrawalPanelProps {
  queuedWithdrawal: VaultQueuedWithdrawal
  currency0?: Currency
  currency1?: Currency
  currentRound?: bigint
}

const VaultQueuedWithdrawalPanel = ({
  currency0,
  currency1,
  currentRound,
  queuedWithdrawal
}: VaultQueuedWithdrawalPanelProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)

  // funds can be claimed if the current round is greater than the round of the queued withdrawal
  const canClaimWithdrawal = !!currentRound
    ? queuedWithdrawal.round < Number(currentRound)
    : false

  // if the funds can be claimed, we get the redeemable amounts
  const { data: redeemableAmounts, isLoading: isLoadingReedemableAmounts } =
    useReadContract({
      abi: VaultABI,
      address: getAddress(queuedWithdrawal.vaultAddress),
      args: account ? [BigInt(queuedWithdrawal.round), account] : undefined,
      chainId,
      functionName: 'getRedeemableAmounts',
      query: { enabled: canClaimWithdrawal && !!account }
    })
  // else we get the preview amounts
  const { data: previewAmounts, isLoading: isLoadingPreviewAmounts } =
    useReadContract({
      abi: VaultABI,
      address: getAddress(queuedWithdrawal.vaultAddress),
      args: [queuedWithdrawal.shares],
      chainId,
      functionName: 'previewAmounts',
      query: { enabled: !canClaimWithdrawal }
    })
  const amounts = redeemableAmounts ?? previewAmounts
  const amountX = amounts?.[0]
  const amountY = amounts?.[1]

  // redeem the queued withdrawal, only enabled if the funds can be claimed
  const {
    isLoading: isRedeemingQueuedWithdrawal,
    isSuccess: isRedeemSuccess,
    redeemQueuedWithdrawal
  } = useVaultRedeemQueuedWithdrawal({
    enabled: canClaimWithdrawal,
    isOneCurrencyNative: (currency0?.isNative || currency1?.isNative) ?? false,
    round: queuedWithdrawal.round,
    vaultAddress: queuedWithdrawal.vaultAddress
  })

  // cancel the queued withdrawal, only enabled if the round is not yet claimable
  const {
    cancelQueuedWithdrawal,
    isLoading: isCancellingQueuedWithdrawal,
    isSuccess: isCancelSuccess
  } = useVaultCancelQueuedWithdrawal({
    enabled: !canClaimWithdrawal,
    shares: queuedWithdrawal.shares,
    vaultAddress: queuedWithdrawal.vaultAddress
  })

  if (!wrappedCurrency0 || !wrappedCurrency1) {
    return null
  }

  return (
    <CurrencyAmountsPanel
      boxProps={{ px: 0, py: 0 }}
      title={`Queued Withdrawal #${queuedWithdrawal.round}`}
      isLoading={isLoadingPreviewAmounts || isLoadingReedemableAmounts}
      tokens={[wrappedCurrency0.address, wrappedCurrency1.address]}
      tokenAmounts={
        amountX && amountY && currency0 && currency1
          ? [
              Number(formatUnits(amountX, currency0.decimals)),
              Number(formatUnits(amountY, currency1.decimals))
            ]
          : [0, 0]
      }
      tokenSymbols={[currency0?.symbol, currency1?.symbol]}
      bottomContent={
        canClaimWithdrawal ? (
          <Web3Button
            data-cy="redeem-queued-withdrawal-button"
            variant="primary"
            colorScheme="accent"
            size="xl"
            w="full"
            mt={4}
            isDisabled={!redeemQueuedWithdrawal || isRedeemSuccess}
            loadingText="Redeeming"
            isLoading={isRedeemingQueuedWithdrawal}
            onClick={redeemQueuedWithdrawal}
          >
            {isRedeemSuccess ? 'Withdrawal Redeemed!' : 'Redeem Withdrawal'}
          </Web3Button>
        ) : (
          <Web3Button
            data-cy="cancel-queued-withdrawal-button"
            variant="primary"
            colorScheme="accent"
            size="xl"
            w="full"
            mt={4}
            isDisabled={!cancelQueuedWithdrawal || isCancelSuccess}
            loadingText="Cancelling"
            isLoading={isCancellingQueuedWithdrawal}
            onClick={cancelQueuedWithdrawal}
          >
            {isCancelSuccess ? 'Withdrawal Cancelled!' : 'Cancel Withdrawal'}
          </Web3Button>
        )
      }
    />
  )
}

export default VaultQueuedWithdrawalPanel
