import { Currency } from '@traderjoe-xyz/sdk-core'
import { LiquidityDistributionParams } from '@traderjoe-xyz/sdk-v2'
import { WEI_PER_ETHER } from 'constants/bigint'

export interface AddUniformLiquidityBatch {
  amount0: bigint
  amount1: bigint
  binRange: number[]
  distributionParams: LiquidityDistributionParams
}

export const getAddUniformLiquidityBatches = (
  binPerBatch: number,
  binRange?: number[],
  activeBinId?: number,
  currency0?: Currency,
  currency1?: Currency,
  totalAmount0?: bigint,
  totalAmount1?: bigint,
  distributionParams?: LiquidityDistributionParams
): AddUniformLiquidityBatch[] | undefined => {
  if (
    !binRange ||
    !activeBinId ||
    !currency0 ||
    !currency1 ||
    totalAmount0 === undefined ||
    totalAmount1 === undefined ||
    !distributionParams
  ) {
    return undefined
  }

  const start = binRange[0]
  const end = binRange[1]

  const isActiveBinTargetAdd =
    binRange.length === 2 &&
    binRange[0] === binRange[1] &&
    binRange[0] === activeBinId

  if (
    start > end ||
    binRange.includes(NaN) ||
    !!binRange.find((val) => !isFinite(val)) ||
    (totalAmount0 === BigInt(0) && totalAmount1 === BigInt(0)) ||
    (!isActiveBinTargetAdd &&
      totalAmount1 === BigInt(0) &&
      start < activeBinId) ||
    (!isActiveBinTargetAdd &&
      totalAmount0 === BigInt(0) &&
      start > activeBinId) ||
    (isActiveBinTargetAdd &&
      totalAmount1 === BigInt(0) &&
      totalAmount0 === BigInt(0))
  ) {
    return undefined
  }

  const totalBins = end - start + 1
  const nbOfBatches = Math.ceil(totalBins / binPerBatch)

  // calculate exact number of bins per batch, distributing any excess evenly
  const exactBinsPerBatch = Math.floor(totalBins / nbOfBatches)
  let extraBins = totalBins % nbOfBatches // bins that didn't evenly divide, to be distributed among the first few batches

  let totalBinsProcessed = 0

  return [...Array(nbOfBatches).keys()].map((i) => {
    const currentBatchSize = exactBinsPerBatch + (extraBins-- > 0 ? 1 : 0) // distribute extra bins among the initial batches
    const batchStart =
      i === 0
        ? start
        : start + i * exactBinsPerBatch + Math.min(i, totalBins % nbOfBatches)
    const batchEnd = batchStart + currentBatchSize - 1

    // Adjust slice indices based on totalBinsProcessed
    const sliceStartIndex = totalBinsProcessed
    const sliceEndIndex = sliceStartIndex + currentBatchSize // Exclusive end index

    // Update totalBinsProcessed for the next iteration
    totalBinsProcessed += currentBatchSize

    const deltaIds = distributionParams.deltaIds.slice(
      sliceStartIndex,
      sliceEndIndex
    )

    // we scale the distribution X to make sure the sum is 1e18
    const distributionX = distributionParams.distributionX.slice(
      sliceStartIndex,
      sliceEndIndex
    )
    const sumDistributionX = distributionX.reduce(
      (acc, val) => acc + val,
      BigInt(0)
    )
    const missingDistributionX = WEI_PER_ETHER - sumDistributionX
    const scaledDistributionX = distributionX.map((val) =>
      sumDistributionX > 0 && missingDistributionX > 0
        ? val + (missingDistributionX * val) / sumDistributionX
        : val
    )

    // we scale the distribution Y to make sure the sum is 1e18
    const distributionY = distributionParams.distributionY.slice(
      sliceStartIndex,
      sliceEndIndex
    )
    const sumDistributionY = distributionY.reduce(
      (acc, val) => acc + val,
      BigInt(0)
    )
    const missingDistributionY = WEI_PER_ETHER - sumDistributionY
    const scaledDistributionY = distributionY.map((val) =>
      sumDistributionY > 0 && missingDistributionY > 0
        ? val + (missingDistributionY * val) / sumDistributionY
        : val
    )

    const amount0 =
      nbOfBatches > 1
        ? distributionX.reduce(
            (acc, val) => acc + (val * totalAmount0) / WEI_PER_ETHER,
            BigInt(0)
          )
        : totalAmount0

    const amount1 =
      nbOfBatches > 1
        ? distributionY.reduce(
            (acc, val) => acc + (val * totalAmount1) / WEI_PER_ETHER,
            BigInt(0)
          )
        : totalAmount1

    return {
      amount0,
      amount1,
      binRange: [batchStart, batchEnd],
      distributionParams: {
        deltaIds,
        distributionX: scaledDistributionX,
        distributionY: scaledDistributionY
      }
    }
  })
}
