import { useCallback, useEffect, useState } from 'react'

interface UseKeyPressProps {
  targetKey: string
  onDown?: () => void
  onUp?: () => void
}

// modified https://usehooks.com/useKeyPress
const useKeyPress = ({ onDown, onUp, targetKey }: UseKeyPressProps) => {
  const [keyPressed, setKeyPressed] = useState<boolean>(false)

  const downHandler = useCallback(
    ({ key }: { key: string }) => {
      if (key === targetKey) {
        setKeyPressed(true)
        onDown?.()
      }
    },
    [targetKey, onDown]
  )

  const upHandler = useCallback(
    ({ key }: { key: string }) => {
      if (key === targetKey) {
        setKeyPressed(false)
        onUp?.()
      }
    },
    [targetKey, onUp]
  )

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [targetKey, downHandler, upHandler])

  return keyPressed
}

export default useKeyPress
