import { Flex, FlexProps, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { WarningIcon } from 'theme/icons'

interface Props {
  message: string
  rightContent?: React.ReactNode
}

const Warning = ({
  message,
  rightContent,
  ...props
}: Props & FlexProps): JSX.Element => {
  return (
    <Flex
      px={4}
      py={2}
      bg="red.500"
      borderRadius="lg"
      justify="space-between"
      align="center"
      {...props}
    >
      <HStack spacing={3}>
        <WarningIcon fill="white" boxSize={4} />
        <Text color="white" fontSize="sm" fontWeight="semibold">
          {message}
        </Text>
      </HStack>
      {rightContent}
    </Flex>
  )
}

export default Warning
