import { CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  HStack,
  IconButton,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import CopyableError from 'components/CopyableError'
import CurrencyLogo from 'components/CurrencyLogo'
import useCancelOrder from 'hooks/limitOrder/useCancelOrder'
import useClaimOrder from 'hooks/limitOrder/useClaimOrder'
import useLimitOrdersMinAmounts from 'hooks/limitOrder/useLimitOrdersMinAmounts'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import React, { useCallback } from 'react'
import { LimitOrder, OrderEventType } from 'types/dexbarn'
import { getPriceFromBinId } from 'utils/bin'
import { formattedNum } from 'utils/format'
import { useAccount, useSwitchChain } from 'wagmi'

export enum TabOption {
  ACTIVE = 0,
  COMPLETED = 1
}

interface OrdersListProps {
  activeOrders: LimitOrder[]
  activeTab: TabOption
  completedOrders: LimitOrder[]
  isFetchingNextPage: boolean
  isLoadingOrders: boolean
  onLoadMoreClick: () => void
  refetchOrders: () => void
  setActiveTab: (tab: TabOption) => void
  hasNextPage?: boolean
}

const OrdersList = ({
  activeOrders,
  activeTab,
  completedOrders,
  hasNextPage,
  isFetchingNextPage,
  isLoadingOrders,
  onLoadMoreClick,
  refetchOrders,
  setActiveTab
}: OrdersListProps) => {
  const minAmounts = useLimitOrdersMinAmounts({
    limitOrders: activeOrders
  })

  return (
    <Flex data-cy="limit-orders-list" flexDir="column" align="center">
      <Tabs
        variant="soft-rounded"
        colorScheme="accent"
        index={activeTab}
        onChange={(index) => setActiveTab(index)}
        w="full"
      >
        <TabList>
          <Tab>
            <Trans>Active Orders</Trans>
          </Tab>
          <Tab>
            <Trans>Completed</Trans>
          </Tab>
        </TabList>
        <TabPanels>
          <OrderTabPanel
            isLoading={isLoadingOrders}
            orders={activeOrders}
            onActionSuccess={refetchOrders}
            minAmounts={minAmounts}
          />
          <OrderTabPanel
            isLoading={isLoadingOrders}
            orders={completedOrders}
            onActionSuccess={refetchOrders}
          />
        </TabPanels>
      </Tabs>
      {hasNextPage ? (
        <Button
          variant="outline-bold"
          size="lg"
          alignSelf="center"
          w="160px"
          isLoading={isFetchingNextPage}
          loadingText={t`Loading`}
          onClick={onLoadMoreClick}
        >
          <Trans>Load More</Trans>
        </Button>
      ) : null}
    </Flex>
  )
}

const OrderTabPanel = ({
  isLoading,
  minAmounts,
  onActionSuccess,
  orders
}: {
  isLoading: boolean
  onActionSuccess: () => void
  orders: LimitOrder[]
  minAmounts?: (readonly [bigint, bigint])[]
}) => {
  return (
    <TabPanel px={0}>
      {isLoading ? (
        <Center py={8}>
          <Spinner size="sm" />
        </Center>
      ) : orders.length > 0 ? (
        <Flex flexDir="column" gap={4}>
          {orders.map((order, i) => (
            <OrderCard
              key={i}
              order={order}
              onActionSuccess={onActionSuccess}
              minAmounts={minAmounts?.[i]}
            />
          ))}
        </Flex>
      ) : (
        <Text textAlign="center" py={8} textColor="textSecondary">
          <Trans>No order found</Trans>
        </Text>
      )}
    </TabPanel>
  )
}

const OrderCard = ({
  minAmounts,
  onActionSuccess,
  order
}: {
  onActionSuccess: () => void
  order: LimitOrder
  minAmounts?: readonly [bigint, bigint]
}) => {
  const chainInUrlParam = useChainFromUrlParam()
  const { chain } = useAccount()
  const { switchChain } = useSwitchChain()
  const isConnectedToWrongNetwork = chainInUrlParam?.id !== chain?.id

  const { binId, pairId, pairLbBinStep, positionId, status, tokenIn } = order
  const { tokenX, tokenY } = order.tokenOut

  const {
    claimOrder,
    error: claimError,
    isLoading: isClaiming
  } = useClaimOrder({
    enabled: status === OrderEventType.EXECUTED,
    limitOrder: order,
    onTransactionSuccess: onActionSuccess
  })
  const {
    cancelOrder,
    error: cancelOrderError,
    isLoading: isCancelling
  } = useCancelOrder({
    enabled: status === OrderEventType.PLACED,
    limitOrder: order,
    minAmounts,
    onTransactionSuccess: onActionSuccess
  })
  const isLoading =
    status === OrderEventType.EXECUTED ? isClaiming : isCancelling
  const write = status === OrderEventType.EXECUTED ? claimOrder : cancelOrder
  const error = claimError || cancelOrderError

  const onActionClick = useCallback(() => {
    if (isConnectedToWrongNetwork) {
      if (chainInUrlParam) {
        switchChain({ chainId: chainInUrlParam.id })
      }
    } else {
      write?.()
    }
  }, [isConnectedToWrongNetwork, switchChain, write, chainInUrlParam])

  const tokenOut =
    order.tokenOut.tokenX.tokenId === tokenIn.tokenId
      ? order.tokenOut.tokenY
      : order.tokenOut.tokenX

  const priceXY = Number(
    getPriceFromBinId(
      binId,
      pairLbBinStep,
      tokenX.decimals,
      tokenY.decimals,
      18
    ) ?? 0
  )

  const price = tokenX.tokenId === tokenOut.tokenId ? 1 / priceXY : priceXY
  const amount = tokenIn.amount
  const total = price * amount

  return (
    <VStack
      key={order.positionId}
      pos="relative"
      overflow="hidden"
      w="full"
      bg="bgCard"
      p={{ base: 4, md: 8 }}
      borderRadius="2xl"
      spacing={4}
      border="1px solid"
      borderColor="border"
    >
      <HStack w="full" gap={1} align="center">
        <Flex gap={1} alignItems="center" flex={1}>
          <Flex gap="2px" alignItems="center">
            <CurrencyLogo
              address={tokenIn.tokenId}
              symbol={tokenIn.symbol}
              boxSize={6}
            />
            <CurrencyLogo
              address={tokenOut.tokenId}
              symbol={tokenOut.symbol}
              boxSize={6}
            />
          </Flex>
          <Text fontWeight="semibold">{tokenIn.symbol}</Text>
          <Text fontWeight="semibold">→</Text>
          <Text fontWeight="semibold">{tokenOut.symbol}</Text>
        </Flex>
        {order.status === OrderEventType.PLACED ? (
          <Box
            pos="absolute"
            top={{ base: 2, md: 5 }}
            right={{ base: 2, md: 5 }}
          >
            <Tooltip label="Cancel Order">
              <IconButton
                onClick={onActionClick}
                isLoading={isLoading}
                aria-label={`cancel-order-${pairId}-${positionId}`}
                bg="transparent"
                icon={<CloseIcon boxSize="10px" />}
              />
            </Tooltip>
          </Box>
        ) : (
          <OrderTag status={order.status} />
        )}
      </HStack>
      <Divider />
      <Grid templateColumns="repeat(3, 1fr)" gap={1} w="full">
        <Flex flexDir="column">
          <Text fontSize="sm" textColor="textSecondary">
            <Trans>Price {`(${tokenOut.symbol} / ${tokenIn.symbol})`}</Trans>
          </Text>
          <Text fontWeight="semibold">{formattedNum(price)}</Text>
        </Flex>
        <Flex flexDir="column">
          <Text fontSize="sm" textColor="textSecondary">
            <Trans>Amount</Trans>
          </Text>
          <Text fontWeight="semibold">
            {`${formattedNum(amount)} ${tokenIn.symbol}`}
          </Text>
        </Flex>
        <Flex flexDir="column">
          <Text fontSize="sm" textColor="textSecondary">
            <Trans>Expected Total</Trans>
          </Text>
          <Text fontWeight="semibold">{`${formattedNum(
            total
          )} ${`${tokenOut.symbol}`}`}</Text>
        </Flex>
      </Grid>
      {order.status === OrderEventType.EXECUTED ? (
        <Flex w="full" flexDir="column" gap={4}>
          <Divider />
          <Button
            w="full"
            variant="outline-bold"
            isLoading={isLoading}
            onClick={onActionClick}
            size="lg"
            _dark={{
              _hover: { bg: 'joeDark.500' },
              bg: 'joeDark.600',
              borderColor: 'joeDark.500'
            }}
            loadingText={t`Claiming`}
          >
            <Trans>Claim Funds</Trans>
          </Button>
        </Flex>
      ) : null}
      {error ? (
        <CopyableError
          error={error.message}
          summary={error.summary}
          textProps={{ alignSelf: 'flex-start' }}
        />
      ) : null}
    </VStack>
  )
}

const OrderTag = ({ status }: { status: OrderEventType }) => {
  if (status === OrderEventType.PLACED) {
    return null
  }

  let bg = ''
  switch (status) {
    case OrderEventType.EXECUTED:
    case OrderEventType.CLAIMED:
      bg = 'green.600'
      break
    case OrderEventType.CANCELLED:
      bg = 'red.600'
      break
  }

  return (
    <Tag variant="solid" textTransform="uppercase" bg={bg} fontSize={10}>
      {status}
    </Tag>
  )
}

export default OrdersList
