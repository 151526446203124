import { Currency } from '@traderjoe-xyz/sdk-core'
import { useMemo } from 'react'
import { TradeBestPath } from 'types/trade'
import { getSwapCallParameters } from 'utils/swap'
import { useAccount } from 'wagmi'

import useChainId from '../useChainId'
import useGetTransactionDeadline from '../useGetTransactionDeadline'

interface UseSwapCallArgumentsProps {
  allowedSlippage: number
  isExactIn: boolean
  currencyIn?: Currency
  currencyOut?: Currency
  trade?: TradeBestPath
}

const useSwapCallArguments = ({
  allowedSlippage,
  currencyIn,
  currencyOut,
  isExactIn,
  trade
}: UseSwapCallArgumentsProps) => {
  const { address: recipient } = useAccount()
  const chainId = useChainId()
  const getTransactionDeadline = useGetTransactionDeadline()

  return useMemo(() => {
    if (!trade || !recipient || !chainId || !currencyIn || !currencyOut) {
      return {
        defaultParameters: undefined,
        feeOnTransferParameters: undefined
      }
    }

    const deadline = getTransactionDeadline()

    return {
      defaultParameters: getSwapCallParameters({
        allowedSlippage: allowedSlippage,
        currencyIn,
        currencyOut,
        deadline: Number(deadline),
        isExactIn,
        recipient,
        trade,
        useFeeOnTransfer: false
      }),
      feeOnTransferParameters: getSwapCallParameters({
        allowedSlippage: allowedSlippage,
        currencyIn,
        currencyOut,
        deadline: Number(deadline),
        isExactIn,
        recipient,
        trade,
        useFeeOnTransfer: true
      })
    }
  }, [
    trade,
    allowedSlippage,
    chainId,
    getTransactionDeadline,
    recipient,
    currencyIn,
    currencyOut,
    isExactIn
  ])
}

export default useSwapCallArguments
