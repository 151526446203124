import {
  Badge,
  Divider,
  Heading,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { format } from 'date-fns'
import React from 'react'
import { LBPoolRewards } from 'types/pool'
import { RewardsStatus } from 'types/rewards'
import { formattedNum } from 'utils/format'

export const EpochRewardStatusBadge = ({
  status
}: {
  status: RewardsStatus
}) => {
  switch (status) {
    case 'upcoming':
      return (
        <Badge colorScheme="orange" size="sm">
          <Trans>Upcoming</Trans>
        </Badge>
      )
    case 'ongoing':
      return (
        <Badge colorScheme="green" size="sm">
          <Trans>Live</Trans>
        </Badge>
      )
    case 'ended':
      return (
        <Badge colorScheme="gray" size="sm">
          <Trans>Ended</Trans>
        </Badge>
      )
  }
}

interface EpochRewardPopoverProps {
  rewards: LBPoolRewards[]
}

const EpochRewardPopover = ({ rewards }: EpochRewardPopoverProps) => {
  return (
    <Popover trigger="hover" placement="top" variant="responsive">
      <PopoverTrigger>
        <Tag
          variant="solid"
          size="md"
          bg="green.400"
          textColor="white"
          _hover={{ opacity: 0.8 }}
        >
          <Trans>REWARDS</Trans>
        </Tag>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody>
            <VStack spacing={4}>
              {rewards.map((reward, index) => {
                return (
                  <VStack align="flex-start" key={index} w="full">
                    <HStack>
                      <Heading size="sm">
                        <Trans>Epoch {reward.epoch.value}</Trans>
                      </Heading>
                      <EpochRewardStatusBadge status={reward.epoch.status} />
                    </HStack>
                    <HStack w="full" spacing={4} fontSize="sm">
                      <VStack spacing={0} align="flex-start">
                        <Text textColor="textSecondary">
                          <Trans>Start:</Trans>
                        </Text>
                        {reward.epoch.start ? (
                          <Text>{format(reward.epoch.start, 'MMM dd')}</Text>
                        ) : (
                          '--'
                        )}
                      </VStack>
                      <VStack spacing={0} align={'flex-start'}>
                        <Text textColor="textSecondary">
                          <Trans>Finish:</Trans>
                        </Text>
                        {reward.epoch.end ? (
                          <Text>{format(reward.epoch.end, 'MMM dd')}</Text>
                        ) : (
                          '--'
                        )}
                      </VStack>
                      <VStack spacing={0} align={'flex-start'}>
                        <Text textColor="textSecondary">
                          <Trans>Rewards per day:</Trans>
                        </Text>
                        <Text>
                          {reward.rewards
                            .map(
                              (r) =>
                                `${formattedNum(r.rewardPerDay)} ${r.rewardToken
                                  ?.symbol}`
                            )
                            .join(' + ')}
                        </Text>
                      </VStack>
                    </HStack>
                    {index < rewards.length - 1 && <Divider mt={2} />}
                  </VStack>
                )
              })}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default EpochRewardPopover
