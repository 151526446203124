import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  VStack
} from '@chakra-ui/react'
import React, { useState } from 'react'

interface CurveShapeAlphaSliderProps {
  alpha: number
  setAlpha: (alpha: number) => void
}

const CurveShapeAlphaSlider = ({
  alpha,
  setAlpha
}: CurveShapeAlphaSliderProps) => {
  const [internalAlpha, setInternalAlpha] = useState(alpha)

  return (
    <VStack w="full" align="flex-start" spacing={1}>
      <Text fontSize="sm">Curvature:</Text>
      <Slider
        aria-label="curvature slider"
        value={internalAlpha}
        onChange={setInternalAlpha}
        onChangeEnd={setAlpha}
        min={0.001}
        max={1}
        step={0.001}
      >
        <SliderTrack borderRadius="full">
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </VStack>
  )
}

export default CurveShapeAlphaSlider
