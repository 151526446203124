import { Divider } from '@chakra-ui/react'
import React from 'react'
import { WalletMenuEnum } from 'types/walletMenu'

import ConnectedAccount from './ConnectedAccount'
import WalletMyInfo from './WalleMyInfo'
import WalletBalances from './WalletBalances'

interface ConnectedScreenProps {
  isOpen: boolean
  onClose: () => void
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
  walletAddress: string
}

const ConnectedScreen = ({
  isOpen,
  onClose,
  setWalletMenuDisplay,
  walletAddress
}: ConnectedScreenProps) => {
  return (
    <>
      <ConnectedAccount
        walletAddress={walletAddress}
        stackProps={{ pb: 4, px: 6 }}
      />
      <Divider />
      <WalletMyInfo
        walletAddress={walletAddress}
        setWalletMenuDisplay={setWalletMenuDisplay}
      />
      <Divider />
      <WalletBalances isVisible={isOpen} onTokenSelected={onClose} />
    </>
  )
}

export default ConnectedScreen
