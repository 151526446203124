import { useQuery } from '@tanstack/react-query'
import { VEJOE_SUBGRAPH_ID } from 'constants/subgraphs'
import {
  VeJoeActiveUserCount,
  VeJoeMedianStakerBalanceQuery
} from 'graphql/stake'
import request from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { VeJoeMedianData } from 'types/stake'
import { buildSubgraphUrl } from 'utils/subgraph'

type VeJoeActiveUserCountQueryResponse = {
  veJoes: [{ activeUserCount: number }]
}
type VeJoeMedianStakerBalanceQueryResponse = {
  users: [{ id: string; veJoeBalance: number }]
}

// Returns veJOE metrics
const useVeJoeAnalytics = () => {
  const chainId = useChainId()

  const subgraphId = VEJOE_SUBGRAPH_ID[chainId]
  const subgraphUrl = subgraphId ? buildSubgraphUrl(subgraphId) : ''

  const activeUserResult = useQuery({
    queryFn: () =>
      request<VeJoeActiveUserCountQueryResponse>(
        subgraphUrl,
        VeJoeActiveUserCount
      ),
    queryKey: ['VeJoeActiveUserCountQuery', subgraphUrl]
  })

  // get median user
  const activeUserCount =
    activeUserResult.data?.veJoes?.[0]?.activeUserCount ?? 0
  const medianStakerIndex = Math.floor(activeUserCount / 2)

  const medianUserResult = useQuery({
    queryFn: () =>
      request<VeJoeMedianStakerBalanceQueryResponse>(
        subgraphUrl,
        VeJoeMedianStakerBalanceQuery,
        {
          first: 1,
          skip: medianStakerIndex
        }
      ),
    queryKey: ['VeJoeMedianStakerBalanceQuery', subgraphUrl, medianStakerIndex]
  })
  const medianBalance = medianUserResult.data?.users?.[0]?.veJoeBalance ?? ''

  const data: VeJoeMedianData = {
    activeUserCount,
    medianBalance: Number(medianBalance)
  }
  return data
}

export default useVeJoeAnalytics
