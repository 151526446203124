import { Flex, Heading, HStack, Tag } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import BackButton from 'components/BackButton'
import ContractLink from 'components/ContractLink'
import CurrencyLogo from 'components/CurrencyLogo'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getChainSlug } from 'utils/chains'

interface MemeFarmDetailHeaderProps {
  farmAddress: string
  tokenAddress: string
  tokenSymbol: string
}

const MemeFarmDetailHeader = ({
  farmAddress,
  tokenAddress,
  tokenSymbol
}: MemeFarmDetailHeaderProps) => {
  const chainId = useChainId()
  const navigate = useNavigate()

  return (
    <Flex
      maxW={{ '2xl': '1600px', base: '1400px' }}
      margin="0 auto"
      flexDir="column"
      align="flex-start"
      gap={2}
      pos="relative"
      mb={8}
      px={4}
    >
      <BackButton
        title={t`Back to farms list`}
        ml={-3}
        minH="24px"
        onClick={() => navigate(`/${getChainSlug(chainId)}/stek`)}
      />
      <Flex
        zIndex={1}
        gap={4}
        flexWrap="wrap"
        align={{ base: 'flex-start', md: 'center' }}
      >
        <HStack>
          <CurrencyLogo
            address={tokenAddress}
            symbol={tokenSymbol}
            boxSize={7}
          />
          <Heading size="lg">{tokenSymbol}</Heading>
        </HStack>

        <Tag variant="outline">
          <ContractLink color="textPrimary" address={farmAddress} />
        </Tag>
      </Flex>
    </Flex>
  )
}

export default MemeFarmDetailHeader
