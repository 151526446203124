import { t } from '@lingui/macro'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'

export const poolsListHeaders = [
  { name: t`Pool name` },
  {
    isNumeric: true,
    name: t`Volume (24H)`,
    sortMethod: PoolSortMethod.VOLUME
  },
  {
    isNumeric: true,
    name: t`Liquidity`,
    sortMethod: PoolSortMethod.LIQUIDITY
  },
  {
    isNumeric: true,
    name: t`+2% depth`
  },
  {
    isNumeric: true,
    name: t`-2% depth`
  },
  {
    isNumeric: true,
    name: t`Fees (24H)`,
    sortMethod: PoolSortMethod.FEE
  },
  {
    isNumeric: true,
    name: t`APR (24H)`,
    sortMethod: PoolSortMethod.APR
  }
]

export enum PoolVersion {
  V1 = 'Basic (V1)',
  V2 = 'Manual (V2)'
}

export type PoolStatus = 'main' | 'all' | 'rewarded'
