import {
  Center,
  Flex,
  FlexProps,
  Hide,
  HStack,
  Image,
  Show,
  useMediaQuery
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import TraderJoeLogo from 'assets/trader-joe_1x.webp'
import TraderJoeLogo2x from 'assets/trader-joe_2x.webp'
import TraderJoeLogo3x from 'assets/trader-joe_3x.webp'
import ConnectButton from 'components/ConnectButton'
import HeaderSearchPanel from 'components/HeaderSearchPanel'
import Submenu from 'components/Submenu'
import UKDisclaimerBanner from 'components/UKDisclaimerBanner'
import {
  joeBridge,
  joeFarm,
  joeLend,
  joeMemeRush,
  joeStake
} from 'constants/urls'
import useChainId from 'hooks/useChainId'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useOriginCountry } from 'state/settings/hooks'
import { getChainSlug } from 'utils/chains'

import HeaderLink from './HeaderLink'

const HeaderContent = (): JSX.Element => {
  const chainId = useChainId()
  const [isLargerThanXL] = useMediaQuery(['(min-width: 1280px)'])

  const menuItems =
    chainId === ChainId.AVALANCHE
      ? isLargerThanXL
        ? [joeMemeRush, joeBridge, joeFarm, joeLend]
        : [joeStake, joeMemeRush, joeBridge, joeFarm, joeLend]
      : isLargerThanXL
        ? [joeBridge, joeFarm, joeLend]
        : [joeStake, joeBridge, joeFarm, joeLend]

  return (
    <Flex
      alignItems="center"
      w="full"
      maxW="1600px"
      margin="0 auto"
      p={4}
      gap={4}
    >
      <Flex gap={6} flexShrink={0}>
        <Center
          as={Link}
          aria-label="Link to Home page"
          to={`/${getChainSlug(chainId)}`}
          flexShrink={0}
        >
          <Image
            objectFit="contain"
            fallbackSrc={TraderJoeLogo2x}
            srcSet={`${TraderJoeLogo} 1x ${TraderJoeLogo2x} 2x ${TraderJoeLogo3x} 3x`}
            transition="transform 0.3s ease"
            boxSize={12}
            _hover={{ transform: 'rotate(4deg)' }}
          />
        </Center>
        <Hide below="lg">
          <HStack spacing={4} flexShrink={0}>
            <HeaderLink
              aria-label="Link to Trade page"
              to="trade"
              title={t`Trade`}
            />
            <HeaderLink aria-label="Link to Pool page" to="pool" title="Pool" />
            <Show above="xl">
              <HeaderLink
                aria-label="Link to Stake page"
                to="stake"
                title={t`Stake`}
              />
            </Show>
            <Submenu items={menuItems} />
          </HStack>
        </Hide>
      </Flex>

      <Hide below="lg">
        <Center w="full">
          <Flex w="full" maxW="600px">
            <HeaderSearchPanel />
          </Flex>
        </Center>
      </Hide>

      <Hide below="lg">
        <Flex columnGap={4} flexShrink={0}>
          <ConnectButton />
        </Flex>
      </Hide>
    </Flex>
  )
}

const HeaderContainer = (props: FlexProps) => {
  const [isBorderVisible, setIsBorderVisible] = useState(false)

  useEffect(() => {
    const updatePosition = () => {
      setIsBorderVisible(window.scrollY > 0)
    }
    document.addEventListener('scroll', updatePosition)
    return () => {
      document.removeEventListener('scroll', updatePosition)
    }
  }, [])

  const useDarkerBackground =
    (location.pathname.includes('/pool') &&
      !location.pathname.includes('/create')) ||
    location.pathname.includes('/stake') ||
    location.pathname.includes('/vault') ||
    location.pathname.includes('/launch') ||
    location.pathname.includes('/stek')

  const useJoeLight400 = location.pathname.includes('/bridge')

  const lightBackgroundColor =
    useDarkerBackground || useJoeLight400 ? 'joeLight.400' : 'joeLight.300'

  const darkBackgroundColor = useDarkerBackground
    ? 'joeDark.600'
    : 'joeDark.700'

  return (
    <Flex
      zIndex={11}
      position="sticky"
      width="full"
      top={0}
      minH="80px"
      flexDir="column"
      bg={lightBackgroundColor}
      _dark={{ bg: darkBackgroundColor }}
      borderBottom="1px"
      borderBottomColor={isBorderVisible ? 'border' : 'transparent'}
      padding={{
        base: '1rem 0.25rem 1rem 1rem',
        md: '1rem 2rem',
        xs: '1rem'
      }}
      {...props}
    />
  )
}

const Header = () => {
  const { originCountryCode } = useOriginCountry()

  if (originCountryCode === 'GB') {
    return (
      <HeaderContainer>
        <UKDisclaimerBanner />

        <Hide below="md">
          <HeaderContent />
        </Hide>
      </HeaderContainer>
    )
  }

  return (
    <Hide below="md">
      <HeaderContainer>
        <HeaderContent />
      </HeaderContainer>
    </Hide>
  )
}

export default Header
