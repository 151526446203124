import { Token } from '@traderjoe-xyz/sdk-core'
import useLBPairBalances from 'hooks/pool/v2/useLBPairBalances'
import { useMemo } from 'react'
import { formatUnits } from 'viem'

interface UseLBPositionProps {
  enabled?: boolean
  lbBinStep?: number
  lbPairAddress?: string
  owner?: string
  token0?: Token
  token1?: Token
}

const useLBPosition = ({
  enabled = true,
  lbBinStep,
  lbPairAddress,
  owner,
  token0,
  token1
}: UseLBPositionProps) => {
  // get user's positions and balances
  const {
    data: userBalances,
    isFetching,
    refetch
  } = useLBPairBalances({
    enabled,
    lbBinStep,
    lbPairAddress,
    owner,
    token0Decimals: token0?.decimals,
    token1Decimals: token1?.decimals
  })

  // get pooled tokens
  const [pooledTokens0, pooledTokens1] = useMemo(() => {
    if (!token0 || !token1 || !userBalances) {
      return [undefined, undefined]
    }
    try {
      const totalAmountX = userBalances.amounts.reduce(
        (acc, { rawAmountX }) => acc + BigInt(rawAmountX),
        BigInt(0)
      )
      const totalAmountY = userBalances.amounts.reduce(
        (acc, { rawAmountY }) => acc + BigInt(rawAmountY),
        BigInt(0)
      )
      return [
        Number(formatUnits(totalAmountX, token0.decimals)),
        Number(formatUnits(totalAmountY, token1.decimals))
      ]
    } catch {
      return [undefined, undefined]
    }
  }, [token0, token1, userBalances])

  return {
    isLoadingLBPosition: isFetching,
    pooledTokens0,
    pooledTokens1,
    refetchLBPosition: refetch,
    userBalances
  }
}

export default useLBPosition
