import { Link, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const PlaceOrderDisclaimer = () => {
  return (
    <Text fontSize="sm" textColor="textSecondary" mt={4}>
      <Trans>
        Always verify the current market price before placing your order. The
        price displayed may not accurately reflect the real-time market rate.{' '}
        <Link
          href="https://support.traderjoexyz.com/en/articles/8223423-how-to-use-limit-orders"
          isExternal
          color="accent.500"
        >
          Learn more
        </Link>
      </Trans>
    </Text>
  )
}

export default PlaceOrderDisclaimer
