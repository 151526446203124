import { useQuery } from '@tanstack/react-query'
import { differenceInCalendarDays } from 'date-fns'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import useEpochRewards from 'hooks/useEpochRewards'
import { LeaderboardData } from 'types/leaderboard'
import { getDexbarnChainParam } from 'utils/chains'

export type PoolLeaderboardSortMethod = 'volume' | 'fees'

interface usePoolLeaderboardProps {
  orderBy: PoolLeaderboardSortMethod
  enabled?: boolean
  endTime?: number
  lbPairAddress?: string
  startTime?: number
}

const usePoolLeaderboard = ({
  enabled = true,
  endTime,
  lbPairAddress,
  orderBy,
  startTime
}: usePoolLeaderboardProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const { epochRewards = [] } = useEpochRewards({
    lbPairAddress,
    status: 'ongoing'
  })

  const fetchLeaderboardData = useDexbarnGet<LeaderboardData[]>(
    `/v1/pools/historical-liquidity-providers/${chain}/${lbPairAddress}?startTime=${startTime}&endTime=${endTime}&orderBy=${orderBy}&pageSize=20`
  )

  return useQuery({
    enabled: !!lbPairAddress && enabled && !!startTime && !!endTime,
    queryFn: () => fetchLeaderboardData(),
    queryKey: [
      'HistoricalLiquidityProviders',
      lbPairAddress,
      startTime,
      endTime,
      chain,
      orderBy
    ],
    select: (data) => {
      const totalRewardScore =
        Math.ceil(
          10 *
            data.reduce(
              (acc, { accruedFeesUsd }) =>
                acc + parseFloat(accruedFeesUsd) ** 0.7,
              0
            )
        ) / 10

      const ongoingRewards = epochRewards?.[0]?.filter(
        (reward) => reward.rewardPerDay > 0
      )

      const ongoingRewardsTotalValueUsd =
        ongoingRewards &&
        ongoingRewards.length > 0 &&
        ongoingRewards.reduce((acc, reward) => {
          if (
            reward.rewardsPerDayUsd &&
            reward.start &&
            reward.end &&
            startTime &&
            endTime &&
            reward.start.getTime() / 1000 >= startTime &&
            reward.end.getTime() / 1000 <= endTime
          ) {
            return (
              acc +
              reward.rewardsPerDayUsd *
                differenceInCalendarDays(reward.end, reward.start)
            )
          } else {
            return acc
          }
        }, 0)

      return data.map((entry) => {
        const estimatedRewardsShare =
          parseFloat(entry.accruedFeesUsd) ** 0.7 / totalRewardScore
        return {
          ...entry,
          estimatedRewardsShare,
          estimatedRewardsUsd: ongoingRewardsTotalValueUsd
            ? ongoingRewardsTotalValueUsd * estimatedRewardsShare
            : undefined
        }
      })
    }
  })
}

export default usePoolLeaderboard
