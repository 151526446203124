import { FEE_RATE } from 'constants/pool'
import { DexbarnPoolV1 } from 'types/dexbarn'
import { Pool } from 'types/pool'

interface ConvertPairToPoolProps {
  dexbarnPool: DexbarnPoolV1
}

export const convertDexbarnPoolV1ToPool = ({
  dexbarnPool
}: ConvertPairToPoolProps): Pool => {
  const liquidityUsd = dexbarnPool.liquidityUsd

  const feesUsd = dexbarnPool.volumeUsd * FEE_RATE
  const apr = (feesUsd / liquidityUsd) * 365

  const tokenX = dexbarnPool.tokenX
  const tokenY = dexbarnPool.tokenY

  return {
    apr,
    feePct: 0.3,
    feesUsd,
    isMigrated: false,
    liquidityUsd,
    name: dexbarnPool.name,
    pairAddress: dexbarnPool.pairAddress,
    points: [],
    tokenX: {
      address: tokenX.address,
      decimals: tokenX.decimals,
      symbol: tokenX.symbol
    },
    tokenY: {
      address: tokenY.address,
      decimals: tokenY.decimals,
      symbol: tokenY.symbol
    },
    volumeUsd: dexbarnPool.volumeUsd
  }
}

interface ConvertPairsToPoolsProps {
  dexbarnPools: DexbarnPoolV1[]
}

export const convertDexbarnPoolsV1ToPools = ({
  dexbarnPools
}: ConvertPairsToPoolsProps): Pool[] => {
  return dexbarnPools
    .map((dexbarnPool) => {
      return convertDexbarnPoolV1ToPool({ dexbarnPool })
    })
    .filter(
      (pool) =>
        pool.liquidityUsd < 1e12 &&
        pool.tokenX.symbol !== 'JLP' &&
        pool.tokenY.symbol !== 'JLP'
    )
}
