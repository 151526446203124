import useChainId from 'hooks/useChainId'
import { erc20Abi, getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

interface UseGetTokensTotalSupplyProps {
  tokens: string[]
}

const useGetTokensTotalSupply = ({ tokens }: UseGetTokensTotalSupplyProps) => {
  const chainId = useChainId()

  const calls = tokens.map((token) => {
    return {
      abi: erc20Abi,
      address: getAddress(token),
      chainId,
      functionName: 'totalSupply'
    } as const
  })

  return useReadContracts({
    allowFailure: true,
    contracts: calls,
    query: {
      select: (data) => {
        return data
          ?.filter((read) => read.status === 'success')
          .map((read) => read?.result as bigint | null)
      }
    }
  })
}

export default useGetTokensTotalSupply
