import {
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import NftImage1x from 'assets/home/nft_1x.webp'
import NftImage2x from 'assets/home/nft_2x.webp'
import NftImage3x from 'assets/home/nft_3x.webp'
import PoolImage1x from 'assets/home/pool_1x.webp'
import PoolImage2x from 'assets/home/pool_2x.webp'
import PoolImage3x from 'assets/home/pool_3x.webp'
import StakeImage1x from 'assets/home/stake_1x.webp'
import StakeImage2x from 'assets/home/stake_2x.webp'
import StakeImage3x from 'assets/home/stake_3x.webp'
import TradeImage1x from 'assets/home/trade_1x.webp'
import TradeImage2x from 'assets/home/trade_2x.webp'
import TradeImage3x from 'assets/home/trade_3x.webp'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getChainSlug } from 'utils/chains'
import { homeClickTile } from 'utils/measure'

interface Tile {
  altTag: string
  imageSrc: string
  srcSet: string
  subtitle: string
  title: string
  to: string
  isExternal?: boolean
}

const HomeTiles = () => {
  const chainId = useChainId()
  const navigate = useNavigate()

  const tiles: Tile[] = [
    {
      altTag: 'trade-tile-image',
      imageSrc: TradeImage2x,
      srcSet: `${TradeImage1x} 1x ${TradeImage2x} 2x ${TradeImage3x} 3x`,
      subtitle: t`Swap tokens with low fees`,
      title: t`Trade`,
      to: 'trade'
    },
    {
      altTag: 'pool-tile-image',
      imageSrc: PoolImage2x,
      srcSet: `${PoolImage1x} 1x ${PoolImage2x} 2x ${PoolImage3x} 3x`,
      subtitle: t`Provide liquidity and earn fees`,
      title: t`Pool`,
      to: 'pool'
    },
    {
      altTag: 'stake-tile-image',
      imageSrc: StakeImage2x,
      srcSet: `${StakeImage1x} 1x ${StakeImage2x} 2x ${StakeImage3x} 3x`,
      subtitle: t`Stake JOE tokens and earn rewards`,
      title: t`Stake`,
      to: 'stake'
    },
    {
      altTag: 'nft-tile-image',
      imageSrc: NftImage2x,
      isExternal: true,
      srcSet: `${NftImage1x} 1x ${NftImage2x} 2x ${NftImage3x} 3x`,
      subtitle: t`Buy and sell NFTs`,
      title: t`NFT`,
      to: 'https://www.joepegs.com'
    }
  ]

  return (
    <Flex flexDir="column" w="full" gap={4} px={{ base: 2, xl: 0 }}>
      <SimpleGrid columns={{ base: 2, xl: 4 }}>
        {tiles.map((tile, i) => (
          <Box
            key={i}
            pos="relative"
            m={{ base: 2, xl: 4 }}
            boxSizing="border-box"
            _before={{
              base: {},
              xl: { content: '""', display: 'block', paddingTop: '100%' }
            }}
          >
            <Box
              pos={{ base: 'relative', xl: 'absolute' }}
              top={0}
              left={0}
              w="full"
              borderRadius="3xl"
              h="full"
              cursor="pointer"
              role="group"
              border="1px"
              borderColor="transparent"
              bg="joeLight.500"
              _dark={{
                _hover: {
                  bg: 'accent.500'
                },
                bg: 'joeDark.600'
              }}
              _hover={{
                bg: 'accent.500',
                transform: 'rotate(2deg) translate(2%, -2%)'
              }}
              transition="150ms all ease-in-out"
              onClick={() => {
                if (tile.isExternal) {
                  window.open(tile.to, '_blank')
                } else {
                  navigate(`/${getChainSlug(chainId)}/${tile.to}`)
                }
                homeClickTile(tile.title)
              }}
            >
              <Flex
                flexDir="column"
                gap={4}
                h="full"
                w="full"
                justify="space-between"
                py={{ base: 4, xl: 6 }}
                px={4}
              >
                <Image
                  flex={-1}
                  fallbackSrc={tile.imageSrc}
                  srcSet={tile.srcSet}
                  alt={tile.altTag}
                  w="full"
                  minH={0}
                  maxH={{ base: '216px', xl: 'full' }}
                  h="auto"
                  objectFit="contain"
                />
                <VStack spacing={0}>
                  <Heading size="sm" _groupHover={{ textColor: 'white' }}>
                    {tile.title}
                  </Heading>
                  <Text
                    fontSize="sm"
                    textColor="textSecondary"
                    textAlign="center"
                    _groupHover={{ textColor: 'joeLight.400' }}
                  >
                    {tile.subtitle}
                  </Text>
                </VStack>
              </Flex>
            </Box>
            <Box
              pos="absolute"
              w="calc(100% - 1px)"
              h="calc(100% - 1px)"
              top={0}
              left={0}
              bg="accent.600"
              borderRadius="3xl"
              zIndex={-10}
            />
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
  )
}

export default HomeTiles
