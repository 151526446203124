import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Flex,
  Heading,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import React from 'react'

interface StakeCardProps {
  image: string
  label1: string
  label2: string
  linkUrl: string
  tag: string
  text1: string
  text2: string
  title: string
  isExternal?: boolean
  label3?: string
  label4?: string
  text3?: string
  text4?: string
}

const StakeCard = ({
  image,
  isExternal,
  label1,
  label2,
  label3,
  label4,
  linkUrl,
  tag,
  text1,
  text2,
  text3,
  text4,
  title
}: StakeCardProps) => {
  return (
    <LinkBox>
      <Flex
        flexDir="column"
        bg="bgCard"
        borderColor="border"
        borderWidth={1}
        borderRadius="xl"
        p={4}
        cursor="pointer"
        _hover={{ bg: 'hover' }}
      >
        <LinkOverlay isExternal={isExternal} href={linkUrl} />
        <HStack spacing={4} justifyContent="space-between">
          <HStack>
            <Image boxSize={8} src={image} />
            <Heading fontSize="md">{title}</Heading>
            {isExternal ? <ExternalLinkIcon /> : null}
          </HStack>
          <Tag bgColor="bgPrimary">{tag}</Tag>
        </HStack>
        <SimpleGrid columns={2} mt="4" spacing={4}>
          <VStack spacing={0} alignItems="left">
            <Text color="textSecondary" fontSize="xs">
              {label1}
            </Text>
            <Text>{text1}</Text>
          </VStack>
          <VStack spacing={0} alignItems="left">
            <Text color="textSecondary" fontSize="xs">
              {label2}
            </Text>
            <Text>{text2}</Text>
          </VStack>
          {label3 && (
            <VStack spacing={0} alignItems="left">
              <Text color="textSecondary" fontSize="xs">
                {label3}
              </Text>
              <Text>{text3}</Text>
            </VStack>
          )}
          {label4 && (
            <VStack spacing={0} alignItems="left">
              <Text color="textSecondary" fontSize="xs">
                {label4}
              </Text>
              <Text>{text4}</Text>
            </VStack>
          )}
        </SimpleGrid>
      </Flex>
    </LinkBox>
  )
}

export default StakeCard
