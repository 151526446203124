import { supportedChains } from 'constants/chains'
import { useParams } from 'react-router-dom'

interface UseChainFromUrlParamProps {
  overridesChainSlug?: string
}

const useChainFromUrlParam = ({
  overridesChainSlug
}: UseChainFromUrlParamProps = {}) => {
  const { chainSlug } = useParams()
  const slug = overridesChainSlug ?? chainSlug
  return supportedChains.find((chain) => chain.slug === slug)
}

export default useChainFromUrlParam
