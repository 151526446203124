import { useMemo } from 'react'
import { LBPool, Pool } from 'types/pool'

import useGetBatchLbRewarderData from './useGetBatchLbRewarderData'
import useGetLbPoolsRewardAPRs from './useGetLbPoolsRewardAPRs'

interface UseLbPoolsWithRewardsProps {
  pools: (Pool | LBPool)[]
}

const useLbPoolsWithRewards = ({ pools }: UseLbPoolsWithRewardsProps) => {
  const lbPoolsV22 = useMemo(() => {
    return pools
      .filter((pool) => 'lbPoolVersion' in pool && pool.lbPoolVersion === 'v22')
      .map((pool) => {
        let binStep = 0
        if ('lbBinStep' in pool) {
          binStep = pool.lbBinStep
        }
        let liquidityDepthMinus = 0
        let liquidityDepthPlus = 0
        if (
          'liquidityUsdDepthPlus' in pool &&
          'liquidityUsdDepthMinus' in pool
        ) {
          liquidityDepthMinus = pool.liquidityUsdDepthMinus
          liquidityDepthPlus = pool.liquidityUsdDepthPlus
        }
        return {
          binStep,
          currency0: pool.tokenX.address,
          currency0Decimals: pool.tokenX.decimals,
          currency1: pool.tokenY.address,
          currency1Decimals: pool.tokenY.decimals,
          ids: [],
          liquidityDepthMinus,
          liquidityDepthPlus,
          pairAddress: pool.pairAddress
        }
      })
  }, [pools])

  const { data: rewarderDataPerPairAddress } = useGetBatchLbRewarderData({
    pairs: lbPoolsV22
  })

  const lbPoolRewardAPRsResults = useGetLbPoolsRewardAPRs({
    calls: lbPoolsV22.map((pool) => {
      return {
        extraRewarderData:
          rewarderDataPerPairAddress?.[pool.pairAddress.toLowerCase()]
            ?.extraRewarderData,
        lbPairAddress: pool.pairAddress,
        liquidityDepthMinus: pool.liquidityDepthMinus,
        liquidityDepthPlus: pool.liquidityDepthPlus,
        rewarderData:
          rewarderDataPerPairAddress?.[pool.pairAddress.toLowerCase()]
            ?.rewarderData
      }
    })
  })

  const lbPoolsWithRewards = useMemo(() => {
    return pools.map((pool) => {
      const pairAddress = pool.pairAddress.toLowerCase()
      const rewarderData = rewarderDataPerPairAddress?.[pairAddress]
      const rewards = rewarderData?.rewards || []

      const rewardsAprData = lbPoolRewardAPRsResults.data.find(
        (result) =>
          result?.lbPairAddress.toLowerCase() === pairAddress.toLowerCase()
      )

      return {
        ...pool,
        lbRewards: rewards.map((reward) => ({
          ...reward,
          rewardsApr:
            reward.type === 'base'
              ? rewardsAprData?.rewarderAprData?.apr
              : rewardsAprData?.extraRewarderAprData?.apr
        }))
      }
    })
  }, [pools, rewarderDataPerPairAddress, lbPoolRewardAPRsResults])

  return { isLoading: lbPoolRewardAPRsResults.isLoading, lbPoolsWithRewards }
}

export default useLbPoolsWithRewards
