import { Box, useColorMode } from '@chakra-ui/react'
import { Currency } from '@traderjoe-xyz/sdk-core'
import {
  Bar,
  ChartingLibraryWidgetOptions,
  DatafeedConfiguration,
  ResolutionString,
  widget
} from 'charting_library'
import useChainId from 'hooks/useChainId'
import React, { useEffect, useRef, useState } from 'react'

import CandleChartHeader from './CandleChartHeader'
import { getCandlestickData } from './candles'
import { getAddress } from './helpers'

const configurationData: DatafeedConfiguration = {
  supported_resolutions: [
    '1',
    '5',
    '15',
    '60',
    '240',
    '720',
    '1D'
  ] as ResolutionString[]
}

interface TradingViewChartProps {
  inputCurrency: Currency
  outputCurrency: Currency
}

const TradingViewChart = ({
  inputCurrency,
  outputCurrency
}: TradingViewChartProps) => {
  const chainId = useChainId()
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  const [latestPrice, setLatestPrice] = useState<number | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [baseCurrency, setBaseCurrency] = useState(inputCurrency)
  const [quoteCurrency, setQuoteCurrency] = useState(outputCurrency)
  const [isInverted, setIsInverted] = useState(false)

  useEffect(() => {
    setBaseCurrency(inputCurrency)
    setQuoteCurrency(outputCurrency)
    setLatestPrice(undefined)
  }, [inputCurrency, outputCurrency])

  useEffect(() => {
    if (chartContainerRef.current === null) return

    const datafeed = {
      getBars: async (
        symbolInfo: any,
        resolution: any,
        periodParams: any,
        onHistoryCallback: any,
        onErrorCallback: any
      ) => {
        const { firstDataRequest, from, to } = periodParams

        try {
          setIsLoading(true)

          const data = await getCandlestickData({
            baseCurrency: inputCurrency,
            chainId: chainId,
            firstDataRequest,
            from,
            isInverted: isInverted,
            quoteCurrency: outputCurrency,
            resolution: resolution as any,
            to
          })

          if (!data.length) {
            onHistoryCallback([], { noData: true })
            return
          }

          const baseTokenAddress = data[0].baseAsset
          const quoteTokenAddress = data[0].quoteAsset

          const baseCurrency =
            getAddress(chainId, inputCurrency)?.toLowerCase() ===
            baseTokenAddress?.toLowerCase()
              ? inputCurrency
              : outputCurrency

          const quoteCurrency =
            getAddress(chainId, inputCurrency)?.toLowerCase() ===
            quoteTokenAddress?.toLowerCase()
              ? inputCurrency
              : outputCurrency

          setBaseCurrency(baseCurrency)
          setQuoteCurrency(quoteCurrency)

          const bars: Bar[] = data.map((bar) => ({
            close: bar.close,
            high: bar.high,
            low: bar.low,
            open: bar.open,
            time: bar.timestamp * 1000
          }))

          setLatestPrice(bars[bars.length - 1].close)

          onHistoryCallback(bars, { noData: false })
        } catch (error) {
          console.error('[getBars]: Get error', error)
          onErrorCallback(error)
        } finally {
          setIsLoading(false)
        }
      },
      onReady: (callback: any) => {
        setTimeout(() => callback(configurationData), 0)
      },
      resolveSymbol: (symbolName: string, onSymbolResolvedCallback: any) => {
        const symbolInfo = {
          has_daily: true,
          has_intraday: true,
          has_no_volume: false,
          minmov: 1,
          name: symbolName,
          pricescale: 100000,
          session: '24x7',
          supported_resolutions: configurationData.supported_resolutions,
          ticker: symbolName,
          timezone: 'Etc/UTC'
        }

        onSymbolResolvedCallback(symbolInfo)
      },
      searchSymbols: () => {},
      subscribeBars: () => {},
      unsubscribeBars: () => {}
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      autosize: true,
      charts_storage_api_version: '1.1',
      charts_storage_url: 'https://saveload.tradingview.com',
      client_id: 'traderjoexyz.com',
      container: chartContainerRef.current,
      datafeed,
      debug: process.env.NODE_ENV === 'development' ? true : false,
      disabled_features: [
        'use_localstorage_for_settings',
        'popup_hints',
        'symbol_search_hot_key',
        'header_symbol_search',
        'header_compare',
        'chart_template_storage',
        'header_saveload'
      ],
      fullscreen: false,
      interval: '1D' as ResolutionString,
      library_path: '/charting_library/',
      locale: 'en',
      studies_overrides: {},
      symbol: `${baseCurrency.symbol}/${quoteCurrency.symbol}`,
      theme: isDark ? 'dark' : 'light'
    }

    const tvWidget = new widget(widgetOptions)

    return () => {
      tvWidget.remove()
    }
  }, [
    isDark,
    quoteCurrency,
    baseCurrency,
    chainId,
    inputCurrency,
    outputCurrency,
    isInverted
  ])

  return (
    <Box w="full">
      <CandleChartHeader
        inputCurrency={baseCurrency}
        outputCurrency={quoteCurrency}
        latestPrice={latestPrice}
        isLoading={isLoading}
        toggleInverted={() => {
          const newBase = quoteCurrency
          const newQuote = baseCurrency
          setBaseCurrency(newBase)
          setQuoteCurrency(newQuote)
          setIsInverted(!isInverted)
        }}
      />
      <Box
        ref={chartContainerRef}
        className="TradingViewChart"
        width="full"
        h={{ base: '400px', md: '600px' }}
      />
    </Box>
  )
}

export default TradingViewChart
