import { Button } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useAddToken, useRemoveToken, useSavedTokens } from 'state/tokens/hooks'
import { LocalToken } from 'state/tokens/reducer'
import { TokenInfo } from 'types/tokensList'

interface SaveTokenButtonProps {
  token: TokenInfo
}

const SaveTokenButton = ({ token }: SaveTokenButtonProps) => {
  const { addTokenToSaved } = useAddToken()
  const { removeTokenFromSaved } = useRemoveToken()
  const savedTokens = useSavedTokens()
  const isTokenSaved = savedTokens.some(
    (_token) => _token.address === token.address
  )

  const onAddTokenToListClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const localToken: LocalToken = {
      address: token.address,
      chainId: token.chainId,
      decimals: token.decimals,
      name: token.name,
      symbol: token.symbol
    }
    isTokenSaved && token.address
      ? removeTokenFromSaved(token.address)
      : addTokenToSaved(localToken)
  }

  return (
    <Button
      size="sm"
      variant="link"
      fontWeight="normal"
      color="textSecondary"
      onClick={onAddTokenToListClick}
    >
      {isTokenSaved ? (
        <Trans>Remove from list</Trans>
      ) : (
        <Trans>Add to list</Trans>
      )}
    </Button>
  )
}

export default SaveTokenButton
