import { Box, Flex, Text } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { CNATIVE, Currency } from '@traderjoe-xyz/sdk-core'
import { LIMIT_ORDER_MANAGER_ADDRESS } from '@traderjoe-xyz/sdk-v2'
import ApproveTokenButton from 'components/ApproveTokenButton'
import MaxButton from 'components/MaxButton'
import Web3Button from 'components/Web3Button'
import useGetOrders from 'hooks/limitOrder/useGetOrders'
import usePlaceLimitOrder from 'hooks/limitOrder/usePlaceLimitOrder'
import usePrepareLimitOrder from 'hooks/limitOrder/usePrepareLimitOrder'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, { useState } from 'react'
import { OrderType } from 'types/limitOrder'
import { getCurrencyAddress, wrappedCurrency } from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'

import TradeCurrencyInputs from '../TradeCurrencyInputs'
import OrdersList, { TabOption } from './OrdersList'
import PlaceOrderPriceInput from './PlaceOrderPriceInput'

interface PlaceOrderProps {
  handleSetCurrency: (currency: Currency, isInput: boolean) => void
  inputCurrency: Currency | undefined
  onChangeSwapDirectionClick: () => void
  outputCurrency: Currency | undefined
}

const PlaceOrder = ({
  handleSetCurrency,
  inputCurrency,
  onChangeSwapDirectionClick,
  outputCurrency
}: PlaceOrderProps) => {
  const { isConnected } = useAccount()
  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)
  const isPlaceOrdersEnabled = false

  const wrappedInputCurrency = wrappedCurrency(inputCurrency, chainId)
  const wrappedOutputCurrency = wrappedCurrency(outputCurrency, chainId)

  const {
    amount: amountIn,
    amountBN: amountInBN,
    setAmount: setAmountIn
  } = useCurrencyInputAmount({
    currency: inputCurrency
  })
  const [amountOut, setAmountOut] = useState<string>('')

  const {
    activeId,
    activePrice,
    arePairTokensInversed,
    binStep,
    inputBinId,
    isLoadingAllLBPairs,
    isPriceRatioInversed,
    lbPairAddr,
    priceXY,
    priceYX,
    resetToMarketPrice,
    setTypedPrice,
    togglePriceRatio,
    tokenX,
    tokenY,
    typedPrice,
    updateInputBinIdFromPrice
  } = usePrepareLimitOrder({
    tokenIn: wrappedInputCurrency,
    tokenOut: wrappedOutputCurrency
  })

  const [activeTab, setActiveTab] = useState<TabOption>(TabOption.ACTIVE)
  const {
    fetchNextPage: fetchNextPageOrders,
    hasNextPage: hasNextPageOrders,
    isFetchingNextPage: isFetchingNextPageOrders,
    isLoading: isLoadingOrders,
    ordersByStatus,
    refetch: refetchOrders
  } = useGetOrders({
    status: activeTab === TabOption.ACTIVE ? 'placed' : 'completed'
  })
  const debouncedRefetchOrders = debounce(() => refetchOrders(), 3000)

  const isIncorrectPrice =
    activeId && inputBinId
      ? arePairTokensInversed
        ? activeId <= inputBinId
        : activeId >= inputBinId
      : false

  // update amount out when input amount changes
  const updateAmountsOnInputAmountChange = (typedValue: string) => {
    setAmountIn(typedValue)

    const val = Number(typedValue)
    if (val === 0 || isNaN(val)) {
      setAmountOut('')
      return
    }

    const price = arePairTokensInversed ? priceYX : priceXY
    setAmountOut((val * price).toString())
  }

  // update price when output amount changes
  const updatePriceOnOutputAmountChange = (typedValue: string) => {
    setAmountOut(typedValue)

    const val = Number(typedValue)
    if (val === 0 || isNaN(val)) {
      setTypedPrice('')
      return
    }

    if (!amountIn) {
      return
    }

    // update input bin id
    const price = Number(typedValue) / Number(amountIn)
    const { binId, priceXY, priceYX } = updateInputBinIdFromPrice(price) || {}

    if (!binId || !priceXY || !priceYX) {
      return
    }

    // update typed price
    const isInversed =
      (arePairTokensInversed && !isPriceRatioInversed) ||
      (!arePairTokensInversed && isPriceRatioInversed)
    setTypedPrice(isInversed ? priceYX.toString() : priceXY.toString())
  }

  // update output amount when price changes
  const updateOutputAmountOnChangePrice = (typedValue: string) => {
    setTypedPrice(typedValue)

    const val = isPriceRatioInversed
      ? 1 / Number(typedValue)
      : Number(typedValue)

    if (!tokenX || !tokenY || !binStep || val < 0) {
      return
    }

    if (val === 0 || isNaN(val) || !isFinite(val)) {
      setAmountOut('')
      return
    }

    // update input bin id
    const { binId, priceXY, priceYX } = updateInputBinIdFromPrice(val) || {}

    if (!binId || !priceXY || !priceYX) {
      return
    }

    // update amount out
    const price = arePairTokensInversed ? priceYX : priceXY
    setAmountOut((Number(amountIn) * Number(price)).toString())
  }

  // reset state to default
  const resetInputsToDefault = () => {
    setAmountIn('')
    setAmountOut('')
  }

  // change swap direction
  const changeSwapDirection = () => {
    onChangeSwapDirectionClick()
    resetInputsToDefault()
  }

  // input currency balance
  const { data: inputCurrencyBalance, refetch: refetchBalance } =
    useTokenBalance({
      token: inputCurrency?.isToken ? inputCurrency.address : undefined
    })
  const debouncedRefetchBalance = debounce(() => refetchBalance(), 2000)

  // Approval
  const {
    approvalType,
    approve,
    isApproved,
    isApproving,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: amountInBN,
    spender: LIMIT_ORDER_MANAGER_ADDRESS[chainId],
    token: getCurrencyAddress(inputCurrency),
    tokenSymbol: inputCurrency?.symbol
  })

  // Refresh state on successful place order
  const onPlaceOrderSuccess = () => {
    resetInputsToDefault()
    resetApproval()
    debouncedRefetchBalance()
    debouncedRefetchOrders()
  }

  const isExceedingBalance = inputCurrencyBalance
    ? Number(inputCurrencyBalance.formatted) < Number(amountIn)
    : false

  const isPairNotFound =
    !!inputCurrency && !!outputCurrency && !lbPairAddr && !isLoadingAllLBPairs

  const isUSDCSelected =
    inputCurrency?.symbol === 'USDC' || outputCurrency?.symbol === 'USDC'
  const errorMessage = isPairNotFound
    ? t`Try pairing with ${nativeCurrency.symbol}${
        isUSDCSelected ? '' : ' or USDC'
      }.`
    : undefined

  // Place Order
  const { isLoading, placeOrder } = usePlaceLimitOrder({
    amount: amountInBN,
    binId: inputBinId,
    binStep,
    isApproved: isApproved || (inputCurrency?.isNative ?? false),
    isDisabled:
      isIncorrectPrice ||
      isExceedingBalance ||
      (!isApproved && !inputCurrency?.isNative) ||
      !isPlaceOrdersEnabled,
    isInputNative: inputCurrency?.isNative,
    onPlaceOrderSuccess,
    orderType:
      !activeId || !inputBinId || activeId === inputBinId
        ? undefined
        : inputBinId < activeId
          ? OrderType.BID
          : OrderType.ASK,
    tokenX,
    tokenY
  })

  return (
    <Flex flexDir="column" gap={{ base: 4, md: 8 }}>
      <TradeCurrencyInputs
        inputCurrencyProps={{
          balance: inputCurrencyBalance?.formatted,
          currency: inputCurrency,
          currencyAddress: inputCurrency?.isToken
            ? inputCurrency.address
            : undefined,
          heading: t`You Pay ${
            inputCurrency ? `(${inputCurrency.symbol})` : ''
          }`,
          isDisabled: !isPlaceOrdersEnabled,
          onPairSelected: (pair) => {
            handleSetCurrency(pair.tokenX, true)
            handleSetCurrency(pair.tokenY, false)
            setAmountIn('')
            setAmountOut('')
          },
          onValueChange: updateAmountsOnInputAmountChange,
          rightElement: inputCurrencyBalance ? (
            <MaxButton
              isDisabled={!isPlaceOrdersEnabled}
              balance={inputCurrencyBalance.formatted}
              onClick={() => {
                updateAmountsOnInputAmountChange(inputCurrencyBalance.formatted)
              }}
            />
          ) : undefined,
          value: amountIn
        }}
        outputCurrencyProps={{
          currency: outputCurrency,
          currencyAddress: outputCurrency?.isToken
            ? outputCurrency.address
            : undefined,
          heading: t`You Receive ${
            outputCurrency ? `(${outputCurrency.symbol})` : ''
          }`,
          isDisabled: !isPlaceOrdersEnabled,
          onPairSelected: (pair) => {
            handleSetCurrency(pair.tokenX, true)
            handleSetCurrency(pair.tokenY, false)
            setAmountIn('')
            setAmountOut('')
          },
          onValueChange: updatePriceOnOutputAmountChange,
          value: amountOut
        }}
        isChangeSwapDirectionDisabled={!isPlaceOrdersEnabled}
        onChangeSwapDirectionClick={changeSwapDirection}
        bottomContent={
          <Flex flexDir="column" w="full" gap={{ base: 4, md: 8 }}>
            <PlaceOrderPriceInput
              isDisabled={!isPlaceOrdersEnabled}
              inputCurrency={inputCurrency}
              outputCurrency={outputCurrency}
              typedPrice={typedPrice}
              isPriceRatioInversed={isPriceRatioInversed}
              arePairTokensInversed={arePairTokensInversed}
              activePrice={activePrice}
              tokenX={tokenX}
              tokenY={tokenY}
              onValueChange={updateOutputAmountOnChangePrice}
              onTogglePriceRatio={togglePriceRatio}
              onResetPriceClick={() => {
                const marketPrice = resetToMarketPrice()
                updateOutputAmountOnChangePrice(marketPrice ?? '')
              }}
              isIncorrectPrice={isIncorrectPrice}
            />
            <Box borderRadius="full" h="2px" bg="bgTertiary" w="full" />
            <Flex flexDir="column" gap={4}>
              {approve && !isApproved && !isExceedingBalance ? (
                <ApproveTokenButton
                  data-cy="place-order-approve-button"
                  amount={amountInBN?.toString()}
                  currencySymbol={inputCurrency?.symbol}
                  approvalType={approvalType}
                  onApprovalTypeSelect={setApprovalType}
                  isLoading={isApproving}
                  onClick={() => approve()}
                />
              ) : null}
              <Web3Button
                data-cy="place-order-button"
                variant="primary"
                colorScheme="accent"
                w="full"
                size="xl"
                isLoading={isLoading}
                loadingText={t`Placing order`}
                isDisabled={!placeOrder || isExceedingBalance}
                onClick={() => {
                  placeOrder?.()
                }}
              >
                {!isPlaceOrdersEnabled ? (
                  <Trans>New orders are disabled</Trans>
                ) : isPairNotFound ? (
                  <Trans>Pair not found</Trans>
                ) : isExceedingBalance ? (
                  <Trans>Not enough {inputCurrency?.symbol}</Trans>
                ) : isIncorrectPrice ? (
                  <Trans>Invalid price</Trans>
                ) : (
                  <Trans>Place Order</Trans>
                )}
              </Web3Button>
              {isPairNotFound ? (
                <Text textColor="red.500" textAlign="center">
                  {errorMessage}
                </Text>
              ) : null}
            </Flex>
          </Flex>
        }
      />
      {isConnected ? (
        <OrdersList
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeOrders={ordersByStatus.placed}
          completedOrders={[
            ...ordersByStatus.executed,
            ...ordersByStatus.claimed,
            ...ordersByStatus.cancelled
          ]}
          isLoadingOrders={isLoadingOrders}
          refetchOrders={debouncedRefetchOrders}
          onLoadMoreClick={() => {
            fetchNextPageOrders()
          }}
          isFetchingNextPage={isFetchingNextPageOrders}
          hasNextPage={hasNextPageOrders}
        />
      ) : null}
    </Flex>
  )
}

export default PlaceOrder
