import { useQuery } from '@tanstack/react-query'
import { OrderablePair } from 'types/limitOrder'

const useFetchOrderableList = (
  { enabled }: { enabled: boolean } = { enabled: true }
) => {
  const url =
    'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/orderable.json'

  return useQuery<{ pairs: OrderablePair[] }, Error>({
    enabled,
    queryFn: () => fetch(url).then((res) => res.json()),
    queryKey: ['FetchOrderableListQuery'],
    staleTime: 10 * 60 * 1000
  })
}

export default useFetchOrderableList
