import { HStack, LinkBox, LinkOverlay, Td, Text, Tr } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import useChainId from 'hooks/useChainId'
import PoolTableNameColumn from 'pages/Pool/PoolTableNameColumn'
import React from 'react'
import { LBPool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailV2Url } from 'utils/poolUrl'

interface MemeFarmRowProps {
  lbPool: LBPool
}

const MemeFarmRow = ({ lbPool }: MemeFarmRowProps) => {
  const chainId = useChainId()
  const href = getPoolDetailV2Url(
    lbPool.lbPoolVersion,
    lbPool.lbBinStep,
    chainId,
    lbPool.tokenX.address,
    lbPool.tokenY.address
  )

  const fmtRewardsApr = `${(
    (lbPool.lbRewards?.reduce((acc, reward) => {
      return acc + (reward.rewardsApr || 0)
    }, 0) || 0) * 100
  ).toFixed(2)}%`

  return (
    <LinkBox
      as={Tr}
      transform="scale(1)"
      cursor="pointer"
      _hover={{ bg: 'hover' }}
    >
      <Td>
        <LinkOverlay
          aria-label={`Link to pool page for ${lbPool.tokenX.symbol}/${lbPool.tokenY.symbol}`}
          href={href}
        />
        <PoolTableNameColumn
          token0={lbPool.tokenX}
          token1={lbPool.tokenY}
          fees={`${lbPool.feePct}%`}
        />
      </Td>
      <Td isNumeric>
        <Text>{formattedNum(lbPool.liquidityUsd, { usd: true })}</Text>
      </Td>
      <Td isNumeric>
        <HStack justify="flex-end">
          {lbPool.lbRewards?.map((reward) => (
            <HStack key={reward.token.symbol}>
              <CurrencyLogo
                address={reward.token.isToken ? reward.token.address : ''}
                symbol={reward.token.symbol}
                boxSize={5}
              />
              <Text>{`${formattedNum(reward.rewardsPerDay, {
                places: 2
              })} ${reward.token.symbol}`}</Text>
            </HStack>
          ))}
        </HStack>
      </Td>
      <Td isNumeric>
        <Text>{fmtRewardsApr}</Text>
      </Td>
    </LinkBox>
  )
}

export default MemeFarmRow
