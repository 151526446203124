import { useQuery } from '@tanstack/react-query'
import {
  DexbarnPoolV1,
  Pool as DexbarnPool,
  PoolQueryParam
} from 'types/dexbarn'
import { LBPool, Pool } from 'types/pool'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolV1ToPool } from 'utils/poolV1'
import { convertDexbarnPoolToPool } from 'utils/poolV2'

import useChainId from './useChainId'
import useDebounce from './useDebounce'
import { useDexbarnGet } from './useDexbarn'
import useEpochRewards from './useEpochRewards'

interface UsePairsSearchProps {
  query: string
  enabled?: boolean
  filterBy?: PoolQueryParam.FilterBy
  includesPoolV1?: boolean
  orderBy?: PoolQueryParam.OrderBy
  status?: PoolQueryParam.Status
}

const usePairsSearch = ({
  enabled = true,
  filterBy = '1d',
  includesPoolV1 = true,
  orderBy = 'liquidity',
  query,
  status = 'all'
}: UsePairsSearchProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const debouncedQuery = useDebounce(query, 400)
  const terms = debouncedQuery.split(' ')
  let _query = terms[0].trim()

  const isQueryTwoCharacters = _query.length === 2
  if (isQueryTwoCharacters) {
    _query = _query + '-'
  }

  const _enabled = _query.length >= 3 && enabled

  const fetchClassicAMMPools = useDexbarnGet<DexbarnPoolV1[]>(
    `/v1/joev1/pools/search?query=${_query}&chain=${chain}&filterBy=${filterBy}&orderBy=${orderBy}`
  )
  const { data: poolsV1, isLoading: isLoadingV1 } = useQuery<DexbarnPoolV1[]>({
    enabled: _enabled,
    queryFn: () => fetchClassicAMMPools(),
    queryKey: ['ClassicAMMPoolsSearchQuery', chain, _query, filterBy, orderBy]
  })

  const fetchLbPairs = useDexbarnGet<DexbarnPool[]>(
    `/v1/pools/search?query=${_query}&chain=${chain}&status=${status}&filterBy=${filterBy}&orderBy=${orderBy}&excludeLowVolumePools=false`
  )
  const { data: poolsV2, isLoading: isLoadingV2 } = useQuery<DexbarnPool[]>({
    enabled: _enabled,
    queryFn: () => fetchLbPairs(),
    queryKey: ['LBPairsSearchV2Query', chain, _query, filterBy, orderBy, status]
  })
  const { epochRewards } = useEpochRewards()

  let pairs: (Pool | LBPool)[] = [
    ...(poolsV2?.map((pool) =>
      convertDexbarnPoolToPool(pool, epochRewards || [])
    ) ?? []),
    ...(poolsV1?.map((dexbarnPool) =>
      convertDexbarnPoolV1ToPool({ dexbarnPool })
    ) ?? [])
  ]

  const term2 = terms.length > 1 ? terms[1].trim().toLowerCase() : undefined
  if (term2) {
    pairs = pairs.filter(
      (pair) =>
        pair.name.toLowerCase().includes(term2) ||
        pair.name.toLowerCase().includes(term2)
    )
  }

  const processedPairs = pairs.sort((a, b) => {
    switch (orderBy) {
      case 'liquidity':
        return b.liquidityUsd - a.liquidityUsd
      case 'volume':
        return b.volumeUsd - a.volumeUsd
      case 'name':
        return a.name.localeCompare(b.name)
    }
  })

  return {
    data: processedPairs,
    isLoading: ((isLoadingV1 && includesPoolV1) || isLoadingV2) && _enabled
  }
}

export default usePairsSearch
