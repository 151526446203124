import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import CurrencyInput from 'components/CurrencyInput'
import Web3Button from 'components/Web3Button'
import { useSJoeWithdraw } from 'hooks/stake/useSJoeStaking'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React from 'react'
import { unstakeSJoe } from 'utils/measure'
import { formatEther } from 'viem'

interface SJoeUnstakePanelProps {
  onUnstakeSuccess: () => void
  joeToken?: Token
  sJoeBalance?: bigint
}

const SJoeUnstakePanel = ({
  joeToken,
  onUnstakeSuccess,
  sJoeBalance
}: SJoeUnstakePanelProps) => {
  // amount
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: joeToken
  })

  // get sJOE userBalance
  const fmtBalance = sJoeBalance ? formatEther(sJoeBalance) : undefined

  const { isLoading, withdraw } = useSJoeWithdraw({
    amount: amountBN,
    onSuccess: () => {
      setAmount('')
      onUnstakeSuccess()
    }
  })

  const isExceedingBalance =
    amountBN && sJoeBalance ? sJoeBalance < amountBN : false

  return (
    <VStack align="flex-start" spacing={4}>
      <CurrencyInput
        data-cy="sjoe-unstake-input"
        currency={joeToken}
        currencyAddress={joeToken?.address}
        value={amount}
        onValueChange={(value) => {
          setAmount(value)
        }}
        balance={fmtBalance}
        error={isExceedingBalance ? t`Exceeded your sJOE balance` : undefined}
      />
      <Web3Button
        data-cy="sjoe-unstake-button"
        colorScheme="accent"
        variant="primary"
        size="xl"
        w="full"
        isDisabled={isExceedingBalance || !withdraw}
        isLoading={isLoading}
        loadingText={t`Unstaking Tokens`}
        onClick={() => {
          withdraw?.()
          unstakeSJoe()
        }}
      >
        <Trans>Unstake Tokens</Trans>
      </Web3Button>
    </VStack>
  )
}

export default SJoeUnstakePanel
