import { Token } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import { wrappedCurrency } from 'utils/wrappedCurrency'

import useOrderableList from './useOrderableList'

const useIsPairOrderable = (tokenX?: Token, tokenY?: Token) => {
  const chainId = useChainId()
  const orderablePairs = useOrderableList()

  const isOrderable =
    orderablePairs.length > 0 &&
    orderablePairs.some(
      (pair) =>
        (wrappedCurrency(pair.tokenX, chainId)?.address === tokenX?.address &&
          wrappedCurrency(pair.tokenY, chainId)?.address === tokenY?.address) ||
        (wrappedCurrency(pair.tokenY, chainId)?.address === tokenX?.address &&
          wrappedCurrency(pair.tokenX, chainId)?.address === tokenY?.address)
    )

  return isOrderable
}

export default useIsPairOrderable
