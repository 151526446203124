import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { ROUTER_ADDRESS, RouterABI } from '@traderjoe-xyz/sdk'
import { Currency } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import useGetTransactionDeadline from 'hooks/useGetTransactionDeadline'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useMemo } from 'react'
import { useSlippageSettings } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { getCurrencyAddress } from 'utils/wrappedCurrency'
import { formatUnits, getAddress, TransactionReceipt } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseRemoveLiquidityProps {
  token0: Currency
  token1: Currency
  amountDesired0?: bigint
  amountDesired1?: bigint
  enabled?: boolean
  onSuccess?: (data: TransactionReceipt) => void
  poolTokenAmount?: bigint
}

const useRemoveLiquidity = ({
  amountDesired0,
  amountDesired1,
  enabled,
  onSuccess,
  poolTokenAmount,
  token0,
  token1
}: UseRemoveLiquidityProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()
  const getTransactionDeadline = useGetTransactionDeadline()

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const {
    slippageSettings: { v1: slippage }
  } = useSlippageSettings()
  const amountMin0 = amountDesired0
    ? (amountDesired0 * BigInt(10000 - slippage * 100)) / BigInt(10000)
    : undefined
  const amountMin1 = amountDesired1
    ? (amountDesired1 * BigInt(10000 - slippage * 100)) / BigInt(10000)
    : undefined

  const oneCurrencyIsNative = token0.isNative || token1.isNative

  const removeLiquidityAvaxArgs = useMemo(() => {
    const transactionDeadline = getTransactionDeadline()
    if (
      !transactionDeadline ||
      !poolTokenAmount ||
      !account ||
      !amountMin0 ||
      !amountMin1
    ) {
      return undefined
    }
    const address0 = getCurrencyAddress(token0)
    const address1 = getCurrencyAddress(token1)
    return [
      token0.isNative ? address1! : address0!,
      poolTokenAmount,
      token0.isNative ? amountMin1 : amountMin0,
      token0.isNative ? amountMin0 : amountMin1,
      account,
      transactionDeadline
    ] as const
  }, [
    token0,
    token1,
    poolTokenAmount,
    amountMin0,
    amountMin1,
    account,
    getTransactionDeadline
  ])

  const removeLiquidityArgs = useMemo(() => {
    const transactionDeadline = getTransactionDeadline()
    if (
      !transactionDeadline ||
      !poolTokenAmount ||
      !account ||
      !amountMin0 ||
      !amountMin1
    ) {
      return undefined
    }
    const address0 = getCurrencyAddress(token0)
    const address1 = getCurrencyAddress(token1)
    if (!address0 || !address1) return undefined
    return [
      address0,
      address1,
      poolTokenAmount,
      amountMin0,
      amountMin1,
      account,
      transactionDeadline
    ] as const
  }, [
    token0,
    token1,
    poolTokenAmount,
    amountMin0,
    amountMin1,
    account,
    getTransactionDeadline
  ])

  const _enabled =
    !!removeLiquidityArgs && !!poolTokenAmount && poolTokenAmount > 0 && enabled

  const { data: removeLiquidityAvaxConfig } = useSimulateContract({
    abi: RouterABI,
    address: getAddress(ROUTER_ADDRESS[chainId]),
    args: removeLiquidityAvaxArgs,
    functionName: 'removeLiquidityAVAX',
    query: { enabled: _enabled && oneCurrencyIsNative, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data: removeLiquidityConfig } = useSimulateContract({
    abi: RouterABI,
    address: getAddress(ROUTER_ADDRESS[chainId]),
    args: removeLiquidityArgs,
    functionName: 'removeLiquidity',
    query: { enabled: _enabled && !oneCurrencyIsNative, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        if (!amountDesired0 || !amountDesired1) return
        const description = t`Remove ${formattedNum(
          formatUnits(amountDesired0, token0.decimals)
        )} ${token0.symbol} and ${formattedNum(
          formatUnits(amountDesired1, token1.decimals)
        )} ${token1.symbol}`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  const removeLiquidity = oneCurrencyIsNative
    ? removeLiquidityAvaxConfig?.request
      ? () => writeContract(removeLiquidityAvaxConfig.request)
      : undefined
    : removeLiquidityConfig?.request
      ? () => writeContract(removeLiquidityConfig.request)
      : undefined

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    hash: data,
    onTransactionSuccess: onSuccess
  })

  return { isLoading: isPending || isWaitingForTransaction, removeLiquidity }
}

export default useRemoveLiquidity
