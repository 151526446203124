import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { VaultABI } from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { getAddress } from 'viem'
import { useWriteContract } from 'wagmi'

interface UseEmergencyWithdrawProps {
  vaultAddress: string
  onSuccess?: () => void
}

const useEmergencyWithdraw = ({
  onSuccess,
  vaultAddress
}: UseEmergencyWithdrawProps) => {
  const chainId = useChainId()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Withdraw from auto pool`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const withdraw = () =>
    writeContract({
      abi: VaultABI,
      address: getAddress(vaultAddress),
      chainId,
      functionName: 'emergencyWithdraw'
    })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash: data,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    withdraw
  }
}

export default useEmergencyWithdraw
