import {
  Box,
  Flex,
  Grid,
  Heading,
  Hide,
  HStack,
  Image,
  ListItem,
  Show,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useToken,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { JOE_ADDRESS, SJOE_REWARD_TOKEN } from '@traderjoe-xyz/sdk'
import { Token } from '@traderjoe-xyz/sdk-core'
import sJoeImage from 'assets/stake/sJoe.webp'
import AnalyticsChart from 'components/AnalyticsChart'
import AnalyticsStat from 'components/AnalyticsStat'
import BackButton from 'components/BackButton'
import PageHelmet from 'components/PageHelmet'
import { STAKE_HELMET_DESCRIPTION, STAKE_HELMET_TITLE } from 'constants/stake'
import useSJoeAnalytics, {
  useSJoePayouts,
  useSJoeRemittances
} from 'hooks/stake/useSJoeAnalytics'
import { useSJoeStaking } from 'hooks/stake/useSJoeStaking'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import debounce from 'lodash.debounce'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { formattedNum } from 'utils/format'
import { calculateSJoeMetrics } from 'utils/stake'
import { formatEther } from 'viem'

import RemittancesTable from './RemittancesTable'
import SJoeClaimPanel from './SJoeClaimPanel'
import SJoeStakePanel from './SJoeStakePanel'
import SJoeUnstakePanel from './SJoeUnstakePanel'

const SJoeDetail = () => {
  const [graphPurple] = useToken('colors', ['graphPurpleDark'])
  const location = useLocation()
  const navigate = useNavigate()
  const chainId = useChainId()

  // get sjoe info
  const {
    depositFee,
    isLoading,
    pendingRewards,
    refetch: refetchSJoeInfo,
    sJoeUserBalance,
    totalStaked
  } = useSJoeStaking()
  const debouncedRefetchSJoeInfo = debounce(() => refetchSJoeInfo(), 4000)

  // get metrics
  const payoutDayDatas = useSJoePayouts() // last 7 day payouts
  const analyticsDatas = useSJoeAnalytics() // get tvl chart
  const remittanceDatas = useSJoeRemittances() // get remittance table

  // get usd price and amounts
  const joeTokenAddress = JOE_ADDRESS[chainId]
  const rewardTokenAddress = SJOE_REWARD_TOKEN[chainId]
  const { data } = useTokenPriceUSD({
    tokens: [joeTokenAddress, rewardTokenAddress]
  })
  const joeUSD = data?.[0]
  const rewardTokenUSD = data?.[1]

  // get tokens
  const { isLoading: isLoadingCurrencies, tokens } = useSdkCurrencies({
    addresses: [joeTokenAddress, rewardTokenAddress]
  })
  const joeToken = tokens?.[0] as Token | undefined
  const rewardToken = tokens?.[1] as Token | undefined

  // calculate metrics
  const metrics = calculateSJoeMetrics(totalStaked, joeUSD, payoutDayDatas)

  // format metrics
  const fmtApr30D = metrics
    ? `${formattedNum(metrics.apr30D * 100, { places: 2 })}%`
    : ''
  const fmtDepositFee =
    depositFee !== undefined
      ? `${formattedNum(Number(formatEther(depositFee)) * 100, { places: 2 })}%`
      : ''
  const fmtTotalStaked = totalStaked
    ? `${formattedNum(formatEther(totalStaked), { places: 0 })}`
    : ''

  return (
    <Box pt={4} w="full" bg="joeLight.400" _dark={{ bg: 'joeDark.600' }}>
      <PageHelmet
        description={STAKE_HELMET_DESCRIPTION}
        title={STAKE_HELMET_TITLE}
        url={location.pathname}
      />
      <Flex
        maxW={{ '2xl': '1600px', base: '1400px' }}
        margin="0 auto"
        flexDir="column"
        align="flex-start"
        gap={2}
        px={4}
        pos="relative"
        h="170px"
      >
        <BackButton
          title="Back"
          ml={-3}
          minH="24px"
          onClick={() => navigate('/stake')}
        />

        <HStack>
          <Image boxSize={12} src={sJoeImage} />
          <Heading fontSize="5xl">sJOE</Heading>
        </HStack>
        <Text color="textSecondary" fontSize="sm">
          <Trans>Rewards distributed every few days</Trans>
        </Text>
      </Flex>
      <Box bg="bgPrimary" maxWidth="100%">
        <Box px={4} maxW={{ '2xl': '1600px', base: '1400px' }} margin="0 auto">
          <Grid
            gap={{ base: 4, lg: 8 }}
            pb={24}
            templateColumns={{
              base: 'minmax(0, 1fr)',
              lg: 'minmax(0, 7fr) minmax(0, 5fr)'
            }}
          >
            <VStack spacing={8} id="vstack1">
              <VStack w="full" spacing={4}>
                <Flex align="center" justify="space-between" w="full" pt={10}>
                  <Heading size="md">
                    <Trans>Overview</Trans>
                  </Heading>
                </Flex>
                <SimpleGrid columns={{ lg: 3, sm: 1 }} gap={4} w="full">
                  <AnalyticsStat
                    title={t`Total Staked`}
                    value={fmtTotalStaked}
                    change={0}
                  />
                  <AnalyticsStat
                    title={t`APR (30D)`}
                    value={fmtApr30D}
                    change={0}
                  />
                  <AnalyticsStat
                    title={t`Deposit Fee`}
                    value={fmtDepositFee}
                    change={0}
                  />
                </SimpleGrid>
              </VStack>
              <Show below="md">
                <VStack w="full" spacing={4} id="vstack2">
                  <Tabs isFitted w="full" colorScheme="accent">
                    <TabList>
                      <Tab data-cy="sjoe-stake-tab">
                        <Trans>Stake</Trans>
                      </Tab>
                      <Tab data-cy="sjoe-unstake-tab">
                        <Trans>Unstake</Trans>
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <SJoeStakePanel
                          onStakeSuccess={() => debouncedRefetchSJoeInfo()}
                          joeToken={joeToken}
                        />
                      </TabPanel>
                      <TabPanel>
                        <SJoeUnstakePanel
                          sJoeBalance={sJoeUserBalance}
                          onUnstakeSuccess={() => debouncedRefetchSJoeInfo()}
                          joeToken={joeToken}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                  <SJoeClaimPanel
                    sJoeBalance={sJoeUserBalance}
                    isLoading={isLoading || isLoadingCurrencies}
                    joePriceUSD={joeUSD}
                    rewardTokenPriceUSD={rewardTokenUSD}
                    pendingRewards={pendingRewards}
                    joeToken={joeToken}
                    rewardToken={rewardToken}
                  />
                </VStack>
              </Show>
              <VStack align="flex-start" spacing={4} w="full">
                <Heading fontSize="xl">
                  <Trans>Stake Information</Trans>
                </Heading>
                <UnorderedList pl={4}>
                  <ListItem>
                    <Trans>
                      Claim your share of protocol revenue generated.
                    </Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>
                      A % fee is deducted from every swap and used to buy a
                      stablecoin which is distributed to all sJOE stakers.
                    </Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>
                      Rewards are distributed every few days, and you can
                      Harvest at any time.
                    </Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>
                      The APR (30D) metric shows an annualized return that is
                      forecasted, based on the revenue collected over the
                      previous thirty days.
                    </Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>
                      Deposit fee is deducted when you deposit your JOE tokens.
                      The deposit fee may be modified at any time.
                    </Trans>
                  </ListItem>
                </UnorderedList>
              </VStack>
              {analyticsDatas && (
                <Box w="full">
                  <AnalyticsChart
                    id="sJOE"
                    usdValue={false}
                    header={
                      <Heading mb={{ base: 0, lg: -6 }} fontSize="xl">
                        <Trans>Total Staked</Trans>
                      </Heading>
                    }
                    hideTab={true}
                    tooltipTitle={t`Total Staked (JOE)`}
                    data={analyticsDatas}
                    fill={graphPurple}
                    stroke="#894bf6"
                    h={{ base: '80px', md: '200px' }}
                  />
                </Box>
              )}
              {remittanceDatas && remittanceDatas.length > 0 && (
                <Box w="full">
                  <RemittancesTable remittanceDatas={remittanceDatas} />
                </Box>
              )}
            </VStack>
            <Hide below="md">
              <VStack spacing={4} id="vstack2">
                <Tabs isFitted w="full" colorScheme="accent">
                  <TabList pt={10}>
                    <Tab data-cy="sjoe-stake-tab">
                      <Trans>Stake</Trans>
                    </Tab>
                    <Tab data-cy="sjoe-unstake-tab">
                      <Trans>Unstake</Trans>
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <SJoeStakePanel
                        onStakeSuccess={() => debouncedRefetchSJoeInfo()}
                        joeToken={joeToken}
                      />
                    </TabPanel>
                    <TabPanel>
                      <SJoeUnstakePanel
                        sJoeBalance={sJoeUserBalance}
                        onUnstakeSuccess={() => debouncedRefetchSJoeInfo()}
                        joeToken={joeToken}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <SJoeClaimPanel
                  sJoeBalance={sJoeUserBalance}
                  isLoading={isLoading || isLoadingCurrencies}
                  joePriceUSD={joeUSD}
                  rewardTokenPriceUSD={rewardTokenUSD}
                  pendingRewards={pendingRewards}
                  joeToken={joeToken}
                  rewardToken={rewardToken}
                />
              </VStack>
            </Hide>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default SJoeDetail
