import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Hide,
  Image,
  Show,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import BoostPoolsImage from 'assets/home/boost_pools.png'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useLbPoolsWithRewards from 'hooks/pool/v2/useLbPoolsWithRewards'
import usePoolsV2 from 'hooks/pool/v2/usePoolsV2'
import useChainId from 'hooks/useChainId'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { LBPool } from 'types/pool'
import { getChainSlug } from 'utils/chains'

import BoostPoolMobileRow from './BoostPoolMobileRow'
import BoostPoolRow from './BoostPoolRow'

const BoostPoolsTable = () => {
  const navigate = useNavigate()
  const chainId = useChainId()

  const { data: lbPools, isLoading: isLoadingPools } = usePoolsV2({
    excludeLowVolumePools: false,
    orderBy: 'liquidity',
    pageSize: 100,
    status: 'main',
    version: 'v2.2'
  })

  const { isLoading: isLoadingRewards, lbPoolsWithRewards } =
    useLbPoolsWithRewards({
      pools: lbPools
    })

  const boostedPools = useMemo(() => {
    return lbPoolsWithRewards.filter(
      (pool) => pool.lbRewards.length > 0 && pool.points.includes('boost')
    ) as LBPool[]
  }, [lbPoolsWithRewards])

  const isLoading = isLoadingPools || isLoadingRewards

  if (!isLoading && boostedPools.length === 0) {
    return null
  }

  return (
    <Box
      w="full"
      bg="bgCard"
      borderRadius="2xl"
      border="1px solid"
      borderColor="border"
      _dark={{ border: 'none' }}
    >
      <Flex
        pos="relative"
        justify="space-between"
        bg="bgTertiary"
        borderRadius="2xl"
        zIndex={1}
        py={{ base: 4, md: 8 }}
        pl={{ base: '125px', md: '160px' }}
        pr={{ base: 2, md: 8 }}
      >
        <Image
          pos="absolute"
          top={{ base: 0, md: -10, sm: -2 }}
          left={{ base: -2, md: -6 }}
          src={BoostPoolsImage}
          w={{ base: '125px', md: '180px' }}
        />

        <VStack align="flex-start" spacing={1}>
          <Heading fontSize="2xl">
            <Trans>Boost Pools</Trans>
          </Heading>
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>Provide liquidity and earned AVAX incentives</Trans>
          </Text>
        </VStack>

        <Hide below="md">
          <Button
            bg="white"
            _hover={{ bg: 'joeLight.300' }}
            _dark={{ _hover: { bg: 'joeDark.600' }, bg: 'joeDark.700' }}
            size="lg"
            px={6}
            borderRadius="full"
            rightIcon={<ArrowForwardIcon />}
            onClick={() => navigate(`/${getChainSlug(chainId)}/pool`)}
          >
            <Trans>Visit Pools</Trans>
          </Button>
        </Hide>
      </Flex>

      <Box pt={{ base: 6, md: 8 }} pb={4}>
        {isLoading ? (
          <Center h="120px">
            <Spinner />
          </Center>
        ) : boostedPools && boostedPools.length > 0 ? (
          <>
            <Hide below="md">
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>
                        <Trans>Pool</Trans>
                      </Th>
                      <Th isNumeric>
                        <Trans>TVL</Trans>
                      </Th>
                      <Th isNumeric>
                        <Trans>Rewards / day</Trans>
                      </Th>
                      <Th isNumeric textDecor="dotted underline">
                        <TouchFriendlyTooltip label="The displayed APR shows the potential yield you could earn, depending on how well you manage your position. This reward APR is calculated from the reward dollar value relative to the liquidity within a +/-2% range projected over a year.">
                          <Trans>Rewards APR</Trans>
                        </TouchFriendlyTooltip>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {boostedPools.map((lbPool) => {
                      return (
                        <BoostPoolRow
                          key={lbPool.pairAddress}
                          lbPool={lbPool}
                        />
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Hide>

            <Show below="md">
              <VStack px={4} pb={2} spacing={6}>
                {boostedPools &&
                  boostedPools.map((lbPool, i) => (
                    <Box key={i} w="full">
                      <BoostPoolMobileRow lbPool={lbPool} />
                      {i < boostedPools.length - 1 && <Divider mt={4} />}
                    </Box>
                  ))}
              </VStack>
            </Show>
          </>
        ) : null}

        <Hide above="md">
          <Center pt={6} px={4}>
            <Button
              w="full"
              colorScheme="joeLight"
              textColor="textPrimary"
              _dark={{ _hover: { bg: 'joeDark.600' }, bg: 'joeDark.500' }}
              size="lg"
              borderRadius="full"
              rightIcon={<ArrowForwardIcon />}
              onClick={() => navigate(`/${getChainSlug(chainId)}/pool`)}
            >
              <Trans>Visit Pools</Trans>
            </Button>
          </Center>
        </Hide>
      </Box>
    </Box>
  )
}

export default BoostPoolsTable
