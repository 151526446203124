import { walletConnect } from 'utils/measure'
import { useAccountEffect } from 'wagmi'

const useOnWalletConnectListeners = () => {
  useAccountEffect({
    onConnect: ({ connector }) => {
      walletConnect(connector?.name)
    }
  })
}

export default useOnWalletConnectListeners
