import {
  ChainId,
  Currency,
  CurrencyAmount,
  Percent,
  Token,
  TokenAmount,
  WNATIVE
} from '@traderjoe-xyz/sdk-core'
import { PoolVersion, RouterPathParameters } from '@traderjoe-xyz/sdk-v2'
import JSBI from 'jsbi'
import { TradeBestPath } from 'types/trade'
import { getAddress, parseUnits } from 'viem'

type ChainTokenList = {
  readonly [chainId in Exclude<ChainId, ChainId.MANTLE>]: Token[]
}

export const USDTe = {
  [ChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0xAb231A5744C8E6c45481754928cCfFFFD4aa0732',
    6,
    'USDT.e',
    'Tether USD'
  ),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    6,
    'USDT.e',
    'Tether USD'
  )
}

export const USDT = {
  [ChainId.FUJI]: USDTe[ChainId.FUJI],
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    6,
    'USDT',
    'Tether USD'
  ),
  [ChainId.ARB_GOERLI]: new Token(
    ChainId.ARB_GOERLI,
    '0xf450749aeA1c5feF27Ae0237C56FecC43f6bE244',
    6,
    'USDT',
    'Tether USD'
  ),
  [ChainId.ARBITRUM_ONE]: new Token(
    ChainId.ARBITRUM_ONE,
    '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    6,
    'USDT',
    'Tether USD'
  ),
  [ChainId.BNB_CHAIN]: new Token(
    ChainId.BNB_CHAIN,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'Tether USD'
  ),
  [ChainId.BNB_TESTNET]: new Token(
    ChainId.BNB_TESTNET,
    '0x0F0D81E7a4a8b8c5295299785840887046C3810a',
    18,
    'USDT',
    'Tether USD'
  ),
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    6,
    'USDT',
    'Tether USD'
  )
}

export const USDCe = {
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    6,
    'USDC.e',
    'USD Coin'
  ),
  [ChainId.ARBITRUM_ONE]: new Token(
    ChainId.ARBITRUM_ONE,
    '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    6,
    'USDC.e',
    'USD Coin'
  )
}

export const USDC: { [chainId in Exclude<ChainId, ChainId.MANTLE>]: Token } = {
  [ChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0xB6076C93701D6a07266c31066B298AeC6dd65c2d',
    6,
    'USDC',
    'USD Coin'
  ),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    6,
    'USDC',
    'USD Coin'
  ),
  [ChainId.ARB_GOERLI]: new Token(
    ChainId.ARB_GOERLI,
    '0xb3482A25a12e5261b02E0acc5b96c656358a4086',
    6,
    'USDC',
    'USD Coin'
  ),
  [ChainId.ARBITRUM_ONE]: new Token(
    ChainId.ARBITRUM_ONE,
    '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    6,
    'USDC',
    'USD Coin'
  ),
  [ChainId.BNB_CHAIN]: new Token(
    ChainId.BNB_CHAIN,
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    18,
    'USDC',
    'USD Coin'
  ),
  [ChainId.BNB_TESTNET]: new Token(
    ChainId.BNB_TESTNET,
    '0xa3a886700f2157b0698AC5ccCAda09C3b02d41a1',
    18,
    'USDC',
    'USD Coin'
  ),
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    6,
    'USDC',
    'USD Coin'
  )
}

export const BUSD = {
  [ChainId.BNB_CHAIN]: new Token(
    ChainId.BNB_CHAIN,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD'
  ),
  [ChainId.BNB_TESTNET]: new Token(
    ChainId.BNB_TESTNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD'
  )
}

export const DAI = {
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    18,
    'DAI',
    'Dai Stablecoin'
  )
}

export const ARB = {
  [ChainId.ARBITRUM_ONE]: new Token(
    ChainId.ARBITRUM_ONE,
    '0x912CE59144191C1204E64559FE8253a0e49E6548',
    18,
    'ARB',
    'Arbitrum'
  )
}

export const WBTC = {
  [ChainId.ARBITRUM_ONE]: new Token(
    ChainId.ARBITRUM_ONE,
    '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    8,
    'WBTC',
    'Wrapped BTC'
  ),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0x50b7545627a5162F82A992c33b87aDc75187B218',
    8,
    'WBTC.e',
    'Wrapped BTC'
  )
}

export const BTCB = {
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
    8,
    'BTC.b',
    'Bitcoin'
  )
}

export const WETH = {
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    18,
    'WETH',
    'Wrapped ETH'
  )
}

const WNATIVE_ONLY: ChainTokenList = {
  [ChainId.FUJI]: [WNATIVE[ChainId.FUJI]],
  [ChainId.AVALANCHE]: [WNATIVE[ChainId.AVALANCHE]],
  [ChainId.ARBITRUM_ONE]: [WNATIVE[ChainId.ARBITRUM_ONE]],
  [ChainId.ARB_GOERLI]: [WNATIVE[ChainId.ARB_GOERLI]],
  [ChainId.BNB_CHAIN]: [WNATIVE[ChainId.BNB_CHAIN]],
  [ChainId.BNB_TESTNET]: [WNATIVE[ChainId.BNB_TESTNET]],
  [ChainId.ETHEREUM]: [WNATIVE[ChainId.ETHEREUM]]
}

export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.AVALANCHE]: [
    ...WNATIVE_ONLY[ChainId.AVALANCHE],
    USDCe[ChainId.AVALANCHE],
    USDC[ChainId.AVALANCHE],
    USDTe[ChainId.AVALANCHE],
    USDT[ChainId.AVALANCHE]
  ],
  [ChainId.FUJI]: [
    ...WNATIVE_ONLY[ChainId.FUJI],
    USDC[ChainId.FUJI],
    USDTe[ChainId.FUJI]
  ],
  [ChainId.ARB_GOERLI]: [
    ...WNATIVE_ONLY[ChainId.ARB_GOERLI],
    USDC[ChainId.ARB_GOERLI],
    USDT[ChainId.ARB_GOERLI]
  ],
  [ChainId.ARBITRUM_ONE]: [
    ...WNATIVE_ONLY[ChainId.ARBITRUM_ONE],
    USDCe[ChainId.ARBITRUM_ONE],
    USDC[ChainId.ARBITRUM_ONE],
    USDT[ChainId.ARBITRUM_ONE]
  ],
  [ChainId.BNB_CHAIN]: [
    ...WNATIVE_ONLY[ChainId.BNB_CHAIN],
    BUSD[ChainId.BNB_CHAIN],
    USDT[ChainId.BNB_CHAIN]
  ],
  [ChainId.BNB_TESTNET]: [
    ...WNATIVE_ONLY[ChainId.BNB_TESTNET],
    BUSD[ChainId.BNB_TESTNET],
    USDT[ChainId.BNB_TESTNET]
  ],
  [ChainId.ETHEREUM]: [
    ...WNATIVE_ONLY[ChainId.ETHEREUM],
    USDC[ChainId.ETHEREUM],
    USDT[ChainId.ETHEREUM]
  ]
}

export const tryParseAmount = (
  value?: string,
  currency?: Currency,
  parseZero?: boolean
): CurrencyAmount | undefined => {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(
      value as `${number}`,
      currency.decimals
    ).toString()
    if (typedValueParsed !== '0' || parseZero) {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(currency.chainId, JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.error(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export const getCurrencyAmount = (currency?: Currency, amount?: bigint) => {
  if (amount === undefined || !currency) return undefined
  return currency instanceof Token
    ? new TokenAmount(currency, JSBI.BigInt(amount.toString()))
    : CurrencyAmount.ether(currency.chainId, JSBI.BigInt(amount.toString()))
}

export const computePriceImpact = ({
  amounts,
  tokenOut,
  virtualAmounts
}: {
  amounts: readonly bigint[]
  tokenOut: Token
  virtualAmounts: readonly bigint[]
}) => {
  const outputAmount = new TokenAmount(
    tokenOut,
    JSBI.BigInt(amounts[amounts.length - 1].toString())
  )
  const exactQuoteStr = virtualAmounts[virtualAmounts.length - 1].toString()
  const exactQuote = new TokenAmount(tokenOut, JSBI.BigInt(exactQuoteStr))
  const slippage = exactQuote.subtract(outputAmount).divide(exactQuote)
  return new Percent(slippage.numerator, slippage.denominator)
}

export const getSwapCallParameters = ({
  allowedSlippage,
  currencyIn,
  currencyOut,
  deadline,
  isExactIn,
  recipient,
  trade,
  useFeeOnTransfer
}: {
  allowedSlippage: number
  currencyIn: Currency
  currencyOut: Currency
  deadline: number
  isExactIn: boolean
  recipient: string
  trade: TradeBestPath
  useFeeOnTransfer: boolean
}) => {
  const isNativeIn = currencyIn.isNative
  const isNativeOut = currencyOut.isNative

  if (isNativeIn && isNativeOut) {
    throw new Error('ETHER_IN_OUT')
  }

  const to = getAddress(recipient)
  const deadlineHex = `0x${deadline.toString(16)}`

  const tokenPath: string[] = [
    getAddress(trade.path[0].tokenInId),
    getAddress(trade.path[0].tokenOutId)
  ]
  const versions: PoolVersion[] = [trade.path[0].version]
  trade.path.slice(1).forEach((pair) => {
    versions.push(pair.version)
    tokenPath.push(
      tokenPath[tokenPath.length - 1] === getAddress(pair.tokenInId)
        ? getAddress(pair.tokenOutId)
        : getAddress(pair.tokenInId)
    )
  })

  const pairBinSteps = trade.path.map(
    ({ binStep }) => '0x' + binStep.toString(16)
  )

  const path: RouterPathParameters = {
    pairBinSteps,
    tokenPath,
    versions
  }

  let amountIn: bigint
  let amountOut: bigint

  if (isExactIn) {
    amountIn = trade.amountIn.value
    amountOut =
      trade.amountOut.value -
      (trade.amountOut.value * BigInt(allowedSlippage)) / BigInt(10_000)
  } else {
    amountIn =
      trade.amountIn.value +
      (trade.amountIn.value * BigInt(allowedSlippage)) / BigInt(10_000)
    amountOut = trade.amountOut.value
  }

  let methodName: string
  let args: (string | string[] | bigint | RouterPathParameters)[]
  let value: bigint

  if (isExactIn) {
    if (isNativeIn) {
      methodName = useFeeOnTransfer
        ? 'swapExactNATIVEForTokensSupportingFeeOnTransferTokens'
        : 'swapExactNATIVEForTokens'
      args = [amountOut.toString(), path, to, deadlineHex]
      value = amountIn
    } else if (isNativeOut) {
      methodName = useFeeOnTransfer
        ? 'swapExactTokensForNATIVESupportingFeeOnTransferTokens'
        : 'swapExactTokensForNATIVE'
      args = [amountIn, amountOut, path, to, deadlineHex]
      value = BigInt(0)
    } else {
      methodName = useFeeOnTransfer
        ? 'swapExactTokensForTokensSupportingFeeOnTransferTokens'
        : 'swapExactTokensForTokens'
      args = [amountIn, amountOut, path, to, deadlineHex]
      value = BigInt(0)
    }
  } else {
    if (isNativeIn) {
      methodName = 'swapNATIVEForExactTokens'
      args = [amountOut, path, to, deadlineHex]
      value = amountIn
    } else if (isNativeOut) {
      methodName = 'swapTokensForExactNATIVE'
      args = [amountOut, amountIn, path, to, deadlineHex]
      value = BigInt(0)
    } else {
      methodName = 'swapTokensForExactTokens'
      args = [amountOut, amountIn, path, to, deadlineHex]
      value = BigInt(0)
    }
  }

  return {
    args,
    methodName,
    value
  }
}

export const compareTradeRoute = (
  tradeA: TradeBestPath,
  tradeB: TradeBestPath
): boolean => {
  if (tradeA.path.length !== tradeB.path.length) {
    return false
  }

  for (let i = 0; i < tradeA.path.length; i++) {
    if (
      tradeA.path[i].tokenInId.toLowerCase() !==
        tradeB.path[i].tokenInId.toLowerCase() ||
      tradeA.path[i].tokenOutId.toLowerCase() !==
        tradeB.path[i].tokenOutId.toLowerCase()
    ) {
      return false
    }
  }

  return true
}
