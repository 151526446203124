import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  Heading,
  Spacer,
  Spinner,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import {
  LB_REWARDER_ADDRESS,
  LIMIT_ORDER_MANAGER_ADDRESS
} from '@traderjoe-xyz/sdk-v2'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import PageHelmet from 'components/PageHelmet'
import TabWithIcon from 'components/TabWithIcon'
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from 'constants/pool'
import useIsPairOrderable from 'hooks/limitOrder/useIsPairOrderable'
import useGetLbRewarderData from 'hooks/pool/v2/useGetLbRewarderData'
import useLBPairRewards from 'hooks/pool/v2/useLBPairRewards'
import useLBPosition from 'hooks/pool/v2/useLBPosition'
import usePoolDetailV2Params from 'hooks/pool/v2/usePoolDetailV2Params'
import useChainId from 'hooks/useChainId'
import { useHasPoolRewarder } from 'hooks/useEpochRewards'
import useIsChainSunset from 'hooks/useIsChainSunset'
import debounce from 'lodash.debounce'
import React, { useState } from 'react'
import {
  AchievementIcon,
  PieChartIcon,
  PlaceOrderIcon,
  PoolIcon
} from 'theme/icons'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { zeroAddress } from 'viem'
import { useAccount } from 'wagmi'

import AddRemoveLiquidityPanelV2 from './AddRemoveLiquidityPanelV2'
import ClaimFees from './ClaimFees'
import ClaimRewards from './ClaimRewards'
import LbRewarderPanel from './LbRewardsPanel'
import Leaderboard from './Leaderboard'
import MigratedPoolBanner from './MigratedPoolBanner'
import PoolDetailV2AnalyticsPanel from './PoolDetailV2AnalyticsPanel'
import PoolDetailV2Header from './PoolDetailV2Header'
import PoolLimitOrders from './PoolLimitOrders'
import RewardingDetails from './RewardingDetails'
import RewardsHistoryTable from './RewardsHistoryTable'
import { useLBPairDataContext, usePoolDetailV2Context } from './state'
import UserEarnedFees from './UserEarnedFees'
import UserLiquidityChart from './UserLiquidityChart'

const PoolDetailV2 = () => {
  const { address: account, isConnected } = useAccount()
  const { binStep, lbPoolVersion } = usePoolDetailV2Params()
  const chainId = useChainId()
  const isChainSunset = useIsChainSunset()

  const {
    balance0,
    balance1,
    currency0,
    currency1,
    inversePriceRatios,
    isLoadingPoolV2,
    pool,
    refetchBalance0,
    refetchBalance1,
    togglePriceRatios
  } = usePoolDetailV2Context()
  const {
    activeBinId,
    baseFee,
    isLoading,
    lbPairAddress,
    refetchReservesAndActiveBinId,
    reserveX,
    reserveY
  } = useLBPairDataContext()

  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)

  // check if the pool is orderable
  const isOrderable = useIsPairOrderable(wrappedCurrency0, wrappedCurrency1)
  const isPlaceOrdersEnabled =
    lbPoolVersion === 'v21' &&
    LIMIT_ORDER_MANAGER_ADDRESS[chainId] !== zeroAddress &&
    isOrderable &&
    !isChainSunset

  // tabs
  enum Tab {
    LIQUIDITY = 0,
    ORDERS = 1,
    ANALYTICS = isPlaceOrdersEnabled ? 2 : 1,
    REWARDS_LEADERBOARD = isPlaceOrdersEnabled ? 3 : 2
  }
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.LIQUIDITY)

  const {
    isLoadingLBPosition: isLoadingUserLBPosition,
    pooledTokens0,
    pooledTokens1,
    refetchLBPosition: refetchUserLBPosition,
    userBalances
  } = useLBPosition({
    lbBinStep: Number(binStep),
    lbPairAddress: lbPairAddress,
    owner: account,
    token0: wrappedCurrency0,
    token1: wrappedCurrency1
  })

  const ongoingRewards = pool?.rewards?.find(
    (reward) => reward.epoch.status === 'ongoing'
  )
  const { hasRewarder } = useHasPoolRewarder(lbPairAddress)

  const {
    claimableRewards = [],
    handleClaimRewards,
    isLoading: isRewardLoading,
    rewardsHistoryData
  } = useLBPairRewards({
    isRewarded: hasRewarder,
    market: lbPairAddress,
    rewarderAddress: LB_REWARDER_ADDRESS[chainId]
  })

  const { data: lbRewarderData, refetch: refetchLbRewarderData } =
    useGetLbRewarderData({
      binStep: Number(binStep),
      currency0,
      currency1,
      enabled: lbPoolVersion === 'v22',
      ids: userBalances?.positions || [],
      pairAddress: lbPairAddress
    })

  if (isLoading || !lbPairAddress || isLoadingPoolV2) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  // wait for the indexer to update the user's positions, then ask for a refetch
  const debouncedRefetchUserLBPosition = debounce(
    () => refetchUserLBPosition(),
    10000
  )
  const debouncedRefetchLbRewarderData = debounce(
    () => refetchLbRewarderData(),
    2000
  )

  // select liquidity tab and scroll to bottom of add liquidity panel
  const onDisplayAddLiquidityPanel = () => {
    setSelectedTab(Tab.LIQUIDITY)
    window.scrollTo(0, 0)
  }

  return (
    <Box pt={4} w="full" bg="joeLight.400" _dark={{ bg: 'joeDark.600' }}>
      <PageHelmet
        title={POOL_HELMET_TITLE}
        description={POOL_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <PoolDetailV2Header
        isRewarded={!!ongoingRewards}
        points={pool?.points || []}
      />
      <Tabs
        variant="enclosed"
        pos="relative"
        mt={{ base: 0, md: '-42px' }}
        w="full"
        index={selectedTab}
        onChange={setSelectedTab}
      >
        <Box overflowX="auto" overflowY="hidden">
          <TabList
            maxW={{ '2xl': '1600px', base: '1400px' }}
            margin="0 auto"
            px={4}
          >
            <TabWithIcon
              title={t`Manage`}
              icon={
                <PoolIcon
                  mr={2}
                  boxSize="22px"
                  fill={
                    selectedTab === Tab.LIQUIDITY
                      ? 'textPrimary'
                      : 'textSecondary'
                  }
                />
              }
            />
            {isPlaceOrdersEnabled ? (
              <TabWithIcon
                data-cy="orders-tab"
                title={t`Orders`}
                icon={
                  <PlaceOrderIcon
                    mr={2}
                    boxSize="16px"
                    fill={
                      selectedTab === Tab.ORDERS
                        ? 'textPrimary'
                        : 'textSecondary'
                    }
                  />
                }
              />
            ) : null}
            {!isChainSunset ? (
              <TabWithIcon
                title={t`Analytics`}
                icon={
                  <PieChartIcon
                    mr={2}
                    boxSize="16px"
                    fill={
                      selectedTab === Tab.ANALYTICS
                        ? 'textPrimary'
                        : 'textSecondary'
                    }
                  />
                }
              />
            ) : null}
            {!isChainSunset ? (
              <TabWithIcon
                data-cy="rewards-leaderboard-tab"
                title={hasRewarder ? t`Leaderboard / Rewards` : t`Leaderboard`}
                icon={
                  <AchievementIcon
                    mr={2}
                    boxSize="16px"
                    fill={
                      selectedTab === Tab.REWARDS_LEADERBOARD
                        ? 'textPrimary'
                        : 'textSecondary'
                    }
                  />
                }
              />
            ) : null}
          </TabList>
        </Box>
        <TabPanels w="full" minH="calc(100vh - 250px)">
          <TabPanel
            pb={32}
            pt={8}
            maxW={{ '2xl': '1600px', base: '1400px' }}
            margin="0 auto"
            w="full"
            px={4}
          >
            <Grid
              templateColumns={{
                base: 'minmax(0, 1fr)',
                xl: 'minmax(0, 1fr) minmax(0, 1fr)'
              }}
              gap={{ base: 4, md: 8 }}
              alignItems="flex-start"
            >
              <VStack spacing={4}>
                {pool && pool.isMigrated && binStep ? (
                  <MigratedPoolBanner
                    pairAddress={pool.pairAddress}
                    tokenX={wrappedCurrency0?.address}
                    tokenY={wrappedCurrency1?.address}
                    binStep={Number(binStep)}
                    lbPoolVersion={pool.lbPoolVersion === 'v2' ? 'v21' : 'v22'}
                  />
                ) : null}
                <Box
                  w="full"
                  bg="bgCard"
                  borderRadius="2xl"
                  border="1px solid"
                  borderColor="border"
                >
                  <Box px={{ base: 4, md: 8 }} py={{ base: 4, md: 6 }}>
                    {activeBinId && binStep ? (
                      <UserLiquidityChart
                        currency0={currency0}
                        currency1={currency1}
                        binStep={binStep}
                        userBalances={userBalances}
                        activeBinId={activeBinId}
                        isLoadingUserBalances={isLoadingUserLBPosition}
                        isPriceRatioInversed={inversePriceRatios}
                        rewardedBins={lbRewarderData?.rewardedBins?.map(
                          (bin) => bin.binId
                        )}
                      />
                    ) : null}
                  </Box>
                  <Divider />
                  {wrappedCurrency0 && wrappedCurrency1 ? (
                    <CurrencyAmountsPanel
                      title={t`Deposit Balance`}
                      tokens={[
                        wrappedCurrency0.address,
                        wrappedCurrency1.address
                      ]}
                      tokenAmounts={[pooledTokens0 ?? 0, pooledTokens1 ?? 0]}
                      tokenSymbols={[currency0?.symbol, currency1?.symbol]}
                    />
                  ) : null}
                </Box>

                {lbRewarderData &&
                lbRewarderData.hasRewarder &&
                (lbRewarderData.rewards.length > 0 ||
                  lbRewarderData.userClaimableRewards.length > 0) &&
                currency0 &&
                currency1 ? (
                  <LbRewarderPanel
                    hookRewarderAddress={
                      lbRewarderData.rewarderData.hooksParameters.hooks
                    }
                    rewards={lbRewarderData.rewards}
                    userClaimableRewards={lbRewarderData.userClaimableRewards}
                    onClaimSuccess={debouncedRefetchLbRewarderData}
                    currency0={currency0}
                    currency1={currency1}
                    userBinIds={userBalances?.positions}
                    inversePriceRatios={inversePriceRatios}
                  />
                ) : null}

                {isConnected &&
                wrappedCurrency0 &&
                wrappedCurrency1 &&
                lbPoolVersion ? (
                  <Box
                    w="full"
                    bg="bgCard"
                    borderRadius="2xl"
                    border="1px solid"
                    borderColor="border"
                  >
                    <Box py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }}>
                      <UserEarnedFees
                        currency0={currency0}
                        currency1={currency1}
                        tokenAddrs={[
                          wrappedCurrency0.address,
                          wrappedCurrency1.address
                        ]}
                        lbPairAddress={lbPairAddress}
                        isPriceRatioInversed={inversePriceRatios}
                        lbPoolVersion={lbPoolVersion}
                      />
                    </Box>
                    {lbPoolVersion === 'v2' ? (
                      <>
                        <Divider />
                        <ClaimFees
                          lbPairAddress={lbPairAddress}
                          wrappedCurrency0={wrappedCurrency0}
                          wrappedCurrency1={wrappedCurrency1}
                          currency0Symbol={currency0?.symbol}
                          currency1Symbol={currency1?.symbol}
                          userPositions={userBalances?.positions}
                        />
                      </>
                    ) : null}
                    {isConnected && hasRewarder ? (
                      <>
                        <Divider />
                        <ClaimRewards
                          claimableRewards={claimableRewards}
                          isLoading={isRewardLoading}
                          handleClaimRewards={handleClaimRewards}
                          poolSymbol={`${currency0?.symbol}-${currency1?.symbol}`}
                        />
                      </>
                    ) : null}
                  </Box>
                ) : null}
              </VStack>
              {currency0 && currency1 && lbPairAddress && lbPoolVersion ? (
                <Box
                  bg="bgCard"
                  borderRadius="2xl"
                  border="1px solid"
                  borderColor="border"
                >
                  <AddRemoveLiquidityPanelV2
                    poolLiquidityUsd={pool?.liquidityUsd}
                    lbPairAddress={lbPairAddress}
                    isPoolMigrated={pool?.isMigrated ?? false}
                    lbPoolVersion={lbPoolVersion}
                    currency0={currency0}
                    currency1={currency1}
                    balance0={balance0}
                    balance1={balance1}
                    binStep={binStep}
                    activeBinId={activeBinId}
                    userBalances={userBalances}
                    rewardedRange={lbRewarderData?.rewardedRange}
                    onAddLiquiditySuccess={() => {
                      refetchBalance0()
                      refetchBalance1()
                      debouncedRefetchUserLBPosition()
                    }}
                    onRemoveLiquiditySuccess={() => {
                      refetchBalance0()
                      refetchBalance1()
                      debouncedRefetchUserLBPosition()
                    }}
                    onRemoveLiquidityConfigError={() => {
                      refetchReservesAndActiveBinId()
                      refetchUserLBPosition()
                    }}
                    inversePriceRatios={inversePriceRatios}
                    togglePriceRatiosClicked={togglePriceRatios}
                  />
                </Box>
              ) : null}
            </Grid>
          </TabPanel>
          {isPlaceOrdersEnabled ? (
            <TabPanel
              pb={32}
              pt={8}
              maxW={{ '2xl': '1600px', base: '1400px' }}
              margin="0 auto"
              w="full"
              px={4}
            >
              <PoolLimitOrders
                lbPairAddr={lbPairAddress}
                binStep={binStep}
                activeBinId={activeBinId}
                currency0={currency0}
                currency1={currency1}
                balance0={balance0}
                balance1={balance1}
                tokenX={wrappedCurrency0}
                tokenY={wrappedCurrency1}
                isPriceRatioInversed={inversePriceRatios}
                togglePriceRatiosClicked={togglePriceRatios}
              />
            </TabPanel>
          ) : null}
          {!isChainSunset ? (
            <TabPanel
              pb={32}
              pt={8}
              maxW={{ '2xl': '1600px', base: '1400px' }}
              margin="0 auto"
            >
              {currency0 && currency1 ? (
                <PoolDetailV2AnalyticsPanel
                  lbPairAddress={lbPairAddress}
                  activeBinId={activeBinId}
                  baseFee={baseFee}
                  isSelected={selectedTab === Tab.ANALYTICS}
                  currency0={currency0}
                  currency1={currency1}
                  reserveX={reserveX}
                  reserveY={reserveY}
                  isPriceRatioInversed={inversePriceRatios}
                  liquidityDepthTokenX={pool?.liquidityDepthTokenX}
                  liquidityDepthTokenY={pool?.liquidityDepthTokenY}
                  rewards={ongoingRewards}
                />
              ) : null}
            </TabPanel>
          ) : null}
          {hasRewarder && !isChainSunset ? (
            <TabPanel
              pb={32}
              pt={8}
              maxW={{ '2xl': '1600px', base: '1400px' }}
              margin="0 auto"
            >
              <Heading size="lg">
                <Trans>Rewards</Trans>
              </Heading>
              <Spacer mt="1rem" />
              <Grid
                templateColumns={{ base: '1fr', lg: '1.5fr 1fr' }}
                gap={{ base: 4, md: 8 }}
                alignItems="flex-start"
              >
                <Flex gap={{ base: 4, md: 8 }} direction="column">
                  {ongoingRewards ? (
                    <RewardingDetails rewards={ongoingRewards} />
                  ) : null}
                  <RewardsHistoryTable
                    rewardsHistoryData={rewardsHistoryData}
                  />
                </Flex>
                {hasRewarder ? (
                  <Box
                    bg="bgCard"
                    border="1px solid"
                    borderColor="border"
                    borderRadius="2xl"
                  >
                    <ClaimRewards
                      claimableRewards={claimableRewards}
                      isLoading={isRewardLoading}
                      handleClaimRewards={handleClaimRewards}
                      poolSymbol={`${currency0?.symbol}-${currency1?.symbol}`}
                    />
                  </Box>
                ) : null}
              </Grid>
              <Spacer mt={{ base: 4, md: 8 }} />
              <Leaderboard
                currency0={currency0}
                currency1={currency1}
                lbPairAddress={lbPairAddress}
                isTabSelected={selectedTab === Tab.REWARDS_LEADERBOARD}
                activeBinId={activeBinId}
                onDisplayAddLiquidityPanel={onDisplayAddLiquidityPanel}
              />
            </TabPanel>
          ) : null}
          {!isChainSunset ? (
            <TabPanel
              pb={32}
              pt={8}
              maxW={{ '2xl': '1600px', base: '1400px' }}
              margin="0 auto"
            >
              {currency0 && currency1 ? (
                <Leaderboard
                  currency0={currency0}
                  currency1={currency1}
                  lbPairAddress={lbPairAddress}
                  isTabSelected={selectedTab === Tab.REWARDS_LEADERBOARD}
                  activeBinId={activeBinId}
                  onDisplayAddLiquidityPanel={onDisplayAddLiquidityPanel}
                />
              ) : null}
            </TabPanel>
          ) : null}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default PoolDetailV2
