import { InfoIcon } from '@chakra-ui/icons'
import { Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { Currency } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import useKeyPress from 'hooks/useKeyPress'
import React, { useEffect, useState } from 'react'
import { getBinIdFromPrice, getBinPctRange } from 'utils/bin'
import { getMaxBinPerAddLiquidityBatch } from 'utils/getMaxBinPerBatch'

import LabeledInput from './LabeledInput'

interface PriceRadiusInputsProps {
  _getPriceFromBinId: (binId: number) => string
  binStep: number
  currency0: Currency
  currency1: Currency
  currencyPrice0: number | undefined
  currencyPrice1: number | undefined
  onBinRangeChange: (range: number[]) => void
  radius: string
  setRadius: (radius: string) => void
  setTargetBinId: (binId: number) => void
  setTargetPrice: (price: string) => void
  targetBinId: number
  targetPrice: string
  inversePriceRatios?: boolean
}

const PriceRadiusInputs = ({
  _getPriceFromBinId,
  binStep,
  currency0,
  currency1,
  currencyPrice0,
  currencyPrice1,
  inversePriceRatios,
  onBinRangeChange,
  radius,
  setRadius,
  setTargetBinId,
  setTargetPrice,
  targetBinId,
  targetPrice
}: PriceRadiusInputsProps) => {
  const chainId = useChainId()
  const uKeyPressed = useKeyPress({ targetKey: 'u' })

  const [isTyping, setIsTyping] = useState(false)
  const minRadius = targetBinId - Number(radius)
  const maxRadius = targetBinId + Number(radius)
  const numsBins = maxRadius - minRadius

  const minPrice = inversePriceRatios
    ? _getPriceFromBinId(maxRadius)
    : _getPriceFromBinId(minRadius)

  const maxPrice = inversePriceRatios
    ? _getPriceFromBinId(minRadius)
    : _getPriceFromBinId(maxRadius)

  const minPriceInUsd = inversePriceRatios
    ? (Number(minPrice) * (currencyPrice0 || 0)).toFixed(6)
    : (Number(minPrice) * (currencyPrice1 || 0)).toFixed(6)
  const maxPriceInUsd = inversePriceRatios
    ? (Number(maxPrice) * (currencyPrice0 || 0)).toFixed(6)
    : (Number(maxPrice) * (currencyPrice1 || 0)).toFixed(6)
  const targetPriceInUsd = inversePriceRatios
    ? (Number(targetPrice) * (currencyPrice0 || 0)).toFixed(6)
    : (Number(targetPrice) * (currencyPrice1 || 0)).toFixed(6)

  const tokenPriceExists =
    currencyPrice0 !== undefined || currencyPrice1 !== undefined

  // update prices on toggle switch
  useEffect(() => {
    if (isTyping) return
    setTargetPrice(_getPriceFromBinId(targetBinId))
  }, [targetBinId, _getPriceFromBinId, setTargetPrice, isTyping])

  const largeRangeDisclaimerText =
    numsBins > getMaxBinPerAddLiquidityBatch(chainId)
      ? 'Adding liquidity to a large range requires more than one transaction.'
      : undefined

  const formatRightLabel = () => {
    return inversePriceRatios
      ? uKeyPressed
        ? `USD per ${currency1.symbol}`
        : `${currency0.symbol} per ${currency1.symbol}`
      : uKeyPressed
        ? `USD per ${currency0.symbol}`
        : `${currency1.symbol} per ${currency0.symbol}`
  }

  return (
    <>
      <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} w="full">
        <LabeledInput
          title="Target Price:"
          rightLabel={formatRightLabel()}
          value={
            tokenPriceExists
              ? uKeyPressed
                ? targetPriceInUsd
                : targetPrice
              : targetPrice
          }
          onFocus={() => setIsTyping(true)}
          onBlur={() => setIsTyping(false)}
          onValueChange={(value) => {
            setTargetPrice(value)
            // update the targetBinId
            let binId: number
            if (inversePriceRatios) {
              binId = getBinIdFromPrice(
                (1 / Number(value)).toString(),
                binStep,
                currency0,
                currency1
              )
            } else {
              binId = getBinIdFromPrice(value, binStep, currency0, currency1)
            }
            setTargetBinId(binId)
            onBinRangeChange([binId - Number(radius), binId + Number(radius)])
          }}
        />

        <LabeledInput
          title="Radius (number of bins):"
          value={radius}
          inputType="integer"
          onValueChange={(value) => {
            setRadius(value)
            onBinRangeChange([
              targetBinId - Number(value),
              targetBinId + Number(value)
            ])
          }}
        />

        <LabeledInput
          title="Range Min:"
          rightLabel={formatRightLabel()}
          value={
            tokenPriceExists
              ? uKeyPressed
                ? minPriceInUsd
                : minPrice
              : minPrice
          }
          isDisabled
        />
        <LabeledInput
          title="Range Max:"
          rightLabel={
            inversePriceRatios
              ? `${currency0.symbol} per ${currency1.symbol}`
              : `${currency1.symbol} per ${currency0.symbol}`
          }
          value={
            tokenPriceExists
              ? uKeyPressed
                ? maxPriceInUsd
                : maxPrice
              : maxPrice
          }
          isDisabled
        />
        <LabeledInput title="Num Bins:" value={`${numsBins}`} isDisabled />
        <LabeledInput
          title="Pct Range"
          isDisabled
          value={getBinPctRange(binStep, numsBins)}
        />
      </SimpleGrid>

      {largeRangeDisclaimerText ? (
        <Flex
          align="center"
          gap={4}
          p={4}
          bg="bgPrimary"
          borderRadius="xl"
          w="full"
          mt={8}
        >
          <InfoIcon />
          <Text fontSize="sm">{largeRangeDisclaimerText}</Text>
        </Flex>
      ) : null}
    </>
  )
}

export default PriceRadiusInputs
