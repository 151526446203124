import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Button, Center, Flex, IconButton } from '@chakra-ui/react'
import React, { useMemo } from 'react'

interface PaginationProps {
  onPageChange: (page: number) => void
  selectedPage: number
  totalPages: number
}

const Pagination = ({
  onPageChange,
  selectedPage,
  totalPages
}: PaginationProps) => {
  const elements: (number | 'dots')[] = useMemo(() => {
    const maxPage = 5
    if (totalPages <= maxPage) {
      return [...Array(totalPages).keys()]
    }
    if (selectedPage <= maxPage - 1) {
      return [...Array(maxPage).keys(), 'dots', totalPages]
    }
    if (selectedPage >= totalPages - 2) {
      return [
        0,
        'dots',
        ...[...Array(maxPage).keys()].map((i) => i + totalPages - 5)
      ]
    }
    return [
      0,
      'dots',
      selectedPage - 2,
      selectedPage - 1,
      selectedPage,
      'dots',
      totalPages
    ]
  }, [selectedPage, totalPages])

  return (
    <Flex w="full" justify="center">
      <IconButton
        variant="ghost"
        icon={<ChevronLeftIcon boxSize="20px" />}
        borderRadius="full"
        aria-label="go to previous page"
        isDisabled={selectedPage === 1}
        onClick={() => onPageChange(selectedPage - 1)}
      />
      {elements.map((element, i) => {
        if (element === 'dots') {
          return (
            <Center key={i} h="40px" w="40px">
              ...
            </Center>
          )
        }
        const page = element + 1
        return (
          <Button
            key={i}
            variant="ghost"
            borderRadius="full"
            h="40px"
            w="40px"
            isActive={selectedPage === page}
            onClick={() => onPageChange(page)}
          >
            {page}
          </Button>
        )
      })}
      <IconButton
        variant="ghost"
        icon={<ChevronRightIcon boxSize="20px" />}
        borderRadius="full"
        aria-label="go to next page"
        isDisabled={selectedPage === totalPages}
        onClick={() => onPageChange(selectedPage + 1)}
      />
    </Flex>
  )
}

export default Pagination
