import { ChainId } from '@traderjoe-xyz/sdk-core'
import { APT_FARM_LENS, APTFarmLensABI } from '@traderjoe-xyz/sdk-v2'
import useActiveChain from 'hooks/useActiveChain'
import { useMemo } from 'react'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

interface useUserAPTFarmBalanceProps {
  vaultAddress?: string
}

const useUserAPTFarmBalance = ({
  vaultAddress
}: useUserAPTFarmBalanceProps) => {
  const chainId = useActiveChain().id as ChainId
  const { address } = useAccount()
  const farmLensContract = getAddress(APT_FARM_LENS[chainId])

  const {
    data,
    isLoading: isLoadingUserAPTFarmBalance,
    refetch: refetchUserAPTFarmBalance
  } = useReadContract({
    abi: APTFarmLensABI,
    address: farmLensContract,
    args: address ? [address] : undefined,
    functionName: 'getAllVaultsIncludingUserInfo',
    query: { enabled: !!address && !!vaultAddress, gcTime: 0 }
  })

  const userCurrentAPTBalanceUSD = useMemo(() => {
    if (!data) return

    const userVaultWithFarmsInfo = data.find(
      (vault) => vault.vaultData.vault.toLowerCase() === vaultAddress
    )

    const userAPTBalanceUSD = userVaultWithFarmsInfo?.farmDataWithUserInfo
      ? formatUnits(
          userVaultWithFarmsInfo.farmDataWithUserInfo.userBalanceUSD,
          18
        )
      : '0'

    return userAPTBalanceUSD
  }, [data, vaultAddress])

  return {
    data: userCurrentAPTBalanceUSD,
    isLoading: isLoadingUserAPTFarmBalance,
    refetch: refetchUserAPTFarmBalance
  }
}

export default useUserAPTFarmBalance
