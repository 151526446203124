import { ChainId } from '@traderjoe-xyz/sdk-core'

export const ALL_MARKETS_BY_CHAIN: { [chainId in ChainId]: string[] } = {
  [ChainId.ARB_GOERLI]: [],
  [ChainId.ARBITRUM_ONE]: [
    '0x7ec3717f70894f6d9ba0be00774610394ce006ee',
    '0x5fbbef48ce0850e5a73bc3f4a6e903458b3c0af4',
    '0xa51ee8b744e6cc1f2ac12b9eeaae8deb27619c6b',
    '0x5813ce0679e67ddaf0e9002939550710856c06d4',
    '0xa0fd049466d57fc3637e27ca585d59e0ad86b902',
    '0x13fda18516eafe5e8ae930f86fa51ae4b6c35e8f',
    '0xafebf9bba7984954e42d7551ab0ce47130bfdc0a',
    '0xf3013b72a4c70a8475ff5331d8f2937e5faf135f',
    '0x94d53be52706a155d27440c4a2434bea772a6f7c',
    '0xee1d31ab646056f549a78feacb73be45332fa078',
    '0x60563686ca7b668e4a2d7d31448e5f10456ecaf8',
    '0x0f475257b6e2fa9a48ef84cb4a91d4288729f39e',
    '0x4b9bfed1dd4e6780454b2b02213788f31ffba74a',
    '0x003ad0975250c810ad75fd83e989f54625cac514',
    '0x9522453f1ea2ad8ae9626cc153b1a988ae2b61de',
    '0xbacd654a7eef4a50dbf1bc8ed8774a58dfbb440d',
    '0xb87495219c432fc85161e4283dff131692a528bd',
    '0xde568fd89b3349a766f45d5ab2a7c0510f476a80',
    '0x69f1216cb2905bf0852f74624d5fa7b5fc4da710',
    '0xfc43aaf89a71acaa644842ee4219e8eb77657427',
    '0x987cdd61b093fb740e7eb04183ee2d29da014af9',
    '0x6362592e1d84d7bc356c5d81fa0cdc116b9b419b',
    '0xd387c40a72703b38a5181573724bcaf2ce6038a5',
    '0xcfa09b20c85933b197e8901226ad0d6daca7f114',
    '0xb782fa425c2ad1ef348257ce38be4e77613cb389',
    '0xcc5072e5f2d7d479114cd47649eb6af5ca0ef1ce',
    '0x9f28b1eacf17f64cd952deaa14f0487b9f74bbb6',
    '0x3be852ff9f142783c71435524d63e2196e5f305a',
    '0x2e14e24ec3efbd3b8c8976405351f8233d59650e',
    '0x830203b9a16920feb55d58d964ce83e1ba000269',
    '0x500173f418137090dad96421811147b63b448a0f',
    '0x0242dd3b2e792cdbd399cc6195951bc202aee97b',
    '0x0be4ac7da6cd4bad60d96fbc6d091e1098afa358',
    '0x5a4ef05343245cf6e5011c48459e68aab0a7b95e',
    '0x7c5c54ecbf3bf9a928890ee675f8591f2cf52e09',
    '0xf83ede541b25f5743a230f85e60266e80cdf1e9a',
    '0x6bb53cdaeaa76cc14878b2f474e0be71b0a95509',
    '0xb0a4c5237c905f07118a87ffef40ba58266104f6',
    '0xac71f308d16f203d22b2c3921d25dae61143bf6e',
    '0x461761f2848ec6b9fb3d3fb031e112c7d5b89563',
    '0xe2b32e6dd706af1ae7e6ea71d0477b5adaf9c9d1',
    '0xc7f394b9515c0ff15a7d755d456dcd0b4aa67b59',
    '0xb6a2a7456e651cc7ceb99735a086bff540e94d5d',
    '0x53eccccaaa368a9431b3659a8e37ce4b411ad258',
    '0xc3bc74dcff9019d064dc269a5266934cd65613e5',
    '0x5ec58446d7791a8d0f5b15cdc81b659fc12665f2',
    '0xa508825dbfd6b6595a37b48c507719ffafa696e5',
    '0xe847c55a3148580e864ec31e7273bc4ec25089c1',
    '0xcaee39dce99006e7d42a92850ea9021eccabbe00',
    '0x6524ec5ea3e10c669d5b6a482f8e59baa338c0d5',
    '0x22b8fd7294c6b1aaab3263902cd2883fc9360fe6',
    '0xbf24d6b6a37c5d950dabbc036c6fb05dd40ae222',
    '0xf8a60082039a1acbe43b045f87aa0c5f24a358a4',
    '0x9c27d9ce9942a7a9bb1889cb5512874b8549ecae',
    '0x28906c6d70534b48752d30b4aed1fff20e98a61e',
    '0x0906b29d5327caf7ebdb9cf3e391fac33492c910',
    '0x9a29da85cafded639c295caaa33348549c881706',
    '0xc9d397b516b54e0a45c1b6f239fabc15545aa0c2',
    '0x380bef61264cba04b6d74002ac6931d1b679c49d',
    '0x81df678a41a5baade2a13b58accb95a43328400a',
    '0x2a1a04d922e2df68289d66ee256b1ef955ba950c'
  ],
  [ChainId.BNB_TESTNET]: [],
  [ChainId.AVALANCHE]: [
    '0x294dc12a20e0018f77d8e043dad60af6777f716b',
    '0x42c701d4e359178412d014506cfac43a67e57d6d',
    '0x1901011a39b11271578a1283d620373abed66faa',
    '0xd446eb1660f766d533beceef890df7a69d26f7d1',
    '0xd9fa522f5bc6cfa40211944f2c8da785773ad99d',
    '0xe6c7e4142b0cb24f2bfa7b9a50911375f8ee8db9',
    '0x9b2cc8e6a2bbb56d6be4682891a91b0e48633c72',
    '0x9f8973fb86b35c307324ec31fd81cf565e2f4a63',
    '0x7c13d4c3e9dfa683e7a5792a9ff20cb5fd22b0c0',
    '0x6fe050dc81b98e4464d3b4461a7995a8bf3350db',
    '0x472de9c4566b2f7ecf7eadae1a843a75c1f7f440',
    '0xeeab99a3a086084f3d740214c64d7ba03acb8bcb',
    '0xd1cfc81c44655d635a19c7e779eb80e27fbec7b2',
    '0x229141bafa57ab0d5bd127619003cd694a2e1d51',
    '0x4f9ad1e4e671c1f95216076637bff9face0983da',
    '0xd0a2e238c85be2022cc6ec81aa2a87930faa0434',
    '0xf00d22dce56d2b243d8ffa025ed99abf6209a0d6',
    '0x10e54566d611d2aa9169cda58ea3be602aaa5749',
    '0xa83fceb94e7a5c2873bdb58db1ff6f498f66b285',
    '0x2edb382a7eebd8a936010775a4688c2ad366070f',
    '0x4c46ea0265efb51f90d405fb27bac92ff1beb274',
    '0xb81a9a30cdb57d52f131de62aa755889b95aeec9',
    '0xd76a1fd2add1fd7d6c92e022d8584913102d205a',
    '0x2eeb17769150f0bdda1cb30f30c08d49617ba458',
    '0x13823df65a950d85ef2bc4361ed9a169b267504f',
    '0x207004f671e20631673c03a20226c5a123ccad29',
    '0x41b3dfc4a55f8b968ecdbf2dffb0cf1b81dee5ce',
    '0x48692b3b38bb7ddfe2b36efabba298f3cd5c49ce',
    '0xd8d29af3365ec03fae7606f42d83892dd77d71b2',
    '0xfc744399a6b2a559d9a23829fe778600e00eb71c',
    '0xf1963d03c77d81649698e32f1632f9164d0c0848',
    '0x472de9c4566b2f7ecf7eadae1a843a75c1f7f440',
    '0xd446eb1660f766d533beceef890df7a69d26f7d1',
    '0xd9fa522f5bc6cfa40211944f2c8da785773ad99d',
    '0x9b2cc8e6a2bbb56d6be4682891a91b0e48633c72',
    '0x294dc12a20e0018f77d8e043dad60af6777f716b',
    '0x1901011a39b11271578a1283d620373abed66faa',
    '0x42c701d4e359178412d014506cfac43a67e57d6d',
    '0x6fe050dc81b98e4464d3b4461a7995a8bf3350db',
    '0x7c13d4c3e9dfa683e7a5792a9ff20cb5fd22b0c0',
    '0x9f8973fb86b35c307324ec31fd81cf565e2f4a63',
    '0xe6c7e4142b0cb24f2bfa7b9a50911375f8ee8db9',
    '0xd1cfc81c44655d635a19c7e779eb80e27fbec7b2',
    '0xeeab99a3a086084f3d740214c64d7ba03acb8bcb',
    '0x229141bafa57ab0d5bd127619003cd694a2e1d51',
    '0x4f9ad1e4e671c1f95216076637bff9face0983da',
    '0xf00d22dce56d2b243d8ffa025ed99abf6209a0d6',
    '0xd0a2e238c85be2022cc6ec81aa2a87930faa0434',
    '0xa83fceb94e7a5c2873bdb58db1ff6f498f66b285',
    '0x10e54566d611d2aa9169cda58ea3be602aaa5749',
    '0x2edb382a7eebd8a936010775a4688c2ad366070f',
    '0xd76a1fd2add1fd7d6c92e022d8584913102d205a',
    '0x4c46ea0265efb51f90d405fb27bac92ff1beb274',
    '0xb81a9a30cdb57d52f131de62aa755889b95aeec9',
    '0x2eeb17769150f0bdda1cb30f30c08d49617ba458',
    '0x48692b3b38bb7ddfe2b36efabba298f3cd5c49ce',
    '0x207004f671e20631673c03a20226c5a123ccad29',
    '0x41b3dfc4a55f8b968ecdbf2dffb0cf1b81dee5ce',
    '0x13823df65a950d85ef2bc4361ed9a169b267504f',
    '0xd8d29af3365ec03fae7606f42d83892dd77d71b2',
    '0xfc744399a6b2a559d9a23829fe778600e00eb71c',
    '0xf1963d03c77d81649698e32f1632f9164d0c0848'
  ],
  [ChainId.BNB_CHAIN]: [
    '0x4e1c5ef5298a1fc620ec213d28914f2f936093f7',
    '0xf258929a659f68ace4732e36f626d6d1544878ac',
    '0x023ff63aa03060cfe97018e2854318bea9873fd5',
    '0x1967f71df1493a4655426a030390ee143f13c8ed',
    '0x717d06b4d8bc6b71d5a2c45d8cc417930aec073d',
    '0x722edd360b53029e287dd4d1ebcfd6cb3710912f',
    '0xac3e25ea542b471921ecfdfe19d5f23add1c73e7',
    '0xffe83b744918650bb4122c53786ec17ef4a9162a',
    '0x3310cbba60ab2e5a73e79c58185aacdf93ab2aaf',
    '0x36c95fcc451fd03a586d5c2555c2c8e76d4b119d',
    '0x4116380614e693800d7b657b1ee6404d0401ef8e',
    '0xfd83d084777143b726dd32d9424c789ad7df1632',
    '0xdf41c34dc3adcce16e1a9e96af74972a21473799',
    '0x023ff63aa03060cfe97018e2854318bea9873fd5',
    '0x1967f71df1493a4655426a030390ee143f13c8ed',
    '0x3310cbba60ab2e5a73e79c58185aacdf93ab2aaf',
    '0x36c95fcc451fd03a586d5c2555c2c8e76d4b119d',
    '0x4116380614e693800d7b657b1ee6404d0401ef8e',
    '0x4e1c5ef5298a1fc620ec213d28914f2f936093f7',
    '0x717d06b4d8bc6b71d5a2c45d8cc417930aec073d',
    '0x722edd360b53029e287dd4d1ebcfd6cb3710912f',
    '0x9f5e2954e388aa2763f77e9ea607823c7a53de43',
    '0xac3e25ea542b471921ecfdfe19d5f23add1c73e7',
    '0xf258929a659f68ace4732e36f626d6d1544878ac',
    '0xfd83d084777143b726dd32d9424c789ad7df1632',
    '0xffe83b744918650bb4122c53786ec17ef4a9162a'
  ],
  [ChainId.ETHEREUM]: [],
  [ChainId.FUJI]: [],
  [ChainId.MANTLE]: []
}
