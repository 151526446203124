import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import SearchBar from 'components/SearchBar'
import useUserPositionSearch from 'hooks/pool/useUserPositionSearch'
import useUserPositionsRows from 'hooks/pool/useUserPositionsRows'
import useUserPoolV1Positions from 'hooks/pool/v1/useUserPoolV1Positions'
import useUserLBPositions from 'hooks/pool/v2/useUserLBPositions'
import useUserVaultsWithPosition from 'hooks/vault/useUserVaultsWithPositions'
import React, { useState } from 'react'
import { useAccount } from 'wagmi'

import LegacyPoolsText from './LegacyPoolsText'
import UserPositionRow from './UserPositionRow'
import VaultPositionRow from './VaultPositionRow'

interface SectionProps {
  rows: JSX.Element[]
  title: string
}

const Section = ({ rows, title }: SectionProps) => (
  <Box>
    <Heading size="sm" ml={{ base: 2, md: 6 }} mb={1}>
      {title}
    </Heading>
    <TableContainer>
      <Table
        variant="card"
        style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
      >
        <Thead>
          <Tr>
            <Th>
              <Trans>Pool name</Trans>
            </Th>
            <Th textAlign="right">
              <Trans>Pool balance</Trans>
            </Th>
            <Th textAlign="right">
              <Trans>USD value</Trans>
            </Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  </Box>
)

const VaultFarmSection = ({ rows, title }: SectionProps) => (
  <Box>
    <Heading size="sm" ml={{ base: 2, md: 6 }} mb={1}>
      {title}
    </Heading>
    <TableContainer>
      <Table
        variant="card"
        style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
      >
        <Thead>
          <Tr>
            <Th>
              <Trans>Pool name</Trans>
            </Th>
            <Th textAlign="right">
              <Trans>Pool balance</Trans>
            </Th>
            <Th textAlign="right">
              <Trans>USD value</Trans>
            </Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  </Box>
)

interface UserPositionsTableProps {
  isTabSelected: boolean
}

const UserPositionsTable = ({ isTabSelected }: UserPositionsTableProps) => {
  const { isConnected } = useAccount()
  const { openConnectModal } = useConnectModal()

  // Workaround for Chakra issue #6927 regarding responsive sizes in InputGroup
  // https://github.com/chakra-ui/chakra-ui/issues/6927
  const [isLargerThanMd] = useMediaQuery(['(min-width: 48em)'])
  function getInputGroupSize() {
    return isLargerThanMd ? 'lg' : 'md'
  }

  const { data: lbPositions, isLoading: isLoadingLbPositions } =
    useUserLBPositions({ enabled: isTabSelected })
  const { data: poolV1Positions, isLoading: isLoadingPoolV1Positions } =
    useUserPoolV1Positions({ enabled: isTabSelected })

  const {
    data: userVaultsWithPosition,
    isLoading: isLoadingUserVaultWithPosition
  } = useUserVaultsWithPosition({ enabled: isTabSelected })

  const [query, setQuery] = useState('')
  const {
    lbPositions: filteredLbPositions,
    poolV1Positions: filteredPoolV1Positions,
    vaults: filteredUserVaults
  } = useUserPositionSearch({
    lbPositions,
    poolV1Positions,
    query,
    userVaults: userVaultsWithPosition
  })

  const poolPositionsRowsProps = useUserPositionsRows({
    lbPositions: filteredLbPositions,
    positions: filteredPoolV1Positions
  })

  if (!isConnected) {
    return (
      <Center flexDir="column" gap={4} h="200px">
        <Text>
          <Trans>Connect your wallet to see your pools.</Trans>
        </Text>
        <Button
          variant="primary"
          colorScheme="accent"
          onClick={openConnectModal}
        >
          <Trans>Connect Wallet</Trans>
        </Button>
      </Center>
    )
  }

  if (
    isLoadingLbPositions ||
    isLoadingPoolV1Positions ||
    isLoadingUserVaultWithPosition
  ) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <Flex flexDir="column" gap={{ base: 8, md: 8 }} pt={{ base: 0, md: 4 }}>
      <Box mx={{ base: 0, md: 4 }}>
        <SearchBar
          placeholder={t`Search by name, symbol or address`}
          value={query}
          onValueChange={setQuery}
          size={getInputGroupSize()}
        />
      </Box>
      {filteredUserVaults.length > 0 ? (
        <Box mb={{ base: 0, md: 4 }}>
          <VaultFarmSection
            title={t`My Auto Pools`}
            rows={filteredUserVaults.map((vault, i) => (
              <VaultPositionRow key={`vault-${i}`} vault={vault} />
            ))}
          />
        </Box>
      ) : null}
      {poolPositionsRowsProps.length > 0 ? (
        <Section
          title={t`My Pools`}
          rows={poolPositionsRowsProps.map((rowProps, i) => (
            <UserPositionRow key={i} {...rowProps} />
          ))}
        />
      ) : null}
      {poolPositionsRowsProps.length === 0 &&
      filteredUserVaults.length === 0 ? (
        <VStack py={8} spacing={8}>
          <Text color="textSecondary">
            <Trans>You don&#39;t have any positions.</Trans>{' '}
          </Text>
          <LegacyPoolsText />
        </VStack>
      ) : (
        <LegacyPoolsText />
      )}
    </Flex>
  )
}

export default UserPositionsTable
