import { Button, ButtonProps } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { supportedChains } from 'constants/chains'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import useSwitchChain from 'hooks/useSwitchChain'
import React from 'react'
import { useSelectedChainId } from 'state/settings/hooks'
import { useAccount, useConfig } from 'wagmi'

const Web3Button = (props: ButtonProps) => {
  const { chain, isConnected } = useAccount()
  const { chains } = useConfig()
  const isChainUnsupported = chain ? !chains.includes(chain) : true
  const { openConnectModal } = useConnectModal()

  const { switchChain } = useSwitchChain()

  const { selectedChainId } = useSelectedChainId()
  const selectedChain = supportedChains.filter(
    (chain) => chain.id === selectedChainId
  )[0]

  const chainInUrlParam = useChainFromUrlParam()
  const targetChain = chainInUrlParam ?? selectedChain

  const currentChainId = isChainUnsupported ? selectedChain.id : chain?.id

  return isConnected &&
    (isChainUnsupported ||
      (chainInUrlParam && chainInUrlParam.id !== currentChainId)) ? (
    <Button
      {...props}
      leftIcon={undefined}
      rightIcon={undefined}
      isDisabled={false}
      onClick={() => switchChain({ chainId: targetChain.id })}
    >
      {t`Switch to ${targetChain.name}`}
    </Button>
  ) : isConnected ? (
    <Button {...props} />
  ) : (
    <Button {...props} isDisabled={false} onClick={openConnectModal}>
      <Trans>Connect Wallet</Trans>
    </Button>
  )
}

export default Web3Button
