export enum OrderType {
  ASK = 1,
  BID = 0
}

export type OrderablePair = {
  chainId: number
  name: string
  tokenX: {
    address: string
    chainId: number
    decimals: number
    logoURI: string
    symbol: string
  }
  tokenY: {
    address: string
    chainId: number
    decimals: number
    logoURI: string
    symbol: string
  }
}
