import { Box, Text, TextProps, useClipboard } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

interface CopyableErrorProps {
  error: string
  summary: string
  textProps?: TextProps
}

const CopyableError = ({ error, summary, textProps }: CopyableErrorProps) => {
  const { onCopy } = useClipboard(error)

  return (
    <Text fontSize="sm" textColor="red.500" textAlign="center" {...textProps}>
      {summary}{' '}
      <Box
        as="span"
        _hover={{ opacity: 0.5 }}
        cursor="pointer"
        fontWeight="semibold"
        onClick={onCopy}
      >
        <Trans>Copy the error</Trans>
      </Box>
    </Text>
  )
}

export default CopyableError
