import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import useEpochRewards from 'hooks/useEpochRewards'
import { useMemo } from 'react'
import { Pool as DexbarnPool, PoolQueryParam } from 'types/dexbarn'
import { Pool } from 'types/pool'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolToPool } from 'utils/poolV2'

interface UsePoolV2Props {
  orderBy: PoolQueryParam.OrderBy
  pageSize: number
  status: PoolQueryParam.Status
  excludeLowVolumePools?: boolean
  version?: PoolQueryParam.Version
}

const usePoolsV2 = ({
  excludeLowVolumePools,
  orderBy,
  pageSize,
  status,
  version
}: UsePoolV2Props) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  // fetch from dexbarn
  const fetchPoolsV2 = useDexbarnGet<DexbarnPool[]>(`/v1/pools/${chain}`)
  const result = useInfiniteQuery<DexbarnPool[]>({
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length % pageSize === 0 && lastPage.length > 0
        ? allPages.length + 1
        : undefined,
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam }) =>
      fetchPoolsV2({
        params: {
          excludeLowVolumePools,
          filterBy: '1d',
          orderBy,
          pageNum: pageParam,
          pageSize,
          status,
          version
        }
      }),
    queryKey: [
      'LBPairsQuery',
      chain,
      pageSize,
      orderBy,
      status,
      version,
      excludeLowVolumePools
    ]
  })

  // fetch epoch rewards
  const { epochRewards } = useEpochRewards()

  // convert to common pool interface
  const pools: Pool[] = useMemo(
    () =>
      result.data?.pages
        .flat()
        .map((pool) => convertDexbarnPoolToPool(pool, epochRewards || [])) ??
      [],
    [result.data, epochRewards]
  )

  return {
    ...result,
    data: pools
  }
}

export default usePoolsV2
