import { InputProps } from '@chakra-ui/react'
import CurrencyInput, { CurrencyInputProps } from 'components/CurrencyInput'
import React from 'react'

const TradeCurrencyInput = (props: CurrencyInputProps & InputProps) => (
  <CurrencyInput
    border={0}
    borderRadius={0}
    p={0}
    _focus={{ border: 0, boxShadow: 'none' }}
    fontWeight="semibold"
    fontSize="3xl"
    placeholder="0.0"
    _placeholder={{ color: 'textSecondary' }}
    currencyPickerButtonProps={{
      _dark: { _hover: { bg: 'hover' }, bg: 'transparent' },
      bg: 'transparent',
      borderRadius: '2xl',
      px: 4,
      size: 'lg',
      variant: 'outline'
    }}
    {...props}
  />
)

export default TradeCurrencyInput
