import { ChainId } from '@traderjoe-xyz/sdk-core'
import { zeroAddress } from 'viem'

export const MEME_COIN_FARM_REWARDER_ADDRESS: {
  [chainId in Exclude<ChainId, ChainId.MANTLE>]: string
} = {
  [ChainId.FUJI]: zeroAddress,
  [ChainId.ARBITRUM_ONE]: zeroAddress,
  [ChainId.ARB_GOERLI]: zeroAddress,
  [ChainId.ETHEREUM]: zeroAddress,
  [ChainId.BNB_TESTNET]: zeroAddress,
  [ChainId.BNB_CHAIN]: zeroAddress,
  [ChainId.AVALANCHE]: '0xc1d6d8a58Ed72f1bb6Bd98b76dEC14A7632a4d86'
}
