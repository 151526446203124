import { CNATIVE, Token } from '@traderjoe-xyz/sdk-core'
import { useMemo } from 'react'
import { erc20Abi, getAddress, isAddress } from 'viem'
import { useReadContracts } from 'wagmi'

import useChainId from './useChainId'

interface UseSdkCurrenciesProps {
  addresses: (string | null | undefined)[]
  symbolByAddressOverrides?: Record<string, string> // allow to override the symbol of a token
}

const useSdkCurrencies = ({
  addresses,
  symbolByAddressOverrides
}: UseSdkCurrenciesProps) => {
  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)

  const contracts = (
    addresses.filter((address) => address && isAddress(address)) as string[]
  ).map((address) => ({
    abi: erc20Abi,
    address: getAddress(address),
    chainId
  }))

  const { data, isLoading } = useReadContracts({
    contracts: [
      ...contracts.map((contract) => ({
        ...contract,
        functionName: 'decimals'
      })),
      ...contracts.map((contract) => ({
        ...contract,
        functionName: 'symbol'
      })),
      ...contracts.map((contract) => ({
        ...contract,
        functionName: 'name'
      }))
    ]
  })

  const tokens = useMemo(() => {
    if (!data) {
      return addresses.map((address) => {
        if (address === nativeCurrency?.symbol) {
          return nativeCurrency
        } else {
          return undefined
        }
      })
    }

    const diff = addresses.length - data.length / 3
    let offset = 0
    return addresses.map((address, index) => {
      if (address === nativeCurrency?.symbol) {
        offset += 1
        return nativeCurrency
      }
      if (!address || !isAddress(address)) {
        offset += 1
        return undefined
      }
      const idx = index - offset
      const decimals = data[idx].result as number | undefined
      const symbol = data[idx + addresses.length - diff].result as
        | string
        | undefined
      const name = data[idx + addresses.length * 2 - diff * 2].result as
        | string
        | undefined
      if (!decimals || !symbol || !name) return undefined
      const _symbol = symbolByAddressOverrides?.[address] ?? symbol
      return new Token(chainId, getAddress(address), decimals, _symbol, name)
    })
  }, [data, chainId, addresses, nativeCurrency, symbolByAddressOverrides])

  return { isLoading, tokens }
}

export default useSdkCurrencies
