import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { HStack, IconButton } from '@chakra-ui/react'
import React from 'react'

interface ChartZoomButtonsProps {
  maxValue: number
  minValue: number
  onChange: (value: number) => void
  step: number
  value: number
}

const ChartZoomButtons = ({
  maxValue,
  minValue,
  onChange,
  step,
  value
}: ChartZoomButtonsProps) => {
  return (
    <HStack>
      <IconButton
        bg="bgTertiary"
        size="sm"
        aria-label="zoom out"
        borderRadius="full"
        icon={<MinusIcon boxSize={3} />}
        isDisabled={value >= maxValue}
        onClick={() => onChange(Math.min(value + step, maxValue))}
      />
      <IconButton
        bg="bgTertiary"
        size="sm"
        borderRadius="full"
        aria-label="zoom in"
        icon={<AddIcon boxSize={3} />}
        isDisabled={value <= minValue}
        onClick={() => onChange(Math.max(value - step, minValue))}
      />
    </HStack>
  )
}

export default ChartZoomButtons
