import { Divider, Flex, HStack, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { ArrowLeftIcon } from 'theme/icons'
import { WalletMenuEnum } from 'types/walletMenu'

import WalletMenuItem from '../ConnectedScreen/WalletMenuItem'
import ActivityList from './ActivityList'

interface ActivityScreenProps {
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
  walletAddress: string
}

const ActivityScreen = ({
  setWalletMenuDisplay,
  walletAddress
}: ActivityScreenProps) => {
  return (
    <>
      <HStack px={3} align="center" pb={4}>
        <WalletMenuItem
          columnGap={4}
          onClick={() => setWalletMenuDisplay(WalletMenuEnum.Default)}
        >
          <ArrowLeftIcon />
          <Flex flexDir="column" alignItems="flex-start">
            <Text fontWeight="bold" fontSize="lg" textColor="textPrimary">
              <Trans>Activity</Trans>
            </Text>
          </Flex>
        </WalletMenuItem>
      </HStack>

      <Divider />

      <ActivityList walletAddress={walletAddress} />
    </>
  )
}

export default ActivityScreen
