import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Vault as DexbarnVault } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnVaultIntoVault } from 'utils/vault'

interface UseVaultProps {
  vaultAddress?: string
}

const useVault = ({ vaultAddress }: UseVaultProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)
  const fetchVault = useDexbarnGet<DexbarnVault>(
    `/v1/vaults/${chain}/${vaultAddress}`
  )
  return useQuery({
    enabled: !!vaultAddress,
    queryFn: () => fetchVault(),
    queryKey: ['VaultQuery', chain, vaultAddress],
    select: (vault) => convertDexbarnVaultIntoVault(vault)
  })
}
export default useVault
