import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Flex, HStack, Text } from '@chakra-ui/react'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { TradeBestPath } from 'types/trade'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

import RouteDetailsStep from './RouteDetailsStep'

interface RouteDetailsProps {
  trade: TradeBestPath
}

const RouteDetails = ({ trade }: RouteDetailsProps) => {
  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)

  const { path } = trade

  const tokenInSymbol = isWrappedNativeCurrency(path[0].tokenInId, chainId)
    ? nativeCurrency.symbol
    : path[0].tokenInSymbol

  return (
    <Flex align="center" flexWrap="wrap" gap={2}>
      <HStack>
        <CurrencyLogo
          address={
            isWrappedNativeCurrency(path[0].tokenInId, chainId)
              ? undefined
              : path[0].tokenInId
          }
          symbol={tokenInSymbol}
          boxSize={5}
        />
        <Text fontSize="sm" fontWeight="semibold">
          {tokenInSymbol}
        </Text>
      </HStack>
      <ArrowForwardIcon boxSize="14px" color="textSecondary" />
      {path.map((pair, i) => {
        return (
          <RouteDetailsStep
            key={i}
            pair={pair}
            showForwardArrow={path.length - 1 > i}
          />
        )
      })}
    </Flex>
  )
}

export default RouteDetails
