import { Button, Center, Image, SimpleGrid, Text } from '@chakra-ui/react'
import { LiquidityDistribution } from '@traderjoe-xyz/sdk-v2'
import BidAskImage1x from 'assets/distribution_bid_ask_1x.webp'
import BidAskImage2x from 'assets/distribution_bid_ask_2x.webp'
import BidAskImage3x from 'assets/distribution_bid_ask_3x.webp'
import CurveImage1x from 'assets/distribution_curve_1x.webp'
import CurveImage2x from 'assets/distribution_curve_2x.webp'
import CurveImage3x from 'assets/distribution_curve_3x.webp'
import SpotImage1x from 'assets/distribution_spot_1x.webp'
import SpotImage2x from 'assets/distribution_spot_2x.webp'
import SpotImage3x from 'assets/distribution_spot_3x.webp'
import React from 'react'

const getDistributionImage = (
  distribution: LiquidityDistribution
): JSX.Element => {
  switch (distribution) {
    case LiquidityDistribution.BID_ASK:
      return (
        <Image
          fallbackSrc={BidAskImage2x}
          srcSet={`${BidAskImage1x} 1x, ${BidAskImage2x} 2x, ${BidAskImage3x} 3x`}
        />
      )
    case LiquidityDistribution.CURVE:
      return (
        <Image
          fallbackSrc={CurveImage2x}
          srcSet={`${CurveImage1x} 1x, ${CurveImage2x} 2x, ${CurveImage3x} 3x`}
        />
      )
    case LiquidityDistribution.SPOT:
      return (
        <Image
          fallbackSrc={SpotImage2x}
          srcSet={`${SpotImage1x} 1x, ${SpotImage2x} 2x, ${SpotImage3x} 3x`}
        />
      )
  }
}

const getDistributtionTitle = (distribution: LiquidityDistribution): string => {
  switch (distribution) {
    case LiquidityDistribution.BID_ASK:
      return 'Bid-Ask'
    case LiquidityDistribution.CURVE:
      return 'Curve'
    case LiquidityDistribution.SPOT:
      return 'Spot'
  }
}

interface DistributionOptionsProps {
  distribution: LiquidityDistribution
  onDistributionChange: (distribution: LiquidityDistribution) => void
}

const DistributionOptions = ({
  distribution,
  onDistributionChange
}: DistributionOptionsProps) => {
  const options = [
    LiquidityDistribution.SPOT,
    LiquidityDistribution.CURVE,
    LiquidityDistribution.BID_ASK
  ]

  return (
    <SimpleGrid columns={3} gap={2} w="full">
      {options.map((option, i) => (
        <Button
          data-cy={`distribution-shape-${i}-button`}
          key={i}
          variant="unstyled"
          border="1px"
          borderColor="border"
          borderRadius="lg"
          h="fit-content"
          py={3}
          _hover={{ bg: 'hover' }}
          isActive={distribution === option}
          _active={{ bg: 'accent.300', borderColor: 'accent.500' }}
          _dark={{ _active: { bg: 'rgb(50, 53, 102)' } }}
          onClick={() => onDistributionChange(option)}
        >
          <Center flexDir="column">
            {getDistributionImage(option)}
            <Text fontSize="xs" fontWeight="semibold">
              {getDistributtionTitle(option)}
            </Text>
          </Center>
        </Button>
      ))}
    </SimpleGrid>
  )
}

export default DistributionOptions
