import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { addToken, removeToken } from './actions'
import { LocalToken } from './reducer'

const useAddToken = () => {
  const dispatch = useAppDispatch()
  const addTokenToSaved = useCallback(
    (token: LocalToken) => {
      dispatch(addToken(token))
    },
    [dispatch]
  )
  return { addTokenToSaved }
}

const useRemoveToken = () => {
  const dispatch = useAppDispatch()
  const removeTokenFromSaved = useCallback(
    (tokenAddress: string) => {
      dispatch(removeToken(tokenAddress))
    },
    [dispatch]
  )
  return { removeTokenFromSaved }
}

const useSavedTokens = () => {
  return useAppSelector((state) => state.tokens.savedTokens)
}

export { useAddToken, useRemoveToken, useSavedTokens }
