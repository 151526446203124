import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Token } from '@traderjoe-xyz/sdk-core'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useMemo } from 'react'
import { OrderType } from 'types/limitOrder'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'
import { getAddress, zeroAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseBatchPlaceLimitOrdersProps {
  amounts?: bigint[]
  binIds?: number[]
  binStep?: number
  enabled?: boolean
  isNative?: boolean
  onOrdersSuccess?: () => void
  orderType?: OrderType
  tokenX?: Token
  tokenY?: Token
  totalAmount?: bigint
}

const useBatchPlaceLimitOrders = ({
  amounts,
  binIds,
  binStep,
  enabled = true,
  isNative,
  onOrdersSuccess,
  orderType,
  tokenX,
  tokenY,
  totalAmount
}: UseBatchPlaceLimitOrdersProps) => {
  const chainId = useChainId()
  const walletChainId = useAccount().chain?.id

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // prepare 'batchPlaceOrdersSamePair' contract write
  const args = useMemo(
    () =>
      tokenX?.address &&
      tokenY?.address &&
      binStep &&
      binIds &&
      amounts &&
      binIds.length > 0 &&
      binIds.length === amounts.length &&
      orderType !== undefined
        ? ([
            isWrappedNativeCurrency(tokenX.address, chainId)
              ? zeroAddress
              : getAddress(tokenX.address),
            isWrappedNativeCurrency(tokenY.address, chainId)
              ? zeroAddress
              : getAddress(tokenY.address),
            binStep,
            binIds.map((binId, index) => ({
              amount: amounts[index],
              binId,
              orderType
            }))
          ] as const)
        : undefined,
    [tokenX, tokenY, binStep, amounts, binIds, orderType, chainId]
  )

  const { data: config } = useSimulateContract({
    abi: LimitOrderManagerABI,
    address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId]),
    args,
    chainId,
    functionName: 'batchPlaceOrdersSamePair',
    query: {
      enabled: !!args && chainId === walletChainId && enabled,
      gcTime: 0
    },

    value: isNative && totalAmount ? totalAmount : BigInt(0)
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Place limit orders`
        addRecentTransaction({ description, hash })
        addTransactionToast({ description, hash })
      }
    }
  })

  const batchPlaceOrdersSamePair = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    hash: data,
    onTransactionSuccess: onOrdersSuccess
  })

  return {
    batchPlaceOrdersSamePair,
    data,
    isLoading: isPending || isWaitingForTransaction
  }
}

export default useBatchPlaceLimitOrders
