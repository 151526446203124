import { t } from '@lingui/macro'

export const extractErrorMessageFromViemError = (error: string): string => {
  const limitIndex = error.indexOf('Contract Call')
  if (limitIndex !== -1) {
    const extractedError = error.substring(0, limitIndex).trim()
    return extractedError
  }
  return t`Error: there was a problem preparing the transaction. Please try again.`
}
