import { ChevronRightIcon } from '@chakra-ui/icons'
import { Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MyActivityIcon, MyNftIcon, MyPoolIcon } from 'theme/icons'
import { WalletMenuEnum } from 'types/walletMenu'
import { getChainSlug } from 'utils/chains'

import WalletMenuItem from './WalletMenuItem'
import WalletSettings from './WalletSettings'

interface WalletMyInfoProps {
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
  walletAddress: string
}

const WalletMyInfo = ({
  setWalletMenuDisplay,
  walletAddress
}: WalletMyInfoProps) => {
  const chain = useActiveChain()
  const navigate = useNavigate()

  return (
    <VStack w="full" alignItems="flex-start" py={4} px={3} rowGap={2}>
      <WalletMenuItem
        onClick={() => navigate(`/${getChainSlug(chain.id)}/pool?tab=my-pools`)}
      >
        <MyPoolIcon boxSize="20px" mr={4} fill="textSecondary" />
        <Text fontWeight="semibold">
          <Trans>My Pools</Trans>
        </Text>
        <ChevronRightIcon textColor="textSecondary" ml="auto" boxSize="20px" />
      </WalletMenuItem>
      <WalletMenuItem
        onClick={() =>
          window.open(`https://joepegs.com/profile/${walletAddress}`)
        }
      >
        <MyNftIcon boxSize="20px" mr={4} fill="textSecondary" />
        <Text fontWeight="semibold">
          <Trans>My NFTs</Trans>
        </Text>
        <ChevronRightIcon textColor="textSecondary" ml="auto" boxSize="20px" />
      </WalletMenuItem>
      <WalletMenuItem
        onClick={() => setWalletMenuDisplay(WalletMenuEnum.Activity)}
      >
        <MyActivityIcon boxSize="20px" mr={4} fill="textSecondary" />
        <Text fontWeight="semibold">
          <Trans>Activity</Trans>
        </Text>
        <ChevronRightIcon textColor="textSecondary" ml="auto" boxSize="20px" />
      </WalletMenuItem>
      <WalletSettings setWalletMenuDisplay={setWalletMenuDisplay} />
    </VStack>
  )
}

export default WalletMyInfo
