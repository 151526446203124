import {
  Box,
  Center,
  Grid,
  Heading,
  Hide,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import useGetMemeFarms from 'hooks/memeFarm/useGetMemeFarms'
import useGetTokensTotalSupply from 'hooks/memeFarm/useGetTokensTotalSupply'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import React, { useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import { formatUnits } from 'viem'

import MemeFarmMobileRow from './MemeFarmMobileRow'
import MemeFarmRow from './MemeFarmRow'

const MemeFarms = () => {
  // fetch meme farms
  const {
    data: memeFarms,
    isLoadingStakedAmounts,
    isLoadingUserStakedAmounts
  } = useGetMemeFarms()

  // fetch total supply of meme tokens
  const tokenAddresses = useMemo(() => {
    return memeFarms?.map((farm) => farm.memeToken.tokenAddress) || []
  }, [memeFarms])
  const { data: tokensTotalSupply } = useGetTokensTotalSupply({
    tokens: tokenAddresses
  })
  const memeTokenSupplyStakedPercentages = useMemo(() => {
    return memeFarms?.map((farm, i) => {
      const totalSupply = tokensTotalSupply?.[i]
      const totalSupplyNum = totalSupply
        ? Number(formatUnits(totalSupply, farm.memeToken.decimals))
        : undefined
      return totalSupplyNum && farm.staked
        ? (Number(farm.staked) / totalSupplyNum) * 100
        : undefined
    })
  }, [memeFarms, tokensTotalSupply])

  return (
    <Box pt={{ base: 4, md: 12 }} bg="bgSecondary">
      <Grid
        templateColumns={{
          base: 'minmax(0, 1fr)',
          xl: 'minmax(0, 1fr) minmax(0, 1.3fr)'
        }}
        maxW={{ '2xl': '1600px', base: '1400px' }}
        margin="0 auto"
        justifyContent="space-between"
        pb={8}
        px={{ base: 4, md: 0 }}
        gap={8}
      >
        <VStack spacing={0} align="flex-start" flexShrink={0}>
          <Heading>
            <Trans>Meme Coin Rush</Trans>
          </Heading>
          <Text color="textSecondary" fontSize="sm">
            <Trans>
              Stake to earn points, the more you stake the more points you
              accrue.
            </Trans>
          </Text>
          <Text color="textSecondary" fontSize="sm">
            <Trans>
              At the end of each weekly epoch, rewards are distributed based on
              final standings.
            </Trans>
          </Text>
        </VStack>
      </Grid>

      <Box bg="bgPrimary">
        <Box
          maxW={{ '2xl': '1600px', base: '1400px' }}
          margin="0 auto"
          minH="60vh"
          pt={4}
          pb={10}
        >
          {isLoadingStakedAmounts ? (
            <Center minH="60vh">
              <Spinner />
            </Center>
          ) : (
            <>
              <Hide below="md">
                <TableContainer>
                  <Table
                    variant="card"
                    style={{
                      borderCollapse: 'separate',
                      borderSpacing: '0 8px'
                    }}
                  >
                    <Thead>
                      <Tr>
                        <Th>
                          <Trans>Token</Trans>
                        </Th>
                        <Th>
                          <Trans>Total Staked</Trans>
                        </Th>
                        <Th>
                          <Trans>Total Supply Staked</Trans>
                        </Th>
                        <Th>
                          <Trans>Your position</Trans>
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {memeFarms?.map((farm, i) => {
                        return (
                          <MemeFarmRow
                            key={farm.memeFarmId}
                            farm={farm}
                            memeTokenSupplyStakedPercentage={
                              memeTokenSupplyStakedPercentages?.[i]
                            }
                            isLoadingUserPositions={isLoadingUserStakedAmounts}
                          />
                        )
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Hide>

              <Hide above="md">
                <VStack spacing={4} px={4}>
                  {memeFarms?.map((farm, i) => {
                    return (
                      <MemeFarmMobileRow
                        key={farm.memeFarmId}
                        farm={farm}
                        memeTokenSupplyStakedPercentage={
                          memeTokenSupplyStakedPercentages?.[i]
                        }
                        isLoadingUserPositions={isLoadingUserStakedAmounts}
                      />
                    )
                  })}
                </VStack>
              </Hide>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const MemeFarmsWithRedirect = () => {
  const chain = useChainFromUrlParam()

  if (chain?.id === ChainId.AVALANCHE) {
    return <MemeFarms />
  }

  return <Navigate to="/404" replace />
}

export default MemeFarmsWithRedirect
