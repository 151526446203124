import { Box, Flex, InputProps } from '@chakra-ui/react'
import SearchBar from 'components/SearchBar'
import React from 'react'
import { searchEnterSearch } from 'utils/measure'

interface SearchPanelProps {
  focused: boolean
  query: string
  queryMinLength: number
  searchResults: JSX.Element
  setFocused: React.Dispatch<React.SetStateAction<boolean>>
  setQuery: React.Dispatch<React.SetStateAction<string>>
  inputRef?: React.RefObject<HTMLInputElement>
  inputRightElement?: JSX.Element
  panelMaxWidth?: string
  resultsContainerRef?: React.RefObject<HTMLDivElement>
  searchPanelRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
}

const SearchPanel = ({
  focused,
  inputRef,
  inputRightElement,
  panelMaxWidth,
  query,
  queryMinLength,
  resultsContainerRef,
  searchPanelRef,
  searchResults,
  setFocused,
  setQuery,
  ...props
}: SearchPanelProps & InputProps) => {
  const showResultsPanel = focused && query.length >= queryMinLength
  return (
    <Flex
      ref={searchPanelRef}
      w="full"
      maxW={panelMaxWidth ?? '760px'}
      pos="relative"
      justify="space-between"
    >
      <SearchBar
        className={showResultsPanel ? 'show-results-panel' : undefined}
        w="full"
        bg="bgPrimary"
        _dark={{ bg: 'transparent' }}
        borderWidth="1px"
        borderStyle="solid"
        borderColor="border"
        color="textPrimary"
        transition="150ms background ease-in-out"
        size="lg"
        fontSize="16px"
        _focus={{
          borderColor: 'accent.500',
          boxShadow: 'none',
          outline: 'none'
        }}
        sx={{
          '&.show-results-panel': {
            borderBottomColor: 'border',
            borderBottomRadius: 0,
            outline: 'none'
          }
        }}
        value={query}
        onValueChange={setQuery}
        onFocus={() => {
          searchEnterSearch()
          setFocused(true)
        }}
        inputRef={inputRef}
        inputRightElement={inputRightElement}
        {...props}
      />
      {showResultsPanel ? (
        <Box
          pos="absolute"
          zIndex={5}
          top="48px"
          right="0px"
          left="0px"
          w="full"
          maxH="480px"
          overflowY="auto"
          borderBottomRadius="12px"
          bg="bgPrimary"
          border="1px solid"
          borderColor="accent.500"
          borderTop={0}
          boxShadow="0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
          ref={resultsContainerRef}
        >
          {searchResults}
        </Box>
      ) : null}
    </Flex>
  )
}

export default SearchPanel
