import { ChevronRightIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MySettingsIcon } from 'theme/icons'
import { WalletMenuEnum } from 'types/walletMenu'

import WalletMenuItem from './WalletMenuItem'

interface WalletSettingsProps {
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
}

const WalletSettings = ({ setWalletMenuDisplay }: WalletSettingsProps) => {
  return (
    <Flex w="full" justifyContent="flex-start" flexDir="column" rowGap={2}>
      <WalletMenuItem
        onClick={() => setWalletMenuDisplay(WalletMenuEnum.Settings)}
      >
        <MySettingsIcon boxSize="20px" mr={4} fill="textSecondary" />
        <Text fontWeight="semibold">
          <Trans>Settings</Trans>
        </Text>
        <ChevronRightIcon textColor="textSecondary" ml="auto" boxSize="20px" />
      </WalletMenuItem>
    </Flex>
  )
}

export default WalletSettings
