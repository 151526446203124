import { Button } from '@chakra-ui/react'
import React from 'react'

const PriceShortcutButton = ({
  marketPrice,
  onValueChange,
  percent
}: {
  marketPrice: number
  onValueChange: (value: string) => void
  percent: number
}) => {
  return (
    <Button
      data-cy={`place-order-set-price-button-${percent}`}
      onClick={() => {
        const price = marketPrice * (1 + percent / 100)
        onValueChange(price.toFixed(20))
      }}
    >
      {`${percent >= 0 ? '+' : ''}${percent}%`}
    </Button>
  )
}

export default PriceShortcutButton
