import { getUnixTime, startOfDay, subDays, subMonths, subWeeks } from 'date-fns'
import dayjs from 'dayjs'

export function oneHundredEightyDays(): number {
  return getUnixTime(startOfDay(subMonths(Date.now(), 6)))
}

export function ninetyDays(): number {
  return getUnixTime(startOfDay(subMonths(Date.now(), 3)))
}

export function thirtyDays(): number {
  return getUnixTime(startOfDay(subMonths(Date.now(), 1)))
}

export function sevenDays(): number {
  return getUnixTime(startOfDay(subWeeks(Date.now(), 1)))
}

export function oneDay(): number {
  return getUnixTime(startOfDay(subDays(Date.now(), 1)))
}

export function roundToNearest5Minutes(date: dayjs.Dayjs) {
  const minutes = date.minute()
  const rounding = minutes % 5
  return date.subtract(rounding, 'minute').second(0).millisecond(0)
}
