import { RawAxiosRequestConfig } from 'axios'
import { joebarnClient, joebarnTestnetClient } from 'constants/joebarn'
import { useCallback } from 'react'

import useActiveChain from './useActiveChain'

const useJoebarnClient = () => {
  const chain = useActiveChain()
  return chain.testnet ? joebarnTestnetClient : joebarnClient
}

export const useJoebarnGet = <T = any, D = any>(
  url: string,
  config?: RawAxiosRequestConfig<D>
) => {
  const client = useJoebarnClient()
  return useCallback(
    async (overridesConfig?: RawAxiosRequestConfig<D>) => {
      const { data } = await client.get<T>(url, overridesConfig ?? config)
      return data
    },
    [client, url, config]
  )
}

export const useJoebarnPost = <T = any, D = any>(url: string, body?: D) => {
  const client = useJoebarnClient()
  return useCallback(async () => {
    const { data } = await client.post<T>(url, body)
    return data
  }, [client, url, body])
}
