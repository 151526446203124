import { ChainId, CNATIVE, WNATIVE } from '@traderjoe-xyz/sdk-core'
import { Token } from 'hooks/useTopTradedTokens'

import { getChainSlug } from './chains'

export interface TokenRow {
  address: string
  chainId: ChainId
  href: string
  position: number
  price: number
  volume: number
  pctChange?: number
  symbol?: string
}

export const convertTokensToTokenRows = (
  tokens: Token[],
  chainId: ChainId
): TokenRow[] => {
  return tokens.map((token, i) => {
    const isWrappedNative =
      WNATIVE[chainId].address.toLowerCase() === token.address.toLowerCase()
    const symbol = isWrappedNative
      ? CNATIVE.onChain(chainId).symbol
      : token.symbol
    const href = isWrappedNative
      ? `/${getChainSlug(chainId)}/trade`
      : `/${getChainSlug(chainId)}/trade?outputCurrency=${token.address}`
    return {
      address: token.address,
      chainId,
      href,
      pctChange: token.pctChange,
      position: i + 1,
      price: token.price,
      symbol,
      volume: token.volume
    }
  })
}
