import { Box, Flex } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { CNATIVE, WNATIVE } from '@traderjoe-xyz/sdk-core'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import Web3Button from 'components/Web3Button'
import useFarmHarvest from 'hooks/farm/useFarmHarvest'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { FarmRewardToken } from 'types/dexbarn'
import { formatUnits, getAddress } from 'viem'

interface PoolRewardsClaimProps {
  farmAddress: string
  pendingRewards: bigint[]
  poolId: string
  rewardTokens: FarmRewardToken[]
  refetchAll?: () => void
}

const PoolRewardsClaim = ({
  farmAddress,
  pendingRewards,
  poolId,
  refetchAll,
  rewardTokens
}: PoolRewardsClaimProps) => {
  const chainId = useChainId()
  const tokenAddresses = rewardTokens.map((token) =>
    token.symbol === CNATIVE.onChain(chainId).symbol
      ? WNATIVE[chainId].address
      : getAddress(token.id)
  )
  const symbols = rewardTokens.map((token) => token.symbol)

  const formattedRewards = pendingRewards.map((amount, idx) => {
    const token = rewardTokens[idx]
    return {
      amount: formatUnits(amount, token.decimals),
      symbol: token.symbol
    }
  })

  const { harvest, isLoading: isHarvesting } = useFarmHarvest({
    farmAddress,
    formattedRewards,
    onSuccess: () => {
      refetchAll?.()
    },
    poolId
  })

  return (
    <Box>
      <CurrencyAmountsPanel
        title={t`Claimable Rewards`}
        tokens={tokenAddresses}
        tokenAmounts={formattedRewards.map((reward) => Number(reward.amount))}
        tokenSymbols={symbols}
      />
      <Flex
        flexDirection="column"
        w="full"
        px={{ base: 4, md: 8 }}
        pb={{ base: 4, md: 8 }}
      >
        <Web3Button
          data-cy="launch-deposit-button"
          colorScheme="accent"
          variant="primary"
          size="xl"
          w="full"
          isDisabled={!harvest}
          isLoading={isHarvesting}
          loadingText={t`Harvesting rewards`}
          onClick={() => harvest?.()}
        >
          <Trans>Claim</Trans>
        </Web3Button>
      </Flex>
    </Box>
  )
}

export default PoolRewardsClaim
