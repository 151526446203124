import { HStack, Link, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import useChainId from 'hooks/useChainId'
import PoolTableNameColumn from 'pages/Pool/PoolTableNameColumn'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { LBPool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailV2Url } from 'utils/poolUrl'

const Value = ({ label, value }: { label: string; value: string }) => {
  return (
    <VStack spacing={0} align="flex-start">
      <Text fontSize="sm" textColor="textSecondary">
        {label}
      </Text>
      <HStack>
        <Text fontSize="sm" fontWeight="semibold">
          {value}
        </Text>
      </HStack>
    </VStack>
  )
}

interface MemeFarmMobileRowProps {
  lbPool: LBPool
}

const MemeFarmMobileRow = ({ lbPool }: MemeFarmMobileRowProps) => {
  const chainId = useChainId()
  const href = getPoolDetailV2Url(
    lbPool.lbPoolVersion,
    lbPool.lbBinStep,
    chainId,
    lbPool.tokenX.address,
    lbPool.tokenY.address
  )

  const fmtRewards =
    lbPool.lbRewards
      ?.map((reward) => {
        return ` ${formattedNum(reward.rewardsPerDay, { places: 0 })} ${
          reward.token.symbol
        }`
      })
      .join(',') || ''

  const fmtRewardsApr = `${(
    (lbPool.lbRewards?.reduce((acc, reward) => {
      return acc + (reward.rewardsApr || 0)
    }, 0) || 0) * 100
  ).toFixed(2)}%`

  return (
    <Link as={RouterLink} _hover={{ textDecor: 'none' }} to={href}>
      <PoolTableNameColumn
        token0={lbPool.tokenX}
        token1={lbPool.tokenY}
        fees={`${lbPool.feePct}%`}
      />
      <SimpleGrid columns={3} gap={2} pt={2}>
        <Value
          label="TVL"
          value={formattedNum(lbPool.liquidityUsd, { usd: true })}
        />
        <Value label="Rewards / day" value={fmtRewards} />
        <Value label="Rewards APR" value={fmtRewardsApr} />
      </SimpleGrid>
    </Link>
  )
}

export default MemeFarmMobileRow
