import { Token } from '@traderjoe-xyz/sdk-core'
import { LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import { LBPoolVersion } from 'types/pool'
import { formatUnits } from 'viem'
import { useReadContracts } from 'wagmi'

import useGetAllLBPairs from './useGetAllLBPairs'

interface UseGetLbPoolWithHighestLiquidityProps {
  version: LBPoolVersion
  tokenX?: Token
  tokenY?: Token
}

const useGetLbPoolWithHighestLiquidity = ({
  tokenX,
  tokenY,
  version
}: UseGetLbPoolWithHighestLiquidityProps) => {
  const chainId = useChainId()

  const tokenXAddress = tokenX?.address
  const tokenYAddress = tokenY?.address

  const { data: existingPairs, isLoading: isLoadingPairs } = useGetAllLBPairs({
    tokenXAddress,
    tokenYAddress,
    version
  })

  const { data, isLoading } = useReadContracts({
    contracts: [
      ...(existingPairs?.map((pair) => ({
        abi: LBPairV21ABI,
        address: pair.LBPair,
        chainId,
        functionName: 'getReserves'
      })) || []),
      ...(existingPairs?.map((pair) => ({
        abi: LBPairV21ABI,
        address: pair.LBPair,
        chainId,
        functionName: 'getActiveId'
      })) || []),
      ...(existingPairs?.map((pair) => ({
        abi: LBPairV21ABI,
        address: pair.LBPair,
        chainId,
        functionName: 'getTokenX'
      })) || []),
      ...(existingPairs?.map((pair) => ({
        abi: LBPairV21ABI,
        address: pair.LBPair,
        chainId,
        functionName: 'getTokenY'
      })) || [])
    ],
    query: { enabled: !!existingPairs && !isLoadingPairs }
  })

  const { data: tokenPricesUSD } = useTokenPriceUSD({
    enabled: !!tokenXAddress && !!tokenYAddress,
    tokens: tokenXAddress && tokenYAddress ? [tokenXAddress, tokenYAddress] : []
  })
  const tokenXPriceUSD = tokenPricesUSD?.[0]
  const tokenYPriceUSD = tokenPricesUSD?.[1]

  // get pairs
  const pairs =
    data && tokenX && tokenY && tokenXPriceUSD && tokenYPriceUSD
      ? existingPairs?.map((pair, i) => {
          const reserves = data[i].result as [bigint, bigint]
          const reserveX = reserves[0]
          const reserveY = reserves[1]
          const reservesUSD =
            Number(formatUnits(reserveX, tokenX.decimals)) * tokenXPriceUSD +
            Number(formatUnits(reserveY, tokenY.decimals)) * tokenYPriceUSD

          return {
            ...pair,
            activeId: data[i + existingPairs.length].result as number,
            reserves,
            reservesUSD,
            tokenXAddr: data[i + existingPairs.length * 2].result as string,
            tokenYAddr: data[i + existingPairs.length * 3].result as string
          }
        })
      : undefined

  // get pair with highest reserves USD
  const highestLiquidityPair =
    pairs && pairs.length > 0
      ? pairs.reduce((prev, current) =>
          prev.reservesUSD > current.reservesUSD ? prev : current
        )
      : undefined

  return { highestLiquidityPair, isLoading: isLoadingPairs || isLoading }
}

export default useGetLbPoolWithHighestLiquidity
