import { Box, Flex, HStack, Td } from '@chakra-ui/react'
import React from 'react'

interface UserPositionValueTdProps {
  pooledTokens0?: string
  pooledTokens1?: string
  symbol0?: string
  symbol1?: string
}

const UserPositionValueTd = ({
  pooledTokens0,
  pooledTokens1,
  symbol0,
  symbol1
}: UserPositionValueTdProps) => (
  <Td>
    <Flex as="span" justifyContent="flex-end">
      <HStack columnGap={1}>
        <Flex columnGap={1}>
          <Box>{pooledTokens0}</Box>
          <Box fontWeight="normal" textColor="textSecondary">
            {symbol0}
          </Box>
        </Flex>
        <Box textColor="textSecondary">•</Box>
        <Flex columnGap={1}>
          <Box>{pooledTokens1}</Box>
          <Box fontWeight="normal" textColor="textSecondary">
            {symbol1}
          </Box>
        </Flex>
      </HStack>
    </Flex>
  </Td>
)

export default UserPositionValueTd
