import { Flex, Heading, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import PageHelmet from 'components/PageHelmet'
import useChainId from 'hooks/useChainId'
import useIsChainSunset from 'hooks/useIsChainSunset'
import React from 'react'
import { useLocation } from 'react-router-dom'

import BoostPoolsTable from './BoostPools/BoostPoolsTable'
import HomeTiles from './HomeTiles'
import JoepegSection from './Joepeg/JoepegSection'
import TokensRanking from './TokensRanking'

const Home = () => {
  const location = useLocation()
  const isChainSunset = useIsChainSunset()
  const chainId = useChainId()

  return (
    <>
      <PageHelmet url={location.pathname} />
      <Flex
        flexDir="column"
        gap={{ base: 10, md: 14 }}
        pt={{ base: 12, md: '80px' }}
        maxW={{ '2xl': '1600px', base: '1416px' }}
        margin="0 auto"
        minH="calc(100vh - 80px)"
        pb={{ base: 8, md: 16 }}
        align="center"
      >
        <Heading textAlign="center" size="2xl" color="textPrimary">
          <Trans>One-stop decentralized trading</Trans>
        </Heading>
        <HomeTiles />
        <VStack
          mt={{ base: 4, md: 8 }}
          maxW="1200px"
          w="full"
          spacing={{ base: 6, md: 20 }}
          align="flex-start"
          px={{ base: 4, xl: 0 }}
        >
          {chainId === ChainId.AVALANCHE ? <BoostPoolsTable /> : null}
          <JoepegSection />
          {!isChainSunset ? <TokensRanking /> : null}
        </VStack>
      </Flex>
    </>
  )
}

export default Home
