import {
  Box,
  Flex,
  Grid,
  Heading,
  Hide,
  HStack,
  Image,
  ListItem,
  Show,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  UnorderedList,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { JOE_ADDRESS } from '@traderjoe-xyz/sdk'
import veJoeImage from 'assets/stake/veJoe.webp'
import AnalyticsStat from 'components/AnalyticsStat'
import BackButton from 'components/BackButton'
import PageHelmet from 'components/PageHelmet'
import { STAKE_HELMET_DESCRIPTION, STAKE_HELMET_TITLE } from 'constants/stake'
import useVeJoeStaking, {
  useVeJoeUserPosition
} from 'hooks/stake/useVeJoeStaking'
import useChainId from 'hooks/useChainId'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import debounce from 'lodash.debounce'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { formattedNum } from 'utils/format'
import { formatEther, formatUnits } from 'viem'
import { useAccount } from 'wagmi'

import VeJoeClaimPanel from './VeJoeClaimPanel'
import VeJoeUnstakePanel from './VeJoeUnstakePanel'

const VeJoeDetail = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { address: account } = useAccount()

  const chainId = useChainId()
  const joeTokenAddress = JOE_ADDRESS[chainId]
  const { data } = useTokenPriceUSD({
    tokens: [joeTokenAddress]
  })
  const joeUSD = data?.[0]

  const { totalStaked, totalSupply } = useVeJoeStaking()
  const totalStakedNum = totalStaked ? Number(formatEther(totalStaked)) : 0
  const totalStakedUSD = totalStakedNum * (joeUSD ?? 0)
  const fmtTotalStakedUSD = formattedNum(totalStakedUSD, {
    places: 2,
    usd: true
  })
  const totalSupplyNum = totalSupply ? Number(formatEther(totalSupply)) : 0
  const fmtTotalSupply = formattedNum(totalSupplyNum, { places: 2 })

  // get veJOE userBalance and rewards
  const {
    claim,
    isClaiming,
    isLoading,
    pending,
    refetch: refetchUserPositions,
    userBalance,
    userVeJoeBalance,
    veJoePerDayForUser
  } = useVeJoeUserPosition()

  const debouncedRefetchUserPositions = debounce(
    () => refetchUserPositions(),
    4000
  )

  const userBalanceBN = useMemo(() => {
    return userBalance ?? BigInt(0)
  }, [userBalance])

  const fmtUserBalance = useMemo(() => {
    return userBalance
      ? formattedNum(formatEther(userBalance), { places: 2 })
      : '0'
  }, [userBalance])

  const fmtVeJoePerDay = useMemo(() => {
    return veJoePerDayForUser
      ? `${Number(veJoePerDayForUser)} veJOE per day`
      : '0'
  }, [veJoePerDayForUser])

  const fmtVeJoeBalance = useMemo(() => {
    return userVeJoeBalance
      ? formattedNum(Number(formatUnits(userVeJoeBalance, 18)), { places: 2 })
      : '0'
  }, [userVeJoeBalance])

  const onClaim = () => {
    claim?.()
  }

  return (
    <Box pt={4} w="full" bg="joeLight.400" _dark={{ bg: 'joeDark.600' }}>
      <PageHelmet
        description={STAKE_HELMET_DESCRIPTION}
        title={STAKE_HELMET_TITLE}
        url={location.pathname}
      />
      <Flex
        maxW={{ '2xl': '1600px', base: '1400px' }}
        margin="0 auto"
        flexDir="column"
        align="flex-start"
        gap={2}
        px={4}
        pos="relative"
        h="140px"
      >
        <BackButton
          title="Back"
          ml={-3}
          minH="24px"
          onClick={() => navigate('/stake')}
        />

        <HStack>
          <Image boxSize={12} src={veJoeImage} />
          <Heading fontSize="5xl">veJOE</Heading>
        </HStack>
      </Flex>
      <Box maxWidth="100%" bg="bgPrimary">
        <Box px={4} maxW={{ '2xl': '1600px', base: '1400px' }} margin="0 auto">
          <Grid
            gap={{ base: 4, lg: 8 }}
            pb={24}
            templateColumns={{
              base: 'minmax(0, 1fr)',
              lg: 'minmax(0, 7fr) minmax(0, 5fr)'
            }}
          >
            <VStack spacing={8} id="vstack1">
              <VStack w="full" spacing={4}>
                <Flex align="center" justify="space-between" w="full" pt={10}>
                  <Heading size="md">
                    <Trans>Overview</Trans>
                  </Heading>
                </Flex>
                <SimpleGrid columns={{ lg: 2, sm: 1 }} gap={4} w="full">
                  <AnalyticsStat
                    title={t`Total Staked (USD)`}
                    value={fmtTotalStakedUSD}
                    change={0}
                  />
                  <AnalyticsStat
                    title={t`Total veJOE`}
                    value={`${fmtTotalSupply} veJOE`}
                    change={0}
                  />
                </SimpleGrid>
              </VStack>
              <Show below="md">
                <VStack w="full" spacing={4} id="vstack2">
                  <Tabs isFitted w="full" colorScheme="accent" index={1}>
                    <TabList>
                      <Tab data-cy="vejoe-stake-tab" isDisabled>
                        <Trans>Stake</Trans>
                      </Tab>
                      <Tab data-cy="vejoe-unstake-tab">
                        <Trans>Unstake</Trans>
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel></TabPanel>
                      <TabPanel>
                        <VeJoeUnstakePanel
                          userBalance={userBalanceBN}
                          onSuccess={() => {
                            debouncedRefetchUserPositions()
                          }}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                  {account ? (
                    <VeJoeClaimPanel
                      claim={onClaim}
                      isClaiming={isClaiming}
                      isLoading={isLoading}
                      pendingVeJoeBalance={pending}
                      userBalance={fmtUserBalance}
                      veJoeBalance={fmtVeJoeBalance}
                      veJoePerDay={fmtVeJoePerDay}
                    />
                  ) : null}
                </VStack>
              </Show>
              <VStack align="flex-start" spacing={4} w="full">
                <Heading fontSize="xl">
                  <Trans>Stake Information</Trans>
                </Heading>
                <UnorderedList pl={4}>
                  <ListItem>
                    <Trans>
                      Stake JOE into veJOE to increase your JOE rewards in Boost
                      Farms.
                    </Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>
                      When you claim veJOE you will automatically apply a Boost
                      APR.
                    </Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>
                      Rewards are distributed every few days, and you can
                      Harvest at any time.
                    </Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>
                      Boost APR will be additional yield earned on top of JOE
                      APR and Pool APR.
                    </Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>
                      The amount of veJOE you have will determine your share of
                      the boosted rewards. If you unstake any amount of JOE from
                      veJOE you will lose all of your accrued veJOE.
                    </Trans>
                  </ListItem>
                </UnorderedList>
              </VStack>
            </VStack>
            <Hide below="md">
              <VStack spacing={4} id="vstack2">
                <Tabs isFitted w="full" colorScheme="accent" index={1}>
                  <TabList pt={10}>
                    <Tab data-cy="vejoe-stake-tab" isDisabled>
                      <Trans>Stake</Trans>
                    </Tab>
                    <Tab data-cy="vejoe-unstake-tab">
                      <Trans>Unstake</Trans>
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel></TabPanel>
                    <TabPanel>
                      <VeJoeUnstakePanel
                        userBalance={userBalanceBN}
                        onSuccess={() => {
                          debouncedRefetchUserPositions()
                        }}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                {account ? (
                  <VeJoeClaimPanel
                    claim={onClaim}
                    isClaiming={isClaiming}
                    isLoading={isLoading}
                    pendingVeJoeBalance={pending}
                    userBalance={fmtUserBalance}
                    veJoeBalance={fmtVeJoeBalance}
                    veJoePerDay={fmtVeJoePerDay}
                  />
                ) : null}
              </VStack>
            </Hide>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default VeJoeDetail
