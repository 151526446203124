import { t } from '@lingui/macro'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import { UsePairUserBalanceData } from 'hooks/pool/v1/usePairUserBalance'
import React from 'react'

interface PoolDetailBalancesPanelV1Props {
  isLoadingUserBalance: boolean
  userBalanceData: UsePairUserBalanceData
}

const PoolDetailBalancesPanelV1 = ({
  isLoadingUserBalance,
  userBalanceData
}: PoolDetailBalancesPanelV1Props) => {
  const {
    pooledToken0,
    pooledToken1,
    token0: _token0,
    token1: _token1
  } = userBalanceData

  return (
    <CurrencyAmountsPanel
      title={t`Deposit Balance`}
      isLoading={isLoadingUserBalance}
      tokens={[_token0.address, _token1.address]}
      tokenAmounts={[pooledToken0, pooledToken1]}
      tokenSymbols={[_token0?.symbol, _token1?.symbol]}
    />
  )
}

export default PoolDetailBalancesPanelV1
