import { t } from '@lingui/macro'
import { VEJOE_STAKING_ADDRESS } from '@traderjoe-xyz/sdk'
import veJoeImage from 'assets/stake/veJoe.webp'
import { ZERO_ADDRESS } from 'constants/tokens'
import useVeJoeAnalytics from 'hooks/stake/useVeJoeAnalytics'
import useVeJoeStaking, {
  useVeJoeUserPosition
} from 'hooks/stake/useVeJoeStaking'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { formatEther } from 'viem'

import { CurrencyTab } from '.'
import { JoeUsdProps } from './SJoeCard'
import StakeCard from './StakeCard'

const VeJoeCard = ({ currencyTab, joeUsd }: JoeUsdProps) => {
  const { userBalance } = useVeJoeUserPosition()
  const { totalStaked, totalSupply } = useVeJoeStaking()
  const { medianBalance } = useVeJoeAnalytics()

  // check if not available on selected chain
  const chainId = useChainId()
  const isNotAvailable = VEJOE_STAKING_ADDRESS[chainId] === ZERO_ADDRESS

  // user balance
  const balanceNum = userBalance ? Number(formatEther(userBalance)) : 0
  const fmtBalance = formattedNum(balanceNum, { places: 2 })
  const fmtBalanceUsd = formattedNum(balanceNum * (joeUsd ?? 0), {
    places: 2,
    usd: true
  })

  // total staked
  const totalStakedNum = totalStaked ? Number(formatEther(totalStaked)) : 0
  const totalStakedUSD = totalStakedNum * (joeUsd ?? 0)
  const fmtTotalStaked = formattedNum(totalStakedNum, { places: 2 })
  const fmtTotalStakedUSD = formattedNum(totalStakedUSD, {
    places: 2,
    usd: true
  })

  // total supply
  const fmtTotalSupply = totalSupply
    ? `${formattedNum(formatEther(totalSupply), { places: 2 })} veJOE`
    : ''

  // median balance
  const fmtMedianBalance = medianBalance
    ? `${formattedNum(medianBalance, { places: 2 })} veJOE`
    : ''

  // link
  const link = `${getChainSlug(chainId)}/stake/vejoe`

  return isNotAvailable ? null : (
    <StakeCard
      image={veJoeImage}
      linkUrl={link}
      title="veJOE"
      tag={t`Withdraw Only`}
      label1={t`Total Staked (${currencyTab})`}
      text1={
        currencyTab == CurrencyTab.JOE ? fmtTotalStaked : fmtTotalStakedUSD
      }
      label2={t`Your Stake (${currencyTab})`}
      text2={currencyTab == CurrencyTab.JOE ? fmtBalance : fmtBalanceUsd}
      label3={t`Total Supply`}
      text3={fmtTotalSupply}
      label4={t`Median Balance`}
      text4={fmtMedianBalance}
    />
  )
}

export default VeJoeCard
