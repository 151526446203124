import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import { bannerText } from '.'

interface UKDisclaimerModalProps {
  isOpen: boolean
  onClose: () => void
}

const UKDisclaimerModal = ({ isOpen, onClose }: UKDisclaimerModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Disclaimer for UK residents</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody data-cy="uk-disclaimer-modal">{bannerText}</ModalBody>

        <ModalFooter>
          <Button w="full" onClick={onClose}>
            <Trans>Dismiss</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UKDisclaimerModal
