import { t } from '@lingui/macro'
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency } from '@traderjoe-xyz/sdk-core'
import {
  jsonAbis,
  LB_ROUTER_V21_ADDRESS,
  LB_ROUTER_V22_ADDRESS
} from '@traderjoe-xyz/sdk-v2'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useEffect, useMemo } from 'react'
import { TradeBestPath } from 'types/trade'
import { extractErrorMessageFromViemError } from 'utils/error'
import { formattedNum } from 'utils/format'
import { getConfigWithGasLimitIncreased } from 'utils/gas'
import { zeroAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

import useSwapCallArguments from './useSwapCallArguments'

interface UseSwapCallArgumentsProps {
  allowedSlippage: number
  enabled: boolean
  isExactIn: boolean
  onSwapSuccess: () => void
  currencyIn?: Currency
  currencyOut?: Currency
  trade?: TradeBestPath
}

export const useSwap = ({
  allowedSlippage,
  currencyIn,
  currencyOut,
  enabled,
  isExactIn,
  onSwapSuccess,
  trade
}: UseSwapCallArgumentsProps) => {
  const { LBRouterV21ABI } = jsonAbis
  const chainId = useChainId()
  const { chain: walletChain } = useAccount()
  const { nativeCurrency } = useActiveChain()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()
  const { defaultParameters, feeOnTransferParameters } = useSwapCallArguments({
    allowedSlippage,
    currencyIn,
    currencyOut,
    isExactIn,
    trade
  })

  const routerAddress =
    LB_ROUTER_V22_ADDRESS[chainId] !== zeroAddress
      ? LB_ROUTER_V22_ADDRESS[chainId]
      : LB_ROUTER_V21_ADDRESS[chainId]

  const {
    data: defaultConfig,
    error: defaultPrepareWriteError,
    isError: isDefaultConfigError
  } = useSimulateContract({
    abi: LBRouterV21ABI,
    address: routerAddress,
    args: defaultParameters?.args,
    functionName: defaultParameters?.methodName,
    query: {
      enabled: !!defaultParameters && enabled && walletChain?.id === chainId,
      gcTime: 0
    },
    value: defaultParameters ? BigInt(defaultParameters.value) : BigInt(0)
  })

  const { data: feeOnTransferConfig, error: feeOnTransferError } =
    useSimulateContract({
      abi: LBRouterV21ABI,
      address: routerAddress,
      args: feeOnTransferParameters?.args,
      functionName: feeOnTransferParameters?.methodName,
      query: {
        enabled:
          !!feeOnTransferParameters && enabled && walletChain?.id === chainId,
        gcTime: 0
      },
      value: feeOnTransferParameters
        ? BigInt(feeOnTransferParameters.value)
        : BigInt(0)
    })

  const transactionSummary = useMemo(() => {
    if (!trade) return ''
    const inputSymbol = currencyIn?.isNative
      ? nativeCurrency?.symbol
      : currencyIn?.symbol
    const outputSymbol = currencyOut?.isNative
      ? nativeCurrency?.symbol
      : currencyOut?.symbol
    const inputAmount = trade.amountIn.formatted
    const outputAmount = trade.amountOut.formatted
    return t`Swap ${formattedNum(
      inputAmount
    )} ${inputSymbol} for ${formattedNum(outputAmount)} ${outputSymbol}`
  }, [trade, nativeCurrency, currencyIn, currencyOut])

  const config = getConfigWithGasLimitIncreased({
    config: isDefaultConfigError ? feeOnTransferConfig : defaultConfig,
    percentageIncrease: 10
  })

  const {
    data: hash,
    isPending,
    reset,
    writeContractAsync
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        addRecentTransaction({ description: transactionSummary, hash })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  //  force send the transaction when the simulation fails still works
  // https://github.com/wevm/wagmi/discussions/2661
  const writeArgsFallback = defaultParameters
    ? {
        abi: LBRouterV21ABI,
        address: routerAddress,
        args: defaultParameters.args,
        functionName: defaultParameters.methodName,
        value: defaultParameters ? BigInt(defaultParameters.value) : BigInt(0)
      }
    : undefined

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSwapSuccess
    })

  useEffect(() => {
    reset()
  }, [chainId, reset])

  const error = useMemo(() => {
    if (!defaultPrepareWriteError || !feeOnTransferError) {
      return undefined
    }

    const defaultErrorSummary = defaultPrepareWriteError
      ? extractErrorMessageFromViemError(defaultPrepareWriteError.message)
      : undefined
    const feeOnTransferErrorSummary = feeOnTransferError
      ? extractErrorMessageFromViemError(feeOnTransferError.message)
      : undefined

    const isErrorDueToSlippageTooLow =
      defaultPrepareWriteError?.message?.includes(
        'LBRouter__InsufficientAmountOut'
      ) ||
      feeOnTransferError?.message?.includes('LBRouter__InsufficientAmountOut')

    const message =
      defaultPrepareWriteError?.message || feeOnTransferError?.message
    const summary =
      defaultErrorSummary ||
      feeOnTransferErrorSummary ||
      t`Error: there was a problem preparing the transaction. Please try again.`

    if (!message) {
      return undefined
    }

    return {
      message,
      summary: isErrorDueToSlippageTooLow
        ? t`Error: the slippage is too low for this trade.`
        : summary
    }
  }, [defaultPrepareWriteError, feeOnTransferError])

  return {
    error,
    isSwapping: isPending || isWaitingForTransaction,
    receipt,
    resetSwap: reset,
    swapAsync: config
      ? () => writeContractAsync(config.request)
      : writeArgsFallback
        ? () => writeContractAsync(writeArgsFallback)
        : undefined
  }
}
