import { RawAxiosRequestConfig } from 'axios'
import { dexbarnClient, dexbarnTestnetClient } from 'constants/dexbarn'
import { useCallback } from 'react'
import { avalancheFuji } from 'wagmi/chains'

import useActiveChain from './useActiveChain'

export const useDexbarnClient = () => {
  const chain = useActiveChain()
  return chain.testnet ? dexbarnTestnetClient : dexbarnClient
}

export const useDexbarnGet = <T = any, D = any>(
  url: string,
  config?: RawAxiosRequestConfig<D>
) => {
  const client = useDexbarnClient()
  const chain = useActiveChain()

  return useCallback(
    async (overridesConfig?: RawAxiosRequestConfig<D>) => {
      if (chain.testnet && chain.id !== avalancheFuji.id) {
        // dexbarn only supports fuji testnets
        return Promise.reject('[Dexbarn] Unsupported chain')
      }

      const { data } = await client.get<T>(url, overridesConfig ?? config)
      return data
    },
    [client, url, config, chain]
  )
}

export const useDexbarnPost = <T = any, D = any>(url: string, body?: D) => {
  const client = useDexbarnClient()
  return useCallback(async () => {
    const { data } = await client.post<T>(url, body)
    return data
  }, [client, url, body])
}
